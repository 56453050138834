export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  ISO8601Date: string
  ISO8601DateTime: string
  SafeInt: number
}

export enum AccessLevelEnum {
  BASIC = 'BASIC',
  FULL = 'FULL',
  NO_ACCESS = 'NO_ACCESS',
  VIEW = 'VIEW',
}

export type Account = {
  accountNumberType: Maybe<VendorAccountNumberTypeEnum>
  accountType: AccountTypeEnum
  accountingAccount: Maybe<AccountingAccount>
  bankAddress: Maybe<Address>
  bankConnection: Maybe<BankLink>
  bankName: Maybe<Scalars['String']>
  beneficiaryFirstName: Maybe<Scalars['String']>
  beneficiaryLastName: Maybe<Scalars['String']>
  beneficiaryName: Maybe<Scalars['String']>
  bicSwift: Maybe<Scalars['String']>
  /** If the account has a recent error of 'R29 Corporate Customer Advises Not Authorized' */
  chargesNotAuthorized: Scalars['Boolean']
  currency: Maybe<Scalars['String']>
  destinationCountry: Scalars['String']
  /** @deprecated Use bankConnection instead */
  finicityConnection: Maybe<FinicityLink>
  hidden: Scalars['Boolean']
  id: Scalars['ID']
  lockbox: Maybe<Scalars['Boolean']>
  mask: Maybe<Scalars['String']>
  microdepositsPaymentMethod: Maybe<MicrodepositsPaymentMethodEnum>
  microdepositsStatus: Maybe<MicrodepositsStatusEnum>
  missingRequiredDetails: Scalars['Boolean']
  name: Maybe<Scalars['String']>
  numOutstandingInvoices: Scalars['Int']
  /** Account ability to process future ACH payments through the system */
  paymentsProcessingAvailable: Maybe<Scalars['Boolean']>
  /** @deprecated Use bankConnection instead */
  plaidItem: Maybe<PlaidLink>
  preferredPayingAccount: Scalars['Boolean']
  preferredReceivingAccount: Scalars['Boolean']
  /** If the account has a successful charge */
  successChargesExists: Scalars['Boolean']
  suggestedAccountingAccount: Maybe<AccountingAccount>
  supportedPaymentTypes: Array<PaymentMethodEnum>
  transactable: Scalars['Boolean']
  verificationSource: Maybe<VerificationSourceEnum>
  wireFields: Maybe<Array<InternationalWireFieldsDataFieldValueType>>
}

export enum AccountConnectionStatusEnum {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  NOT_SET = 'NOT_SET',
}

export type AccountEstimatedDebitDate = {
  date: Scalars['ISO8601Date']
}

export type AccountInputObject = {
  accountNumber: Scalars['String']
  archived?: InputMaybe<Scalars['Boolean']>
  bankAddress?: InputMaybe<AddressInputObject>
  bankName?: InputMaybe<Scalars['String']>
  beneficiaryFirstName?: InputMaybe<Scalars['String']>
  beneficiaryLastName?: InputMaybe<Scalars['String']>
  beneficiaryName?: InputMaybe<Scalars['String']>
  bicSwift?: InputMaybe<Scalars['String']>
  cnaps?: InputMaybe<Scalars['String']>
  currency?: InputMaybe<Scalars['String']>
  destinationCountry: Scalars['String']
  routingNumber?: InputMaybe<Scalars['String']>
  wireFields?: InputMaybe<Array<InternationalWireFieldsDataFieldInputObject>>
  wireRoutingNumber?: InputMaybe<Scalars['String']>
}

export enum AccountTypeEnum {
  PAYING = 'PAYING',
  PAYING_AND_RECEIVING = 'PAYING_AND_RECEIVING',
  RECEIVING = 'RECEIVING',
}

export type AccountantDashboard = {
  /** @deprecated Use firmDisplayName and firmCompanyName */
  accountingFirmName: Scalars['String']
  clients: Array<BusinessClient>
  firmCompanyName: Scalars['String']
  firmDisplayName: Scalars['String']
  teamMembers: Array<TeamMember>
}

export type AccountingAccount = {
  accountSubType: Scalars['String']
  accountType: Scalars['String']
  active: Scalars['Boolean']
  children: Array<AccountingAccount>
  classification: Maybe<Scalars['String']>
  currency: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  number: Maybe<Scalars['String']>
}

export enum AccountingBillingModelTypeEnum {
  AGGREGATED = 'AGGREGATED',
  HYBRID = 'HYBRID',
  INDIVIDUAL = 'INDIVIDUAL',
}

export type AccountingCategory = {
  categories: Array<AccountingDictionary>
  categoryType: AccountingCategoryTypeEnum
  label: Scalars['String']
  showOnTxn: Scalars['Boolean']
  showOnTxnLine: Scalars['Boolean']
}

export type AccountingCategoryType = {
  id: Scalars['ID']
}

export enum AccountingCategoryTypeEnum {
  CLASS = 'CLASS',
  DEPARTMENT = 'DEPARTMENT',
  LOCATION = 'LOCATION',
  TRACKING_CATEGORY_ONE = 'TRACKING_CATEGORY_ONE',
  TRACKING_CATEGORY_TWO = 'TRACKING_CATEGORY_TWO',
}

export enum AccountingConnectionStatusEnum {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  NOT_SET = 'NOT_SET',
}

export type AccountingCounters = {
  excludedFromSync: CountersBySyncObject
  recentlySynced: CountersBySyncObject
  syncErrors: CountersBySyncObject
  waitingForSync: CountersBySyncObject
}

export type AccountingDictionary = {
  children: Array<AccountingDictionary>
  id: Scalars['ID']
  name: Scalars['String']
}

export enum AccountingPermissionsEnum {
  ACCOUNTING_LINK = 'ACCOUNTING_LINK',
  ACCOUNTING_SYNC = 'ACCOUNTING_SYNC',
  ACCOUNTING_SYNC_STATE = 'ACCOUNTING_SYNC_STATE',
  ACCOUNTING_UNIMPORTED_PURCHASE_ORDERS = 'ACCOUNTING_UNIMPORTED_PURCHASE_ORDERS',
  ACCOUNTING_UNLINK = 'ACCOUNTING_UNLINK',
  ACCOUNTING_UNPAID_BILLS = 'ACCOUNTING_UNPAID_BILLS',
  ACCOUNTING_UPDATE_PREFERENCES = 'ACCOUNTING_UPDATE_PREFERENCES',
}

export enum AccountingQuickBooksItemTypeEnum {
  CATEGORY = 'CATEGORY',
  INVENTORY = 'INVENTORY',
  NONINVENTORY = 'NONINVENTORY',
  SERVICE = 'SERVICE',
}

export type AccountingState = {
  accountingProvider: Maybe<ProviderEnum>
  accounts: Array<AccountingAccount>
  bankAccounts: Array<AccountingAccount>
  categoryClasses: Maybe<AccountingCategory>
  categoryDepartments: Maybe<AccountingCategory>
  categoryLocations: Maybe<AccountingCategory>
  categoryTrackingCategoryOne: Maybe<AccountingCategory>
  categoryTrackingCategoryTwo: Maybe<AccountingCategory>
  cogsAccounts: Array<AccountingAccount>
  creditCardAccounts: Array<AccountingAccount>
  customersAndProjects: Maybe<AccountingTrackableEntityType>
  expenseAccounts: Array<AccountingAccount>
  filteredAccounts: Array<AccountingAccount>
  liabilityAccounts: Array<AccountingAccount>
}

export type AccountingSummary = {
  status: AccountingConnectionStatusEnum
  syncErrorCount: Scalars['Int']
}

export type AccountingTrackableEntityType = {
  showOnTxnLine: Scalars['Boolean']
  values: Array<AccountingDictionary>
}

export type AccountsReceivableNotificationSettings = {
  paymentCanceledEmail: Scalars['Boolean']
  paymentLandedInAccountEmail: Scalars['Boolean']
  paymentOnItsWayEmail: Scalars['Boolean']
  paymentScheduledEmail: Scalars['Boolean']
}

export type AccountsReceivableNotificationSettingsInputObject = {
  paymentCanceledEmail?: InputMaybe<Scalars['Boolean']>
  paymentLandedInAccountEmail?: InputMaybe<Scalars['Boolean']>
  paymentOnItsWayEmail?: InputMaybe<Scalars['Boolean']>
  paymentScheduledEmail?: InputMaybe<Scalars['Boolean']>
}

export type AccountsSummary = {
  status: AccountConnectionStatusEnum
  unmappedCount: Scalars['Int']
}

export type Action = {
  allowed: Scalars['Boolean']
  errorReasons: Array<Scalars['String']>
}

export type AdditionalExpense = {
  allocationModel: Maybe<AdditionalExpenseAllocationModelEnum>
  cost: Maybe<Money>
  createdAt: Scalars['ISO8601DateTime']
  expenseType: Maybe<AdditionalExpenseTypeEnum>
  id: Scalars['ID']
  invoiceLineItem: Maybe<InvoiceLineItem>
  name: Maybe<Scalars['String']>
  quantity: Maybe<Scalars['Float']>
  totalCost: Maybe<Money>
}

export enum AdditionalExpenseAllocationModelEnum {
  QUANTITY = 'QUANTITY',
  VALUE = 'VALUE',
}

export type AdditionalExpenseInputObject = {
  allocationModel?: InputMaybe<AdditionalExpenseAllocationModelEnum>
  costCents?: InputMaybe<Scalars['Float']>
  expenseType?: InputMaybe<AdditionalExpenseTypeEnum>
  name?: InputMaybe<Scalars['String']>
  quantity?: InputMaybe<Scalars['Float']>
}

export enum AdditionalExpenseTypeEnum {
  INVENTORY = 'INVENTORY',
  OTHER = 'OTHER',
  PRODUCTION_COST = 'PRODUCTION_COST',
  SHIPPING = 'SHIPPING',
  SURCHARGE = 'SURCHARGE',
  TARIFF = 'TARIFF',
}

export type Address = {
  additionalAddressLines: Maybe<Array<Scalars['String']>>
  addressLine: Maybe<Scalars['String']>
  city: Maybe<Scalars['String']>
  country: Maybe<Scalars['String']>
  id: Scalars['ID']
  oneLineFormatted: Scalars['String']
  postalCode: Maybe<Scalars['String']>
  secondaryAddressLine: Maybe<Scalars['String']>
  state: Maybe<Scalars['String']>
  unitNumber: Maybe<Scalars['String']>
}

export type AddressInputObject = {
  additionalAddressLines?: InputMaybe<Array<Scalars['String']>>
  addressLine?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  postalCode?: InputMaybe<Scalars['String']>
  secondaryAddressLine?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  unitNumber?: InputMaybe<Scalars['String']>
}

export type AddressOcr = {
  addressLine: Maybe<Scalars['String']>
  city: Maybe<Scalars['String']>
  country: Maybe<Scalars['String']>
  postalCode: Maybe<Scalars['String']>
  rawAddress: Maybe<Scalars['String']>
  state: Maybe<Scalars['String']>
}

export type AddressValidation = {
  /** Returns false if the address is a PO box, true otherwise. Makes network request to Smarty */
  nonPoBox: Scalars['Boolean']
  /** Returns true if the address is completely verified, false otherwise. Makes network request to Smarty */
  valid: Scalars['Boolean']
}

export type Agreement = {
  byteSize: Scalars['Int']
  contentType: Scalars['String']
  filename: Scalars['String']
  id: Scalars['ID']
  type: Maybe<Scalars['String']>
  url: Scalars['String']
}

export type Allocation = {
  actions: TransferActions
  amount: Maybe<Money>
  exchangeFeeAmount: Maybe<Money>
  exchangeRate: Maybe<Scalars['String']>
  financingOptions: FinancingOptions
  id: Scalars['ID']
  includedInConsolidatedPayment: Scalars['Boolean']
  invoice: Maybe<Invoice>
  isCashAdvance: Scalars['Boolean']
  isExtendedPaymentTerm: Scalars['Boolean']
  /** Returns true when the allocation matches applied filter. Eg. when splitted invoice has 2 allocations: PENDING and SCHEDULED, and filter by PENDING status applied, PENDING allocation will have the field value 'true' and SCHEDULED allocation - 'false'. */
  matchesFilter: Scalars['Boolean']
  payment: Maybe<Payment>
  /** Error code for Inactive Payment */
  paymentErrorCode: Maybe<Scalars['String']>
  /** Error message for Inactive Payment */
  paymentErrorMessage: Maybe<Scalars['String']>
  reconciliationItems: Array<ReconciliationItem>
  status: InvoiceStatusEnum
  statuses: Array<InvoiceStatusEnum>
  totalUsdAmount: Maybe<Money>
  usdAmount: Maybe<Money>
}

export type AmortizedLoan = {
  /** Annual percentage rate (APR) for the loan */
  apr: Scalars['Float']
  /** The current installment (last failed, next scheduled or the last one) */
  currentInstallment: AmortizedLoanInstallment
  /** Planned or actual installments schedule */
  installments: Array<AmortizedLoanInstallment>
  /** Sum of interest accrued */
  interest: Money
  /** Number of days in each period */
  periodDays: Scalars['Int']
  /** Number of periods ending with installment payment */
  periods: Scalars['Int']
  /** Sum of principal repayments */
  principal: Money
  /** Sum of all installment payments */
  total: Money
  /** Sum of days in all periods */
  totalDays: Scalars['Int']
}

export type AmortizedLoanEarlyRepaymentPreview = {
  /** Current amortized loan schedule */
  current: AmortizedLoan
  /** Expected amortized loan schedule */
  expected: AmortizedLoan
}

export type AmortizedLoanInstallment = {
  /** Installment payment date */
  date: Scalars['ISO8601Date']
  /**
   * Full description of installment (eg. "Installment 1", "Installment 2", ..., "Early repayment")
   *
   */
  description: Scalars['String']
  /** Marks installment which is an early repayment */
  earlyRepayment: Scalars['Boolean']
  /** Interest included into installment amount */
  interest: Money
  /** Number of the installment */
  number: Scalars['Int']
  /** Principal repayment included in installment amount */
  principal: Money
  reconciliationItem: Maybe<ReconciliationItem>
  /** Status of the installment */
  status: AmortizedLoanInstallmentStatusEnum
  /** Installment amount */
  total: Money
}

export enum AmortizedLoanInstallmentStatusEnum {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SCHEDULED = 'SCHEDULED',
}

export enum ApAgingBucketEnum {
  BUCKET_1_TO_30 = 'BUCKET_1_TO_30',
  BUCKET_31_TO_60 = 'BUCKET_31_TO_60',
  BUCKET_61_TO_90 = 'BUCKET_61_TO_90',
  BUCKET_91_PLUS = 'BUCKET_91_PLUS',
  BUCKET_CURRENT = 'BUCKET_CURRENT',
}

export type ApAgingBucketSummary = {
  dayRange: ApAgingBucketEnum
  endDate: Maybe<Scalars['ISO8601Date']>
  otherVendors: Array<LocalBusiness>
  otherVendorsAmount: Maybe<Money>
  startDate: Maybe<Scalars['ISO8601Date']>
  totalAmount: Money
  vendorAmounts: Array<VendorAmount>
}

export type ApplicableVendorCredit = {
  applied: Scalars['Boolean']
  appliedAmount: Money
  autoFillAmount: Money
  availableAmount: Money
  canApply: Scalars['Boolean']
  invoice: Invoice
  reason: Maybe<Scalars['String']>
  vendorCredit: VendorCredit
}

export enum ApplicationThemeEnum {
  ESCALON = 'ESCALON',
  SETTLE = 'SETTLE',
}

export type Approval = {
  requestedAt: Scalars['ISO8601DateTime']
  requestingUser: Maybe<ApproverV2>
  ruleId: Maybe<Scalars['ID']>
  status: Maybe<ApprovalStatusEnumV2>
  steps: Maybe<Array<ApprovalStep>>
}

export type ApprovalRequest = {
  approvingUser: ApproverV2
  autoApproved: Scalars['Boolean']
  decisionMadeAt: Maybe<Scalars['ISO8601DateTime']>
  note: Maybe<Scalars['String']>
  status: ApprovalRequestStatusEnum
}

export enum ApprovalRequestStatusEnum {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED',
  STAGED = 'STAGED',
}

export enum ApprovalStatusEnum {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED',
  STAGED = 'STAGED',
}

export enum ApprovalStatusEnumV2 {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED',
}

export type ApprovalStep = {
  approvalRequests: Maybe<Array<ApprovalRequest>>
  approvalType: RuleActionConditionEnum
  approvedOrRejectedOn: Maybe<Scalars['ISO8601DateTime']>
  status: ApprovalStepStatusEnum
  stepNumber: Scalars['Int']
}

export enum ApprovalStepStatusEnum {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED',
  STAGED = 'STAGED',
}

export type Approver = {
  id: Scalars['ID']
  name: Scalars['String']
}

export type ApproverV2 = {
  id: Scalars['ID']
  name: Scalars['String']
}

export enum AssemblyTypeEnum {
  KIT_TO_ORDER = 'KIT_TO_ORDER',
  KIT_TO_STOCK = 'KIT_TO_STOCK',
  PRODUCTION = 'PRODUCTION',
}

export type AttachedReport = {
  byteSize: Scalars['Int']
  contentType: Scalars['String']
  filename: Scalars['String']
  id: Scalars['ID']
  url: Scalars['String']
}

export type Attachment = {
  byteSize: Scalars['Int']
  category: Maybe<AttachmentCategoryEnum>
  contentType: Scalars['String']
  conversionPending: Scalars['Boolean']
  createdAt: Scalars['ISO8601DateTime']
  createdBySettle: Scalars['Boolean']
  description: Maybe<Scalars['String']>
  filename: Scalars['String']
  id: Scalars['ID']
  name: Maybe<Scalars['String']>
  originalByteSize: Scalars['Int']
  originalContentType: Scalars['String']
  originalFilename: Scalars['String']
  originalType: Maybe<Scalars['String']>
  originalUrl: Scalars['String']
  preview: Maybe<AttachmentPreview>
  previewStatus: AttachmentPreviewStatusEnum
  target: Maybe<AttachmentTargetType>
  /** @deprecated Use target field instead */
  targetId: Maybe<Scalars['Int']>
  /** @deprecated Use target field instead */
  targetType: Maybe<Scalars['String']>
  type: Maybe<Scalars['String']>
  uploadedBy: Maybe<TeamMember>
  url: Scalars['String']
  verified: Scalars['Boolean']
}

export enum AttachmentCategoryEnum {
  CREDIT_LIMIT_REQUEST = 'CREDIT_LIMIT_REQUEST',
  INVENTORY_TRANSFER = 'INVENTORY_TRANSFER',
  INVOICE = 'INVOICE',
  LEGAL_AGREEMENT = 'LEGAL_AGREEMENT',
  MASKED_W8_OR_W9 = 'MASKED_W8_OR_W9',
  OTHER = 'OTHER',
  PAYMENT_ORDER = 'PAYMENT_ORDER',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  QUOTE = 'QUOTE',
  RECEIPT = 'RECEIPT',
  RECEIVABLE = 'RECEIVABLE',
  SETTLE_INBOX = 'SETTLE_INBOX',
  SPECIFICATION = 'SPECIFICATION',
  VENDOR = 'VENDOR',
  VENDOR_CREDIT = 'VENDOR_CREDIT',
  W8_OR_W9 = 'W8_OR_W9',
  WORK_ORDER = 'WORK_ORDER',
}

export type AttachmentPreview = {
  x1: Maybe<Scalars['String']>
  x2: Maybe<Scalars['String']>
  x3: Maybe<Scalars['String']>
}

export enum AttachmentPreviewStatusEnum {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
}

export type AttachmentTargetType =
  | Business
  | InventoryTransfer
  | Invoice
  | LocalBusiness
  | PurchaseOrder
  | Receipt
  | Receivable
  | WorkOrder

export type AuditTrailEvents = Comment | TimelineEventV2

export enum AuthProviderTypeEnum {
  GOOGLE = 'GOOGLE',
}

export type AuthResponse = {
  mfaToken: Maybe<Scalars['ID']>
  profile: Maybe<Profile>
  token: Maybe<Scalars['ID']>
  totpSetupInfo: Maybe<TotpSetupInfo>
}

export type AuthorizationPaginated = {
  data: Array<MarketingVirtualCardAuthorization>
  pagination: Pagination
}

export enum BalancePaymentStatusEnum {
  DELINQUENT = 'DELINQUENT',
  PAID = 'PAID',
  WAITING = 'WAITING',
}

export type BalanceSheet = {
  availableCashAdvanceCents: Scalars['Int']
  availableCreditCents: Scalars['Int']
  cashAdvanceLiabilityCents: Scalars['Int']
  cashAdvanceSubLimitCents: Scalars['Int']
  creditLimitCents: Scalars['Int']
  eptLength: Scalars['Int']
  eptLiabilityCents: Scalars['Int']
  eptRate: Scalars['Float']
  id: Scalars['ID']
  maximumExtensionDays: Scalars['Int']
  minimumEptCents: Scalars['Int']
  numEptMax: Scalars['Int']
}

export enum BankAccountsPermissionsEnum {
  ACCOUNT = 'ACCOUNT',
  ACCOUNTS = 'ACCOUNTS',
  LINK_FINICITY = 'LINK_FINICITY',
  LINK_PLAID = 'LINK_PLAID',
  UPDATE_ACCOUNT = 'UPDATE_ACCOUNT',
}

/** Common fields of bank account links */
export type BankLink = {
  deleted: Scalars['Boolean']
  fixAvailable: Maybe<BankLinkFixEnum>
  id: Scalars['ID']
  name: Scalars['String']
}

export enum BankLinkFixEnum {
  FIX_FLOW = 'FIX_FLOW',
  RELINK = 'RELINK',
}

export type Banner = {
  button: Maybe<BannerButton>
  category: Maybe<BannerCategoryEnum>
  id: Scalars['String']
  message: Scalars['String']
  severity: BannerSeverityEnum
}

export enum BannerActionEnum {
  ADD_BENEFICIAL_OWNER = 'ADD_BENEFICIAL_OWNER',
  CHANGE_BILLING_METHOD = 'CHANGE_BILLING_METHOD',
  CHOOSE_PLAN = 'CHOOSE_PLAN',
  CONFIRM_SHOPIFY_PLAN = 'CONFIRM_SHOPIFY_PLAN',
  DASHBOARD_TO_DO_LIST = 'DASHBOARD_TO_DO_LIST',
  EDIT_BANK_ACCOUNTS = 'EDIT_BANK_ACCOUNTS',
  EDIT_INTEGRATIONS = 'EDIT_INTEGRATIONS',
  EDIT_INTERNATIONAL_RECEIVING_BANK_ACCOUNT = 'EDIT_INTERNATIONAL_RECEIVING_BANK_ACCOUNT',
  EDIT_SYNC_PREFERENCES = 'EDIT_SYNC_PREFERENCES',
  FINICITY_FIX_FLOW = 'FINICITY_FIX_FLOW',
  HELP_CENTER = 'HELP_CENTER',
  INVITE_CUSTOMERS = 'INVITE_CUSTOMERS',
  LINK_UNVERIFIED_BANK_ACCOUNT = 'LINK_UNVERIFIED_BANK_ACCOUNT',
  PLAID_FIX_FLOW = 'PLAID_FIX_FLOW',
  PROVIDE_BUSINESS_INFO = 'PROVIDE_BUSINESS_INFO',
  UPLOAD_W8_W9 = 'UPLOAD_W8_W9',
  VERIFY_BANK_ACCOUNT = 'VERIFY_BANK_ACCOUNT',
}

export type BannerActionParams = {
  accountId: Maybe<Scalars['Int']>
  connectionId: Maybe<Scalars['Int']>
  shopifyPlanConfirmationUrl: Maybe<Scalars['String']>
}

export type BannerButton = {
  action: Maybe<BannerActionEnum>
  actionParams: Maybe<BannerActionParams>
  text: Scalars['String']
}

export enum BannerCategoryEnum {
  ACCOUNTING_SOFTWARE = 'ACCOUNTING_SOFTWARE',
  BANK_ACCOUNTS = 'BANK_ACCOUNTS',
  BANK_ACCOUNTS_INTEGRATION = 'BANK_ACCOUNTS_INTEGRATION',
  BILLING = 'BILLING',
  MARKETNG = 'MARKETNG',
  ONBOARDING = 'ONBOARDING',
  RISK = 'RISK',
}

export enum BannerSeverityEnum {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING',
}

export type Banners = {
  banners: Array<Banner>
}

export type BaseExternalPayableLineItemInputObject = {
  accountingAccountId?: InputMaybe<Scalars['ID']>
  accountingCategoryClassId?: InputMaybe<Scalars['ID']>
  accountingCategoryDepartmentId?: InputMaybe<Scalars['ID']>
  accountingCategoryLocationId?: InputMaybe<Scalars['ID']>
  accountingCategoryOneId?: InputMaybe<Scalars['ID']>
  accountingCategoryTwoId?: InputMaybe<Scalars['ID']>
  catalogItemId?: InputMaybe<Scalars['ID']>
  quickbooksCategoryId?: InputMaybe<Scalars['ID']>
  quickbooksClassId?: InputMaybe<Scalars['ID']>
  trackingCategoryOneId?: InputMaybe<Scalars['ID']>
  trackingCategoryTwoId?: InputMaybe<Scalars['ID']>
  xeroCategoryId?: InputMaybe<Scalars['ID']>
}

export type BeneficialOwner = {
  createdAt: Scalars['ISO8601Date']
  createdBy: Scalars['String']
  fullName: Scalars['String']
  id: Scalars['ID']
  significantControl: Scalars['Boolean']
  status: BeneficialOwnerStatusEnum
}

export type BeneficialOwnerIndividualInputType = {
  dataFields: DataFieldsInput
  significantControl?: InputMaybe<Scalars['Boolean']>
}

export enum BeneficialOwnerStatusEnum {
  APPROVED = 'APPROVED',
  CREATED = 'CREATED',
  DECLINED = 'DECLINED',
  FLAGGED = 'FLAGGED',
  IN_PROGRESS = 'IN_PROGRESS',
  REMOVED = 'REMOVED',
}

/** Autogenerated return type of BeneficialOwnersApprove. */
export type BeneficialOwnersApprovePayload = {
  success: Scalars['Boolean']
}

export type BeneficialOwnersDataFields = {
  communication: Maybe<BeneficialOwnersSectionFields>
  countrySpecific: Maybe<BeneficialOwnersSectionFields>
  driverLicence: Maybe<BeneficialOwnersSectionFields>
  location: Maybe<BeneficialOwnersSectionFields>
  nationalIds: Maybe<BeneficialOwnersSectionFields>
  passport: Maybe<BeneficialOwnersSectionFields>
  personInfo: Maybe<BeneficialOwnersSectionFields>
}

/** Autogenerated return type of BeneficialOwnersDecline. */
export type BeneficialOwnersDeclinePayload = {
  success: Scalars['Boolean']
}

export type BeneficialOwnersFieldDescription = {
  description: Maybe<Scalars['String']>
  label: Maybe<Scalars['String']>
  nestedIn: Maybe<Scalars['String']>
  propertyKey: Scalars['String']
  type: Scalars['String']
}

export type BeneficialOwnersSectionFields = {
  fields: Maybe<Array<BeneficialOwnersFieldDescription>>
  required: Maybe<Array<Scalars['String']>>
}

/** Autogenerated return type of BeneficialOwnersStartReview. */
export type BeneficialOwnersStartReviewPayload = {
  success: Scalars['Boolean']
}

export type BillingCouponType = {
  active: Scalars['Boolean']
  amountOff: Maybe<Money>
  description: Scalars['String']
  displayName: Scalars['String']
  id: Scalars['ID']
  termsLink: Scalars['String']
}

export type BillingDiscountType = {
  activatedAt: Scalars['ISO8601DateTime']
  active: Scalars['Boolean']
  coupon: BillingCouponType
  expiresAt: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  promotionCode: Maybe<BillingPromotionCodeType>
}

export type BillingDowngradeDetailsType = {
  description: Scalars['String']
  name: Scalars['String']
}

export type BillingExtendedDowngradeDetailsType = {
  featureGroupTitle: Scalars['String']
  features: Array<BillingDowngradeDetailsType>
}

export type BillingInvoiceLineItemType = {
  lineItemPriceType: Maybe<BillingPriceTypeEnum>
  perUnitAmount: Maybe<Money>
  periodEnd: Scalars['ISO8601DateTime']
  periodStart: Scalars['ISO8601DateTime']
  quantity: Scalars['Int']
  /** The name of the SaaS plan associated with the line item */
  saasPlanName: Maybe<Scalars['String']>
  totalAmount: Money
}

export enum BillingInvoiceStatusEnum {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  PAID = 'PAID',
  VOID = 'VOID',
}

export type BillingInvoiceType = {
  /** Paid amount */
  amount: Money
  billingInvoiceLineItems: Array<BillingInvoiceLineItemType>
  billingMethod: Maybe<BillingMethod>
  billingPaymentStatus: Maybe<BillingPaymentStatusEnum>
  billingStatementAvailable: Scalars['Boolean']
  date: Scalars['ISO8601DateTime']
  /** The total amount of discounts */
  discountAmount: Maybe<Money>
  discountCouponName: Maybe<Scalars['String']>
  id: Maybe<Scalars['ID']>
  periodEnd: Scalars['ISO8601DateTime']
  periodStart: Scalars['ISO8601DateTime']
  planName: Maybe<Scalars['String']>
  status: BillingInvoiceStatusEnum
  /** The total amount before discounts */
  subtotalAmount: Maybe<Money>
}

export type BillingInvoicesType = {
  data: Array<BillingInvoiceType>
  pagination: Pagination
}

export type BillingLimitType = {
  name: Scalars['String']
  type: Scalars['String']
  value: Scalars['String']
}

export type BillingMethod = {
  default: Scalars['Boolean']
  id: Scalars['ID']
  source: BillingMethodSource
}

export type BillingMethodSource = Account | PaymentCard

export enum BillingPaymentStatusEnum {
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  INITIATED = 'INITIATED',
  PAID = 'PAID',
  REFUNDED = 'REFUNDED',
  RETRY_INITIATED = 'RETRY_INITIATED',
}

export type BillingPlanChangeResponseType = {
  confirmationUrl: Maybe<Scalars['String']>
  subscription: BillingSubscriptionType
}

export type BillingPlanFeatures = {
  billingVersion: BillingVersionsEnum
  feature: BillingPlanFeaturesEnumV2
  supportedByPlan: Scalars['Boolean']
  usageData: BillingPlanUsageData
}

export enum BillingPlanFeaturesEnumV2 {
  ACCOUNTING_SOFTWARE = 'ACCOUNTING_SOFTWARE',
  ADHOC_INVOICE_APPROVALS = 'ADHOC_INVOICE_APPROVALS',
  ADJUSTMENT_LOG = 'ADJUSTMENT_LOG',
  ADVANCED_AND_CUSTOM_USER_ROLES = 'ADVANCED_AND_CUSTOM_USER_ROLES',
  APPROVALS = 'APPROVALS',
  APPROVAL_WORKFLOW_RULES = 'APPROVAL_WORKFLOW_RULES',
  BASIC_USER_ROLES = 'BASIC_USER_ROLES',
  BATCH_PAYMENTS = 'BATCH_PAYMENTS',
  BULK_PAYMENTS = 'BULK_PAYMENTS',
  CASH_FLOW_CHART = 'CASH_FLOW_CHART',
  CASH_FLOW_FORECASTING = 'CASH_FLOW_FORECASTING',
  CASH_OUTFLOWS = 'CASH_OUTFLOWS',
  CATALOG = 'CATALOG',
  COMMENTING = 'COMMENTING',
  CUSTOM_ROLES = 'CUSTOM_ROLES',
  END_OF_MONTH_INVENTORY_REPORT = 'END_OF_MONTH_INVENTORY_REPORT',
  GRN = 'GRN',
  INBOX = 'INBOX',
  INVENTORY_FORECASTING = 'INVENTORY_FORECASTING',
  INVENTORY_TRANSFERS = 'INVENTORY_TRANSFERS',
  INVOICE_LESS_PAYMENTS = 'INVOICE_LESS_PAYMENTS',
  ITEMIZED_ACCOUNTING = 'ITEMIZED_ACCOUNTING',
  LANDED_COSTS = 'LANDED_COSTS',
  LOCATIONS = 'LOCATIONS',
  OCR = 'OCR',
  PREMIUM_CUSTOMER_SUPPORT = 'PREMIUM_CUSTOMER_SUPPORT',
  PRODUCT_COST = 'PRODUCT_COST',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  PURCHASE_ORDERS = 'PURCHASE_ORDERS',
  PURCHASE_ORDER_DEPOSITS = 'PURCHASE_ORDER_DEPOSITS',
  PURCHASE_ORDER_MANAGEMENT = 'PURCHASE_ORDER_MANAGEMENT',
  PURCHASING_SUITE = 'PURCHASING_SUITE',
  SALES_INTEGRATION = 'SALES_INTEGRATION',
  SALES_ORDERS = 'SALES_ORDERS',
  SEATS = 'SEATS',
  SEATS_AVAILABLE = 'SEATS_AVAILABLE',
  SETTLE_INBOX = 'SETTLE_INBOX',
  SPLIT_PAY_AND_VENDOR_CREDITS = 'SPLIT_PAY_AND_VENDOR_CREDITS',
  STANDARD_PAYMENTS = 'STANDARD_PAYMENTS',
  THREE_WAY_MATCH = 'THREE_WAY_MATCH',
  TRANSACTIONS = 'TRANSACTIONS',
  TWO_WAY_SYNC = 'TWO_WAY_SYNC',
  VENDOR_MANAGEMENT = 'VENDOR_MANAGEMENT',
  VENDOR_W9_AND_1099 = 'VENDOR_W9_AND_1099',
  VENDOR_W9_STORAGE_AND_1099 = 'VENDOR_W9_STORAGE_AND_1099',
  WAREHOUSE_INTEGRATION = 'WAREHOUSE_INTEGRATION',
  WORK_ORDERS = 'WORK_ORDERS',
}

export enum BillingPlanPermissionsEnum {
  BILLING_PLANS = 'BILLING_PLANS',
  CHANGE_BILLING_PLAN = 'CHANGE_BILLING_PLAN',
}

export type BillingPlanType = {
  accountingBillingModelType: Maybe<AccountingBillingModelTypeEnum>
  basePlanType: Maybe<BillingPlanTypeEnum>
  billingVersion: BillingVersionsEnum
  description: Maybe<Scalars['String']>
  displayFeatures: Array<Scalars['String']>
  features: Array<BillingPlanFeaturesEnumV2>
  featuresWithLimits: Array<BillingPlanFeatures>
  id: Scalars['ID']
  limits: Array<BillingLimitType>
  name: Scalars['String']
  planPaidBy: Maybe<PayerEnum>
  saasFeePerMonth: Maybe<Money>
  transactionFeesPaidBy: Maybe<PayerEnum>
  transfersPrices: Maybe<BillingPricingType>
  type: BillingPlanTypeEnum
}

export enum BillingPlanTypeEnum {
  ACCELERATE = 'ACCELERATE',
  ACCOUNTING_ACCELERATE = 'ACCOUNTING_ACCELERATE',
  ACCOUNTING_BASIC = 'ACCOUNTING_BASIC',
  ACCOUNTING_BILL_PAY = 'ACCOUNTING_BILL_PAY',
  ACCOUNTING_ELEVATE = 'ACCOUNTING_ELEVATE',
  ACCOUNTING_FIRM = 'ACCOUNTING_FIRM',
  ACCOUNTING_FIRM_V3 = 'ACCOUNTING_FIRM_V3',
  ACCOUNTING_LAUNCH = 'ACCOUNTING_LAUNCH',
  ACCOUNTING_PLUS = 'ACCOUNTING_PLUS',
  ACCOUNTING_WORKING_CAPITAL = 'ACCOUNTING_WORKING_CAPITAL',
  AUTOMATE = 'AUTOMATE',
  BASIC = 'BASIC',
  CUSTOM = 'CUSTOM',
  ELEVATE = 'ELEVATE',
  FREE = 'FREE',
  LAUNCH = 'LAUNCH',
  OPTIMIZE = 'OPTIMIZE',
  PLUS = 'PLUS',
  SAVE = 'SAVE',
  WORKING_CAPITAL = 'WORKING_CAPITAL',
}

export type BillingPlanUpgrade = {
  amount: Money
  currentPeriodStart: Scalars['ISO8601Date']
  prorated: Scalars['Boolean']
}

export type BillingPlanUsageData = {
  currentUsage: Maybe<Scalars['Int']>
  limitReached: Maybe<Scalars['Boolean']>
  usageLimit: Maybe<Scalars['Int']>
}

export type BillingPriceType = {
  name: Scalars['String']
  type: Scalars['String']
  unitAmountCents: Maybe<Scalars['String']>
  unitAmountCurrency: Maybe<Scalars['String']>
  unitAmountFormatted: Scalars['String']
}

export enum BillingPriceTypeEnum {
  ACH_TRANSFER = 'ACH_TRANSFER',
  DOMESTIC_WIRE_TRANSFER = 'DOMESTIC_WIRE_TRANSFER',
  INTERNATIONAL_WIRE_TRANSFER = 'INTERNATIONAL_WIRE_TRANSFER',
  PAPER_CHECK_TRANSFER = 'PAPER_CHECK_TRANSFER',
  SAAS = 'SAAS',
  SAME_DAY_ACH_TRANSFER = 'SAME_DAY_ACH_TRANSFER',
}

export type BillingPricingType = {
  achTransfer: BillingPriceType
  domesticWireTransfer: BillingPriceType
  internationalWireTransfer: BillingPriceType
  paperCheckTransfer: BillingPriceType
  sameDayAchTransfer: BillingPriceType
}

export type BillingPromotionCodeType = {
  active: Scalars['Boolean']
  code: Scalars['String']
  coupon: BillingCouponType
  id: Scalars['ID']
}

export enum BillingProviderEnum {
  SETTLE = 'SETTLE',
  SHOPIFY = 'SHOPIFY',
  STRIPE = 'STRIPE',
}

export enum BillingSubscriptionStatusEnum {
  ACTIVE = 'ACTIVE',
  CANCELLATION_INITIATED = 'CANCELLATION_INITIATED',
  CANCELLED = 'CANCELLED',
  FROZEN = 'FROZEN',
}

export type BillingSubscriptionType = {
  cancelAt: Maybe<Scalars['ISO8601DateTime']>
  cancelledAt: Maybe<Scalars['ISO8601DateTime']>
  currentPeriodEnd: Scalars['ISO8601DateTime']
  currentPeriodStart: Scalars['ISO8601DateTime']
  freeTrialUntil: Maybe<Scalars['ISO8601DateTime']>
  plan: BillingPlanType
  postTrialPlan: Maybe<BillingPlanType>
  provider: BillingProviderEnum
  status: BillingSubscriptionStatusEnum
}

export enum BillingVersionsEnum {
  V2 = 'V2',
  V3 = 'V3',
}

export type BillsSummary = {
  inboxCount: Scalars['Int']
  rejectedCount: Scalars['Int']
}

export type Blob = {
  byteSize: Scalars['Int']
  contentType: Scalars['String']
  filename: Scalars['String']
  id: Scalars['ID']
  type: Maybe<Scalars['String']>
  url: Scalars['String']
}

export type BlockLocalBusinessPayments = {
  reason: BlockLocalBusinessPaymentsReasonEnum
}

export enum BlockLocalBusinessPaymentsReasonEnum {
  PUBLIC_BUSINESS_MATCH_AVAILABLE = 'PUBLIC_BUSINESS_MATCH_AVAILABLE',
}

export type BlockPayments = {
  reason: ReasonEnum
}

export type BomLineItem = {
  bomMaterial: Maybe<BomMaterial>
  catalogItemVariation: Maybe<CatalogItemVariation>
  id: Scalars['ID']
  quantity: Maybe<Scalars['Float']>
  softDeletedAt: Maybe<Scalars['ISO8601DateTime']>
  unitOfMeasure: Maybe<Scalars['String']>
}

export type BomMaterial = {
  defaultCatalogItemVariation: Maybe<CatalogItemVariation>
  defaultQuantity: Maybe<Scalars['Float']>
  id: Scalars['ID']
  name: Scalars['String']
}

export type BomTableRowType = {
  name: Maybe<Scalars['String']>
  quantityPerUnit: Maybe<Scalars['Float']>
  totalQuantity: Maybe<Scalars['Float']>
  type: Maybe<Scalars['String']>
  unitOfMeasure: Maybe<Scalars['String']>
}

export type BulkPayBase = {
  invoice: Maybe<Invoice>
  message: Maybe<Scalars['String']>
  payment: Maybe<Payment>
  success: Scalars['Boolean']
}

export type BulkPayInputObjectV2 = {
  amount?: InputMaybe<MoneyInputObject>
  invoiceId: Scalars['ID']
  toAccountId?: InputMaybe<Scalars['ID']>
}

export type BulletEpt = {
  /** Annual percentage rate (APR) for the loan */
  apr: Scalars['Float']
  /** Planned or actual charges schedule */
  charges: Array<BulletEptCharge>
  /** The current installment (last failed, next scheduled or the last one) */
  currentCharge: Maybe<BulletEptCharge>
  /** Sum of interest accrued */
  interest: Money
  /** Number of days in each period */
  periodDays: Scalars['Int']
  /** Percentage of principal charged each period as interest */
  periodRate: Scalars['Float']
  /** Number of periods ending with installment payment */
  periods: Scalars['Int']
  /** Sum of principal repayments */
  principal: Money
  /** Whether or not this EPT schedule is active on the payment */
  selected: Scalars['Boolean']
  /** Sum of all installment payments */
  total: Money
  /** Sum of days in all periods */
  totalDays: Scalars['Int']
}

export type BulletEptCharge = {
  /** Installment payment date */
  date: Scalars['ISO8601Date']
  /** Full description of charge */
  description: Scalars['String']
  /** Marks charge which is an early repayment */
  earlyRepayment: Scalars['Boolean']
  /** Interest included into installment amount */
  interest: Money
  /** Number of the installment */
  number: Scalars['Int']
  /** Percentage of principal charged each period as interest */
  periodRate: Scalars['Float']
  /** Principal repayment included in installment amount */
  principal: Money
  reconciliationItem: Maybe<ReconciliationItem>
  /** Status of the installment */
  status: AmortizedLoanInstallmentStatusEnum
  /** Installment amount */
  total: Money
}

export type BulletEptEarlyRepaymentPreview = {
  /** Current bullet ept schedule */
  current: BulletEpt
  /** Expected bullet ept schedule */
  expected: BulletEpt
}

export type Business = {
  actions: BusinessActions
  address: Maybe<Address>
  /** Returns true when amortization configured and enabled for the business */
  amortizationAvailable: Scalars['Boolean']
  appliedForFinancing: Scalars['Boolean']
  balanceSheet: Maybe<BalanceSheet>
  billPayFromPayer: Scalars['Boolean']
  billPayToVendor: Scalars['Boolean']
  blockPayments: Maybe<BlockPayments>
  bulletExtendedTermFeatureEnabled: Scalars['Boolean']
  businessStructure: Maybe<BusinessStructureEnum>
  businessStructureTaxClassification: Maybe<BusinessStructureTaxClassificationEnum>
  /** Cash Advance EPT/BEPT and AEPT feature enables */
  cashAdvanceFinancingAvailability: CashAdvanceFinancingAvailability
  companyName: Maybe<Scalars['String']>
  connectedToCin7: Scalars['Boolean']
  counters: Counters
  country: Maybe<Scalars['String']>
  /** Credit limit status of current business */
  creditLimitStatus: Maybe<BusinessCreditLimitStatusEnum>
  creditLimitUpsizeRequestAvailable: Scalars['Boolean']
  current: Scalars['Boolean']
  demoAvailable: Scalars['Boolean']
  displayName: Scalars['String']
  doingBusinessAsNames: Maybe<Array<Scalars['String']>>
  eptFromPayer: Scalars['Boolean']
  eptMetrics: Maybe<EptMetrics>
  eptRate: Maybe<EptRate>
  eptToVendor: Scalars['Boolean']
  exemptFatcaReportingCode: Maybe<Scalars['String']>
  exemptPayeeCode: Maybe<Scalars['String']>
  finaloopLinked: Scalars['Boolean']
  financingNudgeBanner: Maybe<FinancingNudgeBanner>
  firstName: Maybe<Scalars['String']>
  freeTrialAvailable: Scalars['Boolean']
  hasAmortizedPayments: Scalars['Boolean']
  hasBeptPayments: Scalars['Boolean']
  hasCashAdvancePayments: Scalars['Boolean']
  hasEptPayments: Scalars['Boolean']
  hasRefunds: Scalars['Boolean']
  id: Scalars['ID']
  industry: Maybe<BusinessIndustryEnum>
  inputStateOfFormation: Maybe<Scalars['String']>
  internalEmail: Maybe<Scalars['String']>
  is1099Eligible: Maybe<Scalars['Boolean']>
  kybBusinessStatus: Maybe<KybStatusEnum>
  kybStatus: KybStatusEnum
  kybUboStatus: Maybe<KybStatusEnum>
  lastName: Maybe<Scalars['String']>
  lockboxEnabled: Maybe<Scalars['Boolean']>
  logo: Maybe<Blob>
  mailingAddress: Maybe<Address>
  marketingVirtualCardDefaultRepaymentPercent: Scalars['Float']
  marketingVirtualCardEnabled: Scalars['Boolean']
  mfaRequired: Scalars['Boolean']
  netsuiteLinked: Scalars['Boolean']
  netsuiteState: Maybe<NetsuiteState>
  officialName: Scalars['String']
  onboardingStatus: Maybe<OnboardingStatusEnum>
  partnerPricingEnabled: Scalars['Boolean']
  /** Payment dialog configuration for current business */
  paymentOptions: PaymentOptions
  plaidLinked: Scalars['Boolean']
  preferredPayingAccount: Maybe<Account>
  preferredReceivingAccount: Maybe<Account>
  pusherChannelId: Scalars['String']
  quickBooksLinked: Scalars['Boolean']
  quickBooksState: Maybe<QuickBooksState>
  receivablesEnabled: Scalars['Boolean']
  /** @deprecated Use preferredReceivingAccount instead */
  receivingAccount: Maybe<LocalBusinessAccount>
  roles: Array<BusinessRoleEnum>
  /** @deprecated Not needed by FE */
  rulesCount: Scalars['Int']
  showPayablesSignup: Maybe<Scalars['Boolean']>
  /** @deprecated banner deprecated */
  showStatusesRenamingBanner: Scalars['Boolean']
  signupInterests: Maybe<Array<SignupInterestsEnum>>
  subscription: Maybe<BillingSubscriptionType>
  taxpayerId: Maybe<Scalars['String']>
  trackstarIntegrations: Array<TrackstarIntegration>
  underwritingStatus: BusinessUnderwritingStatusEnum
  /** W9 form document uploaded by the current business */
  w9Form: Maybe<Attachment>
  website: Maybe<Scalars['String']>
  xeroLinked: Scalars['Boolean']
}

export type BusinessActions = {
  creditLimitUpsizeRequest: Action
  editDetails: Action
  exportCashFlowReport: Action
}

export type BusinessBeneficialOwnerInput = {
  address: AddressInputObject
  countryOfFormation?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  legalName: Scalars['String']
  stateOfFormation?: InputMaybe<Scalars['String']>
  taxpayerId: Scalars['String']
}

export type BusinessClient = {
  business: PublicBusiness
  teamMemberships: Array<UserClient>
}

export enum BusinessComplianceStatusEnum {
  AWAITING_INFO = 'AWAITING_INFO',
  IN_REVIEW = 'IN_REVIEW',
  REJECTED = 'REJECTED',
  VERIFIED = 'VERIFIED',
}

export enum BusinessCreditLimitStatusEnum {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
}

export enum BusinessDetailsPermissionsEnum {
  ADD_BENEFICIAL_OWNERS = 'ADD_BENEFICIAL_OWNERS',
  UPDATE_BUSINESS_DETAILS = 'UPDATE_BUSINESS_DETAILS',
  VIEW_BENEFICIAL_OWNERS = 'VIEW_BENEFICIAL_OWNERS',
}

export type BusinessFeaturesActions = {
  updateBillAutoItemization: Action
  updatePaymentHoldRelease: Action
  updatePaymentW8OrW9MissingHold: Action
  updatePurchaseOrderAutoItemization: Action
  updateReceiptAutoItemization: Action
  updateVendorDunning: Action
}

export type BusinessFeaturesInputObject = {
  billAutoItemization?: InputMaybe<Scalars['Boolean']>
  paymentHoldRelease?: InputMaybe<Scalars['Boolean']>
  paymentW8OrW9MissingHold?: InputMaybe<Scalars['Boolean']>
  purchaseOrderAutoItemization?: InputMaybe<Scalars['Boolean']>
  receiptAutoItemization?: InputMaybe<Scalars['Boolean']>
  vendorDunning?: InputMaybe<Scalars['Boolean']>
}

export enum BusinessFeaturesPermissionsEnum {
  UPDATE_BILL_AUTO_ITEMIZATION = 'UPDATE_BILL_AUTO_ITEMIZATION',
  UPDATE_PAYMENT_HOLD_RELEASE = 'UPDATE_PAYMENT_HOLD_RELEASE',
  UPDATE_PAYMENT_W8_OR_W9_MISSING_HOLD = 'UPDATE_PAYMENT_W8_OR_W9_MISSING_HOLD',
  UPDATE_PURCHASE_ORDER_AUTO_ITEMIZATION = 'UPDATE_PURCHASE_ORDER_AUTO_ITEMIZATION',
  UPDATE_RECEIPT_AUTO_ITEMIZATION = 'UPDATE_RECEIPT_AUTO_ITEMIZATION',
  UPDATE_VENDOR_DUNNING = 'UPDATE_VENDOR_DUNNING',
}

export type BusinessFeaturesType = {
  actions: BusinessFeaturesActions
  billAutoItemization: Scalars['Boolean']
  paymentHoldRelease: Scalars['Boolean']
  paymentW8OrW9MissingHold: Scalars['Boolean']
  purchaseOrderAutoItemization: Scalars['Boolean']
  receiptAutoItemization: Scalars['Boolean']
  vendorDunning: Scalars['Boolean']
}

export enum BusinessIndustryEnum {
  ACCESSORIES = 'ACCESSORIES',
  ACCOUNTING = 'ACCOUNTING',
  BABY_KIDS = 'BABY_KIDS',
  BEAUTY = 'BEAUTY',
  CLOTHING_APPAREL = 'CLOTHING_APPAREL',
  CONSTRUCTION = 'CONSTRUCTION',
  ELECTRONICS = 'ELECTRONICS',
  FINANCIAL_SERVICES = 'FINANCIAL_SERVICES',
  FOOD_BEVERAGE = 'FOOD_BEVERAGE',
  FURNITURE = 'FURNITURE',
  HEALTH_WELLNESS = 'HEALTH_WELLNESS',
  HOME_KITCHEN = 'HOME_KITCHEN',
  JEWELERY = 'JEWELERY',
  LAW_OFFICES = 'LAW_OFFICES',
  MARKETING = 'MARKETING',
  OTHER = 'OTHER',
  PET_PRODUCTS = 'PET_PRODUCTS',
  REAL_ESTATE = 'REAL_ESTATE',
  RESTAURANT_HOSPITALITY = 'RESTAURANT_HOSPITALITY',
  SERVICES = 'SERVICES',
  SPORTS = 'SPORTS',
  TOYS = 'TOYS',
  VIRTUAL_SERVICES = 'VIRTUAL_SERVICES',
}

export type BusinessMatch = {
  business: PublicBusiness
  id: Scalars['ID']
  localBusiness: LocalBusiness
  matchedOn: Array<Scalars['String']>
  status: BusinessMatchStatusEnum
}

export enum BusinessMatchStatusEnum {
  MERGED = 'MERGED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum BusinessRoleEnum {
  ACCOUNTING_FIRM = 'ACCOUNTING_FIRM',
  PAYER = 'PAYER',
  VENDOR = 'VENDOR',
}

export enum BusinessStructureEnum {
  BUSINESS_GENERAL = 'BUSINESS_GENERAL',
  C_CORPORATION = 'C_CORPORATION',
  INDIVIDUAL = 'INDIVIDUAL',
  LIMITED_PARTNERSHIP = 'LIMITED_PARTNERSHIP',
  LLC = 'LLC',
  NON_PROFIT = 'NON_PROFIT',
  OTHER_BUSINESS = 'OTHER_BUSINESS',
  PARTNERSHIP = 'PARTNERSHIP',
  SOLE_PROP = 'SOLE_PROP',
  S_CORPORATION = 'S_CORPORATION',
}

export enum BusinessStructureTaxClassificationEnum {
  C_CORPORATION = 'C_CORPORATION',
  PARTNERSHIP = 'PARTNERSHIP',
  S_CORPORATION = 'S_CORPORATION',
}

export enum BusinessTraitsEnum {
  ACCOUNTING_FIRM = 'ACCOUNTING_FIRM',
  BILLING_PLAN_ACCELERATE = 'BILLING_PLAN_ACCELERATE',
  BILLING_PLAN_BASIC = 'BILLING_PLAN_BASIC',
  BILLING_PLAN_ELEVATE = 'BILLING_PLAN_ELEVATE',
  BILLING_PLAN_LAUNCH = 'BILLING_PLAN_LAUNCH',
  BILLING_PLAN_PLUS = 'BILLING_PLAN_PLUS',
  BILLING_PLAN_WC = 'BILLING_PLAN_WC',
  FREE_TRIAL = 'FREE_TRIAL',
  INDIVIDUAL = 'INDIVIDUAL',
  IS_1099_ELIGIBLE = 'IS_1099_ELIGIBLE',
  MFA_REQUIRED = 'MFA_REQUIRED',
  MICRO_DEPOSITS_SENT = 'MICRO_DEPOSITS_SENT',
  MICRO_DEPOSITS_WAITING = 'MICRO_DEPOSITS_WAITING',
  MIDDESK_VERIFIED = 'MIDDESK_VERIFIED',
  PAYER = 'PAYER',
  SOLE_PROP = 'SOLE_PROP',
  UNDERWRITTEN = 'UNDERWRITTEN',
  VENDOR = 'VENDOR',
  VERIFICATION_FILLED = 'VERIFICATION_FILLED',
  VERIFIED = 'VERIFIED',
  VERIFIED_INTERNATIONAL = 'VERIFIED_INTERNATIONAL',
  VERIFIED_WITHOUT_RECEIVING_ACCOUNT = 'VERIFIED_WITHOUT_RECEIVING_ACCOUNT',
  WITH_ACCOUNT_BILLING_METHOD = 'WITH_ACCOUNT_BILLING_METHOD',
  WITH_AMORTIZATION = 'WITH_AMORTIZATION',
  WITH_CARD_BILLING_METHOD = 'WITH_CARD_BILLING_METHOD',
  WITH_CLIENT = 'WITH_CLIENT',
  WITH_EPT_LIMIT = 'WITH_EPT_LIMIT',
  WITH_FRAUD_APPROVAL = 'WITH_FRAUD_APPROVAL',
  WITH_PAYEE_AGENT_DISCLOSURE = 'WITH_PAYEE_AGENT_DISCLOSURE',
  WITH_UBO = 'WITH_UBO',
  WITH_W9_FORM = 'WITH_W9_FORM',
  WITH_ZERO_EPT_LIMIT = 'WITH_ZERO_EPT_LIMIT',
}

export enum BusinessUnderwritingStatusEnum {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  MISSING_BANK_ACCOUNT = 'MISSING_BANK_ACCOUNT',
  PENDING = 'PENDING',
}

export type CashAdvanceFinancingAvailability = {
  amortizationAvailable: Scalars['Boolean']
  eptAvailable: Scalars['Boolean']
}

export type CashAdvancePaymentInputObject = {
  amount: MoneyInputObject
  receivingAccountId: Scalars['ID']
  repaymentAccountId: Scalars['ID']
}

export type CashOutflow = {
  endDate: Scalars['ISO8601Date']
  eptPayments: Money
  failed: Money
  fees: Money
  marketingCardPayments: Money
  nonfinancedPayments: Money
  pendingApproval: Money
  purchaseOrderExpectedPayments: Maybe<Money>
  startDate: Scalars['ISO8601Date']
  total: Money
}

export type CatalogItem = {
  accountingCategoryClass: Maybe<AccountingDictionary>
  accountingCategoryDepartment: Maybe<AccountingDictionary>
  accountingCategoryLocation: Maybe<AccountingDictionary>
  accountingSyncEvents: Maybe<Array<SyncEvent>>
  accountingType: Maybe<AccountingQuickBooksItemTypeEnum>
  accountingUrl: Maybe<Scalars['String']>
  actions: CatalogItemActions
  amount: Maybe<Money>
  archived: Scalars['Boolean']
  assemblyType: Maybe<AssemblyTypeEnum>
  availableTotal: Scalars['Float']
  barcodes: Array<CatalogItemBarcodeType>
  baselineCost: Maybe<InfinitePrecisionMoney>
  bomLineItems: Array<BomLineItem>
  bomMaterials: Array<BomMaterial>
  category: Maybe<Scalars['String']>
  createdAt: Scalars['ISO8601DateTime']
  currentInventoryBalances: Array<CatalogItemInventoryBalance>
  defaultVariation: Maybe<CatalogItemVariation>
  description: Maybe<Scalars['String']>
  expenseAccountingAccount: Maybe<AccountingAccount>
  fractionalAmount: Maybe<InfinitePrecisionMoney>
  fractionalLatestLandedCost: Maybe<InfinitePrecisionMoney>
  hasDefaultVariation: Scalars['Boolean']
  id: Scalars['ID']
  imageUrl: Maybe<Scalars['String']>
  incomingTotal: Scalars['Float']
  inventorySnapshots: Array<CatalogItemInventorySnapshot>
  labels: Array<Label>
  name: Maybe<Scalars['String']>
  note: Maybe<Scalars['String']>
  onhandTotal: Scalars['Float']
  physicalAttributes: Maybe<PhysicalAttributes>
  primarySku: Maybe<Scalars['String']>
  properties: Array<CatalogItemProperty>
  purchaseDetails: Maybe<Array<PurchaseDetailsType>>
  quantity: Maybe<Scalars['Int']>
  reservedTotal: Scalars['Float']
  salesDetails: Array<SalesDetailType>
  shopifyUrl: Maybe<Scalars['String']>
  showSyncHistory: Maybe<Scalars['Boolean']>
  sku: Maybe<Scalars['String']>
  syncError: Maybe<SyncError>
  syncObjectStatus: Maybe<SyncObjectStatus>
  unitOfMeasure: Maybe<Scalars['String']>
  variations: Array<CatalogItemVariation>
  vendorLocalBusiness: Maybe<LocalBusiness>
}

export type CatalogItemActions = {
  delete: Action
  editBaseData: Action
  updateVendor: Action
}

export enum CatalogItemBarcodeSourceEnum {
  MANUAL = 'MANUAL',
  TRACKSTAR = 'TRACKSTAR',
}

export type CatalogItemBarcodeType = {
  source: CatalogItemBarcodeSourceEnum
  value: Scalars['String']
}

export type CatalogItemBulkInputObject = {
  barcode?: InputMaybe<Scalars['String']>
  dimensionsUnitOfMeasure?: InputMaybe<Scalars['String']>
  height?: InputMaybe<Scalars['Float']>
  length?: InputMaybe<Scalars['Float']>
  note?: InputMaybe<Scalars['String']>
  vendorLocalBusinessId?: InputMaybe<Scalars['ID']>
  weight?: InputMaybe<Scalars['Float']>
  weightUnitOfMeasure?: InputMaybe<Scalars['String']>
  width?: InputMaybe<Scalars['Float']>
}

export type CatalogItemInputObject = {
  accountingCategoryClassId?: InputMaybe<Scalars['ID']>
  accountingCategoryDepartmentId?: InputMaybe<Scalars['ID']>
  accountingCategoryLocationId?: InputMaybe<Scalars['ID']>
  amount?: InputMaybe<MoneyInputObject>
  assemblyType?: InputMaybe<AssemblyTypeEnum>
  barcode?: InputMaybe<Scalars['String']>
  baselineCost?: InputMaybe<FractionalMoneyInputObject>
  description?: InputMaybe<Scalars['String']>
  dimensionsUnitOfMeasure?: InputMaybe<Scalars['String']>
  expenseAccountingAccountId?: InputMaybe<Scalars['ID']>
  fractionalAmount?: InputMaybe<FractionalMoneyInputObject>
  height?: InputMaybe<Scalars['Float']>
  length?: InputMaybe<Scalars['Float']>
  name?: InputMaybe<Scalars['String']>
  note?: InputMaybe<Scalars['String']>
  primarySku?: InputMaybe<Scalars['String']>
  sku?: InputMaybe<Scalars['String']>
  unitOfMeasure?: InputMaybe<Scalars['String']>
  vendorLocalBusinessId?: InputMaybe<Scalars['ID']>
  weight?: InputMaybe<Scalars['Float']>
  weightUnitOfMeasure?: InputMaybe<Scalars['String']>
  width?: InputMaybe<Scalars['Float']>
}

export type CatalogItemInventoryBalance = {
  availableTotal: Maybe<Scalars['Float']>
  catalogItem: CatalogItem
  catalogItemVariation: CatalogItemVariation
  id: Maybe<Scalars['ID']>
  incomingTotal: Maybe<Scalars['Float']>
  location: Maybe<Location>
  locationSkus: Maybe<Array<Scalars['String']>>
  lotSnapshots: Maybe<Array<LotSnapshot>>
  onhandTotal: Maybe<Scalars['Float']>
  reservedTotal: Maybe<Scalars['Float']>
  snapshotDate: Maybe<Scalars['ISO8601DateTime']>
}

export type CatalogItemInventorySnapshot = {
  available: Maybe<Scalars['Int']>
  catalogItem: Maybe<CatalogItem>
  catalogItemVariation: Maybe<CatalogItemVariation>
  id: Scalars['ID']
  incoming: Maybe<Scalars['Int']>
  location: Location
  onhand: Maybe<Scalars['Int']>
  reserved: Maybe<Scalars['Int']>
  snapshotDate: Maybe<Scalars['ISO8601DateTime']>
}

export type CatalogItemInventorySnapshotInputObject = {
  available: Scalars['Int']
  catalogItemOrVariationId: Scalars['ID']
  incoming?: InputMaybe<Scalars['Int']>
  locationId: Scalars['ID']
  onhand: Scalars['Int']
  reserved: Scalars['Int']
  snapshotDate?: InputMaybe<Scalars['ISO8601DateTime']>
}

export type CatalogItemLandedCostDetails = {
  changeDirection: CatalogItemsLandedCostChangeDirectionEnum
  date: Maybe<Scalars['ISO8601Date']>
  fractionalLandedCost: Maybe<InfinitePrecisionMoney>
  orderDocument: Maybe<OrderDocument>
  /** @deprecated This will be replaced by order_document */
  purchaseOrder: PurchaseOrder
  quantity: Maybe<Scalars['Float']>
}

export type CatalogItemProperty = {
  archived: Scalars['Boolean']
  id: Scalars['ID']
  name: Scalars['String']
  values: Array<CatalogItemPropertyValue>
}

export type CatalogItemPropertyInputObject = {
  name: Scalars['String']
  values: Array<CatalogItemPropertyValuesInputObject>
}

export type CatalogItemPropertyValue = {
  archived: Scalars['Boolean']
  id: Scalars['ID']
  value: Scalars['String']
}

export type CatalogItemPropertyValuesInputObject = {
  id?: InputMaybe<Scalars['ID']>
  value: Scalars['String']
}

export enum CatalogItemSalesDataSourceEnum {
  ACCOUNTING_PLATFORM = 'ACCOUNTING_PLATFORM',
  SHOPIFY = 'SHOPIFY',
}

export type CatalogItemVariation = {
  actions: CatalogItemActions
  amount: Maybe<Money>
  archived: Scalars['Boolean']
  availableTotal: Scalars['Float']
  barcodes: Array<CatalogItemBarcodeType>
  baselineCost: Maybe<InfinitePrecisionMoney>
  bomLineItems: Array<BomLineItem>
  bomReviewed: Maybe<Scalars['Boolean']>
  createdAt: Scalars['ISO8601DateTime']
  currentInventoryBalances: Array<CatalogItemInventoryBalance>
  fractionalAmount: Maybe<InfinitePrecisionMoney>
  fractionalLatestLandedCost: Maybe<InfinitePrecisionMoney>
  id: Scalars['ID']
  imageUrl: Maybe<Scalars['String']>
  incomingTotal: Scalars['Float']
  inventorySnapshots: Array<CatalogItemInventorySnapshot>
  isDefaultVariation: Scalars['Boolean']
  name: Maybe<Scalars['String']>
  note: Maybe<Scalars['String']>
  onhandTotal: Scalars['Float']
  parentCatalogItem: Maybe<CatalogItem>
  physicalAttributes: Maybe<PhysicalAttributes>
  primarySku: Maybe<Scalars['String']>
  propertyValues: Array<CatalogItemPropertyValue>
  purchaseDetails: Maybe<Array<PurchaseDetailsType>>
  quantity: Maybe<Scalars['Int']>
  reservedTotal: Scalars['Float']
  salesDetails: Array<SalesDetailType>
  shopifyUrl: Maybe<Scalars['String']>
  shortName: Maybe<Scalars['String']>
  sku: Maybe<Scalars['String']>
  unitOfMeasure: Maybe<Scalars['String']>
}

export type CatalogItemVariationInputObject = {
  amount?: InputMaybe<MoneyInputObject>
  barcode?: InputMaybe<Scalars['String']>
  baselineCost?: InputMaybe<FractionalMoneyInputObject>
  dimensionsUnitOfMeasure?: InputMaybe<Scalars['String']>
  fractionalAmount?: InputMaybe<FractionalMoneyInputObject>
  height?: InputMaybe<Scalars['Float']>
  length?: InputMaybe<Scalars['Float']>
  note?: InputMaybe<Scalars['String']>
  primarySku?: InputMaybe<Scalars['String']>
  sku?: InputMaybe<Scalars['String']>
  weight?: InputMaybe<Scalars['Float']>
  weightUnitOfMeasure?: InputMaybe<Scalars['String']>
  width?: InputMaybe<Scalars['Float']>
}

export type CatalogItemVariations = {
  data: Array<CatalogItemVariation>
  pagination: Pagination
}

export type CatalogItemVariationsFilteringInputObject = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  idsToExclude?: InputMaybe<Array<Scalars['ID']>>
  search?: InputMaybe<Scalars['String']>
  withValidBom?: InputMaybe<Scalars['Boolean']>
}

export type CatalogItems = {
  data: Array<CatalogItem>
  pagination: Pagination
}

export enum CatalogItemsArchivedStatusEnum {
  ACTIVE = 'ACTIVE',
  ACTIVE_AND_ARCHIVED = 'ACTIVE_AND_ARCHIVED',
  ARCHIVED = 'ARCHIVED',
}

export type CatalogItemsFilteringInputObject = {
  accountingAccountIds?: InputMaybe<Array<Scalars['ID']>>
  archivedStatus?: InputMaybe<CatalogItemsArchivedStatusEnum>
  category?: InputMaybe<Scalars['String']>
  ids?: InputMaybe<Array<Scalars['ID']>>
  labelIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  name?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  sku?: InputMaybe<Scalars['String']>
  vendorLocalBusinessId?: InputMaybe<Scalars['ID']>
  vendorName?: InputMaybe<Scalars['String']>
}

export enum CatalogItemsLandedCostChangeDirectionEnum {
  DECREASE = 'DECREASE',
  INCREASE = 'INCREASE',
  NO_CHANGE = 'NO_CHANGE',
}

export enum CatalogPermissionsEnum {
  UPDATE_CATALOG_ITEM_LABELS = 'UPDATE_CATALOG_ITEM_LABELS',
}

export enum CategoryEnum {
  ADJUSTMENT_LOG = 'ADJUSTMENT_LOG',
  AP_AGING_CSV = 'AP_AGING_CSV',
  END_OF_MONTH_INVENTORY = 'END_OF_MONTH_INVENTORY',
  EXORT_AP_AGING_SUMMARY_CSV = 'EXORT_AP_AGING_SUMMARY_CSV',
  EXPORT_1099_CSV = 'EXPORT_1099_CSV',
  EXPORT_AP_AGING_DETAILS_CSV = 'EXPORT_AP_AGING_DETAILS_CSV',
  EXPORT_CASH_OUTFLOW = 'EXPORT_CASH_OUTFLOW',
  EXPORT_EXTENDED_PAYMENT_TERM_AGREEMENTS = 'EXPORT_EXTENDED_PAYMENT_TERM_AGREEMENTS',
  EXPORT_FACTORING_AGREEMENTS = 'EXPORT_FACTORING_AGREEMENTS',
  EXPORT_HISTORICAL_1099_CSV = 'EXPORT_HISTORICAL_1099_CSV',
  EXPORT_HOLDING_ACCOUNT_TRANSACTIONS = 'EXPORT_HOLDING_ACCOUNT_TRANSACTIONS',
  EXPORT_INVOICES_TO_CSV = 'EXPORT_INVOICES_TO_CSV',
  EXPORT_MVC_TRANSACTIONS_TO_CSV = 'EXPORT_MVC_TRANSACTIONS_TO_CSV',
  EXPORT_MY_INVOICES_TO_CSV = 'EXPORT_MY_INVOICES_TO_CSV',
  EXPORT_OWED_TO_SETTLE = 'EXPORT_OWED_TO_SETTLE',
  EXPORT_PAYEE_AGENT_DISCLOSURES = 'EXPORT_PAYEE_AGENT_DISCLOSURES',
  EXPORT_PAYMENT_CONFIRMATIONS = 'EXPORT_PAYMENT_CONFIRMATIONS',
  EXPORT_PRODUCT_COST = 'EXPORT_PRODUCT_COST',
  EXPORT_PURCHASE_ORDER = 'EXPORT_PURCHASE_ORDER',
  EXPORT_RECEIVABLES_TO_CSV = 'EXPORT_RECEIVABLES_TO_CSV',
  EXPORT_RECONCILIATION_ITEMS_TO_CSV = 'EXPORT_RECONCILIATION_ITEMS_TO_CSV',
  EXPORT_VENDOR_PAYMENT_CONFIRMATIONS = 'EXPORT_VENDOR_PAYMENT_CONFIRMATIONS',
}

export type ChannelDemandForecastData = {
  channel: OutboundOrderChannel
  endDate: Scalars['ISO8601Date']
  quantity: Scalars['Float']
  startDate: Scalars['ISO8601Date']
}

export type ClientApplication = {
  integration: OauthClientIntegrationEnum
  name: Scalars['String']
}

export type ClientBillingDashboardType = {
  clientsBillingSummary: Array<ClientBillingSummaryType>
  firmPlan: BillingPlanType
  pagination: Pagination
}

export type ClientBillingInvoiceType = {
  /** Paid amount */
  amount: Money
  billingInvoiceLineItems: Array<BillingInvoiceLineItemType>
  billingMethod: Maybe<BillingMethod>
  billingPaymentStatus: Maybe<BillingPaymentStatusEnum>
  billingStatementAvailable: Scalars['Boolean']
  /** Amount to charge from the client */
  clientAmount: Maybe<Money>
  date: Scalars['ISO8601DateTime']
  /** The total amount of discounts */
  discountAmount: Maybe<Money>
  discountCouponName: Maybe<Scalars['String']>
  /** Amount to charge from the firm */
  firmAmount: Maybe<Money>
  id: Maybe<Scalars['ID']>
  periodEnd: Scalars['ISO8601DateTime']
  periodStart: Scalars['ISO8601DateTime']
  planName: Maybe<Scalars['String']>
  status: BillingInvoiceStatusEnum
  /** The total amount before discounts */
  subtotalAmount: Maybe<Money>
}

export type ClientBillingInvoicesType = {
  data: Array<ClientBillingInvoiceType>
  pagination: Pagination
}

export type ClientBillingSummaryType = {
  billingPaymentsValid: Scalars['Boolean']
  clientBusiness: Business
  clientUpcomingBillingInvoice: ClientBillingInvoiceType
  historicalBillingInvoicesExists: Scalars['Boolean']
}

export type ClientManagerAssignmentInputObject = {
  role?: InputMaybe<MembershipRolesEnum>
  roleId?: InputMaybe<Scalars['ID']>
  userId: Scalars['ID']
}

export type ClientManagerDashboard = {
  data: Array<ClientSummary>
  pagination: Pagination
}

export enum ClientManagerPermissionsEnum {
  CLIENT_MANAGER_TEAM_MEMBERS = 'CLIENT_MANAGER_TEAM_MEMBERS',
  MANAGE_CLIENTS = 'MANAGE_CLIENTS',
  MANAGE_CLIENT_MANAGER_TEAM_MEMBERS = 'MANAGE_CLIENT_MANAGER_TEAM_MEMBERS',
}

export type ClientSummary = {
  accounting: AccountingSummary
  accounts: AccountsSummary
  bills: BillsSummary
  complianceStatus: Maybe<BusinessComplianceStatusEnum>
  displayName: Scalars['String']
  id: Scalars['ID']
  payments: PaymentsSummary
  status: ClientSummaryStatusEnum
  /** @deprecated Use teamMemberships instead */
  teamMembers: Array<TeamMember>
  teamMemberships: Array<Membership>
}

export enum ClientSummaryStatusEnum {
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  NO_ACTION_REQUIRED = 'NO_ACTION_REQUIRED',
}

/** Fetches referrer link data for a given user/business pair */
export type CodeTypeV2 = {
  referralCode: Scalars['String']
  referralCount: Scalars['Int']
  referrerPromotionAmount: Money
}

export type Comment = {
  /** HTML string containing the comment */
  body: Maybe<Scalars['String']>
  createdAt: Maybe<Scalars['ISO8601DateTime']>
  createdBy: Maybe<TeamMember>
  createdById: Maybe<Scalars['ID']>
  id: Scalars['ID']
  read: Maybe<Scalars['Boolean']>
}

export type CommentInputObject = {
  /** HTML string containing the comment */
  body: Scalars['String']
  /** array of user IDs mentioned in the comment */
  mentions: Array<Scalars['ID']>
}

export type ConnectUrl = {
  disabled: Scalars['Boolean']
  disabledReason: Maybe<Scalars['String']>
  url: Maybe<Scalars['String']>
}

export type ConsolidatePaymentDueDateForGroupInputObject = {
  groupId: Scalars['ID']
  invoiceIds?: InputMaybe<Array<Scalars['ID']>>
}

export type ConsolidatePaymentPreliminaryGroupRequestInputObject = {
  excluded?: InputMaybe<Scalars['Boolean']>
  invoiceId: Scalars['ID']
}

export type ConsolidatedPaymentGroupWithDueDateType = {
  earliestDueDate: Scalars['ISO8601Date']
  groupId: Scalars['ID']
  invoices: Array<Invoice>
  overdue: Scalars['Boolean']
}

export type ConsolidatedPaymentInputObject = {
  members: Array<ConsolidatedPaymentMemberInputObject>
}

export type ConsolidatedPaymentMemberInputObject = {
  amount: MoneyInputObject
  invoiceId: Scalars['ID']
}

export type ConsolidatedPaymentType = {
  errorMessage: Maybe<Scalars['String']>
  invoices: Array<Invoice>
  payment: Maybe<Payment>
  success: Scalars['Boolean']
}

export type Counters = {
  all: Scalars['Int']
  allBySource: CountersBySource
  allInboxMessages: Scalars['Int']
  allUnread: Scalars['Int']
  archived: Scalars['Int']
  archivedInboxMessages: Scalars['Int']
  draftReceivables: Scalars['Int']
  failedReconciliationItems: Scalars['Int']
  /** @deprecated Use receivableInvoices instead */
  myInvoices: Scalars['Int']
  paid: Scalars['Int']
  paidReceivables: Scalars['Int']
  /** @deprecated Use unscheduled field instead */
  pending: Scalars['Int']
  receivableInvoices: Scalars['Int']
  receivables: Scalars['Int']
  reconciliationItemsInReview: Scalars['Int']
  reconciliationItemsOnHold: Scalars['Int']
  scheduled: Scalars['Int']
  scheduledReceivables: Scalars['Int']
  scheduledReconciliationItems: Scalars['Int']
  unreadBySource: CountersBySource
  unreadInboxMessages: Scalars['Int']
  unscheduled: Scalars['Int']
  unscheduledReceivables: Scalars['Int']
  vmcUnpaid: Scalars['Int']
}

export type CountersBySource = {
  email: Scalars['Int']
  eptFee: Scalars['Int']
  quickbooksImport: Scalars['Int']
  uploaded: Scalars['Int']
  vmc: Scalars['Int']
  vmcFee: Scalars['Int']
  xeroImport: Scalars['Int']
}

export type CountersBySyncObject = {
  catalogItems: Scalars['Int']
  invoices: Scalars['Int']
  payments: Scalars['Int']
  purchaseOrders: Scalars['Int']
  vendorCredits: Scalars['Int']
}

export type Country = {
  accountNumberType: VendorAccountNumberTypeEnum
  code: Scalars['String']
  domestic: Scalars['Boolean']
  name: Scalars['String']
}

/** Autogenerated return type of CreateBusiness. */
export type CreateBusinessPayload = {
  businessId: Scalars['String']
}

/** Autogenerated return type of CreateDocumentRequestItem. */
export type CreateDocumentRequestItemPayload = {
  success: Scalars['Boolean']
}

/** Autogenerated return type of CreateInvitationLink. */
export type CreateInvitationLinkPayload = {
  invitationLink: Scalars['String']
}

/** Autogenerated return type of CreateInvitation. */
export type CreateInvitationPayload = {
  invitationUrl: Scalars['String']
}

export type CreateOrderDocumentInputObject = {
  linkableOrderDocumentId: Scalars['ID']
  orderDocumentType: OrderDocumentTypeEnum
}

export type CreatePayableInvoiceResponse = {
  invoice: Invoice
  settleInboxMessage: SettleInboxMessage
}

export type CreateTotpResponse = {
  mfaToken: Scalars['ID']
  totpSetupInfo: TotpSetupInfo
}

/** Autogenerated return type of CreateUser. */
export type CreateUserPayload = {
  token: Scalars['ID']
  userId: Scalars['String']
}

export type CreateVendorCreditResponseType = {
  settleInboxMessage: SettleInboxMessage
  vendorCredit: VendorCredit
}

/** Autogenerated return type of CreateW9Link. */
export type CreateW9LinkPayload = {
  link: Scalars['String']
}

export type CreateW9ResponseType = {
  settleInboxMessage: SettleInboxMessage
  w9Form: Attachment
}

export type CreatedDocument = Attachment | Invoice | VendorCredit

export type Currency = {
  decimalMark: Scalars['String']
  exponent: Scalars['Int']
  isoCode: Scalars['String']
  subunitToUnit: Scalars['Int']
  symbol: Scalars['String']
  thousandsSeparator: Scalars['String']
}

export type Dashboard = {
  actionableItems: DashboardActionableItemConnection
  bankAccounts: Maybe<Array<DashboardBankAccount>>
  paid: Maybe<DashboardPaid>
  scheduledAndProcessing: Maybe<DashboardScheduledAndProcessing>
  toDos: Array<JourneyStep>
  totalOwe: Maybe<DashboardTotalOwe>
  unscheduled: Maybe<DashboardUnscheduled>
  virtualMarketingCard: Maybe<DashboardVirtualMarketingCard>
}

export type DashboardactionableItemsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DashboardActionableItem = {
  invoice: Maybe<Invoice>
  invoiceReasons: Array<TransferActionableItem>
  purchaseOrder: Maybe<PurchaseOrder>
  purchaseOrderReasons: Array<DashboardActionableItemReasonEnum>
  receivable: Maybe<Receivable>
  receivableReason: Maybe<DashboardActionableItemReasonEnum>
}

/** The connection type for DashboardActionableItem. */
export type DashboardActionableItemConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DashboardActionableItemEdge>>>
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<DashboardActionableItem>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type DashboardActionableItemEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: Maybe<DashboardActionableItem>
}

export enum DashboardActionableItemReasonEnum {
  AT_RISK_PAYMENT = 'AT_RISK_PAYMENT',
  EPT_EXTENSION_AVAILABLE = 'EPT_EXTENSION_AVAILABLE',
  FAILED_INVOICE = 'FAILED_INVOICE',
  FAILED_PAYMENT = 'FAILED_PAYMENT',
  GRN_UNLINKED_ORDER_DOCUMENTS = 'GRN_UNLINKED_ORDER_DOCUMENTS',
  NEEDS_REVIEW_SALES_CHANNEL_CONFIGURATIONS = 'NEEDS_REVIEW_SALES_CHANNEL_CONFIGURATIONS',
  OVERDUE_OPEN_BILL = 'OVERDUE_OPEN_BILL',
  PENDING_APPROVAL_BILL = 'PENDING_APPROVAL_BILL',
  PENDING_APPROVAL_PAYMENT = 'PENDING_APPROVAL_PAYMENT',
  PENDING_APPROVAL_PURCHASE_ORDER = 'PENDING_APPROVAL_PURCHASE_ORDER',
  PENDING_INVOICE = 'PENDING_INVOICE',
  READY_TO_EXTEND_PAYMENT = 'READY_TO_EXTEND_PAYMENT',
  RECEIVABLE_APPROVED = 'RECEIVABLE_APPROVED',
  RECEIVABLE_DEBIT_REQUESTED = 'RECEIVABLE_DEBIT_REQUESTED',
  REJECTED_BILL = 'REJECTED_BILL',
  REJECTED_PAYMENT = 'REJECTED_PAYMENT',
  UNKNOWN = 'UNKNOWN',
  UNLINKED_CATALOG_ITEMS = 'UNLINKED_CATALOG_ITEMS',
  UNPROCESSABLE_ACCOUNTS_PAYMENTS = 'UNPROCESSABLE_ACCOUNTS_PAYMENTS',
  VENDOR_W8_OR_W9_FORM_REQUIRED = 'VENDOR_W8_OR_W9_FORM_REQUIRED',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  WAITING_FOR_INVOICE_APPROVAL = 'WAITING_FOR_INVOICE_APPROVAL',
}

export type DashboardBankAccount = {
  balance: Maybe<Money>
  id: Scalars['String']
  maskedNumber: Maybe<Scalars['String']>
  name: Scalars['String']
  scheduledAndProcessing: DashboardGroupedAmounts
}

export type DashboardGroupedAmounts = {
  sevenDays: Money
  thirtyDays: Money
}

export type DashboardPaid = {
  payerToSettle: DashboardGroupedAmounts
  payerToVendors: DashboardGroupedAmounts
  payerToVendorsAndSettle: DashboardGroupedAmounts
  total: Money
}

export enum DashboardPermissionsEnum {
  DASHBOARD_WIDGETS = 'DASHBOARD_WIDGETS',
  FULL_TODO_LIST = 'FULL_TODO_LIST',
}

export type DashboardScheduledAndProcessing = {
  payerToSettle: DashboardGroupedAmounts
  payerToVendors: DashboardGroupedAmounts
  payerToVendorsAndSettle: DashboardGroupedAmounts
  total: Money
}

export type DashboardTotalOwe = {
  scheduledAndProcessing: Money
  total: Money
  unscheduled: Money
}

export type DashboardUnscheduled = {
  failed: Money
  overdue: Money
  payerToVendors: DashboardGroupedAmounts
  total: Money
}

export type DashboardVirtualMarketingCard = {
  availableCredit: Money
  creditLimit: Money
  currentPeriod: Money
  previousUnpaid: Money
}

export type DataFieldsInput = {
  communication?: InputMaybe<Array<SectionFieldsInput>>
  countrySpecific?: InputMaybe<Array<SectionFieldsInput>>
  driverLicence?: InputMaybe<Array<SectionFieldsInput>>
  location?: InputMaybe<Array<SectionFieldsInput>>
  nationalIds?: InputMaybe<Array<SectionFieldsInput>>
  passport?: InputMaybe<Array<SectionFieldsInput>>
  personInfo?: InputMaybe<Array<SectionFieldsInput>>
}

export enum DateFilterPresetEnum {
  AFTER_TODAY = 'AFTER_TODAY',
  ALL_DATES = 'ALL_DATES',
  BEFORE_TODAY = 'BEFORE_TODAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  NEXT_7_DAYS = 'NEXT_7_DAYS',
  NEXT_30_DAYS = 'NEXT_30_DAYS',
  NOT_SET = 'NOT_SET',
  THIS_MONTH = 'THIS_MONTH',
  THIS_QUARTER = 'THIS_QUARTER',
  THIS_YEAR = 'THIS_YEAR',
}

export type DateFilteringInputObject = {
  from?: InputMaybe<Scalars['ISO8601Date']>
  /** Use presetEnum instead */
  preset?: InputMaybe<Scalars['String']>
  presetEnum?: InputMaybe<DateFilterPresetEnum>
  to?: InputMaybe<Scalars['ISO8601Date']>
}

export enum DeferralPeriodEnum {
  DAYS_03 = 'DAYS_03',
  DAYS_07 = 'DAYS_07',
  DAYS_15 = 'DAYS_15',
  DAYS_30 = 'DAYS_30',
  DAYS_45 = 'DAYS_45',
  DAYS_60 = 'DAYS_60',
}

export type DeferralsRequestsInputObject = {
  explanation?: InputMaybe<Scalars['String']>
  reconciliationItemsData: Array<DeferralsRequestsItemsInputObject>
}

export type DeferralsRequestsItemsInputObject = {
  reconciliationItemId: Scalars['ID']
  requestedDeferralLength: DeferralPeriodEnum
}

export type DeleteCommentPayload = {
  success: Scalars['Boolean']
}

export type DeletePayload = {
  deletedIds: Array<Scalars['ID']>
}

export type DeleteRoleResponse = {
  deletedRole: UserRole
  teamMembers: Maybe<TeamMembers>
}

export type Disclosures = {
  payeeAgentDisclosure: Scalars['String']
}

export enum DiscrepancyDocumentEnum {
  INVOICE = 'INVOICE',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  RECEIPT = 'RECEIPT',
}

export enum DiscrepancyFieldEnum {
  PRICE = 'PRICE',
  QUANTITY = 'QUANTITY',
  TOTAL = 'TOTAL',
}

export type DiscrepancyRule = {
  id: Scalars['ID']
  thresholds: Array<DiscrepancyThreshold>
  vendorLocalBusiness: Maybe<LocalBusiness>
}

export enum DiscrepancyRulePermissionsEnum {
  CREATE_DISCREPANCY_RULE = 'CREATE_DISCREPANCY_RULE',
  DELETE_DISCREPANCY_RULE = 'DELETE_DISCREPANCY_RULE',
  UPDATE_DISCREPANCY_RULE = 'UPDATE_DISCREPANCY_RULE',
  VIEW_DISCREPANCY_RULES = 'VIEW_DISCREPANCY_RULES',
}

export type DiscrepancyThreshold = {
  field: DiscrepancyFieldEnum
  id: Scalars['ID']
  sourceDocumentType: DiscrepancyDocumentEnum
  targetDocumentType: DiscrepancyDocumentEnum
  threshold: Maybe<Scalars['Float']>
}

export type DiscrepancyThresholdInputObject = {
  field: DiscrepancyFieldEnum
  id?: InputMaybe<Scalars['ID']>
  sourceDocumentType: DiscrepancyDocumentEnum
  targetDocumentType: DiscrepancyDocumentEnum
  threshold?: InputMaybe<Scalars['Float']>
}

export enum DocumentCategoryEnum {
  ARTICLES_OF_INCORPORATION = 'ARTICLES_OF_INCORPORATION',
  BALANCE_SHEET = 'BALANCE_SHEET',
  BANK_STATEMENT = 'BANK_STATEMENT',
  BUSINESS_TAX_RETURN = 'BUSINESS_TAX_RETURN',
  DBA_CERTIFICATION_ASSUMED_NAME_CERTIFICATION = 'DBA_CERTIFICATION_ASSUMED_NAME_CERTIFICATION',
  ID_CARD = 'ID_CARD',
  LOAN_AGREEMENT = 'LOAN_AGREEMENT',
  OPERATING_AGREEMENT_CORORATE_BYLAWS = 'OPERATING_AGREEMENT_CORORATE_BYLAWS',
  PASSPORT = 'PASSPORT',
  PROFIT_AND_LOSS = 'PROFIT_AND_LOSS',
  PROOF_OF_SOS_FILING = 'PROOF_OF_SOS_FILING',
  REVENUE_FORECAST = 'REVENUE_FORECAST',
  SS4 = 'SS4',
  SSN9_DOCUMENT = 'SSN9_DOCUMENT',
}

export type DocumentReference = {
  document: Maybe<LinkedDocument>
  documentType: DocumentReferenceTypeEnum
  id: Scalars['ID']
  linkedDocuments: Array<LinkedDocument>
  number: Maybe<Scalars['String']>
}

export type DocumentReferenceCreateInputObject = {
  documentType: DocumentReferenceTypeEnum
  number: Scalars['String']
}

export enum DocumentReferenceTypeEnum {
  INVOICE = 'INVOICE',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  RECEIPT = 'RECEIPT',
}

export type DocumentReferenceUpdateInputObject = {
  number?: InputMaybe<Scalars['String']>
}

export enum DocumentTypeEnum {
  ARTICLES_OF_INCORPORATION = 'ARTICLES_OF_INCORPORATION',
  BALANCE_SHEET = 'BALANCE_SHEET',
  BANK_STATEMENT = 'BANK_STATEMENT',
  BUSINESS_TAX_RETURN = 'BUSINESS_TAX_RETURN',
  DBA_CERTIFICATION_ASSUMED_NAME_CERTIFICATION = 'DBA_CERTIFICATION_ASSUMED_NAME_CERTIFICATION',
  ID_CARD = 'ID_CARD',
  LOAN_AGREEMENT = 'LOAN_AGREEMENT',
  OPERATING_AGREEMENT_CORORATE_BYLAWS = 'OPERATING_AGREEMENT_CORORATE_BYLAWS',
  PASSPORT = 'PASSPORT',
  PROFIT_AND_LOSS = 'PROFIT_AND_LOSS',
  PROOF_OF_SOS_FILING = 'PROOF_OF_SOS_FILING',
  REVENUE_FORECAST = 'REVENUE_FORECAST',
  SS4 = 'SS4',
  SSN9_DOCUMENT = 'SSN9_DOCUMENT',
}

export type DocumentsMatchingData = {
  discrepancies: LinkedDocumentDiscrepancies
  invoicesLinesTotal: LineItemsMatchingTotal
  matchedLines: Array<MatchedLine>
  matchingDocuments: Array<LinkedDocument>
  purchaseOrdersLinesTotal: LineItemsMatchingTotal
  receiptsLinesTotal: LineItemsMatchingTotal
  unmatchedBillLines: Array<LineItemDetails>
  /** @deprecated Use unmatchedBillLines and unmatchedPurchaseOrderLines instead */
  unmatchedLines: Array<UnmatchedLine>
  unmatchedPurchaseOrderLines: Array<LineItemDetails>
  unmatchedReceiptLines: Array<LineItemDetails>
}

export type EmailOptOut = {
  email: Scalars['String']
  emailAction: Scalars['String']
}

export enum EmailTypeEnum {
  GOOGLE = 'GOOGLE',
  INVALID = 'INVALID',
  UNKNOWN = 'UNKNOWN',
}

export enum EptFilterEnum {
  FILTER_AMRT = 'FILTER_AMRT',
  FILTER_AMRT30 = 'FILTER_AMRT30',
  FILTER_AMRT60 = 'FILTER_AMRT60',
  FILTER_AMRT90 = 'FILTER_AMRT90',
  FILTER_AMRT120 = 'FILTER_AMRT120',
  FILTER_AMRT150 = 'FILTER_AMRT150',
  FILTER_AMRT180 = 'FILTER_AMRT180',
  FILTER_AMRT180PLUS = 'FILTER_AMRT180PLUS',
  FILTER_EPT = 'FILTER_EPT',
  FILTER_EPT30 = 'FILTER_EPT30',
  FILTER_EPT60 = 'FILTER_EPT60',
  FILTER_EPT90 = 'FILTER_EPT90',
  FILTER_EPT120 = 'FILTER_EPT120',
  FILTER_EPT120PLUS = 'FILTER_EPT120PLUS',
}

export type EptMetricVendorTotal = {
  paid: Scalars['Int']
  unpaid: Scalars['Int']
}

export type EptMetrics = {
  cashAdvance: EptMetricVendorTotal
  days30: EptMetricVendorTotal
  days30Amortized: EptMetricVendorTotal
  days60: EptMetricVendorTotal
  days60Amortized: EptMetricVendorTotal
  days90: EptMetricVendorTotal
  days90Amortized: EptMetricVendorTotal
  days120: EptMetricVendorTotal
  days120Amortized: EptMetricVendorTotal
  days120Plus: EptMetricVendorTotal
  days150Amortized: EptMetricVendorTotal
  days180Amortized: EptMetricVendorTotal
  days180PlusAmortized: EptMetricVendorTotal
}

export type EptRate = {
  eptLength: Scalars['Int']
  eptRate: Maybe<Scalars['Float']>
  id: Scalars['ID']
  minEptThresholdCents: Scalars['Int']
  numEptMax: Scalars['Int']
}

export type ExchangeFeeData = {
  exchangeFeeAmount: Maybe<Money>
  exchangeRate: Maybe<Scalars['String']>
  totalUsdAmount: Money
  usdAmount: Money
}

export type ExternalPayableLineItemInputObject = {
  accountingAccountId?: InputMaybe<Scalars['ID']>
  accountingCategoryClassId?: InputMaybe<Scalars['ID']>
  accountingCategoryDepartmentId?: InputMaybe<Scalars['ID']>
  accountingCategoryLocationId?: InputMaybe<Scalars['ID']>
  accountingCategoryOneId?: InputMaybe<Scalars['ID']>
  accountingCategoryTwoId?: InputMaybe<Scalars['ID']>
  catalogItemId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  quickbooksCategoryId?: InputMaybe<Scalars['ID']>
  quickbooksClassId?: InputMaybe<Scalars['ID']>
  trackingCategoryOneId?: InputMaybe<Scalars['ID']>
  trackingCategoryTwoId?: InputMaybe<Scalars['ID']>
  xeroCategoryId?: InputMaybe<Scalars['ID']>
}

export enum FailedEntityTypeEnum {
  INVOICE = 'INVOICE',
  PAYMENT = 'PAYMENT',
}

export type FinaloopAccount = {
  active: Scalars['Boolean']
  id: Scalars['ID']
  name: Scalars['String']
}

export type FinaloopState = {
  accounts: Array<FinaloopAccount>
  connected: Scalars['Boolean']
  filteredAccounts: Array<FinaloopAccount>
  pullFinancials: Scalars['Boolean']
}

export type FinancingNudgeBanner = {
  qboBillsToNudge: Array<Scalars['ID']>
  settleInvoicesToNudge: Scalars['Int']
}

export type FinancingOptions = {
  /** Details of amortized loan offer when it's available */
  amortizedOffer: Maybe<AmortizedLoan>
  /** Details of EPT offers when EPT is available */
  bulletEptOffers: Maybe<Array<BulletEpt>>
  /** Details of EPT offers when EPT is available */
  eptOffers: Maybe<Array<InvoiceExtendedPaymentTermOffer>>
  /** Estimated range of payment arrival to vendor */
  etaRange: Maybe<Array<Scalars['ISO8601Date']>>
  /** Planned date of initiating payment to vendor */
  vendorPaymentDate: Maybe<Scalars['ISO8601Date']>
}

export type FinicityLink = BankLink & {
  deleted: Scalars['Boolean']
  fixAvailable: Maybe<BankLinkFixEnum>
  id: Scalars['ID']
  name: Scalars['String']
}

export type FractionalMoneyInputObject = {
  cents: Scalars['Float']
  currency: Scalars['String']
}

/** Autogenerated return type of FraudBusinessReviewApprove. */
export type FraudBusinessReviewApprovePayload = {
  success: Scalars['Boolean']
}

export type FullRepaymentBalance = {
  interest: Money
  principal: Money
  total: Money
}

export type Holiday = {
  date: Scalars['ISO8601Date']
  name: Scalars['String']
}

export enum IconEnum {
  ADDED_TO_PAYABLES = 'ADDED_TO_PAYABLES',
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED',
  GENERIC = 'GENERIC',
  LIGHTNING = 'LIGHTNING',
  ON_APPROVAL = 'ON_APPROVAL',
  PAID = 'PAID',
  RECEIVED = 'RECEIVED',
  REJECTED = 'REJECTED',
  SCHEDULED = 'SCHEDULED',
  SENT = 'SENT',
}

export type InfinitePrecisionMoney = {
  cents: Scalars['Float']
  currency: Scalars['String']
  currencySymbol: Scalars['String']
  formatted: Scalars['String']
  formattedNoCents: Scalars['String']
  subunitToUnit: Scalars['Int']
}

export type InformationRequest = {
  email: Maybe<Scalars['String']>
  id: Scalars['ID']
  informationType: InformationRequestTypeEnum
  localBusiness: PublicLocalBusiness
  requestedAt: Scalars['ISO8601DateTime']
  status: InformationRequestStatusEnum
  verifyBusinessLink: Maybe<Scalars['String']>
}

export enum InformationRequestStatusEnum {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  REQUESTED = 'REQUESTED',
}

export enum InformationRequestTypeEnum {
  W8_OR_W9 = 'W8_OR_W9',
}

export type Integration = NetsuiteState | QuickBooksState | XeroState

export enum IntegrationSourceEnum {
  QUICKBOOKS = 'QUICKBOOKS',
  SHOPIFY = 'SHOPIFY',
  TRACKSTAR = 'TRACKSTAR',
}

export enum IntegrationSourceTypeEnum {
  ACCOUNTING = 'ACCOUNTING',
  LOCATIONS = 'LOCATIONS',
  SALES = 'SALES',
}

export type IntegrationsState = {
  a2x: IntegrationsStateInfo
}

export type IntegrationsStateInfo = {
  connected: Scalars['Boolean']
}

export type InternationalWireFieldsDataFieldInputObject = {
  id?: InputMaybe<Scalars['ID']>
  value?: InputMaybe<Scalars['String']>
}

export type InternationalWireFieldsDataFieldType = {
  country: Scalars['String']
  currency: Maybe<Scalars['String']>
  description: Scalars['String']
  id: Scalars['ID']
  label: Scalars['String']
  length: Maybe<Scalars['Int']>
  propertyKey: InternationalWirePropertyKeyTypeEnum
  regex: Maybe<Scalars['String']>
  targetType: InternationalWireTargetTypeEnum
}

export type InternationalWireFieldsDataFieldValueType = {
  id: Scalars['ID']
  value: Maybe<Scalars['String']>
}

export enum InternationalWirePropertyKeyTypeEnum {
  BANK_NAME = 'BANK_NAME',
  CNAPS = 'CNAPS',
  LOCAL_BANK_CODE = 'LOCAL_BANK_CODE',
}

export enum InternationalWireTargetTypeEnum {
  ACCOUNT = 'ACCOUNT',
  PAYMENT = 'PAYMENT',
}

export type InventoryConnector = {
  redirectUri: Scalars['String']
}

export type InventoryDemandForecastFilteringInputObject = {
  catalogItemVariationId?: InputMaybe<Scalars['ID']>
  itemName?: InputMaybe<Scalars['String']>
  salesChannelId?: InputMaybe<Scalars['ID']>
  sku?: InputMaybe<Scalars['String']>
}

export enum InventoryDemandForecastPeriod {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export enum InventoryDemandForecastSortableFieldEnum {
  ITEM_NAME = 'ITEM_NAME',
  PRIMARY_SKU = 'PRIMARY_SKU',
}

export enum InventoryDemandForecastSortingInputDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type InventoryDemandForecastSortingInputObject = {
  direction: InventoryDemandForecastSortingInputDirectionEnum
  field: InventoryDemandForecastSortableFieldEnum
}

export type InventoryItemDemandForecast = {
  catalogItemVariation: CatalogItemVariation
  channelForecast: Array<ChannelDemandForecastData>
  manualForecast: Array<ManualDemandForecastMetadata>
  period: InventoryDemandForecastPeriod
  totalForecast: Array<TotalDemandForecastData>
  updatedAt: Scalars['ISO8601DateTime']
}

export enum InventoryPermissionsEnum {
  CREATE_CATALOG_ITEM = 'CREATE_CATALOG_ITEM',
  CREATE_LOCATION = 'CREATE_LOCATION',
  CREATE_MANUAL_INVENTORY_DEMAND_FORECAST = 'CREATE_MANUAL_INVENTORY_DEMAND_FORECAST',
  DELETE_CATALOG_ITEM = 'DELETE_CATALOG_ITEM',
  DELETE_LOCATION = 'DELETE_LOCATION',
  DELETE_MANUAL_INVENTORY_DEMAND_FORECAST = 'DELETE_MANUAL_INVENTORY_DEMAND_FORECAST',
  EDIT_CATALOG_SYNC_CENTER = 'EDIT_CATALOG_SYNC_CENTER',
  EDIT_INVENTORY_BALANCE = 'EDIT_INVENTORY_BALANCE',
  LINK_ECOMMERCE_SERVICE = 'LINK_ECOMMERCE_SERVICE',
  UPDATE_CATALOG_ITEM = 'UPDATE_CATALOG_ITEM',
  UPDATE_LOCATION = 'UPDATE_LOCATION',
  UPDATE_MANUAL_INVENTORY_DEMAND_FORECAST = 'UPDATE_MANUAL_INVENTORY_DEMAND_FORECAST',
  VIEW_CATALOG_SYNC_CENTER = 'VIEW_CATALOG_SYNC_CENTER',
  VIEW_INVENTORY_DEMAND_FORECAST = 'VIEW_INVENTORY_DEMAND_FORECAST',
}

export type InventoryTransfer = {
  additionalExpenses: Array<AdditionalExpense>
  archived: Scalars['Boolean']
  attachment: Maybe<Attachment>
  createdAt: Scalars['ISO8601DateTime']
  fullyReceivedAt: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  issueDate: Maybe<Scalars['ISO8601Date']>
  lineItems: Array<InventoryTransferLineItem>
  linkedDocuments: Array<LinkedDocument>
  number: Maybe<Scalars['String']>
  receiveByDate: Maybe<Scalars['ISO8601Date']>
  receivingLocation: Maybe<Location>
  sendingLocation: Maybe<Location>
  status: InventoryTransferStatusEnum
  trackingNumber: Maybe<Scalars['String']>
}

export enum InventoryTransferArchivedStatusEnum {
  ACTIVE_AND_ARCHIVED = 'ACTIVE_AND_ARCHIVED',
  ACTIVE_ONLY = 'ACTIVE_ONLY',
  ARCHIVED_ONLY = 'ARCHIVED_ONLY',
}

export type InventoryTransferInputObject = {
  issueDate?: InputMaybe<Scalars['ISO8601Date']>
  number?: InputMaybe<Scalars['String']>
  receiveByDate?: InputMaybe<Scalars['ISO8601Date']>
  receivingLocationId?: InputMaybe<Scalars['ID']>
  sendingLocationId?: InputMaybe<Scalars['ID']>
  status?: InputMaybe<InventoryTransferStatusEnum>
  trackingNumber?: InputMaybe<Scalars['String']>
}

export type InventoryTransferLineItem = {
  catalogItemVariation: Maybe<CatalogItemVariation>
  id: Scalars['ID']
  position: Scalars['Int']
  quantity: Maybe<Scalars['Float']>
}

export type InventoryTransferLineItemInputObject = {
  catalogItemOrVariationId?: InputMaybe<Scalars['ID']>
  quantity?: InputMaybe<Scalars['Float']>
}

export type InventoryTransferLocationEmailInputObject = {
  email: Scalars['String']
  message?: InputMaybe<Scalars['String']>
}

export enum InventoryTransferPermissionsEnum {
  CREATE_INVENTORY_TRANSFER = 'CREATE_INVENTORY_TRANSFER',
  UPDATE_INVENTORY_TRANSFER = 'UPDATE_INVENTORY_TRANSFER',
  UPDATE_INVENTORY_TRANSFER_ARCHIVED = 'UPDATE_INVENTORY_TRANSFER_ARCHIVED',
  VIEW_INVENTORY_TRANSFER = 'VIEW_INVENTORY_TRANSFER',
}

export enum InventoryTransferSortableFieldEnum {
  CREATED_AT = 'CREATED_AT',
  ISSUE_DATE = 'ISSUE_DATE',
  NUMBER = 'NUMBER',
  RECEIVE_BY_DATE = 'RECEIVE_BY_DATE',
}

export enum InventoryTransferSortingDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type InventoryTransferSortingInputObject = {
  direction: InventoryTransferSortingDirectionEnum
  field: InventoryTransferSortableFieldEnum
}

export enum InventoryTransferStatusEnum {
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
}

export type InventoryTransfersFilteringInputObject = {
  archivedStatus?: InputMaybe<InventoryTransferArchivedStatusEnum>
  ids?: InputMaybe<Array<Scalars['ID']>>
  number?: InputMaybe<Scalars['String']>
  statuses?: InputMaybe<Array<InventoryTransferStatusEnum>>
}

export type Invitation = {
  businessName: Scalars['String']
  email: Maybe<Scalars['String']>
  existingUser: Maybe<Profile>
  id: Scalars['ID']
  occupation: Maybe<Scalars['String']>
  role: Maybe<MembershipRolesEnum>
  userRole: Maybe<UserRole>
}

/** Autogenerated return type of InvitePayer. */
export type InvitePayerPayload = {
  localBusiness: Maybe<LocalBusiness>
}

/** Autogenerated return type of InviteVendor. */
export type InviteVendorPayload = {
  invoice: Maybe<Invoice>
  localBusiness: Maybe<LocalBusiness>
}

export type Invoice = {
  /** @deprecated Use pay_from_account field instead */
  account: Maybe<Account>
  accountingCategoryClassId: Maybe<Scalars['ID']>
  accountingCategoryDepartmentId: Maybe<Scalars['ID']>
  accountingCategoryLocationId: Maybe<Scalars['ID']>
  accountingMemo: Maybe<Scalars['String']>
  accountingSyncEvents: Maybe<Array<SyncEvent>>
  accountingUrl: Maybe<Scalars['String']>
  actions: InvoiceActions
  allocations: Maybe<Array<Allocation>>
  allowAccountChange: Scalars['Boolean']
  allowPaymentCardMethod: Maybe<Scalars['Boolean']>
  allowSkipVendorInvitation: Scalars['Boolean']
  allowVendorChange: Scalars['Boolean']
  amount: Maybe<Money>
  applicableVendorCredits: Array<ApplicableVendorCredit>
  approval: Maybe<Approval>
  archived: Scalars['Boolean']
  attachments: Array<Attachment>
  auditTrailEvents: Array<AuditTrailEvents>
  balanceDue: Maybe<Money>
  balanceDueToSettle: Maybe<Money>
  billInCin7: Scalars['Boolean']
  cancelledAllocations: Maybe<Array<Allocation>>
  cin7BillUrl: Maybe<Scalars['String']>
  createdAt: Scalars['ISO8601DateTime']
  createdForPayment: Maybe<Payment>
  /** @deprecated Use vendorCurrency.isoCode instead */
  currency: Maybe<Scalars['String']>
  deletable: Scalars['Boolean']
  description: Maybe<Scalars['String']>
  discrepancyAmount: Maybe<Money>
  dueDate: Maybe<Scalars['ISO8601Date']>
  dueDays: Maybe<Scalars['Int']>
  dueToVendorAmount: Maybe<Money>
  failedPayments: Array<Payment>
  /** Total of all fee associated with EPT agreements accepted by customer for the invoice. */
  feeAmount: Maybe<Money>
  hasAccessToView: Scalars['Boolean']
  hasUnprocessedTransfers: Scalars['Boolean']
  id: Scalars['ID']
  inboundProcessing: Scalars['Boolean']
  invoiceApproval: Maybe<InvoiceApproval>
  invoiceApprovalRule: Maybe<InvoiceApprovalRule>
  invoiceErrorMessages: Maybe<Array<InvoiceErrorMessage>>
  isExtendedPaymentTermFeeInvoice: Scalars['Boolean']
  isExtendedPaymentTermInvoice: Scalars['Boolean']
  isMarketingVirtualCardAggregatedInvoice: Scalars['Boolean']
  isMarketingVirtualCardFeeInvoice: Scalars['Boolean']
  isRelatedPaymentsExists: Scalars['Boolean']
  issueDate: Maybe<Scalars['ISO8601Date']>
  labels: Array<Label>
  lastAttached: Maybe<Attachment>
  liabilityAmount: Maybe<Money>
  lineItems: Array<InvoiceLineItem>
  linkedDocuments: Array<LinkedDocument>
  linkedDocumentsDiscrepancy: Scalars['Boolean']
  markedAsPaid: Scalars['Boolean']
  markedAsPaidBy: Maybe<Approver>
  markedAsPaidFromAccount: Maybe<Account>
  /** Date the 'mark invoice paid' action was taken */
  markedAsPaidOn: Maybe<Scalars['ISO8601Date']>
  marketingVirtualCardInvoice: Maybe<InvoiceMarketingVirtualCard>
  memo: Maybe<Scalars['String']>
  /** @deprecated use nearestProcessingDate query instead */
  nearestProcessingDate: Maybe<Scalars['ISO8601Date']>
  /** @deprecated use nearestProcessingDate query instead */
  nearestProcessingDateEpt: Maybe<Scalars['ISO8601Date']>
  number: Maybe<Scalars['String']>
  paidOn: Maybe<Scalars['ISO8601Date']>
  paidWithMethod: Maybe<PaymentMethodEnum>
  paperCheck: Scalars['Boolean']
  paperCheckDetails: Maybe<PaperCheckDetails>
  payFromAccount: Maybe<Account>
  payToAccount: Maybe<LocalBusinessAccount>
  payableActionIndicator: Maybe<PayableActionIndicatorEnum>
  payerBusiness: Maybe<PublicBusiness>
  payerLocalBusiness: Maybe<LocalBusiness>
  payerTaxpayerId: Maybe<Scalars['String']>
  paymentAccountingAccount: Maybe<AccountingAccount>
  paymentAccountingAccountId: Maybe<Scalars['ID']>
  paymentCard: Maybe<PaymentCard>
  paymentTermDays: Maybe<Scalars['Int']>
  paymentsTotalAmount: Money
  payoutReconciliationItems: Maybe<Array<ReconciliationItem>>
  /** Only query for this field for single invoices. Querying at volume may introduce high latency */
  possibleDuplicates: Array<Invoice>
  purposeCode: Maybe<Scalars['String']>
  read: Scalars['Boolean']
  recommendedAccount: Maybe<Account>
  recommendedAccountWarning: Maybe<Scalars['String']>
  recommendedPaymentMethod: Maybe<PaymentMethodEnum>
  relatedInvoices: Array<Invoice>
  selectedForFinancing: Scalars['Boolean']
  settleInboxMessage: Maybe<SettleInboxMessage>
  shippingVendors: Array<LocalBusiness>
  showDiscrepancyAlert: Scalars['Boolean']
  showSyncHistory: Maybe<Scalars['Boolean']>
  signedDocuments: Array<SignedDocument>
  skipVendorInvitation: Scalars['Boolean']
  source: Maybe<InvoiceSourceEnum>
  status: InvoiceStatusEnum
  /** @deprecated Use statusesForBadge */
  statuses: Array<InvoiceStatusEnum>
  statusesForBadge: Array<InvoiceStatusEnum>
  syncCurrency: Maybe<Scalars['String']>
  syncError: Maybe<SyncError>
  syncObjectStatus: Maybe<SyncObjectStatus>
  syncPossible: Maybe<Scalars['Boolean']>
  timelineEvents: Maybe<Array<TimelineEvent>>
  totalUsdAmount: Maybe<Money>
  transfers: Array<Transfer>
  unscheduledAmount: Maybe<Money>
  uploadedByCurrentBusiness: Scalars['Boolean']
  /** @deprecated Use pay_to_account field instead */
  vendorAccount: Maybe<LocalBusinessAccount>
  vendorAmount: Maybe<Money>
  vendorBusiness: Maybe<PublicBusiness>
  vendorCreditApplications: Array<VendorCreditApplication>
  vendorCreditAppliedTotal: Money
  vendorCurrency: Maybe<Currency>
  vendorInvited: Scalars['Boolean']
  vendorLocalBusiness: Maybe<LocalBusiness>
  vendorReceivedAmount: Money
}

export type InvoiceActions = {
  addNewPayment: Action
  applyVendorCredit: Action
  bulkPay: Action
  cancelVendorFinancing: Action
  createLinkedPurchaseOrder: Action
  createLinkedReceipt: Action
  financeInvoice: Action
  markPaid: Action
  markUnpaid: Action
  partialPayment: Action
  requestRuleBasedApproval: Action
  sendInvoice: Action
  updateAmount: Action
  updateArchived: Action
  updateCurrency: Action
  updateVendor: Action
  updateVendorAccount: Action
}

export type InvoiceAddressValidationType = {
  addressValidation: AddressValidation
  invoice: Invoice
}

export type InvoiceAmountFilteringInputObject = {
  from?: InputMaybe<Scalars['Int']>
  to?: InputMaybe<Scalars['Int']>
}

export type InvoiceApproval = {
  approvedBy: Maybe<Array<Approver>>
  approvedOrRejectedOn: Maybe<Scalars['ISO8601Date']>
  approvers: Maybe<Array<Scalars['ID']>>
  requestingUser: Maybe<Approver>
}

export type InvoiceApprovalRule = {
  approvalRule: Maybe<Rule>
  approvalSteps: Maybe<Array<InvoiceApprovalStep>>
}

export type InvoiceApprovalStep = {
  approvalType: RuleActionConditionEnum
  approvers: Maybe<Array<Approver>>
  stepNumber: Maybe<Scalars['Int']>
}

export enum InvoiceArchivedStatusEnum {
  ACTIVE_AND_ARCHIVED = 'ACTIVE_AND_ARCHIVED',
  ACTIVE_ONLY = 'ACTIVE_ONLY',
  ARCHIVED_ONLY = 'ARCHIVED_ONLY',
}

export type InvoiceDateFilteringOption = {
  code: Maybe<Scalars['String']>
  empty: Scalars['Boolean']
  from: Maybe<Scalars['ISO8601Date']>
  name: Maybe<Scalars['String']>
  to: Maybe<Scalars['ISO8601Date']>
}

export type InvoiceDateFilteringOptions = {
  field: InvoiceSortableFieldEnum
  presets: Array<InvoiceDateFilteringOption>
}

export type InvoiceErrorMessage = {
  code: Maybe<Scalars['String']>
  failedEntityType: FailedEntityTypeEnum
  message: Maybe<Scalars['String']>
}

export enum InvoiceEventTypeEnum {
  APPLIED_VENDOR_CREDITS_CLOSED = 'APPLIED_VENDOR_CREDITS_CLOSED',
  APPLIED_VENDOR_CREDITS_UPDATED = 'APPLIED_VENDOR_CREDITS_UPDATED',
  COLLECTION_EMAIL_SENT = 'COLLECTION_EMAIL_SENT',
  EPT_AGREEMENT_SIGNED = 'EPT_AGREEMENT_SIGNED',
  EPT_TERMS_CHANGED = 'EPT_TERMS_CHANGED',
  INVOICE_APPROVAL_CANCELLED = 'INVOICE_APPROVAL_CANCELLED',
  INVOICE_APPROVAL_CANCELLED_PLAN_CHANGE = 'INVOICE_APPROVAL_CANCELLED_PLAN_CHANGE',
  INVOICE_APPROVAL_REQUESTED = 'INVOICE_APPROVAL_REQUESTED',
  INVOICE_APPROVED = 'INVOICE_APPROVED',
  INVOICE_ARCHIVED = 'INVOICE_ARCHIVED',
  INVOICE_CREATED_BY_EMAIL = 'INVOICE_CREATED_BY_EMAIL',
  INVOICE_CREATED_BY_IMPORT = 'INVOICE_CREATED_BY_IMPORT',
  INVOICE_CREATED_BY_USER = 'INVOICE_CREATED_BY_USER',
  INVOICE_CREATED_FROM_SETTLE_INBOX = 'INVOICE_CREATED_FROM_SETTLE_INBOX',
  INVOICE_MARKED_AS_PAID = 'INVOICE_MARKED_AS_PAID',
  INVOICE_MARKED_AS_UNPAID = 'INVOICE_MARKED_AS_UNPAID',
  INVOICE_RECEIVED_IN_SETTLE_INBOX = 'INVOICE_RECEIVED_IN_SETTLE_INBOX',
  INVOICE_REJECTED = 'INVOICE_REJECTED',
  INVOICE_SENT = 'INVOICE_SENT',
  INVOICE_VIEWED_BY_PAYER = 'INVOICE_VIEWED_BY_PAYER',
  PAYMENT_APPROVAL_CANCELLED = 'PAYMENT_APPROVAL_CANCELLED',
  PAYMENT_APPROVAL_CANCELLED_PLAN_CHANGE = 'PAYMENT_APPROVAL_CANCELLED_PLAN_CHANGE',
  PAYMENT_APPROVAL_REQUESTED = 'PAYMENT_APPROVAL_REQUESTED',
  PAYMENT_APPROVED = 'PAYMENT_APPROVED',
  PAYMENT_CANCELLED = 'PAYMENT_CANCELLED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_FROM_PAYER_PAID = 'PAYMENT_FROM_PAYER_PAID',
  PAYMENT_FROM_PAYER_PROCESSED = 'PAYMENT_FROM_PAYER_PROCESSED',
  PAYMENT_FULLY_REVERSED = 'PAYMENT_FULLY_REVERSED',
  PAYMENT_ON_HOLD = 'PAYMENT_ON_HOLD',
  PAYMENT_PARTIALLY_REPAID_EARLY = 'PAYMENT_PARTIALLY_REPAID_EARLY',
  PAYMENT_REFUND_COMPLETED = 'PAYMENT_REFUND_COMPLETED',
  PAYMENT_REFUND_INITIATED = 'PAYMENT_REFUND_INITIATED',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
  PAYMENT_RELEASED = 'PAYMENT_RELEASED',
  PAYMENT_REPAID_EARLY = 'PAYMENT_REPAID_EARLY',
  PAYMENT_RESCHEDULED = 'PAYMENT_RESCHEDULED',
  PAYMENT_RETRIED = 'PAYMENT_RETRIED',
  PAYMENT_SCHEDULED = 'PAYMENT_SCHEDULED',
  PAYMENT_TO_VENDOR_PAID = 'PAYMENT_TO_VENDOR_PAID',
  PAYMENT_TO_VENDOR_PAPER_CHECK_CASHED = 'PAYMENT_TO_VENDOR_PAPER_CHECK_CASHED',
  PAYMENT_TO_VENDOR_PROCESSED = 'PAYMENT_TO_VENDOR_PROCESSED',
  RECEIVABLE_PURCHASED = 'RECEIVABLE_PURCHASED',
  RECEIVABLE_PURCHASE_CANCELED = 'RECEIVABLE_PURCHASE_CANCELED',
  SWITCH_TO_PRO = 'SWITCH_TO_PRO',
  VENDOR_CREDIT_ADDED = 'VENDOR_CREDIT_ADDED',
  VENDOR_CREDIT_APPLIED = 'VENDOR_CREDIT_APPLIED',
  VENDOR_CREDIT_CLOSED = 'VENDOR_CREDIT_CLOSED',
  VENDOR_CREDIT_CREATED = 'VENDOR_CREDIT_CREATED',
  VENDOR_CREDIT_DELETED = 'VENDOR_CREDIT_DELETED',
  VENDOR_CREDIT_UNAPPLIED = 'VENDOR_CREDIT_UNAPPLIED',
  W8_OR_W9_EMAIL_REQUEST_SENT = 'W8_OR_W9_EMAIL_REQUEST_SENT',
  W8_OR_W9_SUBMITTED = 'W8_OR_W9_SUBMITTED',
}

export type InvoiceExtendedPaymentTermConfirmation = {
  additionalDays: Scalars['Int']
  agreement: SignedDocument
  dueDate: Scalars['ISO8601Date']
  feeAmount: Money
  feeInvoice: Maybe<Invoice>
  feePaymentDate: Scalars['ISO8601Date']
  feePercent: Scalars['Float']
  id: Scalars['ID']
  payerPaymentDate: Scalars['ISO8601Date']
}

export type InvoiceExtendedPaymentTermOffer = {
  additionalDays: Scalars['Int']
  feeAmount: Maybe<Money>
  feeAmountWithRepayments: Maybe<Money>
  feeInvoice: Maybe<Invoice>
  feePaymentDate: Scalars['ISO8601Date']
  feePercent: Scalars['Float']
  id: Scalars['ID']
  payerPaymentDate: Maybe<Scalars['ISO8601Date']>
  status: OfferStatusEnum
}

export type InvoiceFilteringOptions = {
  createdAt: Array<InvoiceDateFilteringOption>
  dates: Array<InvoiceDateFilteringOptions>
  dueDate: Array<InvoiceDateFilteringOption>
  epts: Maybe<Array<EptFilterEnum>>
  issueDate: Array<InvoiceDateFilteringOption>
  scheduledDate: Array<InvoiceDateFilteringOption>
}

export type InvoiceInputObject = {
  accountNumber?: InputMaybe<Scalars['String']>
  accountingAccountId?: InputMaybe<Scalars['ID']>
  accountingCategoryClassId?: InputMaybe<Scalars['ID']>
  accountingCategoryDepartmentId?: InputMaybe<Scalars['ID']>
  accountingCategoryLocationId?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  dueDate?: InputMaybe<Scalars['ISO8601Date']>
  issueDate?: InputMaybe<Scalars['ISO8601Date']>
  memo?: InputMaybe<Scalars['String']>
  number?: InputMaybe<Scalars['String']>
  payerLocalBusinessId?: InputMaybe<Scalars['ID']>
  payerTaxpayerId?: InputMaybe<Scalars['String']>
  paymentCardId?: InputMaybe<Scalars['ID']>
  purposeCode?: InputMaybe<Scalars['String']>
  quickBooksAccountId?: InputMaybe<Scalars['ID']>
  quickBooksClassId?: InputMaybe<Scalars['ID']>
  quickBooksLocationId?: InputMaybe<Scalars['ID']>
  quickBooksMemo?: InputMaybe<Scalars['String']>
  routingNumber?: InputMaybe<Scalars['String']>
  shippingVendorIds?: InputMaybe<Array<Scalars['ID']>>
  skipVendorInvitation?: InputMaybe<Scalars['Boolean']>
  trackingCategoryOneId?: InputMaybe<Scalars['ID']>
  trackingCategoryTwoId?: InputMaybe<Scalars['ID']>
  vendorAccountId?: InputMaybe<Scalars['ID']>
  vendorLocalBusinessId?: InputMaybe<Scalars['ID']>
  xeroAccountId?: InputMaybe<Scalars['ID']>
}

export type InvoiceLineItem = {
  accountingAccount: Maybe<AccountingAccount>
  accountingAccountId: Maybe<Scalars['ID']>
  accountingCategoryClass: Maybe<AccountingDictionary>
  accountingCategoryClassId: Maybe<Scalars['ID']>
  accountingCategoryDepartment: Maybe<AccountingDictionary>
  accountingCategoryDepartmentId: Maybe<Scalars['ID']>
  accountingCategoryLocation: Maybe<AccountingDictionary>
  accountingCategoryLocationId: Maybe<Scalars['ID']>
  accountingCategoryOneId: Maybe<Scalars['ID']>
  accountingCategoryTwoId: Maybe<Scalars['ID']>
  accountingCustomerOrProject: Maybe<AccountingDictionary>
  amount: Money
  catalogItem: Maybe<CatalogItem>
  catalogItemVariation: Maybe<CatalogItemVariation>
  children: Array<InvoiceLineItem>
  description: Maybe<Scalars['String']>
  hasSimilarCatalogItem: Scalars['Boolean']
  id: Scalars['ID']
  invoice: Invoice
  linkedAdditionalExpenses: Array<AdditionalExpense>
  manuallyCreated: Scalars['Boolean']
  netsuiteId: Maybe<Scalars['String']>
  position: Maybe<Scalars['Int']>
  quantity: Maybe<Scalars['Float']>
  quickbooksId: Maybe<Scalars['String']>
  trackingCategoryOne: Maybe<AccountingDictionary>
  trackingCategoryOneId: Maybe<Scalars['ID']>
  trackingCategoryTwo: Maybe<AccountingDictionary>
  trackingCategoryTwoId: Maybe<Scalars['ID']>
  unitAmount: Maybe<Money>
  unitOfMeasure: Maybe<Scalars['String']>
  xeroId: Maybe<Scalars['String']>
}

export type InvoiceLineItemDetailsInputObject = {
  amountCents: Scalars['SafeInt']
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  quantity?: InputMaybe<Scalars['Float']>
  unitAmountCents?: InputMaybe<Scalars['Int']>
}

export type InvoiceLineItemInputObject = {
  accountingAccountId?: InputMaybe<Scalars['ID']>
  accountingCategoryClassId?: InputMaybe<Scalars['ID']>
  accountingCategoryDepartmentId?: InputMaybe<Scalars['ID']>
  accountingCategoryLocationId?: InputMaybe<Scalars['ID']>
  accountingCategoryOneId?: InputMaybe<Scalars['ID']>
  accountingCategoryTwoId?: InputMaybe<Scalars['ID']>
  accountingCustomerOrProjectId?: InputMaybe<Scalars['ID']>
  amountCents?: InputMaybe<Scalars['SafeInt']>
  catalogItemId?: InputMaybe<Scalars['ID']>
  catalogItemVariationId?: InputMaybe<Scalars['ID']>
  children?: InputMaybe<Array<InvoiceLineItemInputObject>>
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  manuallyCreated?: InputMaybe<Scalars['Boolean']>
  netsuiteId?: InputMaybe<Scalars['String']>
  position?: InputMaybe<Scalars['Int']>
  quantity: Scalars['Float']
  quickbooksId?: InputMaybe<Scalars['String']>
  trackingCategoryOneId?: InputMaybe<Scalars['ID']>
  trackingCategoryTwoId?: InputMaybe<Scalars['ID']>
  unitAmountCents: Scalars['Int']
  unitOfMeasure?: InputMaybe<Scalars['String']>
  xeroId?: InputMaybe<Scalars['String']>
}

export type InvoiceMarketingVirtualCard = {
  fromDate: Scalars['ISO8601Date']
  toDate: Scalars['ISO8601Date']
}

export type InvoiceOcr = {
  ocrRecordLineItems: Array<InvoiceOcrLineItem>
  payerAddress: Maybe<AddressOcr>
  payerEmail: Maybe<Scalars['String']>
  payerName: Maybe<Scalars['String']>
  routingNumberValidation: Maybe<RoutingNumberValidation>
  vendorAccountNumber: Maybe<Scalars['String']>
  vendorAddress: Maybe<AddressOcr>
  vendorEmail: Maybe<Scalars['String']>
  vendorIban: Maybe<Scalars['String']>
  vendorName: Maybe<Scalars['String']>
  vendorPhone: Maybe<Scalars['String']>
  vendorRoutingNumber: Maybe<Scalars['String']>
  vendorSwift: Maybe<Scalars['String']>
}

export type InvoiceOcrLineItem = {
  amount: Maybe<Money>
  id: Scalars['ID']
  matchingCatalogItem: Maybe<CatalogItem>
  productName: Maybe<Scalars['String']>
  quantity: Maybe<Scalars['Float']>
  sku: Maybe<Scalars['String']>
  unitAmount: Maybe<Money>
}

export type InvoicePayableDataInputObject = {
  accountId?: InputMaybe<Scalars['ID']>
  accountingMemo?: InputMaybe<Scalars['String']>
  paperCheck?: InputMaybe<Scalars['Boolean']>
  paymentMethod?: InputMaybe<PaymentMethodEnum>
  quickBooksLocationId?: InputMaybe<Scalars['ID']>
  quickBooksMemo?: InputMaybe<Scalars['String']>
}

export enum InvoiceSortableFieldEnum {
  AMOUNT = 'AMOUNT',
  AMOUNT_CURRENCY = 'AMOUNT_CURRENCY',
  CREATED_AT = 'CREATED_AT',
  DUE_DATE = 'DUE_DATE',
  FEE_AMOUNT = 'FEE_AMOUNT',
  INVOICE_NUMBER = 'INVOICE_NUMBER',
  ISSUE_DATE = 'ISSUE_DATE',
  LABELS = 'LABELS',
  NEAREST_INSTALLMENT_AMOUNT = 'NEAREST_INSTALLMENT_AMOUNT',
  NEAREST_PAYER_PAYMENT_DATE = 'NEAREST_PAYER_PAYMENT_DATE',
  NEAREST_VENDOR_PAYMENT_DATE = 'NEAREST_VENDOR_PAYMENT_DATE',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  SCHEDULED_DATE = 'SCHEDULED_DATE',
  STATUS = 'STATUS',
  TERMS = 'TERMS',
  TOTAL_USD_AMOUNT = 'TOTAL_USD_AMOUNT',
  VENDOR_NAME = 'VENDOR_NAME',
}

export enum InvoiceSortingDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type InvoiceSortingInputObject = {
  direction: InvoiceSortingDirectionEnum
  field: InvoiceSortableFieldEnum
}

export enum InvoiceSourceEnum {
  ADDED_WITHOUT_ATTACHMENT = 'ADDED_WITHOUT_ATTACHMENT',
  CREATED = 'CREATED',
  EMAIL = 'EMAIL',
  EPT_FEE = 'EPT_FEE',
  FROM_REFERENCE = 'FROM_REFERENCE',
  NETSUITE_IMPORT = 'NETSUITE_IMPORT',
  QUICKBOOKS_IMPORT = 'QUICKBOOKS_IMPORT',
  UPLOADED = 'UPLOADED',
  VMC = 'VMC',
  VMC_FEE = 'VMC_FEE',
  XERO_IMPORT = 'XERO_IMPORT',
}

export type InvoiceStatusCounts = {
  approvalRequested: Scalars['Int']
  approved: Scalars['Int']
  draft: Scalars['Int']
  error: Scalars['Int']
  failed: Scalars['Int']
  onHold: Scalars['Int']
  paid: Scalars['Int']
  partiallyRepaid: Scalars['Int']
  paymentPlan: Scalars['Int']
  processed: Scalars['Int']
  rejected: Scalars['Int']
  scheduled: Scalars['Int']
  splitted: Scalars['Int']
  unscheduled: Scalars['Int']
  vendorPartiallyPaid: Scalars['Int']
  voided: Scalars['Int']
  waiting: Scalars['Int']
}

export enum InvoiceStatusEnum {
  APPROVAL_REQUESTED = 'APPROVAL_REQUESTED',
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
  ERROR = 'ERROR',
  FAILED = 'FAILED',
  ON_HOLD = 'ON_HOLD',
  PAID = 'PAID',
  PARTIALLY_REPAID = 'PARTIALLY_REPAID',
  PAYMENT_PLAN = 'PAYMENT_PLAN',
  PROCESSED = 'PROCESSED',
  REJECTED = 'REJECTED',
  SCHEDULED = 'SCHEDULED',
  SPLITTED = 'SPLITTED',
  UNSCHEDULED = 'UNSCHEDULED',
  VENDOR_PARTIALLY_PAID = 'VENDOR_PARTIALLY_PAID',
  VOIDED = 'VOIDED',
  WAITING = 'WAITING',
}

export type InvoiceSuggestedVendorAccount = {
  routingNumberValidation: Maybe<RoutingNumberValidation>
  type: Maybe<SuggestedVendorAccountTypeEnum>
  vendorAccountNumber: Maybe<Scalars['String']>
  vendorIban: Maybe<Scalars['String']>
  vendorRoutingNumber: Maybe<Scalars['String']>
  vendorSwift: Maybe<Scalars['String']>
}

export type Invoices = {
  data: Array<Invoice>
  pagination: Pagination
}

export enum InvoicesEptReasonEnum {
  CREDIT_LIMIT = 'CREDIT_LIMIT',
  THRESHOLD = 'THRESHOLD',
}

export type InvoicesFilteringInputObject = {
  accountingAccountIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  accountingCategoryClassIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  accountingCategoryDepartmentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  accountingCategoryLocationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  accountingCategoryOneIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  accountingCategoryTwoIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  amountCents?: InputMaybe<InvoiceAmountFilteringInputObject>
  amountCurrencies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  approverIds?: InputMaybe<Array<Scalars['ID']>>
  archivedStatus?: InputMaybe<InvoiceArchivedStatusEnum>
  confirmationNumber?: InputMaybe<Scalars['String']>
  confirmationNumbers?: InputMaybe<Array<Scalars['String']>>
  createdAt?: InputMaybe<DateFilteringInputObject>
  dueDate?: InputMaybe<DateFilteringInputObject>
  epts?: InputMaybe<Array<InputMaybe<EptFilterEnum>>>
  issueDate?: InputMaybe<DateFilteringInputObject>
  labelIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  number?: InputMaybe<Scalars['String']>
  payerName?: InputMaybe<Scalars['String']>
  paymentMethods?: InputMaybe<Array<InputMaybe<PaymentMethodEnum>>>
  purchaseOrderNumber?: InputMaybe<Scalars['String']>
  quickbooksClassIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  quickbooksLocationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  read?: InputMaybe<Scalars['Boolean']>
  scheduledDate?: InputMaybe<DateFilteringInputObject>
  search?: InputMaybe<Scalars['String']>
  selectedForFinancing?: InputMaybe<Scalars['Boolean']>
  sources?: InputMaybe<Array<InvoiceSourceEnum>>
  sourcesV2?: InputMaybe<Array<InvoiceSourceEnum>>
  statuses?: InputMaybe<Array<InvoiceStatusEnum>>
  vendorLocalBusinessIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  vendorName?: InputMaybe<Scalars['String']>
}

export enum InvoicesPermissionsEnum {
  ATTACH_INVOICE_DOCUMENT = 'ATTACH_INVOICE_DOCUMENT',
  CANCEL_INVOICE_APPROVAL_REQUEST = 'CANCEL_INVOICE_APPROVAL_REQUEST',
  CREATE_PAYABLE_INVOICE = 'CREATE_PAYABLE_INVOICE',
  CREATE_RECEIVABLE_INVOICE = 'CREATE_RECEIVABLE_INVOICE',
  DELETE_INVOICES = 'DELETE_INVOICES',
  DELETE_INVOICE_ATTACHMENT = 'DELETE_INVOICE_ATTACHMENT',
  INVOICE_STATUS_COUNTS = 'INVOICE_STATUS_COUNTS',
  MARK_INVOICE_PAID = 'MARK_INVOICE_PAID',
  MARK_INVOICE_UNPAID = 'MARK_INVOICE_UNPAID',
  NOTIFY_EPT_REQUEST = 'NOTIFY_EPT_REQUEST',
  NOTIFY_WRONG_INVOICE = 'NOTIFY_WRONG_INVOICE',
  REQUEST_INVOICE_APPROVAL = 'REQUEST_INVOICE_APPROVAL',
  SPLIT_TRANSFERS = 'SPLIT_TRANSFERS',
  UPDATE_INVOICE = 'UPDATE_INVOICE',
  UPDATE_INVOICE_ARCHIVED = 'UPDATE_INVOICE_ARCHIVED',
  UPDATE_INVOICE_EXTERNAL_ATTRIBUTES = 'UPDATE_INVOICE_EXTERNAL_ATTRIBUTES',
  UPDATE_INVOICE_LABELS = 'UPDATE_INVOICE_LABELS',
  UPDATE_INVOICE_PAYABLE_DATA = 'UPDATE_INVOICE_PAYABLE_DATA',
  UPDATE_TRANSFER = 'UPDATE_TRANSFER',
}

export type Journey = {
  active: Scalars['Boolean']
  status: JourneyStatus
  steps: Array<JourneyStep>
  type: JourneyEnum
}

export enum JourneyEnum {
  ACTIVATE_BUSINESS = 'ACTIVATE_BUSINESS',
  LENDING = 'LENDING',
  PAYER = 'PAYER',
  PURCHASING = 'PURCHASING',
  SYNC_INVENTORY = 'SYNC_INVENTORY',
}

export enum JourneyStatus {
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type JourneyStep = {
  relatedObjectData: Maybe<JourneyStepRelatedObject>
  status: JourneyStepStatus
  type: JourneyStepEnum
  userCanCompleteStep: Scalars['Boolean']
}

export enum JourneyStepEnum {
  ADD_BENEFICIAL_OWNERS = 'ADD_BENEFICIAL_OWNERS',
  COMPLETE_BUSINESS_DETAILS = 'COMPLETE_BUSINESS_DETAILS',
  CONNECT_ACCOUNTING = 'CONNECT_ACCOUNTING',
  CONNECT_BANK_ACCOUNT = 'CONNECT_BANK_ACCOUNT',
  CONNECT_SHOPIFY = 'CONNECT_SHOPIFY',
  CONNECT_WMS = 'CONNECT_WMS',
  CREATE_OR_UPLOAD_PURCHASE_ORDER = 'CREATE_OR_UPLOAD_PURCHASE_ORDER',
  DOCUMENT_OR_DATA_REQUEST = 'DOCUMENT_OR_DATA_REQUEST',
  SCHEDULE_BILL_PAYMENT = 'SCHEDULE_BILL_PAYMENT',
}

export type JourneyStepRelatedObject = {
  dataRequestId: Maybe<Scalars['ID']>
  documentType: Maybe<DocumentTypeEnum>
  documentUploadRequestId: Maybe<Scalars['ID']>
  latestAccountingDate: Maybe<Scalars['ISO8601Date']>
  targetDescription: Maybe<Scalars['String']>
}

export enum JourneyStepStatus {
  COMPLETE = 'COMPLETE',
  TO_DO = 'TO_DO',
}

export type KybDataField = {
  description: Maybe<Scalars['String']>
  label: Maybe<Scalars['String']>
  propertyKey: Scalars['String']
  type: Scalars['String']
}

export type KybDataFieldInput = {
  intValue?: InputMaybe<Scalars['Int']>
  key: Scalars['String']
  stringValue?: InputMaybe<Scalars['String']>
}

export type KybDataRequest = {
  dataFields: Maybe<Array<KybDataField>>
  dataRequestId: Maybe<Scalars['String']>
  status: Maybe<Scalars['String']>
}

export type KybDataRequestInput = {
  dataFieldInputs: Array<KybDataFieldInput>
  dataRequestId: Scalars['ID']
}

export enum KybStatusEnum {
  /** Awaiting approval by Settle */
  AWAITING_OPS_APPROVAL = 'AWAITING_OPS_APPROVAL',
  /** Blocked by Settle */
  BLOCKED = 'BLOCKED',
  /** Awaiting business details or UBO information */
  NEED_BUSINESS_INFO = 'NEED_BUSINESS_INFO',
  /** Approved by Settle */
  VERIFIED = 'VERIFIED',
}

export type Label = {
  /** HEX representation of a color (e.g. #RRGGBB) */
  color: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
}

export type LabelInputObject = {
  /** HEX representation of a color (e.g. #RRGGBB) */
  color: Scalars['String']
  name: Scalars['String']
}

export type LineItemDetails = {
  /** catalog item of the line. always present for matched lines(and variations), can be null for unmatched lines (categories) */
  catalogItem: Maybe<CatalogItem>
  /** null for lines with children, not null if it's a variation */
  catalogItemVariation: Maybe<CatalogItemVariation>
  /** linked document: bill/PO */
  document: LinkedDocument
  /** id of the line item (either bill or PO) */
  id: Scalars['ID']
  /** name of the item */
  itemName: Maybe<Scalars['String']>
  /** number of line in document it comes from */
  lineNumber: Scalars['Int']
  /** we should show a warning (price mismatch) */
  priceConflict: Maybe<Scalars['Boolean']>
  /** if the discrepancy falls within a rule threshold */
  priceConflictWithinThreshold: Maybe<Scalars['Boolean']>
  /** which thresholds the price discrepancy is within */
  priceDiscrepancyThresholdsTriggered: Maybe<Array<DiscrepancyThreshold>>
  quantity: Scalars['Float']
  /** we should show a warning (quantity mismatch) */
  quantityConflict: Maybe<Scalars['Boolean']>
  /** if the discrepancy falls within a rule threshold */
  quantityConflictWithinThreshold: Maybe<Scalars['Boolean']>
  /** which thresholds the quantity discrepancy is within */
  quantityDiscrepancyThresholdsTriggered: Maybe<Array<DiscrepancyThreshold>>
  total: Money
  /** we should show a warning (total mismatch) */
  totalConflict: Maybe<Scalars['Boolean']>
  unitAmount: Money
}

export type LineItemsMatchingTotal = {
  /** total amount of all matched lines in the view */
  totalMatchedAmount: Money
  /** number of matched catalog items (groups) and categories */
  totalMatchedLines: Scalars['Int']
  /** total quantity of matched lines in the view */
  totalMatchedQuantity: Scalars['Int']
  /** total amount of all unmatched lines in the view */
  totalUnmatchedAmount: Money
  /** number of unmatched catalog items (groups) and categories */
  totalUnmatchedLines: Scalars['Int']
  /** total quantity of unmatched lines in the view */
  totalUnmatchedQuantity: Scalars['Int']
}

export type LinkOrderDocumentInputObject = {
  linkableOrderDocumentId: Scalars['ID']
  orderDocumentId: Scalars['ID']
  orderDocumentType: OrderDocumentTypeEnum
}

export type LinkableCatalogItem = {
  catalogItemVariation: Maybe<CatalogItemVariation>
  externalName: Maybe<Scalars['String']>
  externalSku: Scalars['String']
  externalUrl: Maybe<Scalars['String']>
  id: Scalars['ID']
  integrationSource: IntegrationSourceEnum
  integrationSourceName: Maybe<Scalars['String']>
  integrationSourceType: IntegrationSourceTypeEnum
  state: LinkableCatalogItemStateEnum
}

export enum LinkableCatalogItemStateEnum {
  ARCHIVED = 'ARCHIVED',
  LINKED = 'LINKED',
  UNLINKED = 'UNLINKED',
}

export type LinkableCatalogItemsFilteringInputObject = {
  endDate?: InputMaybe<Scalars['ISO8601Date']>
  startDate?: InputMaybe<Scalars['ISO8601Date']>
  state?: InputMaybe<LinkableCatalogItemStateEnum>
}

export type LinkableOrderDocument = {
  externalGrnNumber: Maybe<Scalars['String']>
  externalOrderNumber: Maybe<Scalars['String']>
  externalUrl: Maybe<Scalars['String']>
  externalVendorName: Maybe<Scalars['String']>
  id: Scalars['ID']
  integrationSource: IntegrationSourceEnum
  integrationSourceName: Maybe<Scalars['String']>
  state: LinkableOrderDocumentStateEnum
}

export enum LinkableOrderDocumentStateEnum {
  ARCHIVED = 'ARCHIVED',
  LINKED = 'LINKED',
  UNLINKED = 'UNLINKED',
}

export type LinkableOrderDocumentsFilteringInputObject = {
  endDate?: InputMaybe<Scalars['ISO8601Date']>
  startDate?: InputMaybe<Scalars['ISO8601Date']>
  state?: InputMaybe<LinkableOrderDocumentStateEnum>
}

export type LinkedDocument = DocumentReference | InventoryTransfer | Invoice | PurchaseOrder | Receipt | WorkOrder

export type LinkedDocumentDiscrepancies = {
  discrepanciesOutsideThresholdCount: Scalars['Int']
  discrepanciesWithinThresholdCount: Scalars['Int']
  discrepancyRulesApplied: Scalars['Boolean']
  fullyMatchedCount: Scalars['Int']
  matchedWithDiscrepanciesCount: Scalars['Int']
  partiallyMatchedCount: Scalars['Int']
  totalDiscrepanciesCount: Scalars['Int']
  unmatchedCount: Scalars['Int']
}

export type LocalBusiness = {
  accounts: Array<LocalBusinessAccount>
  actions: LocalBusinessActions
  address: Maybe<Address>
  addressForPaperCheck: Maybe<Address>
  allowsPaperCheck: Scalars['Boolean']
  availableVendorCreditAmount: Maybe<Money>
  bankVerified: Scalars['Boolean']
  blockPayments: Maybe<BlockLocalBusinessPayments>
  business: Maybe<PublicBusiness>
  businessName: Maybe<Scalars['String']>
  businessStructure: Maybe<BusinessStructureEnum>
  /** @deprecated Use suggestedBusinessMatches instead */
  canMergeWithPublicBusiness: Scalars['Boolean']
  currency: Maybe<Scalars['String']>
  defaultBeneficiaryFirstName: Maybe<Scalars['String']>
  defaultBeneficiaryLastName: Maybe<Scalars['String']>
  defaultBeneficiaryName: Maybe<Scalars['String']>
  deleted: Scalars['Boolean']
  factoringEmail: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  id: Scalars['ID']
  importedEmails: Array<Scalars['String']>
  invited: Scalars['Boolean']
  invitedAsPayer: Scalars['Boolean']
  invitedAt: Maybe<Scalars['ISO8601DateTime']>
  invoicesStatistics: LocalBusinessStatusesCountType
  is1099Eligible: Maybe<Scalars['Boolean']>
  lastName: Maybe<Scalars['String']>
  /** Returns true when the there is a W9 form uploaded by the verified businessand it should not be accessible to the creator business */
  maskW9Form: Scalars['Boolean']
  name: Maybe<Scalars['String']>
  notificationsEmail: Maybe<Scalars['String']>
  numBills: Scalars['Int']
  numPayments: Scalars['Int']
  officialName: Maybe<Scalars['String']>
  operationsAddress: Maybe<Address>
  operationsEmail: Maybe<Scalars['String']>
  /** non-w9 documents */
  otherAttachments: Array<Attachment>
  paymentsForewarnings: Array<WarningMessage>
  /** @deprecated Use suggestedBusinessMatches instead */
  publicBusinessMatch: Maybe<PublicBusiness>
  registeredBusinessName: Maybe<Scalars['String']>
  /** Returns true when missing W9 form message should be shown to the creator business */
  showW9MissingMessage: Scalars['Boolean']
  suggestedBusinessMatches: Array<BusinessMatch>
  taxpayerId: Maybe<Scalars['String']>
  vendorCredits: Array<VendorCredit>
  vendorPaymentTerms: Array<VendorPaymentTerm>
  /** W9 form uploaded by the verified business */
  verifiedW9Form: Maybe<Attachment>
  visible: Scalars['Boolean']
  /** W9 form uploaded for the local business */
  w9Form: Maybe<Attachment>
  w9Request: Maybe<InformationRequest>
}

export type LocalBusinessAccount = {
  accountNumber: Maybe<Scalars['String']>
  accountNumberType: Maybe<VendorAccountNumberTypeEnum>
  archived: Scalars['Boolean']
  bankAddress: Maybe<Address>
  bankName: Maybe<Scalars['String']>
  beneficiaryFirstName: Maybe<Scalars['String']>
  beneficiaryLastName: Maybe<Scalars['String']>
  beneficiaryName: Maybe<Scalars['String']>
  bicSwift: Maybe<Scalars['String']>
  cnaps: Maybe<Scalars['String']>
  copyOfVerifiedAccount: Scalars['Boolean']
  currency: Maybe<Scalars['String']>
  deletable: Scalars['Boolean']
  destinationCountry: Scalars['String']
  editable: Scalars['Boolean']
  id: Scalars['ID']
  lastUsedInvoice: Maybe<Invoice>
  lastUsedOn: Maybe<Scalars['ISO8601Date']>
  mask: Scalars['String']
  /** True iff this account is missing wire fields and has a complete local copy */
  missingWireFieldsProvidedByLocalCopy: Scalars['Boolean']
  name: Maybe<Scalars['String']>
  ownedByPayer: Scalars['Boolean']
  /** Account ability to process future ACH payments through the system */
  paymentsProcessingAvailable: Maybe<Scalars['Boolean']>
  preferredReceivingAccount: Scalars['Boolean']
  routingNumber: Maybe<Scalars['String']>
  routingNumberValidation: Maybe<RoutingNumberValidation>
  supportedPaymentTypes: Array<PaymentMethodEnum>
  verified: Scalars['Boolean']
  wireFields: Maybe<Array<InternationalWireFieldsDataFieldValueType>>
  wireRoutingNumber: Maybe<Scalars['String']>
  wireRoutingNumberValidation: Maybe<RoutingNumberValidation>
}

export type LocalBusinessAccountInputObject = {
  accountNumber: Scalars['String']
  archived?: InputMaybe<Scalars['Boolean']>
  bankAddress?: InputMaybe<AddressInputObject>
  bankName?: InputMaybe<Scalars['String']>
  beneficiaryFirstName?: InputMaybe<Scalars['String']>
  beneficiaryLastName?: InputMaybe<Scalars['String']>
  beneficiaryName?: InputMaybe<Scalars['String']>
  bicSwift?: InputMaybe<Scalars['String']>
  cnaps?: InputMaybe<Scalars['String']>
  currency?: InputMaybe<Scalars['String']>
  destinationCountry: Scalars['String']
  routingNumber?: InputMaybe<Scalars['String']>
  wireFields?: InputMaybe<Array<InternationalWireFieldsDataFieldInputObject>>
  wireRoutingNumber?: InputMaybe<Scalars['String']>
}

export type LocalBusinessActions = {
  delete: Action
  deleteW9: Action
  updateW9: Action
}

export type LocalBusinessEmailInputObject = {
  email: Scalars['String']
  localBusinessId: Scalars['ID']
}

export type LocalBusinessInputObject = {
  address?: InputMaybe<AddressInputObject>
  addressForPaperCheck?: InputMaybe<AddressInputObject>
  businessName?: InputMaybe<Scalars['String']>
  businessStructure?: InputMaybe<BusinessStructureEnum>
  currency?: InputMaybe<Scalars['String']>
  factoringEmail?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  initialRole?: InputMaybe<LocalBusinessRoleEnum>
  is1099Eligible?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  notificationsEmail?: InputMaybe<Scalars['String']>
  operationsAddress?: InputMaybe<AddressInputObject>
  operationsEmail?: InputMaybe<Scalars['String']>
  taxpayerId?: InputMaybe<Scalars['String']>
  vendorPaymentTerms?: InputMaybe<Array<VendorPaymentTermInputObject>>
  visible?: InputMaybe<Scalars['Boolean']>
}

export type LocalBusinessPublicInputObject = {
  businessStructure?: InputMaybe<BusinessStructureEnum>
  currency?: InputMaybe<Scalars['String']>
  initialRole?: InputMaybe<LocalBusinessRoleEnum>
  is1099Eligible?: InputMaybe<Scalars['Boolean']>
  name: Scalars['String']
  notificationsEmail?: InputMaybe<Scalars['String']>
  vendorPaymentTerms?: InputMaybe<Array<VendorPaymentTermInputObject>>
}

export enum LocalBusinessRoleEnum {
  PAYER = 'PAYER',
  VENDOR = 'VENDOR',
}

export type LocalBusinessStatusesCountType = {
  all: Scalars['Int']
  lastInvoiceDate: Maybe<Scalars['ISO8601Date']>
  lastInvoiceTotal: Maybe<Money>
  paid: Scalars['Int']
  pending: Scalars['Int']
  scheduled: Scalars['Int']
  totalAll: Money
  totalPaid: Money
  totalPending: Money
  totalScheduled: Money
}

export type LocalBusinesses = {
  data: Array<LocalBusiness>
  hasDeleted: Scalars['Boolean']
  pagination: Pagination
}

export enum LocalBusinessesPermissionsEnum {
  ACCEPT_BUSINESS_MATCH = 'ACCEPT_BUSINESS_MATCH',
  CREATE_FROM_PUBLIC_BUSINESS = 'CREATE_FROM_PUBLIC_BUSINESS',
  CREATE_LOCAL_BUSINESS = 'CREATE_LOCAL_BUSINESS',
  CREATE_LOCAL_BUSINESS_ACCOUNT = 'CREATE_LOCAL_BUSINESS_ACCOUNT',
  DELETE_LOCAL_BUSINESSES = 'DELETE_LOCAL_BUSINESSES',
  DELETE_LOCAL_BUSINESS_ACCOUNT = 'DELETE_LOCAL_BUSINESS_ACCOUNT',
  INVITE_PAYER = 'INVITE_PAYER',
  INVITE_VENDOR = 'INVITE_VENDOR',
  MERGE_WITH_PUBLIC_BUSINESS = 'MERGE_WITH_PUBLIC_BUSINESS',
  REJECT_ALL_BUSINESS_MATCHES = 'REJECT_ALL_BUSINESS_MATCHES',
  RESTORE_LOCAL_BUSINESS = 'RESTORE_LOCAL_BUSINESS',
  SYNC_WITH_PUBLIC_BUSINESS = 'SYNC_WITH_PUBLIC_BUSINESS',
  UPDATE_LOCAL_BUSINESS = 'UPDATE_LOCAL_BUSINESS',
  UPDATE_LOCAL_BUSINESS_ACCOUNT = 'UPDATE_LOCAL_BUSINESS_ACCOUNT',
}

export type Location = {
  address: Maybe<Address>
  archived: Scalars['Boolean']
  code: Scalars['String']
  companyName: Maybe<Scalars['String']>
  contactEmail: Maybe<Scalars['String']>
  contactName: Maybe<Scalars['String']>
  currentInventoryBalances: Array<CatalogItemInventoryBalance>
  group: Maybe<LocationGroupTypeEnum>
  id: Scalars['ID']
  inventory: Array<CatalogItemInventorySnapshot>
  inventoryUpdatedAt: Maybe<Scalars['ISO8601DateTime']>
  localBusiness: Maybe<LocalBusiness>
  locationType: Maybe<LocationTypeEnum>
  lotSnapshots: Array<LotSnapshot>
  name: Maybe<Scalars['String']>
  notes: Maybe<Scalars['String']>
  source: LocationSourceTypeEnum
}

export type LocationFilter = {
  locationIds?: InputMaybe<Array<Scalars['ID']>>
  locationType?: InputMaybe<LocationTypeEnum>
}

export enum LocationGroupTypeEnum {
  FBA = 'FBA',
}

export type LocationInputObject = {
  address?: InputMaybe<AddressInputObject>
  code?: InputMaybe<Scalars['String']>
  companyName?: InputMaybe<Scalars['String']>
  contactEmail?: InputMaybe<Scalars['String']>
  contactName?: InputMaybe<Scalars['String']>
  locationType?: InputMaybe<LocationTypeEnum>
  name?: InputMaybe<Scalars['String']>
  notes?: InputMaybe<Scalars['String']>
}

export enum LocationSourceTypeEnum {
  MANUAL = 'MANUAL',
  WMS = 'WMS',
}

export enum LocationTypeEnum {
  CORPORATE = 'CORPORATE',
  DISTRIBUTION_CENTER = 'DISTRIBUTION_CENTER',
  MANUFACTURING = 'MANUFACTURING',
  RETAIL_STORE = 'RETAIL_STORE',
  WAREHOUSE = 'WAREHOUSE',
}

/** Stores the UTMs to use for the referral */
export type LongLinkUtms = {
  utmCampaign: Maybe<Scalars['String']>
  utmContent: Maybe<Scalars['String']>
  utmMedium: Maybe<Scalars['String']>
  utmSource: Maybe<Scalars['String']>
}

export type Lot = {
  code: Scalars['String']
  expirationDate: Maybe<Scalars['ISO8601Date']>
  id: Scalars['ID']
  lotSnapshots: Array<LotSnapshot>
  note: Maybe<Scalars['String']>
}

export type LotInputObject = {
  expirationDate?: InputMaybe<Scalars['ISO8601Date']>
  note?: InputMaybe<Scalars['String']>
}

export type LotSnapshot = {
  available: Maybe<Scalars['Float']>
  catalogItem: CatalogItem
  catalogItemVariation: CatalogItemVariation
  id: Scalars['ID']
  incoming: Maybe<Scalars['Float']>
  location: Maybe<Location>
  lot: Lot
  onhand: Maybe<Scalars['Float']>
  reserved: Maybe<Scalars['Float']>
  snapshotDate: Maybe<Scalars['ISO8601DateTime']>
}

export type ManualDemandForecastData = {
  endDate: Scalars['ISO8601Date']
  quantity: Scalars['Float']
  startDate: Scalars['ISO8601Date']
}

export type ManualDemandForecastMetadata = {
  forecast: Array<ManualDemandForecastData>
  id: Scalars['ID']
  name: Scalars['String']
  period: InventoryDemandForecastPeriod
}

export type ManualInventoryDemandForecastInputObject = {
  catalogItemVariationId: Scalars['ID']
  forecastType?: InputMaybe<InventoryDemandForecastPeriod>
  name?: InputMaybe<Scalars['String']>
  periods: Array<ManualInventoryDemandForecastPeriodInputObject>
}

export type ManualInventoryDemandForecastPeriodInputObject = {
  endDate: Scalars['ISO8601Date']
  quantity: Scalars['Float']
  startDate: Scalars['ISO8601Date']
}

export type MarketingVirtualCard = {
  account: Account
  accountingAccount: Maybe<AccountingAccount>
  accountingAccountId: Maybe<Scalars['ID']>
  accountingCategoryClassId: Maybe<Scalars['ID']>
  accountingCategoryLocationId: Maybe<Scalars['ID']>
  accountingMemo: Maybe<Scalars['String']>
  availableCredit: Money
  balance: Money
  cardNumberLast4: Scalars['String']
  currentPeriodAmount: Money
  deletable: Scalars['Boolean']
  expirationMonth: Scalars['Int']
  expirationYear: Scalars['Int']
  feeAccountingAccount: Maybe<AccountingAccount>
  feeAccountingAccountId: Maybe<Scalars['ID']>
  feeAccountingCategoryClassId: Maybe<Scalars['ID']>
  feeAccountingCategoryLocationId: Maybe<Scalars['ID']>
  feeAccountingMemo: Maybe<Scalars['String']>
  id: Scalars['ID']
  limit: Money
  name: Scalars['String']
  paymentSystem: Scalars['String']
  pendingAuthorizationsAmount: Money
  postalCode: Scalars['String']
  previousUnpaidAmount: Money
  repaymentPercent: Scalars['Float']
  repaymentPeriod: MarketingVirtualCardRepaymentPeriodEnum
  terms: Scalars['String']
  useSameExpanseDetailsForFee: Scalars['Boolean']
}

export type MarketingVirtualCardAuthorization = {
  amount: Money
  createdAt: Scalars['ISO8601Date']
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  status: MarketingVirtualCardAuthorizationStatusEnum
}

export enum MarketingVirtualCardAuthorizationStatusEnum {
  CLOSED = 'CLOSED',
  PENDING = 'PENDING',
  REVERSED = 'REVERSED',
}

export type MarketingVirtualCardInputObject = {
  accountId: Scalars['ID']
  accountingAccountId?: InputMaybe<Scalars['ID']>
  accountingCategoryClassId?: InputMaybe<Scalars['ID']>
  accountingCategoryLocationId?: InputMaybe<Scalars['ID']>
  accountingMemo?: InputMaybe<Scalars['String']>
  feeAccountingAccountId?: InputMaybe<Scalars['ID']>
  feeAccountingCategoryClassId?: InputMaybe<Scalars['ID']>
  feeAccountingCategoryLocationId?: InputMaybe<Scalars['ID']>
  feeAccountingMemo?: InputMaybe<Scalars['String']>
  feeQuickBooksAccountId?: InputMaybe<Scalars['ID']>
  feeQuickBooksClassId?: InputMaybe<Scalars['ID']>
  feeQuickBooksLocationId?: InputMaybe<Scalars['ID']>
  feeQuickBooksMemo?: InputMaybe<Scalars['String']>
  feeXeroAccountId?: InputMaybe<Scalars['ID']>
  quickBooksAccountId?: InputMaybe<Scalars['ID']>
  quickBooksClassId?: InputMaybe<Scalars['ID']>
  quickBooksLocationId?: InputMaybe<Scalars['ID']>
  quickBooksMemo?: InputMaybe<Scalars['String']>
  repaymentPeriod: MarketingVirtualCardRepaymentPeriodEnum
  useSameExpanseDetailsForFee?: InputMaybe<Scalars['Boolean']>
  xeroAccountId?: InputMaybe<Scalars['ID']>
}

export enum MarketingVirtualCardRepaymentPeriodEnum {
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export type MarketingVirtualCardSecure = {
  data: Maybe<MarketingVirtualCardSecureData>
  reauthenticationRequired: Scalars['Boolean']
}

export type MarketingVirtualCardSecureData = {
  cvc: Scalars['String']
  expirationMonth: Scalars['Int']
  expirationYear: Scalars['Int']
  id: Scalars['ID']
  name: Scalars['String']
  number: Scalars['String']
  postalCode: Scalars['String']
}

export type MarketingVirtualCardTransaction = {
  amount: Money
  createdAt: Scalars['ISO8601Date']
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  status: MarketingVirtualCardTransactionStatusEnum
}

export enum MarketingVirtualCardTransactionStatusEnum {
  COMPLETED = 'COMPLETED',
  REFUNDED = 'REFUNDED',
  UNDEFINED = 'UNDEFINED',
}

export type MatchedCatalogItemInputObject = {
  catalogItemId: Scalars['ID']
  linkableCatalogItemId: Scalars['ID']
}

export type MatchedLine = {
  /** child lines (variations) */
  children: Array<MatchedLine>
  /** data for rendering Bill columns */
  invoiceLine: Array<LineItemDetails>
  /** data for rendering PO columns */
  purchaseOrderLine: Array<LineItemDetails>
  /** data for rendering Receipt columns */
  receiptLine: Array<LineItemDetails>
}

export type Membership = {
  /** Should only return null if the membership is deactivated. A null response is not expected in frontend flows. */
  business: Maybe<Business>
  externalBusiness: Maybe<PublicBusiness>
  id: Scalars['ID']
  lastTosAccepted: Maybe<Scalars['ISO8601Date']>
  role: MembershipRolesEnum
  roleAccountingAccounts: Maybe<Array<AccountingAccount>>
  user: Profile
  userRole: UserRole
}

export enum MembershipRolesEnum {
  ADMIN = 'ADMIN',
  APPROVER = 'APPROVER',
  CLERK = 'CLERK',
  CLIENT_MANAGER_ADMIN = 'CLIENT_MANAGER_ADMIN',
  CLIENT_MANAGER_TEAM_MEMBER = 'CLIENT_MANAGER_TEAM_MEMBER',
  CUSTOM = 'CUSTOM',
  PROCUREMENT = 'PROCUREMENT',
  REGULAR = 'REGULAR',
  VIEWER = 'VIEWER',
}

export type Message = {
  message: Scalars['String']
}

export enum MicrodepositsPaymentMethodEnum {
  ACH = 'ACH',
  RTP = 'RTP',
}

export enum MicrodepositsStatusEnum {
  PENDING = 'PENDING',
  VERIFIABLE = 'VERIFIABLE',
}

/** Search results from Middesk Name/State Search API */
export type MiddeskSearch = {
  maxAttemptsReached: Scalars['Boolean']
  providedInputMatched: Scalars['Boolean']
  suggestions: Array<MiddeskSearchSuggestion>
}

/** Middesk search result schema */
export type MiddeskSearchSuggestion = {
  name: Scalars['String']
  state: Scalars['String']
}

export type Money = {
  cents: Scalars['SafeInt']
  currency: Scalars['String']
  currencySymbol: Scalars['String']
  formatted: Scalars['String']
  formattedNoCents: Scalars['String']
  subunitToUnit: Scalars['Int']
}

export type MoneyInputObject = {
  cents: Scalars['SafeInt']
  currency: Scalars['String']
}

export type Mutation = {
  acceptBusinessMatch: Maybe<LocalBusiness>
  acceptInvitation: Maybe<AuthResponse>
  acceptInvitationAuthenticated: Maybe<Business>
  /** Create a new TosAcceptance entry for current user/business membership with specified version. */
  acceptTos: Maybe<Membership>
  acceptVendorInvitation: Maybe<AuthResponse>
  acceptVendorInvitationAuthenticated: Maybe<Business>
  activateRuleV2: Maybe<Rule>
  activateTeamMember: Maybe<TeamMember>
  activateTotp: Maybe<Scalars['Boolean']>
  addCatalogItemsLabels: Maybe<Array<CatalogItem>>
  addInvoicesLabels: Maybe<Array<Invoice>>
  addJourneyV2: Maybe<Array<Journey>>
  addPurchaseOrdersLabels: Maybe<Array<PurchaseOrder>>
  addTeamMemberToClient: Maybe<Scalars['Boolean']>
  addVendorCredit: Maybe<VendorCredit>
  allocatePaymentToInvoice: Maybe<Payment>
  amortizedRepayEarly: Maybe<Payment>
  applyPromotionCode: Maybe<BillingPromotionCodeType>
  applyPromotionCodeV2: Maybe<BillingDiscountType>
  approveInvoiceApprovalRequest: Maybe<Invoice>
  approvePayment: Maybe<Payment>
  approvePurchaseOrderApprovalRequest: Maybe<PurchaseOrder>
  approveReceivableDebit: Maybe<Receivable>
  archiveCatalogItemProperty: Maybe<CatalogItem>
  archiveCatalogItemPropertyTemplate: Maybe<CatalogItemProperty>
  archiveLocation: Maybe<Location>
  archiveUnlinkedCatalogItem: Maybe<Array<LinkableCatalogItem>>
  /** Upload an additional document to existing invoice */
  attachInvoiceDocument: Maybe<Invoice>
  attachLocalBusinessDocument: Maybe<Attachment>
  attachPurchaseOrderDocument: Maybe<PurchaseOrder>
  attachReceiptDocument: Maybe<Receipt>
  attachVendorCreditDocument: Maybe<VendorCredit>
  attachW9: Maybe<LocalBusiness>
  authProviderAcceptInvitation: Maybe<AuthResponse>
  authProviderAcceptVendorInvitation: Maybe<AuthResponse>
  authProviderSignIn: Maybe<AuthResponse>
  authProviderSignUp: Maybe<AuthResponse>
  bulkPayAsapV2: Maybe<Array<BulkPayBase>>
  bulkPayOnDateV2: Maybe<Array<BulkPayBase>>
  bulkPayOnDueDateV2: Maybe<Array<BulkPayBase>>
  bulkUpdateCatalogItem: Maybe<Array<CatalogItem>>
  cancelInvoiceApprovalRequest: Maybe<Invoice>
  cancelJourneyStep: Maybe<Array<Journey>>
  cancelPurchaseOrderApprovalRequest: Maybe<PurchaseOrder>
  cancelSubscription: Maybe<Business>
  cancelVendorFinancing: Maybe<ReceivableInvoice>
  changeBillingPlan: Maybe<BillingPlanChangeResponseType>
  changeBulletEptTermLength: Maybe<Payment>
  changeClientBillingPlan: Maybe<Message>
  changePayFromAccountRecurrentPayments: Maybe<RecurrentPaymentsSchedule>
  changePayToAccountRecurrentPayments: Maybe<RecurrentPaymentsSchedule>
  checkAllocationAllowed: Maybe<Action>
  clearPayerInvoice: Maybe<Invoice>
  convertVendorIntoPayer: Maybe<Business>
  copyOrUpdateLocalBusinessAccount: Maybe<LocalBusinessAccount>
  createAccount: Maybe<Account>
  createAmortizedLoanPaymentV2: Maybe<Payment>
  createAttachment: Maybe<Attachment>
  createBeneficialOwnersV2: Maybe<Array<BeneficialOwner>>
  createBillingMethod: Maybe<BillingMethod>
  createBomMaterial: Maybe<CatalogItem>
  createBulletEptPayment: Maybe<Payment>
  createBusiness: Maybe<Business>
  createByNumberPurchaseOrder: Maybe<PurchaseOrder>
  createCashAdvanceAmortizedLoanPayment: Maybe<Payment>
  createCashAdvanceBulletEptPayment: Maybe<Payment>
  createCashAdvanceEptPayment: Maybe<Payment>
  createCashAdvancePreliminaryAmortizedLoanAgreement: Maybe<Agreement>
  createCashAdvancePreliminaryBulletEptAgreement: Maybe<Agreement>
  createCashAdvancePreliminaryEptAgreement: Maybe<Agreement>
  createCatalogItem: Maybe<CatalogItem>
  createCatalogItemFromExternalSource: Maybe<Array<LinkableCatalogItem>>
  createCatalogItemInventorySnapshot: Maybe<Location>
  createCatalogItemProperty: Maybe<CatalogItem>
  createCatalogItemPropertyTemplate: Maybe<CatalogItemProperty>
  createClientBusiness: Maybe<Business>
  createComment: Maybe<Comment>
  createConsolidatedPayments: Maybe<Array<ConsolidatedPaymentType>>
  createCustomBomLineItem: Maybe<CatalogItemVariation>
  createCustomInventoryTransfer: Maybe<InventoryTransfer>
  createCustomOutboundOrder: Maybe<OutboundOrder>
  createCustomPurchaseOrder: Maybe<PurchaseOrder>
  createDeferralRequest: Maybe<Message>
  createDiscrepancyRule: Maybe<DiscrepancyRule>
  createDocumentFromReference: Maybe<LinkedDocument>
  createDocumentReference: Maybe<LinkedDocument>
  createEmailOptOut: Maybe<EmailOptOut>
  createExtendedTermPaymentV2: Maybe<Payment>
  createFactoringAgreement: Maybe<Agreement>
  createFromPublicBusiness: Maybe<LocalBusiness>
  createInventoryTransferAdditionalExpense: Maybe<InventoryTransfer>
  createInventoryTransferLineItem: Maybe<InventoryTransfer>
  createInvitation: Maybe<CreateInvitationPayload>
  createInvoiceLineItem: Maybe<Invoice>
  createLabel: Maybe<Label>
  createLocalBusiness: Maybe<LocalBusiness>
  createLocalBusinessAccount: Maybe<LocalBusinessAccount>
  createLocation: Maybe<Location>
  createLocationFromLocalBusiness: Maybe<Location>
  createLocationFromPublicBusiness: Maybe<Location>
  createManualInventoryDemandForecast: Maybe<InventoryItemDemandForecast>
  /** @deprecated Deprecated feature, will be removed after FE cleanup */
  createMarketingVirtualCard: Maybe<MarketingVirtualCard>
  createOrderDocumentFromExternalGrn: Maybe<Array<LinkableOrderDocument>>
  createOutboundOrderLineItem: Maybe<OutboundOrder>
  createPayable: Maybe<Invoice>
  createPayableFromDataAccessToken: Maybe<Invoice>
  createPayableFromSettleInbox: Maybe<CreatePayableInvoiceResponse>
  createPayableWithoutAttachment: Maybe<Invoice>
  createPayerInvitation: Maybe<PayerInvitationV2>
  createPaymentPublicInvoice: Maybe<PublicInvoice>
  createPaymentV2: Maybe<Payment>
  /** Generates preview or signed agreement for amortized loans in Payments UX 3.0 */
  createPreliminaryAmortizedLoanAgreementV2: Maybe<Agreement>
  createPreliminaryBulletEptAgreement: Maybe<Agreement>
  createPreliminaryEptAgreement: Maybe<Agreement>
  createPreliminaryEptAgreementV2: Maybe<Agreement>
  createPurchaseDetails: Maybe<Array<PurchaseDetailsType>>
  createPurchaseOrder: Maybe<PurchaseOrder>
  createPurchaseOrderAdditionalExpense: Maybe<PurchaseOrder>
  createPurchaseOrderLineItem: Maybe<PurchaseOrder>
  createReceipt: Maybe<Receipt>
  createReceiptLineItem: Maybe<Receipt>
  createReceivable: Maybe<Receivable>
  createReceivableInvoice: Maybe<ReceivableInvoice>
  createRecurrentPaymentsSchedule: Maybe<RecurrentPaymentsSchedule>
  createRole: Maybe<UserRole>
  createRule: Maybe<Rule>
  createSalesChannel: Maybe<OutboundOrderChannel>
  createShopifyConnector: Maybe<InventoryConnector>
  createStandalonePayment: Maybe<Payment>
  createTotp: Maybe<CreateTotpResponse>
  createUploadedDocument: Maybe<UploadedDocument>
  createVendorCredit: Maybe<VendorCredit>
  createVendorCreditFromSettleInbox: Maybe<CreateVendorCreditResponseType>
  createVendorInvitationLink: Maybe<CreateInvitationLinkPayload>
  createVendorLeadTime: Maybe<VendorLeadTime>
  createVendorTerm: Maybe<VendorTerms>
  createVendorTermOption: Maybe<VendorTermOption>
  createW9FromSettleInbox: Maybe<CreateW9ResponseType>
  createW9RequestLink: Maybe<CreateW9LinkPayload>
  createWorkOrder: Maybe<WorkOrder>
  createWorkOrderAdditionalExpense: Maybe<WorkOrder>
  createWorkOrderLineItem: Maybe<WorkOrder>
  deactivateRule: Maybe<Message>
  deactivateRuleV2: Maybe<Rule>
  declineTermExtensions: Maybe<Message>
  deleteAttachment: Maybe<DeletePayload>
  deleteBillingMethod: Maybe<BillingMethod>
  deleteBom: Maybe<CatalogItem>
  deleteBomLineItem: Maybe<BomLineItem>
  deleteBomMaterial: Maybe<CatalogItem>
  deleteBusinessLogo: Maybe<Business>
  /** Clear out all instances of W9 forms attached to the business. Require BusinessDetailsPermissionsEnum::UPDATE_BUSINESS_DETAILS permission */
  deleteBusinessW9: Maybe<Business>
  deleteComment: Maybe<DeleteCommentPayload>
  deleteDiscrepancyRule: Maybe<Scalars['Boolean']>
  deleteInventoryTransferAdditionalExpense: Maybe<InventoryTransfer>
  deleteInventoryTransferLineItems: Maybe<Array<InventoryTransfer>>
  deleteInvoiceAttachment: Maybe<Invoice>
  deleteInvoiceLineItems: Maybe<Invoice>
  deleteInvoices: Maybe<DeletePayload>
  deleteLabels: Maybe<Message>
  deleteLocalBusinessAccount: Maybe<Message>
  deleteLocalBusinesses: Maybe<Message>
  deleteManualInventoryDemandForecast: Maybe<InventoryItemDemandForecast>
  /** @deprecated Deprecated feature, will be removed after FE cleanup */
  deleteMarketingVirtualCard: Maybe<Message>
  deleteOutboundOrder: Maybe<OutboundOrder>
  deleteOutboundOrderLineItems: Maybe<OutboundOrder>
  deletePurchaseDetails: Maybe<DeletePayload>
  deletePurchaseOrderAdditionalExpense: Maybe<PurchaseOrder>
  deletePurchaseOrderLineItems: Maybe<PurchaseOrder>
  deleteReceiptLineItems: Maybe<Receipt>
  deleteReceivable: Maybe<DeletePayload>
  deleteRoleV2: Maybe<DeleteRoleResponse>
  deleteRule: Maybe<Message>
  deleteSettleInboxMessages: Maybe<DeletePayload>
  deleteTeamMember: Maybe<Message>
  deleteTotp: Maybe<Scalars['Boolean']>
  deleteVendorCredit: Maybe<DeletePayload>
  deleteVendorLeadTime: Maybe<DeletePayload>
  deleteVendorTermOption: Maybe<DeletePayload>
  deleteW9: Maybe<LocalBusiness>
  deleteWorkOrderAdditionalExpense: Maybe<WorkOrder>
  deleteWorkOrderLineItem: Maybe<WorkOrder>
  downloadBillingStatement: Maybe<Blob>
  downloadClientBillingStatement: Maybe<Blob>
  downloadClientUpcomingBillingStatement: Maybe<Blob>
  downloadClientsBillingStatement: Maybe<Blob>
  downloadUpcomingBillingStatement: Maybe<Blob>
  duplicatePurchaseOrder: Maybe<PurchaseOrder>
  excludeFromSync: Maybe<Array<SyncObject>>
  export1099Csv: Maybe<Report>
  exportAdjustmentLog: Maybe<Report>
  exportApAgingDetails: Maybe<Report>
  exportApAgingSummary: Maybe<Report>
  exportCashOutflow: Maybe<Report>
  exportEndOfMonthInventory: Maybe<Report>
  exportFactoringAgreements: Maybe<Report>
  exportInvoicesV2: Maybe<Report>
  exportLockboxTransactions: Maybe<Report>
  /** @deprecated Deprecated feature, will be removed after FE cleanup */
  exportMvcTransactions: Maybe<Report>
  exportMyInvoices: Maybe<Report>
  exportOwedToSettle: Maybe<Report>
  exportPaymentConfirmationsV2: Maybe<Report>
  exportProductCost: Maybe<Report>
  exportPurchaseOrders: Maybe<Report>
  exportReceivables: Maybe<Report>
  exportReconciliationItems: Maybe<Report>
  exportSignedDocumentsV2: Maybe<Report>
  exportVendorPaymentConfirmations: Maybe<Report>
  extendPaymentTerm: Maybe<Message>
  financeInvoice: Maybe<ReceivableInvoice>
  importBills: Maybe<Array<Invoice>>
  importHistoricalBills: Maybe<Scalars['Boolean']>
  importPurchaseOrders: Maybe<Scalars['Boolean']>
  initiateVerification: Maybe<Account>
  inventoryTransferPreview: Maybe<InventoryTransfer>
  invitePayer: Maybe<InvitePayerPayload>
  inviteTeamMember: Maybe<Invitation>
  inviteVendor: Maybe<InviteVendorPayload>
  itemizeInvoice: Maybe<Invoice>
  itemizePurchaseOrder: Maybe<PurchaseOrder>
  itemizeReceipt: Maybe<Receipt>
  linkAuthProvider: Maybe<AuthResponse>
  linkDocuments: Array<LinkedDocument>
  linkFinaloop: Maybe<FinaloopState>
  linkFinicity: Maybe<Array<Account>>
  linkInventoryTransferAdditionalExpenseToInvoiceItem: Maybe<InventoryTransfer>
  linkNetsuite: Maybe<NetsuiteState>
  linkOrderDocumentToExternalGrn: Maybe<Array<LinkableOrderDocument>>
  linkPaymentToOrderDocument: Maybe<OrderDocument>
  linkPaymentToPurchaseOrder: Maybe<PurchaseOrder>
  linkPlaid: Maybe<Array<Account>>
  linkPlaidPublicInvoice: Maybe<Account>
  linkPurchaseOrderAdditionalExpenseToInvoiceItem: Maybe<PurchaseOrder>
  linkQuickBooks: Maybe<QuickBooksState>
  linkShopify: Maybe<ShopifyState>
  linkTrackstar: Maybe<TrackstarIntegration>
  linkWorkOrderAdditionalExpenseToInvoiceItem: Maybe<WorkOrder>
  linkXero: Maybe<XeroState>
  manageAssignments: Maybe<Scalars['Boolean']>
  markAsFixed: Maybe<Array<SyncError>>
  markBomsAsReviewed: Maybe<Array<CatalogItemVariation>>
  markCommentsRead: Maybe<Array<Comment>>
  markInvoicePaid: Maybe<Invoice>
  markInvoiceUnpaid: Maybe<Invoice>
  markReceivableInvoicePaid: Maybe<ReceivableInvoice>
  mergeUnlinkedCatalogItem: Maybe<Array<LinkableCatalogItem>>
  mergeWithPublicBusiness: Maybe<LocalBusiness>
  migrateBusinessAccount: Maybe<Account>
  /** @deprecated Use changeBillingPlan instead */
  newChangeBillingPlan: Maybe<Message>
  newPasswordRequest: Maybe<Message>
  /** @deprecated Use PasswordUpdateWithoutLogin instead */
  newPasswordUpdate: Maybe<AuthResponse>
  notifyEptRequest: Maybe<Message>
  notifyWrongInvoice: Maybe<Message>
  openPurchaseOrder: Maybe<PurchaseOrder>
  passwordUpdate: Maybe<Scalars['Boolean']>
  payNowPayment: Maybe<Payment>
  previewInvoice: Maybe<Blob>
  processKybDataRequest: Maybe<KybDataRequest>
  publicDeleteLocalBusinessW9: Maybe<PublicLocalBusiness>
  publicPurchaseOrderPreview: Maybe<Blob>
  publicUpdateLocalBusiness: Maybe<PublicLocalBusiness>
  publicUploadLocalBusinessW9: Maybe<PublicLocalBusiness>
  purchaseOrderPreview: Maybe<PurchaseOrder>
  reactivateSubscription: Maybe<Business>
  rejectAllBusinessMatch: Maybe<LocalBusiness>
  rejectInvoiceApprovalRequest: Maybe<Invoice>
  rejectPayment: Maybe<Payment>
  rejectPurchaseOrderApprovalRequest: Maybe<PurchaseOrder>
  releasePayment: Maybe<Payment>
  releasePayments: Maybe<Array<Payment>>
  removeCatalogItemsLabels: Maybe<Array<CatalogItem>>
  removeInvoicesLabels: Maybe<Array<Invoice>>
  removePayerAccountPublicInvoice: Maybe<PublicInvoice>
  removePurchaseOrdersLabels: Maybe<Array<PurchaseOrder>>
  removeVendorTerm: Maybe<VendorTerms>
  repayEarlyV2: Maybe<Payment>
  reportLinkPlaidFailed: Maybe<Message>
  requestBillingInvoice: Maybe<Blob>
  requestClientBillingInvoice: Maybe<Blob>
  requestCustomBillingPlan: Maybe<Message>
  requestInvoiceApproval: Maybe<Array<Invoice>>
  requestPurchaseOrderApproval: Maybe<Array<PurchaseOrder>>
  requestPurchaseOrderRuleBasedApproval: Maybe<PurchaseOrder>
  requestReceivableFactoring: Maybe<Receivable>
  requestRuleBasedApproval: Maybe<Invoice>
  requireMfa: Maybe<Business>
  reschedulePayment: Maybe<Payment>
  resetMicrodeposits: Maybe<Business>
  restoreBomLineItem: Maybe<BomLineItem>
  restoreLocalBusiness: Maybe<LocalBusiness>
  restorePaymentDate: Maybe<Payment>
  retryFailedBillingPayments: Maybe<Message>
  retryPayment: Maybe<Invoice>
  revokeInvitation: Maybe<Message>
  revokePayment: Maybe<Payment>
  revokePaymentPublicInvoice: Maybe<PublicInvoice>
  revokeReceivableFactoring: Maybe<Receivable>
  revokeRecurrentPayments: Maybe<RecurrentPaymentsSchedule>
  scheduleReceivableFactoring: Maybe<Receivable>
  sendInventoryTransfer: Maybe<InventoryTransfer>
  sendInvoice: Maybe<ReceivableInvoice>
  sendPublicPurchaseOrderEmail: Maybe<Scalars['Boolean']>
  sendPurchaseOrderEmail: Maybe<PurchaseOrder>
  sendW9RequestEmail: Maybe<Array<LocalBusiness>>
  sendWorkOrder: Maybe<WorkOrder>
  setBillingMethodDefault: Maybe<BillingMethod>
  setRoleV2: Maybe<Array<Membership>>
  signIn: Maybe<AuthResponse>
  signInByToken: Maybe<AuthResponse>
  signInWithTotp: Maybe<AuthResponse>
  signInWithTotpRecoveryCode: Maybe<AuthResponse>
  signOut: Maybe<Scalars['Boolean']>
  signUp: Maybe<AuthResponse>
  splitTransfers: Maybe<Invoice>
  startFreeTrial: Maybe<Business>
  syncNetsuite: Maybe<NetsuiteState>
  syncQuickBooks: Maybe<QuickBooksState>
  syncTrackstarIntegration: Maybe<TrackstarIntegration>
  syncWithPublicBusiness: Maybe<LocalBusiness>
  syncXero: Maybe<XeroState>
  testAutoEptSet: Maybe<Message>
  testBeneficialOwnersApprove: Maybe<BeneficialOwnersApprovePayload>
  testBeneficialOwnersDecline: Maybe<BeneficialOwnersDeclinePayload>
  testBeneficialOwnersStartReview: Maybe<BeneficialOwnersStartReviewPayload>
  testCancelSubscription: Maybe<Business>
  testCreateBusiness: Maybe<CreateBusinessPayload>
  testCreateDocumentRequestItem: Maybe<CreateDocumentRequestItemPayload>
  testCreateUser: Maybe<CreateUserPayload>
  testFraudBusinessReviewApprove: Maybe<FraudBusinessReviewApprovePayload>
  testLinkPlaid: Maybe<Array<Account>>
  testMicroDepositsCompleted: Maybe<Account>
  testPayVendor: Maybe<Payment>
  testPayerKycOverride: Maybe<Business>
  testPaymentPaid: Maybe<Payment>
  testPaymentProcessed: Maybe<Payment>
  testSendPayment: Maybe<Payment>
  testVendorFinancingCreditLimit: Maybe<Business>
  unapplyVendorCredit: Maybe<Invoice>
  unlinkDocuments: Array<LinkedDocument>
  unlinkFinaloop: Maybe<FinaloopState>
  unlinkFinicity: Maybe<Array<Account>>
  unlinkNetsuite: Maybe<NetsuiteState>
  unlinkPaymentFromOrderDocument: Maybe<OrderDocument>
  unlinkPaymentFromPurchaseOrder: Maybe<PurchaseOrder>
  unlinkPlaid: Maybe<Array<Account>>
  unlinkQuickBooks: Maybe<QuickBooksState>
  unlinkTrackstar: Maybe<TrackstarIntegration>
  unlinkXero: Maybe<XeroState>
  unrequireMfa: Maybe<Business>
  updateAccount: Maybe<Account>
  updateAccountingAutoImport: Maybe<Integration>
  updateAccountingDetailsRecurrentPayments: Maybe<RecurrentPaymentsSchedule>
  updateAppliedVendorCredits: Maybe<Invoice>
  updateAttachment: Maybe<Attachment>
  updateBomLineItem: Maybe<BomLineItem>
  updateBomMaterial: Maybe<CatalogItem>
  updateBusinessDetails: Maybe<Business>
  updateBusinessDetailsPublicInvoice: Maybe<PublicInvoice>
  updateBusinessFeatures: Maybe<BusinessFeaturesType>
  updateBusinessLogo: Maybe<Business>
  /** Set new W9 form to the business. Require BusinessDetailsPermissionsEnum::UPDATE_BUSINESS_DETAILS permission */
  updateBusinessW9: Maybe<Business>
  /** @deprecated Use updatePaymentReceivingAccount mutation instead. */
  updateCashAdvancePaymentReceivingAccount: Maybe<Payment>
  updateCatalogItem: Maybe<CatalogItem>
  updateCatalogItemInventorySnapshot: Maybe<Location>
  updateCatalogItemProperty: Maybe<CatalogItem>
  updateCatalogItemPropertyTemplate: Maybe<CatalogItemProperty>
  updateCatalogItemVariation: Maybe<CatalogItemVariation>
  updateCatalogItemsArchived: Maybe<Array<CatalogItem>>
  updateCombinedLineItems: Maybe<Invoice>
  updateDefaultVendorTerms: Maybe<VendorTerms>
  updateDiscrepancyRule: Maybe<DiscrepancyRule>
  updateDocumentReference: Maybe<DocumentReference>
  updateExternalPayableInvoiceLineItems: Maybe<Invoice>
  updateFinaloopPreferences: Maybe<FinaloopState>
  updateInternationalDetailsRecurrentPayments: Maybe<RecurrentPaymentsSchedule>
  updateInventoryTransfer: Maybe<InventoryTransfer>
  updateInventoryTransferAdditionalExpense: Maybe<InventoryTransfer>
  updateInventoryTransferLineItem: Maybe<InventoryTransfer>
  updateInventoryTransfersArchive: Maybe<Array<InventoryTransfer>>
  updateInvoice: Maybe<Invoice>
  updateInvoiceAmount: Maybe<Invoice>
  updateInvoiceExternalAttributes: Maybe<Invoice>
  updateInvoiceLineItem: Maybe<Invoice>
  updateInvoicePayableData: Maybe<Invoice>
  updateInvoicesRead: Maybe<Array<Invoice>>
  updateLabel: Maybe<Label>
  updateLocalBusiness: Maybe<LocalBusiness>
  updateLocalBusinessAccount: Maybe<LocalBusinessAccount>
  updateLocalBusinessVisibility: Maybe<LocalBusiness>
  updateLocation: Maybe<Location>
  updateLot: Maybe<Lot>
  updateManualInventoryDemandForecast: Maybe<InventoryItemDemandForecast>
  /** @deprecated Deprecated feature, will be removed after FE cleanup */
  updateMarketingVirtualCard: Maybe<Message>
  updateNetsuitePreferences: Maybe<NetsuiteState>
  updateNotificationSettings: Maybe<NotificationSettings>
  updateOutboundOrder: Maybe<OutboundOrder>
  updateOutboundOrderLineItem: Maybe<OutboundOrder>
  updatePayablesArchived: Maybe<Array<Invoice>>
  updatePayerInvitation: Maybe<PayerInvitationV2>
  updatePaymentAccount: Maybe<Payment>
  updatePaymentAccountingDetails: Maybe<PaymentAccountingDetailsType>
  updatePaymentInternationalDetails: Maybe<Payment>
  updatePaymentReceivingAccount: Maybe<Payment>
  updatePostTrialPlan: Maybe<Business>
  updatePurchaseDetails: Maybe<Array<PurchaseDetailsType>>
  updatePurchaseOrder: Maybe<PurchaseOrder>
  updatePurchaseOrderAdditionalExpense: Maybe<PurchaseOrder>
  updatePurchaseOrderLineItem: Maybe<PurchaseOrder>
  updatePurchaseOrderLineItems: Maybe<PurchaseOrder>
  updatePurchaseOrdersArchived: Maybe<Array<PurchaseOrder>>
  updateQuickBooksPreferences: Maybe<QuickBooksState>
  updateReceipt: Maybe<Receipt>
  updateReceiptLineItem: Maybe<Receipt>
  updateReceiptLineItems: Maybe<Receipt>
  updateReceiptsArchived: Maybe<Array<Receipt>>
  updateReceivable: Maybe<Receivable>
  updateReceivableInvoice: Maybe<ReceivableInvoice>
  updateReceivableInvoiceLineItems: Maybe<ReceivableInvoice>
  updateReceivablesArchived: Maybe<Array<ReceivableInvoice>>
  updateRole: Maybe<UserRole>
  updateRule: Maybe<Rule>
  updateSalesChannel: Maybe<OutboundOrderChannel>
  updateSalesChannelConfigurationStates: Maybe<Array<SalesChannelConfiguration>>
  updateSettleInboxMessagesArchived: Maybe<Array<SettleInboxMessage>>
  updateSettleInboxMessagesRead: Maybe<Array<SettleInboxMessage>>
  updateShopifyIntegrationFeature: Maybe<ShopifyIntegrationFeature>
  updateTrackstarIntegrationFeature: Maybe<TrackstarIntegrationFeature>
  updateUserDetails: Maybe<Profile>
  updateVendorCredit: Maybe<VendorCredit>
  updateVendorLeadTime: Maybe<VendorLeadTime>
  updateVendorTerm: Maybe<PurchaseOrder>
  updateWorkOrder: Maybe<WorkOrder>
  updateWorkOrderAdditionalExpense: Maybe<WorkOrder>
  updateWorkOrderLineItem: Maybe<WorkOrder>
  updateXeroPreferences: Maybe<XeroState>
  upsertAutopayableConfig: Maybe<SettleInboxAutopayableConfigType>
  validateInternationalWireField: Maybe<RoutingNumberValidation>
  validateRoutingNumber: Maybe<RoutingNumberValidation>
  verificationConfirm: Maybe<Profile>
  verificationRequest: Maybe<Message>
  verifyAccount: Maybe<Business>
  verifyAccountPublicInvoice: Maybe<Account>
  viewInvoiceV2: Maybe<Invoice>
  viewPublicInvoiceV2: Maybe<Invoice>
  workOrderPreview: Maybe<WorkOrder>
}

export type MutationacceptBusinessMatchArgs = {
  businessMatchId: Scalars['ID']
}

export type MutationacceptInvitationArgs = {
  email: Scalars['String']
  firstName: Scalars['String']
  invitationToken: Scalars['String']
  lastName: Scalars['String']
  password: Scalars['String']
}

export type MutationacceptInvitationAuthenticatedArgs = {
  invitationToken: Scalars['String']
}

export type MutationacceptTosArgs = {
  businessId?: InputMaybe<Scalars['ID']>
  effectiveDate: Scalars['ISO8601Date']
}

export type MutationacceptVendorInvitationArgs = {
  email: Scalars['String']
  firstName: Scalars['String']
  invitationToken: Scalars['String']
  lastName: Scalars['String']
  password: Scalars['String']
}

export type MutationacceptVendorInvitationAuthenticatedArgs = {
  firstName: Scalars['String']
  invitationToken: Scalars['String']
  lastName: Scalars['String']
}

export type MutationactivateRuleV2Args = {
  ruleId: Scalars['ID']
}

export type MutationactivateTeamMemberArgs = {
  teamMemberId: Scalars['ID']
}

export type MutationactivateTotpArgs = {
  mfaToken: Scalars['String']
  totpCode: Scalars['String']
}

export type MutationaddCatalogItemsLabelsArgs = {
  catalogItemIds: Array<Scalars['ID']>
  labelIds: Array<Scalars['ID']>
}

export type MutationaddInvoicesLabelsArgs = {
  invoiceIds: Array<Scalars['ID']>
  labelIds: Array<Scalars['ID']>
}

export type MutationaddJourneyV2Args = {
  journeyType: JourneyEnum
}

export type MutationaddPurchaseOrdersLabelsArgs = {
  labelIds: Array<Scalars['ID']>
  purchaseOrderIds: Array<Scalars['ID']>
}

export type MutationaddTeamMemberToClientArgs = {
  clientBusinessId: Scalars['ID']
  role?: InputMaybe<MembershipRolesEnum>
  roleId?: InputMaybe<Scalars['ID']>
  teamMemberId: Scalars['ID']
}

export type MutationaddVendorCreditArgs = {
  vendorCreditId: Scalars['ID']
}

export type MutationallocatePaymentToInvoiceArgs = {
  invoiceId: Scalars['ID']
  paymentId: Scalars['ID']
}

export type MutationamortizedRepayEarlyArgs = {
  paymentId: Scalars['ID']
}

export type MutationapplyPromotionCodeArgs = {
  billingPromotionCodeId: Scalars['ID']
}

export type MutationapplyPromotionCodeV2Args = {
  billingPromotionCodeId: Scalars['ID']
}

export type MutationapproveInvoiceApprovalRequestArgs = {
  approvalNote?: InputMaybe<Scalars['String']>
  invoiceId: Scalars['ID']
}

export type MutationapprovePaymentArgs = {
  approvalNote?: InputMaybe<Scalars['String']>
  paymentId: Scalars['ID']
}

export type MutationapprovePurchaseOrderApprovalRequestArgs = {
  approvalNote?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type MutationapproveReceivableDebitArgs = {
  receivableId: Scalars['ID']
}

export type MutationarchiveCatalogItemPropertyArgs = {
  propertyId: Scalars['ID']
}

export type MutationarchiveCatalogItemPropertyTemplateArgs = {
  propertyId: Scalars['ID']
}

export type MutationarchiveLocationArgs = {
  locationId: Scalars['ID']
}

export type MutationarchiveUnlinkedCatalogItemArgs = {
  linkableCatalogItemIds: Array<Scalars['ID']>
}

export type MutationattachInvoiceDocumentArgs = {
  category?: InputMaybe<AttachmentCategoryEnum>
  invoiceId: Scalars['ID']
  uploadSignedId: Scalars['ID']
}

export type MutationattachLocalBusinessDocumentArgs = {
  category: AttachmentCategoryEnum
  localBusinessId: Scalars['ID']
  uploadSignedId: Scalars['ID']
}

export type MutationattachPurchaseOrderDocumentArgs = {
  category?: InputMaybe<AttachmentCategoryEnum>
  purchaseOrderId: Scalars['ID']
  uploadSignedId: Scalars['ID']
}

export type MutationattachReceiptDocumentArgs = {
  category?: InputMaybe<AttachmentCategoryEnum>
  receiptId: Scalars['ID']
  uploadSignedId: Scalars['ID']
}

export type MutationattachVendorCreditDocumentArgs = {
  uploadSignedId: Scalars['ID']
  vendorCreditId: Scalars['ID']
}

export type MutationattachW9Args = {
  attachedDocumentId: Scalars['ID']
  localBusinessId: Scalars['ID']
}

export type MutationauthProviderAcceptInvitationArgs = {
  data: Scalars['String']
  firstName: Scalars['String']
  invitationToken: Scalars['String']
  lastName: Scalars['String']
  provider: AuthProviderTypeEnum
}

export type MutationauthProviderAcceptVendorInvitationArgs = {
  data: Scalars['String']
  firstName: Scalars['String']
  invitationToken: Scalars['String']
  lastName: Scalars['String']
  provider: AuthProviderTypeEnum
}

export type MutationauthProviderSignInArgs = {
  data: Scalars['String']
  provider: AuthProviderTypeEnum
}

export type MutationauthProviderSignUpArgs = {
  businessStructure?: InputMaybe<BusinessStructureEnum>
  company?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  data: Scalars['String']
  firstName?: InputMaybe<Scalars['String']>
  invitationCode: Scalars['String']
  is1099Eligible?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  partnerDataAccessToken?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  provider: AuthProviderTypeEnum
  website?: InputMaybe<Scalars['String']>
}

export type MutationbulkPayAsapV2Args = {
  fromAccountId: Scalars['ID']
  invoices: Array<BulkPayInputObjectV2>
  paymentMethod: PaymentMethodEnum
}

export type MutationbulkPayOnDateV2Args = {
  fromAccountId: Scalars['ID']
  invoices: Array<BulkPayInputObjectV2>
  paymentMethod: PaymentMethodEnum
  processDate: Scalars['ISO8601Date']
}

export type MutationbulkPayOnDueDateV2Args = {
  fromAccountId: Scalars['ID']
  invoices: Array<BulkPayInputObjectV2>
  paymentMethod: PaymentMethodEnum
}

export type MutationbulkUpdateCatalogItemArgs = {
  attributes: CatalogItemBulkInputObject
  ids: Array<Scalars['ID']>
}

export type MutationcancelInvoiceApprovalRequestArgs = {
  invoiceId: Scalars['ID']
}

export type MutationcancelJourneyStepArgs = {
  stepType: JourneyStepEnum
}

export type MutationcancelPurchaseOrderApprovalRequestArgs = {
  id: Scalars['ID']
}

export type MutationcancelVendorFinancingArgs = {
  invoiceId: Scalars['ID']
}

export type MutationchangeBillingPlanArgs = {
  billingPlanId: Scalars['ID']
}

export type MutationchangeBulletEptTermLengthArgs = {
  paymentId: Scalars['ID']
  periods: Scalars['Int']
}

export type MutationchangeClientBillingPlanArgs = {
  billingPlanId: Scalars['ID']
  businessId: Scalars['ID']
}

export type MutationchangePayFromAccountRecurrentPaymentsArgs = {
  payFromAccountId: Scalars['ID']
  scheduleId: Scalars['ID']
}

export type MutationchangePayToAccountRecurrentPaymentsArgs = {
  payToAccountId: Scalars['ID']
  scheduleId: Scalars['ID']
}

export type MutationcheckAllocationAllowedArgs = {
  invoiceId: Scalars['ID']
  paymentId: Scalars['ID']
}

export type MutationclearPayerInvoiceArgs = {
  invoiceId: Scalars['ID']
}

export type MutationcopyOrUpdateLocalBusinessAccountArgs = {
  accountId: Scalars['ID']
  currency: Scalars['String']
  migrateInvoices: Scalars['Boolean']
  wireFields: Array<InternationalWireFieldsDataFieldInputObject>
}

export type MutationcreateAccountArgs = {
  attributes: AccountInputObject
  receiving?: InputMaybe<Scalars['Boolean']>
}

export type MutationcreateAmortizedLoanPaymentV2Args = {
  data: PaymentInputObject
  invoiceId: Scalars['ID']
}

export type MutationcreateAttachmentArgs = {
  category: AttachmentCategoryEnum
  uploadSignedId: Scalars['ID']
}

export type MutationcreateBeneficialOwnersV2Args = {
  businessBeneficialOwners?: InputMaybe<Array<BusinessBeneficialOwnerInput>>
  individualBeneficialOwners?: InputMaybe<Array<BeneficialOwnerIndividualInputType>>
}

export type MutationcreateBillingMethodArgs = {
  accountId?: InputMaybe<Scalars['ID']>
  default?: InputMaybe<Scalars['Boolean']>
  stripeCardToken?: InputMaybe<Scalars['String']>
}

export type MutationcreateBomMaterialArgs = {
  catalogItemId: Scalars['ID']
  defaultCatalogItemVariationId?: InputMaybe<Scalars['ID']>
  defaultQuantity?: InputMaybe<Scalars['Float']>
  name: Scalars['String']
}

export type MutationcreateBulletEptPaymentArgs = {
  data: PaymentInputObject
  invoiceId: Scalars['ID']
  periods: Scalars['Int']
}

export type MutationcreateBusinessArgs = {
  businessStructure: BusinessStructureEnum
  firstName?: InputMaybe<Scalars['String']>
  industry?: InputMaybe<BusinessIndustryEnum>
  interestedIn?: InputMaybe<Array<SignupInterestsEnum>>
  is1099Eligible?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export type MutationcreateByNumberPurchaseOrderArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>
  number?: InputMaybe<Scalars['String']>
  receiptId?: InputMaybe<Scalars['ID']>
}

export type MutationcreateCashAdvanceAmortizedLoanPaymentArgs = {
  data: CashAdvancePaymentInputObject
}

export type MutationcreateCashAdvanceBulletEptPaymentArgs = {
  data: CashAdvancePaymentInputObject
  periods: Scalars['Int']
}

export type MutationcreateCashAdvanceEptPaymentArgs = {
  data: CashAdvancePaymentInputObject
  offerIds: Array<Scalars['ID']>
}

export type MutationcreateCashAdvancePreliminaryAmortizedLoanAgreementArgs = {
  amount: MoneyInputObject
  signed?: InputMaybe<Scalars['Boolean']>
}

export type MutationcreateCashAdvancePreliminaryBulletEptAgreementArgs = {
  amount: MoneyInputObject
  periods: Scalars['Int']
  signed?: InputMaybe<Scalars['Boolean']>
}

export type MutationcreateCashAdvancePreliminaryEptAgreementArgs = {
  amount: MoneyInputObject
  offerIds: Array<Scalars['ID']>
  signed?: InputMaybe<Scalars['Boolean']>
}

export type MutationcreateCatalogItemArgs = {
  attributes: CatalogItemInputObject
}

export type MutationcreateCatalogItemFromExternalSourceArgs = {
  linkableCatalogItemIds: Array<Scalars['ID']>
}

export type MutationcreateCatalogItemInventorySnapshotArgs = {
  attributes: CatalogItemInventorySnapshotInputObject
}

export type MutationcreateCatalogItemPropertyArgs = {
  attributes?: InputMaybe<CatalogItemPropertyInputObject>
  catalogItemId: Scalars['ID']
  templateId?: InputMaybe<Scalars['ID']>
}

export type MutationcreateCatalogItemPropertyTemplateArgs = {
  attributes: CatalogItemPropertyInputObject
}

export type MutationcreateClientBusinessArgs = {
  businessStructure: BusinessStructureEnum
  firstName?: InputMaybe<Scalars['String']>
  industry?: InputMaybe<BusinessIndustryEnum>
  interestedIn?: InputMaybe<Array<SignupInterestsEnum>>
  is1099Eligible?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export type MutationcreateCommentArgs = {
  attributes: CommentInputObject
  entityId: Scalars['ID']
}

export type MutationcreateConsolidatedPaymentsArgs = {
  fromAccountId: Scalars['ID']
  onDueDates?: InputMaybe<Scalars['Boolean']>
  paymentMethod: PaymentMethodEnum
  paymentsData: Array<ConsolidatedPaymentInputObject>
  processDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type MutationcreateCustomBomLineItemArgs = {
  catalogItemVariationId: Scalars['ID']
  lineItemVariationId?: InputMaybe<Scalars['ID']>
  quantity?: InputMaybe<Scalars['Float']>
}

export type MutationcreateCustomInventoryTransferArgs = {
  attributes?: InputMaybe<InventoryTransferInputObject>
  linkedDocumentId?: InputMaybe<Scalars['ID']>
}

export type MutationcreateCustomOutboundOrderArgs = {
  attributes: OutboundOrdersInputObject
}

export type MutationcreateCustomPurchaseOrderArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>
  receiptId?: InputMaybe<Scalars['ID']>
}

export type MutationcreateDeferralRequestArgs = {
  data: DeferralsRequestsInputObject
}

export type MutationcreateDiscrepancyRuleArgs = {
  thresholds: Array<DiscrepancyThresholdInputObject>
  vendorLocalBusinessId?: InputMaybe<Scalars['ID']>
}

export type MutationcreateDocumentFromReferenceArgs = {
  documentReferenceId: Scalars['ID']
}

export type MutationcreateDocumentReferenceArgs = {
  documentReferenceData: DocumentReferenceCreateInputObject
  sourceDocumentId: Scalars['ID']
}

export type MutationcreateEmailOptOutArgs = {
  optOutToken: Scalars['String']
}

export type MutationcreateExtendedTermPaymentV2Args = {
  data: PaymentInputObject
  invoiceId: Scalars['ID']
  offerIds: Array<Scalars['ID']>
}

export type MutationcreateFactoringAgreementArgs = {
  invoiceId: Scalars['ID']
  signed?: InputMaybe<Scalars['Boolean']>
}

export type MutationcreateFromPublicBusinessArgs = {
  attributes: LocalBusinessPublicInputObject
  catalogItemId?: InputMaybe<Scalars['ID']>
  invoiceId?: InputMaybe<Scalars['ID']>
  publicBusinessId: Scalars['ID']
  purchaseOrderId?: InputMaybe<Scalars['ID']>
  receiptId?: InputMaybe<Scalars['ID']>
}

export type MutationcreateInventoryTransferAdditionalExpenseArgs = {
  attributes?: InputMaybe<AdditionalExpenseInputObject>
  inventoryTransferId: Scalars['ID']
  invoiceLineItemIds?: InputMaybe<Array<Scalars['ID']>>
}

export type MutationcreateInventoryTransferLineItemArgs = {
  attributes: InventoryTransferLineItemInputObject
  inventoryTransferId: Scalars['ID']
}

export type MutationcreateInvoiceLineItemArgs = {
  attributes: InvoiceLineItemInputObject
  invoiceId: Scalars['ID']
}

export type MutationcreateLabelArgs = {
  attributes: LabelInputObject
}

export type MutationcreateLocalBusinessArgs = {
  accountAttributes?: InputMaybe<LocalBusinessAccountInputObject>
  attributes: LocalBusinessInputObject
  catalogItemId?: InputMaybe<Scalars['ID']>
  invoiceId?: InputMaybe<Scalars['ID']>
  purchaseOrderId?: InputMaybe<Scalars['ID']>
  receiptId?: InputMaybe<Scalars['ID']>
}

export type MutationcreateLocalBusinessAccountArgs = {
  attributes: LocalBusinessAccountInputObject
  localBusinessId: Scalars['ID']
}

export type MutationcreateLocationArgs = {
  attributes: LocationInputObject
}

export type MutationcreateLocationFromLocalBusinessArgs = {
  inventoryTransferReceivingId?: InputMaybe<Scalars['ID']>
  inventoryTransferSendingId?: InputMaybe<Scalars['ID']>
  localBusinessId: Scalars['ID']
  purchaseOrderId?: InputMaybe<Scalars['ID']>
  receiptId?: InputMaybe<Scalars['ID']>
  workOrderId?: InputMaybe<Scalars['ID']>
}

export type MutationcreateLocationFromPublicBusinessArgs = {
  attributes: LocalBusinessPublicInputObject
  inventoryTransferReceivingId?: InputMaybe<Scalars['ID']>
  inventoryTransferSendingId?: InputMaybe<Scalars['ID']>
  publicBusinessId: Scalars['ID']
  purchaseOrderId?: InputMaybe<Scalars['ID']>
  receiptId?: InputMaybe<Scalars['ID']>
  workOrderId?: InputMaybe<Scalars['ID']>
}

export type MutationcreateManualInventoryDemandForecastArgs = {
  attributes: ManualInventoryDemandForecastInputObject
}

export type MutationcreateMarketingVirtualCardArgs = {
  data?: InputMaybe<MarketingVirtualCardInputObject>
}

export type MutationcreateOrderDocumentFromExternalGrnArgs = {
  attributes: Array<CreateOrderDocumentInputObject>
}

export type MutationcreateOutboundOrderLineItemArgs = {
  attributes?: InputMaybe<OutboundOrderLineItemInputObject>
  outboundOrderId: Scalars['ID']
}

export type MutationcreatePayableArgs = {
  purchaseOrderId?: InputMaybe<Scalars['ID']>
  receiptId?: InputMaybe<Scalars['ID']>
  uploadSignedId: Scalars['ID']
}

export type MutationcreatePayableFromDataAccessTokenArgs = {
  partnerDataAccessToken: Scalars['String']
}

export type MutationcreatePayableFromSettleInboxArgs = {
  attributes: PayableInvoiceInputObject
  settleInboxAttachmentId?: InputMaybe<Scalars['ID']>
  settleInboxAttachmentIds?: InputMaybe<Array<Scalars['ID']>>
  settleInboxMessageId: Scalars['ID']
}

export type MutationcreatePayableWithoutAttachmentArgs = {
  number?: InputMaybe<Scalars['String']>
  purchaseOrderId?: InputMaybe<Scalars['ID']>
  receiptId?: InputMaybe<Scalars['ID']>
}

export type MutationcreatePayerInvitationArgs = {
  accountingFirmId?: InputMaybe<Scalars['ID']>
  billingPromotionCodeId?: InputMaybe<Scalars['ID']>
  email?: InputMaybe<Scalars['String']>
  interestedIn: Array<SignupInterestsEnum>
  partnerDataAccessToken?: InputMaybe<Scalars['String']>
  referralCode?: InputMaybe<Scalars['String']>
  source?: InputMaybe<Scalars['String']>
}

export type MutationcreatePaymentPublicInvoiceArgs = {
  processDate?: InputMaybe<Scalars['ISO8601Date']>
  uuid: Scalars['String']
}

export type MutationcreatePaymentV2Args = {
  data: PaymentInputObject
  invoiceId: Scalars['ID']
}

export type MutationcreatePreliminaryAmortizedLoanAgreementV2Args = {
  amount: MoneyInputObject
  invoiceId: Scalars['ID']
  paymentMethod?: InputMaybe<PaymentMethodEnum>
  signed?: InputMaybe<Scalars['Boolean']>
  vendorPaymentDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type MutationcreatePreliminaryBulletEptAgreementArgs = {
  amount: MoneyInputObject
  invoiceId: Scalars['ID']
  paymentMethod?: InputMaybe<PaymentMethodEnum>
  periods: Scalars['Int']
  signed?: InputMaybe<Scalars['Boolean']>
  vendorPaymentDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type MutationcreatePreliminaryEptAgreementArgs = {
  offerIds: Array<Scalars['ID']>
  signed?: InputMaybe<Scalars['Boolean']>
  transferId: Scalars['ID']
  vendorPaymentDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type MutationcreatePreliminaryEptAgreementV2Args = {
  amount: MoneyInputObject
  invoiceId: Scalars['ID']
  offerIds: Array<Scalars['ID']>
  paymentMethod?: InputMaybe<PaymentMethodEnum>
  signed?: InputMaybe<Scalars['Boolean']>
  vendorPaymentDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type MutationcreatePurchaseDetailsArgs = {
  attributes: PurchaseDetailsInputObject
  catalogItemId: Scalars['ID']
}

export type MutationcreatePurchaseOrderArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>
  number?: InputMaybe<Scalars['String']>
  receiptId?: InputMaybe<Scalars['ID']>
  uploadSignedId?: InputMaybe<Scalars['ID']>
}

export type MutationcreatePurchaseOrderAdditionalExpenseArgs = {
  attributes?: InputMaybe<AdditionalExpenseInputObject>
  invoiceLineItemIds?: InputMaybe<Array<Scalars['ID']>>
  purchaseOrderId: Scalars['ID']
}

export type MutationcreatePurchaseOrderLineItemArgs = {
  attributes: PurchaseOrderLineItemInputObject
  purchaseOrderId: Scalars['ID']
}

export type MutationcreateReceiptArgs = {
  attributes?: InputMaybe<ReceiptCreateInputObject>
}

export type MutationcreateReceiptLineItemArgs = {
  attributes: ReceiptLineItemInputObject
  receiptId: Scalars['ID']
}

export type MutationcreateReceivableArgs = {
  uploadSignedId: Scalars['ID']
}

export type MutationcreateRecurrentPaymentsScheduleArgs = {
  scheduleData: RecurrentPaymentsScheduleInputObject
}

export type MutationcreateRoleArgs = {
  description: Scalars['String']
  name: Scalars['String']
  permissionSets: Array<PermissionSetInputObject>
}

export type MutationcreateRuleArgs = {
  actions?: InputMaybe<Array<RuleActionInputObject>>
  conditions?: InputMaybe<Array<RuleConditionInputObject>>
  name: Scalars['String']
  priority?: InputMaybe<Scalars['Int']>
  targetType?: InputMaybe<RuleTypeEnum>
}

export type MutationcreateSalesChannelArgs = {
  attributes: SalesChannelInputObject
}

export type MutationcreateShopifyConnectorArgs = {
  redirectUri?: InputMaybe<Scalars['String']>
}

export type MutationcreateStandalonePaymentArgs = {
  data: StandalonePaymentInputObject
}

export type MutationcreateUploadedDocumentArgs = {
  documentRequestId: Scalars['ID']
  uploadSignedIds: Array<Scalars['ID']>
}

export type MutationcreateVendorCreditArgs = {
  uploadSignedId?: InputMaybe<Scalars['ID']>
  vendorLocalBusinessId?: InputMaybe<Scalars['ID']>
}

export type MutationcreateVendorCreditFromSettleInboxArgs = {
  settleInboxAttachmentId: Scalars['ID']
  settleInboxMessageId: Scalars['ID']
}

export type MutationcreateVendorInvitationLinkArgs = {
  localBusinessId: Scalars['ID']
}

export type MutationcreateVendorLeadTimeArgs = {
  vendorLeadTime: VendorLeadTimeInputObject
}

export type MutationcreateVendorTermArgs = {
  purchaseOrderId: Scalars['ID']
  vendorTerm: VendorTermInputObject
}

export type MutationcreateVendorTermOptionArgs = {
  vendorTermOption: VendorTermOptionInputObject
}

export type MutationcreateW9FromSettleInboxArgs = {
  settleInboxAttachmentId: Scalars['ID']
  settleInboxMessageId: Scalars['ID']
  vendorLocalBusinessId: Scalars['ID']
}

export type MutationcreateW9RequestLinkArgs = {
  localBusinessId: Scalars['ID']
}

export type MutationcreateWorkOrderArgs = {
  data?: InputMaybe<WorkOrderInputObject>
}

export type MutationcreateWorkOrderAdditionalExpenseArgs = {
  attributes?: InputMaybe<AdditionalExpenseInputObject>
  invoiceLineItemIds?: InputMaybe<Array<Scalars['ID']>>
  workOrderId: Scalars['ID']
}

export type MutationcreateWorkOrderLineItemArgs = {
  data?: InputMaybe<WorkOrderLineItemInputObject>
  workOrderId: Scalars['ID']
}

export type MutationdeactivateRuleArgs = {
  ruleId: Scalars['ID']
}

export type MutationdeactivateRuleV2Args = {
  ruleId: Scalars['ID']
}

export type MutationdeclineTermExtensionsArgs = {
  offerIds: Array<Scalars['ID']>
  paymentId: Scalars['ID']
}

export type MutationdeleteAttachmentArgs = {
  attachmentId: Scalars['ID']
}

export type MutationdeleteBillingMethodArgs = {
  billingMethodId: Scalars['ID']
}

export type MutationdeleteBomArgs = {
  catalogItemId: Scalars['ID']
}

export type MutationdeleteBomLineItemArgs = {
  lineItemId: Scalars['ID']
}

export type MutationdeleteBomMaterialArgs = {
  materialId: Scalars['ID']
}

export type MutationdeleteCommentArgs = {
  commentId: Scalars['ID']
}

export type MutationdeleteDiscrepancyRuleArgs = {
  id: Scalars['ID']
}

export type MutationdeleteInventoryTransferAdditionalExpenseArgs = {
  expenseId: Scalars['ID']
}

export type MutationdeleteInventoryTransferLineItemsArgs = {
  inventoryTransferLineItemIds: Array<Scalars['ID']>
}

export type MutationdeleteInvoiceAttachmentArgs = {
  attachmentId?: InputMaybe<Scalars['ID']>
  invoiceId: Scalars['ID']
}

export type MutationdeleteInvoiceLineItemsArgs = {
  invoiceLineItemIds: Array<Scalars['ID']>
}

export type MutationdeleteInvoicesArgs = {
  invoiceIds: Array<Scalars['ID']>
}

export type MutationdeleteLabelsArgs = {
  labelIds: Array<Scalars['ID']>
}

export type MutationdeleteLocalBusinessAccountArgs = {
  accountId: Scalars['ID']
}

export type MutationdeleteLocalBusinessesArgs = {
  localBusinessIds: Array<Scalars['ID']>
}

export type MutationdeleteManualInventoryDemandForecastArgs = {
  id: Scalars['ID']
}

export type MutationdeleteOutboundOrderArgs = {
  id: Scalars['ID']
}

export type MutationdeleteOutboundOrderLineItemsArgs = {
  outboundOrderLineItemIds: Array<Scalars['ID']>
}

export type MutationdeletePurchaseDetailsArgs = {
  purchaseDetailsIds: Array<Scalars['ID']>
}

export type MutationdeletePurchaseOrderAdditionalExpenseArgs = {
  expenseId: Scalars['ID']
}

export type MutationdeletePurchaseOrderLineItemsArgs = {
  purchaseOrderLineItemIds: Array<Scalars['ID']>
}

export type MutationdeleteReceiptLineItemsArgs = {
  receiptLineItemIds: Array<Scalars['ID']>
}

export type MutationdeleteReceivableArgs = {
  receivableIds: Array<Scalars['ID']>
}

export type MutationdeleteRoleV2Args = {
  roleId: Scalars['ID']
  roleIdToAssign?: InputMaybe<Scalars['ID']>
}

export type MutationdeleteRuleArgs = {
  ruleId: Scalars['ID']
}

export type MutationdeleteSettleInboxMessagesArgs = {
  settleInboxMessageIds: Array<Scalars['ID']>
}

export type MutationdeleteTeamMemberArgs = {
  businessId?: InputMaybe<Scalars['ID']>
  teamMemberId: Scalars['ID']
}

export type MutationdeleteVendorCreditArgs = {
  vendorCreditId: Scalars['ID']
}

export type MutationdeleteVendorLeadTimeArgs = {
  localBusinessId?: InputMaybe<Scalars['ID']>
  purchaseOrderId?: InputMaybe<Scalars['ID']>
  vendorLeadTimeIds: Array<Scalars['ID']>
}

export type MutationdeleteVendorTermOptionArgs = {
  vendorTermOptionIds: Array<Scalars['ID']>
}

export type MutationdeleteW9Args = {
  localBusinessId: Scalars['ID']
}

export type MutationdeleteWorkOrderAdditionalExpenseArgs = {
  additionalExpenseId: Scalars['ID']
}

export type MutationdeleteWorkOrderLineItemArgs = {
  workOrderLineItemId: Scalars['ID']
}

export type MutationdownloadBillingStatementArgs = {
  billingInvoiceId: Scalars['ID']
}

export type MutationdownloadClientBillingStatementArgs = {
  billingInvoiceId: Scalars['ID']
}

export type MutationdownloadClientUpcomingBillingStatementArgs = {
  clientBusinessId: Scalars['ID']
}

export type MutationduplicatePurchaseOrderArgs = {
  purchaseOrderId: Scalars['ID']
}

export type MutationexcludeFromSyncArgs = {
  excludeFromSync: Scalars['Boolean']
  syncObjectIds: Array<Scalars['ID']>
  syncObjectType: SyncObjectTypeEnum
}

export type Mutationexport1099CsvArgs = {
  year?: InputMaybe<Scalars['Int']>
}

export type MutationexportApAgingDetailsArgs = {
  asOfDate?: InputMaybe<Scalars['ISO8601Date']>
  includeVendorCredits?: InputMaybe<Scalars['Boolean']>
  invoicesFilter?: InputMaybe<InvoicesFilteringInputObject>
}

export type MutationexportApAgingSummaryArgs = {
  asOfDate?: InputMaybe<Scalars['ISO8601Date']>
  invoicesFilter?: InputMaybe<InvoicesFilteringInputObject>
}

export type MutationexportCashOutflowArgs = {
  apForecastEnabled?: InputMaybe<Scalars['Boolean']>
}

export type MutationexportEndOfMonthInventoryArgs = {
  endDate: Scalars['ISO8601Date']
  startDate: Scalars['ISO8601Date']
}

export type MutationexportFactoringAgreementsArgs = {
  dateRange?: InputMaybe<DateFilteringInputObject>
  receivableIds?: InputMaybe<Array<Scalars['ID']>>
}

export type MutationexportInvoicesV2Args = {
  invoiceIds?: InputMaybe<Array<Scalars['ID']>>
  invoicesFilter?: InputMaybe<InvoicesFilteringInputObject>
  sorting?: InputMaybe<InvoiceSortingInputObject>
}

export type MutationexportLockboxTransactionsArgs = {
  dateRange?: InputMaybe<DateFilteringInputObject>
}

export type MutationexportMvcTransactionsArgs = {
  dateRange?: InputMaybe<DateFilteringInputObject>
}

export type MutationexportMyInvoicesArgs = {
  dateRange?: InputMaybe<DateFilteringInputObject>
}

export type MutationexportOwedToSettleArgs = {
  asOfDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type MutationexportPaymentConfirmationsV2Args = {
  dateRange?: InputMaybe<DateFilteringInputObject>
  invoiceIds?: InputMaybe<Array<Scalars['ID']>>
  invoicesFilter?: InputMaybe<InvoicesFilteringInputObject>
}

export type MutationexportPurchaseOrdersArgs = {
  purchaseOrderIds?: InputMaybe<Array<Scalars['ID']>>
  purchaseOrdersFilter?: InputMaybe<PurchaseOrdersFilteringInputObject>
}

export type MutationexportReceivablesArgs = {
  dateRange?: InputMaybe<DateFilteringInputObject>
  receivableIds?: InputMaybe<Array<Scalars['ID']>>
}

export type MutationexportReconciliationItemsArgs = {
  reconciliationItemIds?: InputMaybe<Array<Scalars['ID']>>
  reconciliationItemsFilter?: InputMaybe<ReconciliationItemsFilteringInputObject>
  sorting?: InputMaybe<ReconciliationItemSortingInputObject>
}

export type MutationexportSignedDocumentsV2Args = {
  dateRange?: InputMaybe<DateFilteringInputObject>
  documentType: SignedDocumentTypesEnum
  invoiceIds?: InputMaybe<Array<Scalars['ID']>>
  invoicesFilter?: InputMaybe<InvoicesFilteringInputObject>
}

export type MutationexportVendorPaymentConfirmationsArgs = {
  dateRange?: InputMaybe<DateFilteringInputObject>
}

export type MutationextendPaymentTermArgs = {
  offerIds?: InputMaybe<Array<Scalars['ID']>>
  paymentId: Scalars['ID']
}

export type MutationfinanceInvoiceArgs = {
  invoiceId: Scalars['ID']
}

export type MutationimportBillsArgs = {
  billIds: Array<Scalars['ID']>
}

export type MutationimportHistoricalBillsArgs = {
  endDate?: InputMaybe<Scalars['ISO8601Date']>
  startDate: Scalars['ISO8601Date']
}

export type MutationimportPurchaseOrdersArgs = {
  purchaseOrderIds: Array<Scalars['ID']>
}

export type MutationinitiateVerificationArgs = {
  accountId: Scalars['ID']
  accountNumber: Scalars['String']
  routingNumber?: InputMaybe<Scalars['String']>
  wireRoutingNumber?: InputMaybe<Scalars['String']>
}

export type MutationinventoryTransferPreviewArgs = {
  inventoryTransferId: Scalars['ID']
}

export type MutationinvitePayerArgs = {
  localBusinessId: Scalars['ID']
}

export type MutationinviteTeamMemberArgs = {
  accountingAccountIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  email: Scalars['String']
  occupation?: InputMaybe<Scalars['String']>
  occupationType?: InputMaybe<OccupationEnum>
  role?: InputMaybe<MembershipRolesEnum>
  roleId?: InputMaybe<Scalars['ID']>
}

export type MutationinviteVendorArgs = {
  customMessage?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  invoiceId?: InputMaybe<Scalars['ID']>
  localBusinessId: Scalars['ID']
}

export type MutationitemizeInvoiceArgs = {
  invoiceId: Scalars['ID']
}

export type MutationitemizePurchaseOrderArgs = {
  purchaseOrderId: Scalars['ID']
}

export type MutationitemizeReceiptArgs = {
  receiptId: Scalars['ID']
}

export type MutationlinkAuthProviderArgs = {
  data: Scalars['String']
  provider: AuthProviderTypeEnum
}

export type MutationlinkDocumentsArgs = {
  linkedDocumentIds: Array<Scalars['ID']>
  sourceDocumentId: Scalars['ID']
}

export type MutationlinkFinaloopArgs = {
  businessId?: InputMaybe<Scalars['ID']>
}

export type MutationlinkInventoryTransferAdditionalExpenseToInvoiceItemArgs = {
  expenseId: Scalars['ID']
  invoiceLineItemId: Scalars['ID']
}

export type MutationlinkNetsuiteArgs = {
  accountId: Scalars['String']
  analyticsRoleId?: InputMaybe<Scalars['String']>
  analyticsTokenId?: InputMaybe<Scalars['String']>
  analyticsTokenSecret?: InputMaybe<Scalars['String']>
  apiTokenId: Scalars['String']
  apiTokenSecret: Scalars['String']
  businessId?: InputMaybe<Scalars['ID']>
  integrationKey: Scalars['String']
  integrationSecret: Scalars['String']
  subsidiaryId: Scalars['String']
}

export type MutationlinkOrderDocumentToExternalGrnArgs = {
  attributes: Array<LinkOrderDocumentInputObject>
}

export type MutationlinkPaymentToOrderDocumentArgs = {
  orderDocumentId: Scalars['ID']
  paymentId: Scalars['ID']
}

export type MutationlinkPaymentToPurchaseOrderArgs = {
  paymentId: Scalars['ID']
  purchaseOrderId: Scalars['ID']
}

export type MutationlinkPlaidArgs = {
  businessId?: InputMaybe<Scalars['ID']>
  publicToken: Scalars['String']
  selectedAccountIds?: InputMaybe<Array<Scalars['String']>>
}

export type MutationlinkPlaidPublicInvoiceArgs = {
  publicToken: Scalars['String']
  selectedAccountId: Scalars['String']
  uuid: Scalars['String']
}

export type MutationlinkPurchaseOrderAdditionalExpenseToInvoiceItemArgs = {
  expenseId: Scalars['ID']
  invoiceLineItemId: Scalars['ID']
}

export type MutationlinkQuickBooksArgs = {
  businessId?: InputMaybe<Scalars['ID']>
  code: Scalars['String']
}

export type MutationlinkShopifyArgs = {
  businessId?: InputMaybe<Scalars['ID']>
  code: Scalars['String']
  hmac: Scalars['String']
  host: Scalars['String']
  shop: Scalars['String']
  state: Scalars['String']
  timestamp: Scalars['String']
}

export type MutationlinkTrackstarArgs = {
  authCode: Scalars['String']
  businessId?: InputMaybe<Scalars['ID']>
}

export type MutationlinkWorkOrderAdditionalExpenseToInvoiceItemArgs = {
  additionalExpenseId: Scalars['ID']
  invoiceLineItemId: Scalars['ID']
}

export type MutationlinkXeroArgs = {
  businessId?: InputMaybe<Scalars['ID']>
  code: Scalars['String']
}

export type MutationmanageAssignmentsArgs = {
  accountingAccountIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  accountingFirmId: Scalars['ID']
  assignments: Array<ClientManagerAssignmentInputObject>
  clientId: Scalars['ID']
}

export type MutationmarkAsFixedArgs = {
  markAsFixed: Scalars['Boolean']
  syncErrorIds: Array<Scalars['ID']>
}

export type MutationmarkBomsAsReviewedArgs = {
  variationIds: Array<Scalars['ID']>
}

export type MutationmarkCommentsReadArgs = {
  commentIds: Array<Scalars['ID']>
}

export type MutationmarkInvoicePaidArgs = {
  accountId?: InputMaybe<Scalars['ID']>
  accountingAccountId?: InputMaybe<Scalars['ID']>
  amountCents?: InputMaybe<Scalars['SafeInt']>
  amountCurrency?: InputMaybe<Scalars['String']>
  datePaid?: InputMaybe<Scalars['ISO8601Date']>
  invoiceId: Scalars['ID']
  paymentMethod?: InputMaybe<PaymentMethodEnum>
}

export type MutationmarkInvoiceUnpaidArgs = {
  invoiceId: Scalars['ID']
}

export type MutationmarkReceivableInvoicePaidArgs = {
  datePaid: Scalars['ISO8601Date']
  invoiceId: Scalars['ID']
  paymentMethod: PaymentMethodEnum
}

export type MutationmergeUnlinkedCatalogItemArgs = {
  matchedCatalogItems: Array<MatchedCatalogItemInputObject>
}

export type MutationmergeWithPublicBusinessArgs = {
  localBusinessId: Scalars['ID']
  publicBusinessId: Scalars['ID']
}

export type MutationmigrateBusinessAccountArgs = {
  accountId: Scalars['ID']
  newAccountId: Scalars['ID']
}

export type MutationnewChangeBillingPlanArgs = {
  billingPlanId: Scalars['ID']
}

export type MutationnewPasswordRequestArgs = {
  email: Scalars['String']
}

export type MutationnewPasswordUpdateArgs = {
  password: Scalars['String']
  resetToken: Scalars['String']
}

export type MutationnotifyEptRequestArgs = {
  invoiceId: Scalars['ID']
  reason?: InputMaybe<InvoicesEptReasonEnum>
}

export type MutationnotifyWrongInvoiceArgs = {
  invoiceId: Scalars['ID']
}

export type MutationopenPurchaseOrderArgs = {
  purchaseOrderId: Scalars['ID']
}

export type MutationpasswordUpdateArgs = {
  password: Scalars['String']
  resetToken: Scalars['String']
}

export type MutationpayNowPaymentArgs = {
  paymentId: Scalars['ID']
}

export type MutationpreviewInvoiceArgs = {
  invoiceId: Scalars['ID']
}

export type MutationprocessKybDataRequestArgs = {
  dataRequestInput: KybDataRequestInput
}

export type MutationpublicDeleteLocalBusinessW9Args = {
  localBusinessId: Scalars['ID']
  token: Scalars['String']
}

export type MutationpublicPurchaseOrderPreviewArgs = {
  purchaseOrderData: PublicPurchaseOrderInputObject
}

export type MutationpublicUpdateLocalBusinessArgs = {
  address?: InputMaybe<AddressInputObject>
  attachW9Form?: InputMaybe<Scalars['Boolean']>
  businessName?: InputMaybe<Scalars['String']>
  businessStructure?: InputMaybe<BusinessStructureEnum>
  businessStructureTaxClassification?: InputMaybe<BusinessStructureTaxClassificationEnum>
  exemptFatcaReportingCode?: InputMaybe<Scalars['String']>
  exemptPayeeCode?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  is1099Eligible?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  localBusinessId: Scalars['ID']
  requestorSignature?: InputMaybe<Scalars['String']>
  taxpayerId?: InputMaybe<Scalars['String']>
  token: Scalars['String']
}

export type MutationpublicUploadLocalBusinessW9Args = {
  localBusinessId: Scalars['ID']
  token: Scalars['String']
  uploadSignedId: Scalars['ID']
}

export type MutationpurchaseOrderPreviewArgs = {
  purchaseOrderId: Scalars['ID']
}

export type MutationrejectAllBusinessMatchArgs = {
  businessMatchIds: Array<Scalars['ID']>
}

export type MutationrejectInvoiceApprovalRequestArgs = {
  invoiceId: Scalars['ID']
  rejectionNote?: InputMaybe<Scalars['String']>
}

export type MutationrejectPaymentArgs = {
  paymentId: Scalars['ID']
  rejectionNote?: InputMaybe<Scalars['String']>
}

export type MutationrejectPurchaseOrderApprovalRequestArgs = {
  id: Scalars['ID']
  rejectionNote?: InputMaybe<Scalars['String']>
}

export type MutationreleasePaymentArgs = {
  paymentId: Scalars['ID']
}

export type MutationreleasePaymentsArgs = {
  paymentIds: Array<Scalars['ID']>
}

export type MutationremoveCatalogItemsLabelsArgs = {
  catalogItemIds: Array<Scalars['ID']>
  labelIds: Array<Scalars['ID']>
}

export type MutationremoveInvoicesLabelsArgs = {
  invoiceIds: Array<Scalars['ID']>
  labelIds: Array<Scalars['ID']>
}

export type MutationremovePayerAccountPublicInvoiceArgs = {
  uuid: Scalars['String']
}

export type MutationremovePurchaseOrdersLabelsArgs = {
  labelIds: Array<Scalars['ID']>
  purchaseOrderIds: Array<Scalars['ID']>
}

export type MutationremoveVendorTermArgs = {
  id: Scalars['ID']
  localBusinessId: Scalars['ID']
  purchaseOrderId: Scalars['ID']
}

export type MutationrepayEarlyV2Args = {
  paymentId: Scalars['ID']
  repaymentAmount: MoneyInputObject
  repaymentDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type MutationreportLinkPlaidFailedArgs = {
  displayMessage?: InputMaybe<Scalars['String']>
  errorCode?: InputMaybe<Scalars['String']>
  errorMessage?: InputMaybe<Scalars['String']>
  errorType?: InputMaybe<Scalars['String']>
  institutionId?: InputMaybe<Scalars['String']>
  linkSessionId: Scalars['String']
  status?: InputMaybe<Scalars['String']>
}

export type MutationrequestBillingInvoiceArgs = {
  billingInvoiceId: Scalars['ID']
}

export type MutationrequestClientBillingInvoiceArgs = {
  billingInvoiceId: Scalars['ID']
}

export type MutationrequestInvoiceApprovalArgs = {
  condition: RuleActionConditionEnum
  invoiceIds: Array<Scalars['ID']>
  requestNote?: InputMaybe<Scalars['String']>
  values: Array<Scalars['String']>
}

export type MutationrequestPurchaseOrderApprovalArgs = {
  condition: RuleActionConditionEnum
  ids: Array<Scalars['ID']>
  postApprovalWorkflow?: InputMaybe<PurchaseOrderPostApprovalWorkflow>
  requestNote?: InputMaybe<Scalars['String']>
  values: Array<Scalars['String']>
}

export type MutationrequestPurchaseOrderRuleBasedApprovalArgs = {
  id: Scalars['ID']
  postApprovalWorkflow?: InputMaybe<PurchaseOrderPostApprovalWorkflow>
  requestNote?: InputMaybe<Scalars['String']>
}

export type MutationrequestReceivableFactoringArgs = {
  receivableId: Scalars['ID']
}

export type MutationrequestRuleBasedApprovalArgs = {
  invoiceId: Scalars['ID']
  requestNote?: InputMaybe<Scalars['String']>
}

export type MutationreschedulePaymentArgs = {
  paymentId: Scalars['ID']
  processOn: Scalars['ISO8601Date']
}

export type MutationresetMicrodepositsArgs = {
  accountId: Scalars['ID']
  businessId?: InputMaybe<Scalars['ID']>
}

export type MutationrestoreBomLineItemArgs = {
  lineItemId: Scalars['ID']
}

export type MutationrestoreLocalBusinessArgs = {
  localBusinessId: Scalars['ID']
}

export type MutationrestorePaymentDateArgs = {
  paymentId: Scalars['ID']
}

export type MutationretryPaymentArgs = {
  paymentId: Scalars['ID']
}

export type MutationrevokeInvitationArgs = {
  invitationId: Scalars['ID']
}

export type MutationrevokePaymentArgs = {
  paymentId: Scalars['ID']
}

export type MutationrevokePaymentPublicInvoiceArgs = {
  uuid: Scalars['String']
}

export type MutationrevokeReceivableFactoringArgs = {
  receivableId: Scalars['ID']
}

export type MutationrevokeRecurrentPaymentsArgs = {
  scheduleId: Scalars['ID']
}

export type MutationscheduleReceivableFactoringArgs = {
  ccAddress?: InputMaybe<Scalars['String']>
  receivableId: Scalars['ID']
}

export type MutationsendInventoryTransferArgs = {
  inventoryTransferId: Scalars['ID']
  receivingLocationEmail: InventoryTransferLocationEmailInputObject
  sendingLocationEmail: InventoryTransferLocationEmailInputObject
}

export type MutationsendInvoiceArgs = {
  invoiceId: Scalars['ID']
}

export type MutationsendPublicPurchaseOrderEmailArgs = {
  blobId: Scalars['ID']
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  receivingEmail: Scalars['String']
}

export type MutationsendPurchaseOrderEmailArgs = {
  body?: InputMaybe<Scalars['String']>
  includeAdditionalDocuments?: InputMaybe<Scalars['Boolean']>
  purchaseOrderId: Scalars['ID']
  receivingEmails: Array<Scalars['String']>
}

export type MutationsendW9RequestEmailArgs = {
  data: Array<LocalBusinessEmailInputObject>
}

export type MutationsendWorkOrderArgs = {
  changeStatusToOpen?: InputMaybe<Scalars['Boolean']>
  data: WorkOrderEmailInputObject
  workOrderId: Scalars['ID']
}

export type MutationsetBillingMethodDefaultArgs = {
  billingMethodId: Scalars['ID']
}

export type MutationsetRoleV2Args = {
  accountingAccountIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  businessId?: InputMaybe<Scalars['ID']>
  roleId: Scalars['ID']
  teamMemberIds: Array<Scalars['ID']>
}

export type MutationsignInArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type MutationsignInByTokenArgs = {
  signInToken: Scalars['String']
}

export type MutationsignInWithTotpArgs = {
  email: Scalars['String']
  mfaToken: Scalars['String']
  totpCode: Scalars['String']
}

export type MutationsignInWithTotpRecoveryCodeArgs = {
  mfaToken: Scalars['ID']
  recoveryCode: Scalars['String']
}

export type MutationsignUpArgs = {
  businessStructure?: InputMaybe<BusinessStructureEnum>
  company?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  firstName?: InputMaybe<Scalars['String']>
  invitationCode: Scalars['String']
  is1099Eligible?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  partnerDataAccessToken?: InputMaybe<Scalars['String']>
  password: Scalars['String']
  phoneNumber?: InputMaybe<Scalars['String']>
  website?: InputMaybe<Scalars['String']>
}

export type MutationsplitTransfersArgs = {
  invoiceId: Scalars['ID']
}

export type MutationsyncTrackstarIntegrationArgs = {
  integrationId: Scalars['ID']
}

export type MutationsyncWithPublicBusinessArgs = {
  localBusinessId: Scalars['ID']
  publicBusinessId: Scalars['ID']
}

export type MutationtestAutoEptSetArgs = {
  amortizedPeriod?: InputMaybe<Scalars['Int']>
  amortizedPeriods?: InputMaybe<Scalars['Int']>
  amortizedRate?: InputMaybe<Scalars['Float']>
  businessId?: InputMaybe<Scalars['ID']>
  creditLimitCents: Scalars['Int']
  eptLength?: InputMaybe<Scalars['Int']>
  eptRate?: InputMaybe<Scalars['Float']>
  numEptMax?: InputMaybe<Scalars['Int']>
}

export type MutationtestBeneficialOwnersApproveArgs = {
  beneficialOwnerId: Scalars['ID']
}

export type MutationtestBeneficialOwnersDeclineArgs = {
  beneficialOwnerId: Scalars['ID']
}

export type MutationtestBeneficialOwnersStartReviewArgs = {
  beneficialOwnerId: Scalars['ID']
}

export type MutationtestCreateBusinessArgs = {
  companyName: Scalars['String']
  traits?: InputMaybe<Array<BusinessTraitsEnum>>
}

export type MutationtestCreateDocumentRequestItemArgs = {
  beneficialOwnerId?: InputMaybe<Scalars['ID']>
  category: DocumentCategoryEnum
}

export type MutationtestCreateUserArgs = {
  businessId: Scalars['ID']
  email: Scalars['String']
  password: Scalars['String']
  traits?: InputMaybe<Array<UserTraitsEnum>>
}

export type MutationtestFraudBusinessReviewApproveArgs = {
  businessId: Scalars['ID']
}

export type MutationtestLinkPlaidArgs = {
  businessId?: InputMaybe<Scalars['ID']>
}

export type MutationtestMicroDepositsCompletedArgs = {
  accountId: Scalars['ID']
}

export type MutationtestPayVendorArgs = {
  paymentId: Scalars['ID']
}

export type MutationtestPayerKycOverrideArgs = {
  businessId?: InputMaybe<Scalars['ID']>
  verified?: InputMaybe<Scalars['Boolean']>
}

export type MutationtestPaymentPaidArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>
  paymentId?: InputMaybe<Scalars['ID']>
}

export type MutationtestPaymentProcessedArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>
  paymentId?: InputMaybe<Scalars['ID']>
}

export type MutationtestSendPaymentArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>
  paymentId?: InputMaybe<Scalars['ID']>
}

export type MutationtestVendorFinancingCreditLimitArgs = {
  businessId: Scalars['ID']
  creditLimitCents: Scalars['Int']
  expirationDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type MutationunapplyVendorCreditArgs = {
  invoiceId: Scalars['ID']
  vendorCreditId: Scalars['ID']
}

export type MutationunlinkDocumentsArgs = {
  sourceDocumentId: Scalars['ID']
  unlinkedDocumentIds: Array<Scalars['ID']>
}

export type MutationunlinkFinicityArgs = {
  finicityConnectionId: Scalars['ID']
}

export type MutationunlinkPaymentFromOrderDocumentArgs = {
  orderDocumentId: Scalars['ID']
  paymentId: Scalars['ID']
}

export type MutationunlinkPaymentFromPurchaseOrderArgs = {
  paymentId: Scalars['ID']
  purchaseOrderId: Scalars['ID']
}

export type MutationunlinkPlaidArgs = {
  plaidConnectionId: Scalars['ID']
}

export type MutationunlinkTrackstarArgs = {
  integrationId: Scalars['ID']
}

export type MutationupdateAccountArgs = {
  accountId: Scalars['ID']
  accountType?: InputMaybe<AccountTypeEnum>
  accountingAccountId?: InputMaybe<Scalars['ID']>
  currency?: InputMaybe<Scalars['String']>
  hidden?: InputMaybe<Scalars['Boolean']>
  quickBooksAccountId?: InputMaybe<Scalars['ID']>
  wireFields?: InputMaybe<Array<InternationalWireFieldsDataFieldInputObject>>
  xeroAccountId?: InputMaybe<Scalars['ID']>
}

export type MutationupdateAccountingAutoImportArgs = {
  autoImportBills?: InputMaybe<Scalars['Boolean']>
}

export type MutationupdateAccountingDetailsRecurrentPaymentsArgs = {
  accountingDetails: PaymentAccountingDetailsInputObject
  scheduleId: Scalars['ID']
}

export type MutationupdateAppliedVendorCreditsArgs = {
  invoiceId: Scalars['ID']
  scheduledFor?: InputMaybe<Scalars['ISO8601Date']>
  vendorCreditApplications: Array<VendorCreditApplicationInputObject>
}

export type MutationupdateAttachmentArgs = {
  attachmentId: Scalars['ID']
  category?: InputMaybe<AttachmentCategoryEnum>
  description?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export type MutationupdateBomLineItemArgs = {
  catalogItemVariationId?: InputMaybe<Scalars['ID']>
  lineItemId: Scalars['ID']
  quantity?: InputMaybe<Scalars['Float']>
}

export type MutationupdateBomMaterialArgs = {
  defaultCatalogItemVariationId?: InputMaybe<Scalars['ID']>
  defaultQuantity?: InputMaybe<Scalars['Float']>
  materialId: Scalars['ID']
  name: Scalars['String']
}

export type MutationupdateBusinessDetailsArgs = {
  address?: InputMaybe<AddressInputObject>
  attachW9Form?: InputMaybe<Scalars['Boolean']>
  bankAccount?: InputMaybe<LocalBusinessAccountInputObject>
  businessStructure?: InputMaybe<BusinessStructureEnum>
  businessStructureTaxClassification?: InputMaybe<BusinessStructureTaxClassificationEnum>
  doingBusinessAsNames?: InputMaybe<Array<Scalars['String']>>
  exemptFatcaReportingCode?: InputMaybe<Scalars['String']>
  exemptPayeeCode?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  industry?: InputMaybe<BusinessIndustryEnum>
  inputStateOfFormation?: InputMaybe<Scalars['String']>
  is1099Eligible?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  mailingAddress?: InputMaybe<AddressInputObject>
  name?: InputMaybe<Scalars['String']>
  preferredPayingAccountId?: InputMaybe<Scalars['ID']>
  preferredReceivingAccountId?: InputMaybe<Scalars['ID']>
  prohibitedIndustryConfirmation?: InputMaybe<Scalars['Boolean']>
  requestorSignature?: InputMaybe<Scalars['String']>
  taxpayerId?: InputMaybe<Scalars['String']>
  website?: InputMaybe<Scalars['String']>
}

export type MutationupdateBusinessDetailsPublicInvoiceArgs = {
  bankAccount?: InputMaybe<AccountInputObject>
  name?: InputMaybe<Scalars['String']>
  payerNotificationsEmail?: InputMaybe<Scalars['String']>
  uuid: Scalars['String']
}

export type MutationupdateBusinessFeaturesArgs = {
  features: BusinessFeaturesInputObject
}

export type MutationupdateBusinessLogoArgs = {
  uploadSignedId: Scalars['ID']
}

export type MutationupdateBusinessW9Args = {
  uploadSignedId: Scalars['ID']
}

export type MutationupdateCashAdvancePaymentReceivingAccountArgs = {
  accountId: Scalars['ID']
  paymentId: Scalars['ID']
}

export type MutationupdateCatalogItemArgs = {
  attributes: CatalogItemInputObject
  id: Scalars['ID']
}

export type MutationupdateCatalogItemInventorySnapshotArgs = {
  attributes: CatalogItemInventorySnapshotInputObject
  id: Scalars['ID']
}

export type MutationupdateCatalogItemPropertyArgs = {
  attributes: CatalogItemPropertyInputObject
  propertyId: Scalars['ID']
}

export type MutationupdateCatalogItemPropertyTemplateArgs = {
  attributes: CatalogItemPropertyInputObject
  propertyId: Scalars['ID']
}

export type MutationupdateCatalogItemVariationArgs = {
  attributes: CatalogItemVariationInputObject
  variationId: Scalars['ID']
}

export type MutationupdateCatalogItemsArchivedArgs = {
  archived: Scalars['Boolean']
  catalogItemIds: Array<Scalars['ID']>
}

export type MutationupdateCombinedLineItemsArgs = {
  invoiceId: Scalars['ID']
  invoiceLineItems: Array<InvoiceLineItemInputObject>
}

export type MutationupdateDefaultVendorTermsArgs = {
  default: Scalars['Boolean']
  localBusinessId: Scalars['ID']
  purchaseOrderId: Scalars['ID']
}

export type MutationupdateDiscrepancyRuleArgs = {
  id: Scalars['ID']
  thresholds: Array<DiscrepancyThresholdInputObject>
  vendorLocalBusinessId?: InputMaybe<Scalars['ID']>
}

export type MutationupdateDocumentReferenceArgs = {
  documentReferenceData: DocumentReferenceUpdateInputObject
  documentReferenceId: Scalars['ID']
}

export type MutationupdateExternalPayableInvoiceLineItemsArgs = {
  invoiceId: Scalars['ID']
  lineItems: Array<ExternalPayableLineItemInputObject>
}

export type MutationupdateFinaloopPreferencesArgs = {
  pullFinancials: Scalars['Boolean']
}

export type MutationupdateInternationalDetailsRecurrentPaymentsArgs = {
  purposeCode?: InputMaybe<Scalars['String']>
  scheduleId: Scalars['ID']
}

export type MutationupdateInventoryTransferArgs = {
  attributes: InventoryTransferInputObject
  inventoryTransferId: Scalars['ID']
}

export type MutationupdateInventoryTransferAdditionalExpenseArgs = {
  attributes: AdditionalExpenseInputObject
  expenseId: Scalars['ID']
}

export type MutationupdateInventoryTransferLineItemArgs = {
  attributes: InventoryTransferLineItemInputObject
  inventoryTransferLineItemId: Scalars['ID']
}

export type MutationupdateInventoryTransfersArchiveArgs = {
  archived: Scalars['Boolean']
  inventoryTransferIds: Array<Scalars['ID']>
}

export type MutationupdateInvoiceArgs = {
  invoiceData: InvoiceInputObject
  invoiceId: Scalars['ID']
  paperCheckDetails?: InputMaybe<PaperCheckDetailsInputObject>
}

export type MutationupdateInvoiceAmountArgs = {
  amount: MoneyInputObject
  invoiceId: Scalars['ID']
}

export type MutationupdateInvoiceExternalAttributesArgs = {
  accountingCategoryClassId?: InputMaybe<Scalars['ID']>
  accountingCategoryDepartmentId?: InputMaybe<Scalars['ID']>
  accountingCategoryLocationId?: InputMaybe<Scalars['ID']>
  accountingMemo?: InputMaybe<Scalars['String']>
  invoiceId: Scalars['ID']
  lineItems?: InputMaybe<Array<InvoiceLineItemInputObject>>
  paymentAccountingAccountId?: InputMaybe<Scalars['ID']>
  quickbooksLocationId?: InputMaybe<Scalars['ID']>
  quickbooksMemo?: InputMaybe<Scalars['String']>
  quickbooksPaymentAccountId?: InputMaybe<Scalars['ID']>
  xeroPaymentAccountId?: InputMaybe<Scalars['ID']>
}

export type MutationupdateInvoiceLineItemArgs = {
  attributes: InvoiceLineItemInputObject
  invoiceLineItemId: Scalars['ID']
}

export type MutationupdateInvoicePayableDataArgs = {
  invoiceId: Scalars['ID']
  payableData: InvoicePayableDataInputObject
}

export type MutationupdateInvoicesReadArgs = {
  invoiceIds: Array<Scalars['ID']>
  read: Scalars['Boolean']
}

export type MutationupdateLabelArgs = {
  attributes: LabelInputObject
  labelId: Scalars['ID']
}

export type MutationupdateLocalBusinessArgs = {
  accountAttributes?: InputMaybe<LocalBusinessAccountInputObject>
  attributes: LocalBusinessInputObject
  localBusinessId: Scalars['ID']
}

export type MutationupdateLocalBusinessAccountArgs = {
  accountId: Scalars['ID']
  attributes: LocalBusinessAccountInputObject
}

export type MutationupdateLocalBusinessVisibilityArgs = {
  localBusinessId: Scalars['ID']
  visible: Scalars['Boolean']
}

export type MutationupdateLocationArgs = {
  attributes: LocationInputObject
  locationId: Scalars['ID']
}

export type MutationupdateLotArgs = {
  attributes: LotInputObject
  id: Scalars['ID']
}

export type MutationupdateManualInventoryDemandForecastArgs = {
  attributes: ManualInventoryDemandForecastInputObject
  id: Scalars['ID']
}

export type MutationupdateMarketingVirtualCardArgs = {
  data: MarketingVirtualCardInputObject
}

export type MutationupdateNetsuitePreferencesArgs = {
  accountMappings: Array<NetsuiteAccountMappingInputObject>
  eptSyncEnabled?: InputMaybe<Scalars['Boolean']>
  feeExpensesAccountId?: InputMaybe<Scalars['ID']>
  prepaymentsAccountId?: InputMaybe<Scalars['ID']>
  readOnly?: InputMaybe<Scalars['Boolean']>
  receivableBankAccountId?: InputMaybe<Scalars['ID']>
  settleLiabilityAccountId?: InputMaybe<Scalars['ID']>
  standalonePaymentsExpensesAccountId?: InputMaybe<Scalars['ID']>
  syncAutomatically: Scalars['Boolean']
  syncStartDate?: InputMaybe<Scalars['ISO8601Date']>
  syncUncategorizedExpenses?: InputMaybe<Scalars['Boolean']>
  unallocatedExpensesAccountId?: InputMaybe<Scalars['ID']>
}

export type MutationupdateNotificationSettingsArgs = {
  notificationSettings: NotificationSettingsInputObject
}

export type MutationupdateOutboundOrderArgs = {
  attributes: OutboundOrdersInputObject
  id: Scalars['ID']
}

export type MutationupdateOutboundOrderLineItemArgs = {
  attributes: OutboundOrderLineItemInputObject
  outboundOrderLineItemId: Scalars['ID']
}

export type MutationupdatePayablesArchivedArgs = {
  archived: Scalars['Boolean']
  invoiceIds: Array<Scalars['ID']>
}

export type MutationupdatePayerInvitationArgs = {
  approximateRevenue?: InputMaybe<Scalars['String']>
  bankAccountLocation?: InputMaybe<Scalars['String']>
  billingPromotionCodeId?: InputMaybe<Scalars['ID']>
  businessIndustry?: InputMaybe<BusinessIndustryEnum>
  businessName?: InputMaybe<Scalars['String']>
  countryOfRegistration?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  interestedIn?: InputMaybe<Array<SignupInterestsEnum>>
  invitationCode: Scalars['String']
  lastName?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  prohibitedIndustryConfirmation?: InputMaybe<Scalars['Boolean']>
  referredFrom?: InputMaybe<ReferralSourceEnum>
  source?: InputMaybe<Scalars['String']>
  userOccupation?: InputMaybe<OccupationEnum>
  website?: InputMaybe<Scalars['String']>
}

export type MutationupdatePaymentAccountArgs = {
  accountId: Scalars['ID']
  paymentId: Scalars['ID']
}

export type MutationupdatePaymentAccountingDetailsArgs = {
  accountingDetails: PaymentAccountingDetailsInputObject
  paymentId: Scalars['ID']
}

export type MutationupdatePaymentInternationalDetailsArgs = {
  paymentId: Scalars['ID']
  purposeCode?: InputMaybe<Scalars['String']>
}

export type MutationupdatePaymentReceivingAccountArgs = {
  accountId: Scalars['ID']
  paymentId: Scalars['ID']
}

export type MutationupdatePostTrialPlanArgs = {
  billingPlanId?: InputMaybe<Scalars['ID']>
}

export type MutationupdatePurchaseDetailsArgs = {
  attributes: PurchaseDetailsInputObject
  purchaseDetailsIds: Array<Scalars['ID']>
}

export type MutationupdatePurchaseOrderArgs = {
  purchaseOrderData?: InputMaybe<PurchaseOrderInputObject>
  purchaseOrderId: Scalars['ID']
}

export type MutationupdatePurchaseOrderAdditionalExpenseArgs = {
  attributes: AdditionalExpenseInputObject
  expenseId: Scalars['ID']
}

export type MutationupdatePurchaseOrderLineItemArgs = {
  attributes: PurchaseOrderLineItemInputObject
  purchaseOrderLineItemId: Scalars['ID']
}

export type MutationupdatePurchaseOrderLineItemsArgs = {
  purchaseOrderId: Scalars['ID']
  purchaseOrderLineItems: Array<PurchaseOrderLineItemInputObject>
}

export type MutationupdatePurchaseOrdersArchivedArgs = {
  archived: Scalars['Boolean']
  purchaseOrderIds: Array<Scalars['ID']>
}

export type MutationupdateQuickBooksPreferencesArgs = {
  accountMappings: Array<QuickBooksAccountMappingInputObject>
  eptSyncEnabled?: InputMaybe<Scalars['Boolean']>
  feeExpensesAccountId?: InputMaybe<Scalars['ID']>
  prepaymentsAccountId?: InputMaybe<Scalars['ID']>
  readOnly?: InputMaybe<Scalars['Boolean']>
  receivableBankAccountId?: InputMaybe<Scalars['ID']>
  settleLiabilityAccountId?: InputMaybe<Scalars['ID']>
  standalonePaymentsExpensesAccountId?: InputMaybe<Scalars['ID']>
  syncAutomatically: Scalars['Boolean']
  syncStartDate?: InputMaybe<Scalars['ISO8601Date']>
  syncUncategorizedExpenses?: InputMaybe<Scalars['Boolean']>
  trackClasses: Scalars['Boolean']
  trackLocations: Scalars['Boolean']
  unallocatedExpensesAccountId?: InputMaybe<Scalars['ID']>
}

export type MutationupdateReceiptArgs = {
  receiptData?: InputMaybe<ReceiptInputObject>
  receiptId: Scalars['ID']
}

export type MutationupdateReceiptLineItemArgs = {
  attributes: ReceiptLineItemInputObject
  receiptLineItemId: Scalars['ID']
}

export type MutationupdateReceiptLineItemsArgs = {
  receiptId: Scalars['ID']
  receiptLineItems: Array<ReceiptLineItemInputObject>
}

export type MutationupdateReceiptsArchivedArgs = {
  archived: Scalars['Boolean']
  receiptIds: Array<Scalars['ID']>
}

export type MutationupdateReceivableArgs = {
  data: ReceivableInputObject
  receivableId: Scalars['ID']
}

export type MutationupdateReceivableInvoiceArgs = {
  invoiceData: ReceivableInvoiceInputObject
  invoiceId: Scalars['ID']
}

export type MutationupdateReceivableInvoiceLineItemsArgs = {
  invoiceId: Scalars['ID']
  lineItems: Array<ReceivableLineItemInputObject>
}

export type MutationupdateReceivablesArchivedArgs = {
  archived: Scalars['Boolean']
  invoiceIds: Array<Scalars['ID']>
}

export type MutationupdateRoleArgs = {
  description?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  permissionSets?: InputMaybe<Array<PermissionSetInputObject>>
  roleId: Scalars['ID']
}

export type MutationupdateRuleArgs = {
  actions?: InputMaybe<Array<RuleActionInputObject>>
  conditions?: InputMaybe<Array<RuleConditionInputObject>>
  name: Scalars['String']
  priority?: InputMaybe<Scalars['Int']>
  ruleId: Scalars['ID']
  targetType?: InputMaybe<RuleTypeEnum>
}

export type MutationupdateSalesChannelArgs = {
  attributes: SalesChannelInputObject
  salesChannelId: Scalars['ID']
}

export type MutationupdateSalesChannelConfigurationStatesArgs = {
  configurations: Array<SalesChannelConfigurationInputObject>
}

export type MutationupdateSettleInboxMessagesArchivedArgs = {
  archived: Scalars['Boolean']
  settleInboxMessageIds: Array<Scalars['ID']>
}

export type MutationupdateSettleInboxMessagesReadArgs = {
  read?: InputMaybe<Scalars['Boolean']>
  settleInboxMessageIds: Array<Scalars['ID']>
}

export type MutationupdateShopifyIntegrationFeatureArgs = {
  featureId: Scalars['ID']
  permission: ShopifyFeaturePermissionEnum
}

export type MutationupdateTrackstarIntegrationFeatureArgs = {
  featureId: Scalars['ID']
  permission: TrackstarFeaturePermissionEnum
}

export type MutationupdateUserDetailsArgs = {
  email?: InputMaybe<Scalars['String']>
  firstName: Scalars['String']
  lastName: Scalars['String']
  phoneNumber?: InputMaybe<Scalars['String']>
}

export type MutationupdateVendorCreditArgs = {
  vendorCreditData: VendorCreditInputObject
  vendorCreditId: Scalars['ID']
}

export type MutationupdateVendorLeadTimeArgs = {
  id: Scalars['ID']
  vendorLeadTime: VendorLeadTimeInputObject
}

export type MutationupdateVendorTermArgs = {
  purchaseOrderId: Scalars['ID']
  vendorTerm: VendorTermInputObject
}

export type MutationupdateWorkOrderArgs = {
  data: WorkOrderInputObject
  workOrderId: Scalars['ID']
}

export type MutationupdateWorkOrderAdditionalExpenseArgs = {
  additionalExpenseId: Scalars['ID']
  attributes: AdditionalExpenseInputObject
}

export type MutationupdateWorkOrderLineItemArgs = {
  data: WorkOrderLineItemInputObject
  workOrderLineItemId: Scalars['ID']
}

export type MutationupdateXeroPreferencesArgs = {
  accountMappings: Array<XeroAccountMappingInputObject>
  eptSyncEnabled?: InputMaybe<Scalars['Boolean']>
  feeExpensesAccountId?: InputMaybe<Scalars['ID']>
  readOnly?: InputMaybe<Scalars['Boolean']>
  settleLiabilityAccountId?: InputMaybe<Scalars['ID']>
  syncAutomatically: Scalars['Boolean']
  syncStartDate?: InputMaybe<Scalars['ISO8601Date']>
  syncUncategorizedExpenses?: InputMaybe<Scalars['Boolean']>
  trackCategoryOne: Scalars['Boolean']
  trackCategoryTwo: Scalars['Boolean']
  unallocatedExpensesAccountId?: InputMaybe<Scalars['ID']>
}

export type MutationupsertAutopayableConfigArgs = {
  allowlistEmails: Array<Scalars['String']>
}

export type MutationvalidateInternationalWireFieldArgs = {
  wireField: InternationalWireFieldsDataFieldInputObject
}

export type MutationvalidateRoutingNumberArgs = {
  routingNumber: Scalars['String']
}

export type MutationverificationConfirmArgs = {
  verificationToken: Scalars['String']
}

export type MutationverificationRequestArgs = {
  payerOnboarding?: InputMaybe<Scalars['Boolean']>
}

export type MutationverifyAccountArgs = {
  accountId?: InputMaybe<Scalars['ID']>
  amountCents1: Scalars['Int']
  amountCents2: Scalars['Int']
}

export type MutationverifyAccountPublicInvoiceArgs = {
  amountCents1: Scalars['Int']
  amountCents2: Scalars['Int']
  uuid: Scalars['String']
}

export type MutationviewInvoiceV2Args = {
  invoiceId: Scalars['ID']
}

export type MutationviewPublicInvoiceV2Args = {
  uuid: Scalars['String']
}

export type MutationworkOrderPreviewArgs = {
  workOrderId: Scalars['ID']
}

export type NetsuiteAccountMapping = {
  accountId: Scalars['ID']
  externalAccountId: Maybe<Scalars['ID']>
  netsuiteAccountId: Maybe<Scalars['ID']>
}

export type NetsuiteAccountMappingInputObject = {
  accountId: Scalars['ID']
  externalAccountId?: InputMaybe<Scalars['ID']>
  netsuiteAccountId?: InputMaybe<Scalars['ID']>
}

export type NetsuiteState = {
  accountMappings: Array<NetsuiteAccountMapping>
  accounts: Array<AccountingAccount>
  analyticsConnected: Scalars['Boolean']
  apiConnected: Scalars['Boolean']
  autoImportBills: Scalars['Boolean']
  autoImportPurchaseOrders: Scalars['Boolean']
  bankAccounts: Array<AccountingAccount>
  bookCloseDate: Maybe<Scalars['ISO8601Date']>
  creditCardAccounts: Array<AccountingAccount>
  eptSyncEnabled: Scalars['Boolean']
  eptSyncStartDate: Maybe<Scalars['ISO8601Date']>
  expenseAccounts: Array<AccountingAccount>
  feeExpensesAccountId: Maybe<Scalars['ID']>
  filteredAccounts: Array<AccountingAccount>
  liabilityAccounts: Array<AccountingAccount>
  prepaymentsAccountId: Maybe<Scalars['ID']>
  readOnly: Scalars['Boolean']
  receivableBankAccountId: Maybe<Scalars['ID']>
  settleLiabilityAccountId: Maybe<Scalars['ID']>
  standalonePaymentsExpensesAccountId: Maybe<Scalars['ID']>
  syncAutomatically: Scalars['Boolean']
  syncErrorMessage: Maybe<Scalars['String']>
  syncScheduledAt: Maybe<Scalars['ISO8601DateTime']>
  syncStartDate: Maybe<Scalars['ISO8601Date']>
  syncStartedAt: Maybe<Scalars['ISO8601DateTime']>
  syncState: SyncStateEnum
  syncUncategorizedExpenses: Scalars['Boolean']
  syncedAt: Maybe<Scalars['ISO8601DateTime']>
  unallocatedExpensesAccountId: Maybe<Scalars['ID']>
}

export type NetsuiteUnpaidBill = {
  amount: Maybe<Money>
  balance: Maybe<Money>
  dueDate: Maybe<Scalars['ISO8601Date']>
  id: Scalars['ID']
  issueDate: Maybe<Scalars['ISO8601Date']>
  number: Maybe<Scalars['String']>
  url: Maybe<Scalars['String']>
  vendorName: Maybe<Scalars['String']>
}

export type NotificationSettings = {
  accountsReceivable: AccountsReceivableNotificationSettings
  announcementsEmail: Scalars['Boolean']
  approvalRequestStatusUpdateEmail: Scalars['Boolean']
  approveInvoiceEmail: Scalars['Boolean']
  approvePaymentEmail: Scalars['Boolean']
  extendPaymentEmail: Scalars['Boolean']
  failedPaymentsDigestEmail: Scalars['Boolean']
  pendingInvoiceEmail: Scalars['Boolean']
  ruleModifiedEmail: Scalars['Boolean']
  settleInboxWeeklyDigestEmail: Scalars['Boolean']
  upcomingPaymentsDigestEmail: Scalars['Boolean']
}

export type NotificationSettingsInputObject = {
  accountsReceivable?: InputMaybe<AccountsReceivableNotificationSettingsInputObject>
  announcementsEmail?: InputMaybe<Scalars['Boolean']>
  approvalRequestStatusUpdateEmail?: InputMaybe<Scalars['Boolean']>
  approveInvoiceEmail?: InputMaybe<Scalars['Boolean']>
  approvePaymentEmail?: InputMaybe<Scalars['Boolean']>
  extendPaymentEmail?: InputMaybe<Scalars['Boolean']>
  failedPaymentsDigestEmail?: InputMaybe<Scalars['Boolean']>
  pendingInvoiceEmail?: InputMaybe<Scalars['Boolean']>
  ruleModifiedEmail?: InputMaybe<Scalars['Boolean']>
  settleInboxWeeklyDigestEmail?: InputMaybe<Scalars['Boolean']>
  upcomingPaymentsDigestEmail?: InputMaybe<Scalars['Boolean']>
}

export enum OauthClientIntegrationEnum {
  A2X = 'A2X',
  ESCALON = 'ESCALON',
  FINALOOP = 'FINALOOP',
  UNKNOWN = 'UNKNOWN',
}

export enum ObjectSyncStatusEnum {
  EXCLUDED_BY_BOOK_CLOSE_DATE = 'EXCLUDED_BY_BOOK_CLOSE_DATE',
  EXCLUDED_BY_STATUS = 'EXCLUDED_BY_STATUS',
  EXCLUDED_BY_SYNC_START_DATE = 'EXCLUDED_BY_SYNC_START_DATE',
  EXCLUDED_BY_USER = 'EXCLUDED_BY_USER',
  SYNCED = 'SYNCED',
  SYNCED_WITH_ERROR = 'SYNCED_WITH_ERROR',
  UNSYNCED = 'UNSYNCED',
}

export enum OccupationEnum {
  COO = 'COO',
  CSUITE = 'CSUITE',
  EXTERNAL_ACCOUNTANT = 'EXTERNAL_ACCOUNTANT',
  EXTERNAL_CFO = 'EXTERNAL_CFO',
  FINANCE = 'FINANCE',
  FOUNDER_OR_CEO = 'FOUNDER_OR_CEO',
  INTERNAL_ACCOUNTANT = 'INTERNAL_ACCOUNTANT',
  INTERNAL_CFO = 'INTERNAL_CFO',
  INVESTOR = 'INVESTOR',
  INVESTOR_OR_CONSULTANT = 'INVESTOR_OR_CONSULTANT',
  MARKETING = 'MARKETING',
  MARKETING_OR_SALES = 'MARKETING_OR_SALES',
  OPERATIONS = 'OPERATIONS',
  OTHER = 'OTHER',
  OWNER = 'OWNER',
  PROCUREMENT = 'PROCUREMENT',
}

export enum OfferStatusEnum {
  ACTIVATED = 'ACTIVATED',
  AVAILABLE = 'AVAILABLE',
}

export enum OnboardingStatusEnum {
  MICRO_DEPOSITS_SENT = 'MICRO_DEPOSITS_SENT',
  MICRO_DEPOSITS_WAITING = 'MICRO_DEPOSITS_WAITING',
  UNVERIFIED = 'UNVERIFIED',
}

export type OrderDocument = InventoryTransfer | PurchaseOrder | WorkOrder

export enum OrderDocumentTypeEnum {
  INVENTORY_TRANSFER = 'INVENTORY_TRANSFER',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  WORK_ORDER = 'WORK_ORDER',
}

export type OutboundOrder = {
  cancelDate: Maybe<Scalars['ISO8601DateTime']>
  channel: Maybe<OutboundOrderChannel>
  currency: Scalars['String']
  deleteDate: Maybe<Scalars['ISO8601DateTime']>
  discountAmount: Maybe<Money>
  dutyAmount: Maybe<Money>
  financialStatus: Maybe<OutboundOrderFinancialStatusEnum>
  fulfillmentStatus: Maybe<OutboundOrderFulfillmentStatusEnum>
  id: Scalars['ID']
  importDate: Maybe<Scalars['ISO8601DateTime']>
  lineItems: Array<OutboundOrderLineItem>
  location: Maybe<Location>
  number: Maybe<Scalars['String']>
  orderDate: Maybe<Scalars['ISO8601DateTime']>
  orderPageUrl: Maybe<Scalars['String']>
  orderSubtotal: Money
  orderTotalAmount: Money
  paymentPageUrl: Maybe<Scalars['String']>
  refundedAmount: Maybe<Money>
  shippingAmount: Maybe<Money>
  source: OutboundOrderSourceEnum
  status: OutboundOrderStatusEnum
  targetFulfillmentDate: Maybe<Scalars['ISO8601DateTime']>
  taxAmount: Maybe<Money>
  timelineEvents: Array<OutboundOrderTimelineEvent>
  updateDate: Maybe<Scalars['ISO8601DateTime']>
}

export type OutboundOrderChannel = {
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  source: SalesChannelIntegrationEnum
}

export enum OutboundOrderEventTypeEnum {
  OUTBOUND_ORDER_CREATED = 'OUTBOUND_ORDER_CREATED',
  OUTBOUND_ORDER_FULFILLED = 'OUTBOUND_ORDER_FULFILLED',
  OUTBOUND_ORDER_PAYMENT_CAPTURED = 'OUTBOUND_ORDER_PAYMENT_CAPTURED',
  OUTBOUND_ORDER_SHIPPED = 'OUTBOUND_ORDER_SHIPPED',
}

export enum OutboundOrderFinancialStatusEnum {
  CREATED = 'CREATED',
  PAYMENT_CAPTURED = 'PAYMENT_CAPTURED',
}

export enum OutboundOrderFulfillmentStatusEnum {
  CREATED = 'CREATED',
  FULFILLED = 'FULFILLED',
  SHIPPED = 'SHIPPED',
}

export type OutboundOrderLineItem = {
  catalogItemVariation: CatalogItemVariation
  discountPrice: Money
  id: Scalars['ID']
  quantity: Scalars['Float']
  salesChannelSku: Scalars['String']
  taxPrice: Money
  total: Money
  unitPrice: Money
}

export type OutboundOrderLineItemInputObject = {
  catalogItemId: Scalars['ID']
  discountPriceCents?: InputMaybe<Scalars['Int']>
  quantity: Scalars['Float']
  salesChannelSku?: InputMaybe<Scalars['String']>
  taxPriceCents?: InputMaybe<Scalars['Int']>
  unitPriceCents?: InputMaybe<Scalars['Int']>
}

export enum OutboundOrderPermissionsEnum {
  CREATE_OUTBOUND_ORDER = 'CREATE_OUTBOUND_ORDER',
  DELETE_OUTBOUND_ORDER = 'DELETE_OUTBOUND_ORDER',
  UPDATE_OUTBOUND_ORDER = 'UPDATE_OUTBOUND_ORDER',
  VIEW_OUTBOUND_ORDER = 'VIEW_OUTBOUND_ORDER',
}

export enum OutboundOrderSortableFieldEnum {
  NUMBER = 'NUMBER',
  ORDER_DATE = 'ORDER_DATE',
  ORDER_TOTAL_AMOUNT = 'ORDER_TOTAL_AMOUNT',
}

export enum OutboundOrderSortingDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum OutboundOrderSourceEnum {
  MANUAL = 'MANUAL',
  SHOPIFY = 'SHOPIFY',
  TRACKSTAR = 'TRACKSTAR',
}

export enum OutboundOrderStatusEnum {
  CANCELED = 'CANCELED',
  CREATED = 'CREATED',
  FULFILLED = 'FULFILLED',
  PAYMENT_CAPTURED = 'PAYMENT_CAPTURED',
  SHIPPED = 'SHIPPED',
}

export type OutboundOrderTimelineEvent = TimelineEventInterface & {
  createdAt: Maybe<Scalars['ISO8601DateTime']>
  displayText: Scalars['String']
  eventType: OutboundOrderEventTypeEnum
  icon: IconEnum
  occurredAt: Maybe<Scalars['ISO8601DateTime']>
  user: Maybe<Approver>
}

export type OutboundOrdersFilteringInputObject = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  statuses?: InputMaybe<Array<OutboundOrderStatusEnum>>
}

export type OutboundOrdersInputObject = {
  dutyAmountCents?: InputMaybe<Scalars['SafeInt']>
  financialStatus?: InputMaybe<OutboundOrderFinancialStatusEnum>
  fulfillmentStatus?: InputMaybe<OutboundOrderFulfillmentStatusEnum>
  locationId?: InputMaybe<Scalars['ID']>
  number?: InputMaybe<Scalars['String']>
  orderDate?: InputMaybe<Scalars['ISO8601Date']>
  refundAmountCents?: InputMaybe<Scalars['SafeInt']>
  salesChannelId?: InputMaybe<Scalars['ID']>
  shippingAmountCents?: InputMaybe<Scalars['SafeInt']>
  targetFulfillmentDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type OutboundOrdersSortingInputObject = {
  direction: OutboundOrderSortingDirectionEnum
  field: OutboundOrderSortableFieldEnum
}

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']>
}

export enum PagePermissionsEnum {
  ACCOUNTING_FIRM_DASHBOARD_PAGE = 'ACCOUNTING_FIRM_DASHBOARD_PAGE',
  CATALOG_PAGE = 'CATALOG_PAGE',
  DASHBOARD_PAGE = 'DASHBOARD_PAGE',
  INBOX_PAGE = 'INBOX_PAGE',
  INVENTORY_FORECAST_PAGE = 'INVENTORY_FORECAST_PAGE',
  INVENTORY_TRANSFERS_PAGE = 'INVENTORY_TRANSFERS_PAGE',
  INVOICES_PAGE = 'INVOICES_PAGE',
  LOCATIONS_PAGE = 'LOCATIONS_PAGE',
  MY_INVOICES_PAGE = 'MY_INVOICES_PAGE',
  PAYABLES_PAGE = 'PAYABLES_PAGE',
  PAYMENTS_PAGE = 'PAYMENTS_PAGE',
  PURCHASERS_PAGE = 'PURCHASERS_PAGE',
  PURCHASE_ORDERS_PAGE = 'PURCHASE_ORDERS_PAGE',
  RECEIPTS_PAGE = 'RECEIPTS_PAGE',
  RECEIVABLES_PAGE = 'RECEIVABLES_PAGE',
  VENDORS_PAGE = 'VENDORS_PAGE',
  WORK_ORDERS_PAGE = 'WORK_ORDERS_PAGE',
}

export type PagedInventoryDemandForecast = {
  data: Array<InventoryItemDemandForecast>
  pagination: Pagination
}

export type PagedInventoryTransfers = {
  data: Array<InventoryTransfer>
  pagination: Pagination
}

export type PagedLinkableCatalogItems = {
  data: Array<LinkableCatalogItem>
  pagination: Pagination
}

export type PagedLinkableOrderDocuments = {
  data: Array<LinkableOrderDocument>
  pagination: Pagination
}

export type PagedLocations = {
  data: Array<Location>
  pagination: Pagination
}

export type PagedOrderDocuments = {
  data: Array<OrderDocument>
  pagination: Pagination
}

export type PagedOrderDocumentsFilterInput = {
  search?: InputMaybe<Scalars['String']>
}

export type PagedOutboundOrders = {
  data: Array<OutboundOrder>
  pagination: Pagination
}

export type PagedSalesChannelConfigurations = {
  data: Array<SalesChannelConfiguration>
  pagination: Pagination
}

export type PagedWorkOrders = {
  data: Maybe<Array<WorkOrder>>
  pagination: Maybe<Pagination>
}

export type Pagination = {
  page: Scalars['Int']
  pageSize: Scalars['Int']
  totalCount: Scalars['Int']
  totalPages: Scalars['Int']
}

export type PaperCheckDetails = {
  address: Maybe<Address>
  email: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
}

export type PaperCheckDetailsInputObject = {
  email?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export enum PayableActionIndicatorEnum {
  INCOMPLETE = 'INCOMPLETE',
  READY = 'READY',
}

export type PayableInvoiceInputObject = {
  amountCents?: InputMaybe<Scalars['SafeInt']>
  amountCurrency?: InputMaybe<Scalars['String']>
  dueDate?: InputMaybe<Scalars['ISO8601Date']>
  issueDate?: InputMaybe<Scalars['ISO8601Date']>
  lineItems?: InputMaybe<Array<BaseExternalPayableLineItemInputObject>>
  number?: InputMaybe<Scalars['String']>
  vendorLocalBusinessId?: InputMaybe<Scalars['ID']>
}

export type PayableInvoicePreview = {
  amount: Maybe<Money>
  dueDate: Maybe<Scalars['ISO8601Date']>
  issueDate: Maybe<Scalars['ISO8601Date']>
  number: Maybe<Scalars['String']>
  vendor: Maybe<LocalBusiness>
}

export enum PayerEnum {
  accounting_firm = 'accounting_firm',
  client = 'client',
}

export type PayerInvitation = {
  businessName: Maybe<Scalars['String']>
  demoAvailable: Scalars['Boolean']
  valid: Scalars['Boolean']
}

export type PayerInvitationV2 = {
  approximateRevenue: Maybe<Scalars['String']>
  bankAccountLocation: Maybe<Scalars['String']>
  businessIndustry: Maybe<BusinessIndustryEnum>
  businessName: Maybe<Scalars['String']>
  countryOfRegistration: Maybe<Scalars['String']>
  demoAvailable: Maybe<Scalars['Boolean']>
  email: Scalars['String']
  firstName: Maybe<Scalars['String']>
  id: Scalars['String']
  interestedIn: Array<SignupInterestsEnum>
  invitationCode: Scalars['String']
  lastName: Maybe<Scalars['String']>
  phoneNumber: Maybe<Scalars['String']>
  prohibitedIndustryConfirmation: Maybe<Scalars['Boolean']>
  promotionCode: Maybe<BillingPromotionCodeType>
  /** @deprecated Use referrer instead */
  referralCode: Maybe<Scalars['String']>
  referredFrom: Maybe<ReferralSourceEnum>
  referrer: Maybe<Referral>
  source: Maybe<Scalars['String']>
  userOccupation: Maybe<OccupationEnum>
  website: Maybe<Scalars['String']>
}

export type Payment = {
  accountingDetails: Maybe<PaymentAccountingDetailsType>
  actions: PaymentActions
  agreementSignRequired: Scalars['Boolean']
  allocations: Maybe<Array<Allocation>>
  allowAccountChange: Scalars['Boolean']
  allowReschedule: Scalars['Boolean']
  allowRestorePaymentDate: Scalars['Boolean']
  amortizedLoan: Maybe<AmortizedLoan>
  amount: Money
  anyEarlyRepayments: Scalars['Boolean']
  approval: Maybe<Approval>
  approvalRequestedAt: Maybe<Scalars['ISO8601DateTime']>
  approvalSteps: Array<PaymentApprovalStep>
  approvedBy: Maybe<Array<Approver>>
  approvers: Maybe<Array<Scalars['ID']>>
  auditTrailEvents: Array<AuditTrailEvents>
  awaitingApproval: Scalars['Boolean']
  balanceDueToSettle: Money
  bulletEpt: Maybe<BulletEpt>
  canChangeExtendedPaymentTerms: Maybe<Scalars['Boolean']>
  cancelledAt: Maybe<Scalars['ISO8601DateTime']>
  cancelledBy: Maybe<Approver>
  consolidated: Scalars['Boolean']
  createdAt: Scalars['ISO8601DateTime']
  createdBy: Maybe<Approver>
  currentExtendedPaymentTermConfirmation: Maybe<InvoiceExtendedPaymentTermConfirmation>
  debitDate: Maybe<Scalars['ISO8601Date']>
  errorCode: Maybe<Scalars['String']>
  errorDirection: Maybe<Scalars['String']>
  errorMessage: Maybe<Scalars['String']>
  etaFromPayer: Maybe<Scalars['ISO8601Date']>
  etaToVendor: Maybe<Array<Scalars['ISO8601Date']>>
  extendedPaymentTermAdditionalDays: Maybe<Scalars['Int']>
  extendedTermPayerPaymentDate: Maybe<Scalars['ISO8601Date']>
  extendedTermUnrefundedFee: Maybe<Money>
  failedAt: Maybe<Scalars['ISO8601Date']>
  feeInvoices: Maybe<Array<Invoice>>
  financed: Scalars['Boolean']
  id: Scalars['ID']
  invoices: Array<Invoice>
  isCashAdvance: Scalars['Boolean']
  isDailyInterestAccrual: Scalars['Boolean']
  loanAgreementDocumentUrl: Maybe<Scalars['String']>
  onHold: Scalars['Boolean']
  originalInvoice: Maybe<Invoice>
  otherReasonNote: Maybe<Scalars['String']>
  paidWithMethod: Maybe<PaymentMethodEnum>
  payFromAccount: Maybe<Account>
  payToAccount: Maybe<LocalBusinessAccount>
  payerAmount: Money
  payerCharged: Scalars['Boolean']
  payerPaymentDate: Scalars['ISO8601Date']
  payerPaymentStatus: PaymentStatusEnum
  payerTransferDetails: Maybe<TransferDetails>
  payerTransfersDetails: Maybe<Array<TransferDetails>>
  paymentConfirmationDocumentUrl: Maybe<Scalars['String']>
  paymentDescription: Maybe<Scalars['String']>
  paymentMethod: Maybe<PaymentMethodEnum>
  paymentNumber: Maybe<Scalars['Int']>
  paymentRestoreDate: Maybe<Scalars['ISO8601Date']>
  paymentRestoreDay: Maybe<Scalars['Int']>
  paymentType: Maybe<PaymentTypeEnum>
  purchaseOrders: Array<PurchaseOrder>
  purposeCode: Maybe<Scalars['String']>
  reasonCode: Maybe<PaymentReasonEnum>
  reconciliationItems: Array<ReconciliationItem>
  recurrentPaymentsSchedule: Maybe<RecurrentPaymentsSchedule>
  refundItems: Array<ReconciliationItem>
  releasedAt: Maybe<Scalars['ISO8601DateTime']>
  repaidCompletely: Scalars['Boolean']
  revokable: Scalars['Boolean']
  scheduledOn: Scalars['ISO8601Date']
  secondaryStatusDescription: Maybe<Scalars['String']>
  sentDate: Maybe<Scalars['ISO8601Date']>
  standalone: Scalars['Boolean']
  status: PaymentStatusEnum
  statusDescription: Maybe<Scalars['String']>
  statusDescriptions: Maybe<Array<Scalars['String']>>
  syncError: Maybe<SyncError>
  syncObjectStatus: Maybe<SyncObjectStatus>
  transfers: Array<Transfer>
  vendorAmount: Money
  vendorLocalBusiness: LocalBusiness
  vendorPaid: Scalars['Boolean']
  vendorPaymentDate: Scalars['ISO8601Date']
  vendorPaymentStatus: PaymentStatusEnum
  vendorReceivedOn: Maybe<Scalars['ISO8601Date']>
  vendorTransferDetails: Maybe<TransferDetails>
  w9FormHoldSubmittedAt: Maybe<Scalars['ISO8601DateTime']>
  w9FormHoldSubmittedByName: Maybe<Scalars['String']>
  warningMessages: Maybe<Array<WarningMessage>>
  workOrders: Array<WorkOrder>
}

export type PaymentAccountingDetailsInputObject = {
  accountingAccountId?: InputMaybe<Scalars['ID']>
  accountingCategoryClassId?: InputMaybe<Scalars['ID']>
  accountingMemo?: InputMaybe<Scalars['String']>
}

export type PaymentAccountingDetailsType = {
  accountingAccount: Maybe<AccountingAccount>
  accountingCategoryClass: Maybe<AccountingDictionary>
  accountingMemo: Maybe<Scalars['String']>
}

export type PaymentActions = {
  amortizedRepayEarly: Action
  eptRepayEarly: Action
  releasePayment: Action
  retryPayment: Action
  vendorAccountChange: Action
}

export type PaymentApprovalRequest = {
  approver: Approver
  decisionMadeAt: Maybe<Scalars['ISO8601DateTime']>
  status: ApprovalStatusEnum
}

export type PaymentApprovalStep = {
  approvalType: RuleActionConditionEnum
  requests: Array<PaymentApprovalRequest>
  status: ApprovalStatusEnum
  stepNumber: Scalars['Int']
}

export type PaymentAttributesForEstimatesInputObject = {
  payerBusinessId: Scalars['ID']
  receivingAccountId?: InputMaybe<Scalars['ID']>
  vendorAmount: MoneyInputObject
}

export type PaymentCard = {
  default: Scalars['Boolean']
  expirationMonth: Scalars['Int']
  expirationYear: Scalars['Int']
  id: Scalars['ID']
  maskedNumber: Scalars['String']
  paymentSystem: Maybe<Scalars['String']>
}

export type PaymentEstimatedDelay = {
  processingDelayDays: Array<Scalars['Int']>
  scheduleNowProcessingDelayDays: Array<Scalars['Int']>
}

export type PaymentInputObject = {
  accountId: Scalars['ID']
  amount: MoneyInputObject
  paymentMethod: PaymentMethodEnum
  processDate: Scalars['ISO8601Date']
}

export enum PaymentMethodEnum {
  ACH = 'ACH',
  PAPER_CHECK = 'PAPER_CHECK',
  PAYMENT_CARD = 'PAYMENT_CARD',
  SLOW_ACH = 'SLOW_ACH',
  WIRE = 'WIRE',
}

export type PaymentOptions = {
  billpayPaymentMethods: Array<PaymentMethodEnum>
  financingPaymentMethods: Array<PaymentMethodEnum>
}

export enum PaymentReasonEnum {
  EXPENSE_REIMBURSEMENT = 'EXPENSE_REIMBURSEMENT',
  MARKETING = 'MARKETING',
  MATERIALS_AND_SUPPLIES = 'MATERIALS_AND_SUPPLIES',
  MONTHLY_FEE = 'MONTHLY_FEE',
  OTHER = 'OTHER',
  PAYROLL_AND_COMMISSION = 'PAYROLL_AND_COMMISSION',
  PROPERTY = 'PROPERTY',
}

export enum PaymentStatusEnum {
  APPROVAL_PENDING = 'APPROVAL_PENDING',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  FULLY_REVERSED = 'FULLY_REVERSED',
  ON_HOLD = 'ON_HOLD',
  PAID = 'PAID',
  PARTIALLY_REPAID = 'PARTIALLY_REPAID',
  PAYMENT_PLAN = 'PAYMENT_PLAN',
  PROCESSED = 'PROCESSED',
  SCHEDULED = 'SCHEDULED',
  SENT = 'SENT',
}

export enum PaymentTypeEnum {
  AMORTIZED_LOAN = 'AMORTIZED_LOAN',
  BULLET_EPT = 'BULLET_EPT',
  DIRECT = 'DIRECT',
  EPT = 'EPT',
  EPT_FEE = 'EPT_FEE',
}

export type PaymentsForecast = {
  amount: Money
  days: Maybe<Scalars['Int']>
  expectedPaymentDate: Maybe<Scalars['ISO8601Date']>
  percentage: Scalars['Int']
  termType: Scalars['String']
}

export enum PaymentsPermissionsEnum {
  ALLOCATE_PAYMENT_TO_INVOICE = 'ALLOCATE_PAYMENT_TO_INVOICE',
  CREATE_AMORTIZED_LOAN_PAYMENT = 'CREATE_AMORTIZED_LOAN_PAYMENT',
  CREATE_EXTENDED_TERM_PAYMENT = 'CREATE_EXTENDED_TERM_PAYMENT',
  CREATE_PAYMENT = 'CREATE_PAYMENT',
  CREATE_PRELIMINARY_AMORTIZED_LOAN_AGREEMENT = 'CREATE_PRELIMINARY_AMORTIZED_LOAN_AGREEMENT',
  CREATE_PRELIMINARY_EPT_AGREEMENT = 'CREATE_PRELIMINARY_EPT_AGREEMENT',
  DECLINE_TERM_EXTENSIONS = 'DECLINE_TERM_EXTENSIONS',
  EXTEND_PAYMENT_TERM = 'EXTEND_PAYMENT_TERM',
  PAY_NOW_PAYMENT = 'PAY_NOW_PAYMENT',
  RELEASE_PAYMENT = 'RELEASE_PAYMENT',
  REPAY_EARLY = 'REPAY_EARLY',
  RESCHEDULE_PAYMENT = 'RESCHEDULE_PAYMENT',
  REVOKE_PAYMENT = 'REVOKE_PAYMENT',
  UPDATE_PAYMENT_ACCOUNT = 'UPDATE_PAYMENT_ACCOUNT',
  UPDATE_PAYMENT_ACCOUNTING_DETAILS = 'UPDATE_PAYMENT_ACCOUNTING_DETAILS',
}

export type PaymentsSummary = {
  failedCount: Scalars['Int']
  rejectedCount: Scalars['Int']
}

export type PermissionSet = {
  availableLevels: Maybe<Array<AccessLevelEnum>>
  availableOptions: Maybe<Array<PermissionSetOptionEnum>>
  grantedLevel: Maybe<AccessLevelEnum>
  grantedOptions: Maybe<Array<PermissionSetOptionEnum>>
  name: PermissionSetEnum
}

export enum PermissionSetEnum {
  ACCOUNTING_SYNC = 'ACCOUNTING_SYNC',
  APPROVAL_RULES = 'APPROVAL_RULES',
  BILLS = 'BILLS',
  BUSINESS_DETAILS = 'BUSINESS_DETAILS',
  CLIENT_MANAGER = 'CLIENT_MANAGER',
  CONNECTIONS = 'CONNECTIONS',
  DASHBOARD = 'DASHBOARD',
  DISCREPANCY_RULES = 'DISCREPANCY_RULES',
  INBOX = 'INBOX',
  INVENTORY = 'INVENTORY',
  INVENTORY_DEMAND_FORECAST = 'INVENTORY_DEMAND_FORECAST',
  INVENTORY_TRANSFERS = 'INVENTORY_TRANSFERS',
  OUTBOUND_ORDERS = 'OUTBOUND_ORDERS',
  PAYMENTS = 'PAYMENTS',
  PURCHASE_ORDERS_AND_RECEIPTS = 'PURCHASE_ORDERS_AND_RECEIPTS',
  SALES_CHANNELS = 'SALES_CHANNELS',
  TEAM_MEMBERS = 'TEAM_MEMBERS',
}

export type PermissionSetInputObject = {
  grantedLevel?: InputMaybe<AccessLevelEnum>
  grantedOptions?: InputMaybe<Array<PermissionSetOptionEnum>>
  name: PermissionSetEnum
}

export enum PermissionSetOptionEnum {
  APPLY_VENDOR_CREDITS = 'APPLY_VENDOR_CREDITS',
  CLIENT_MANAGER = 'CLIENT_MANAGER',
  CUSTOM_ROLES = 'CUSTOM_ROLES',
  EXPORT_REPORTS = 'EXPORT_REPORTS',
  LABEL_INVOICES_AS_PAID_OUTSIDE_SETTLE = 'LABEL_INVOICES_AS_PAID_OUTSIDE_SETTLE',
  LINK_RECEIPTS_TO_PURCHASE_ORDERS_AND_INVOICES = 'LINK_RECEIPTS_TO_PURCHASE_ORDERS_AND_INVOICES',
  MANAGE_BANK_ACCOUNTS = 'MANAGE_BANK_ACCOUNTS',
  MARKETING_VIRTUAL_CARD = 'MARKETING_VIRTUAL_CARD',
}

export type Permissions = {
  accountingPermissions: Array<AccountingPermissionsEnum>
  bankAccountsPermissions: Array<BankAccountsPermissionsEnum>
  billingPlanPermissions: Array<BillingPlanPermissionsEnum>
  businessDetailsPermissions: Array<BusinessDetailsPermissionsEnum>
  businessFeaturesPermissions: Array<BusinessFeaturesPermissionsEnum>
  catalogPermissions: Array<CatalogPermissionsEnum>
  clientManagerPermissions: Array<ClientManagerPermissionsEnum>
  dashboardPermissions: Array<DashboardPermissionsEnum>
  discrepancyRulePermissions: Array<DiscrepancyRulePermissionsEnum>
  inventoryPermissions: Array<InventoryPermissionsEnum>
  inventoryTransferPermissions: Array<InventoryTransferPermissionsEnum>
  invoicesPermissions: Array<InvoicesPermissionsEnum>
  localBusinessesPermissions: Array<LocalBusinessesPermissionsEnum>
  outboundOrderPermissions: Array<OutboundOrderPermissionsEnum>
  pagePermissions: Array<PagePermissionsEnum>
  paymentsPermissions: Array<PaymentsPermissionsEnum>
  purchaseOrderPermissions: Array<PurchaseOrderPermissionsEnum>
  receiptPermissions: Array<ReceiptPermissionsEnum>
  receivablesPermissions: Array<ReceivablesPermissionsEnum>
  reconciliationItemsPermissions: Array<ReconciliationItemsPermissionsEnum>
  rulesPermissions: Array<RulesPermissionsEnum>
  salesChannelConfigurationPermissions: Array<SalesChannelConfigurationPermissionsEnum>
  settleInboxConfigPermissions: Array<SettleInboxConfigPermissionsEnum>
  settleInboxPermissions: Array<SettleInboxPermissionsEnum>
  teamPermissions: Array<TeamPermissionsEnum>
  vendorCreditsPermissions: Array<VendorCreditsPermissionsEnum>
  workOrderPermissions: Array<WorkOrderPermissionsEnum>
}

export type PhysicalAttributes = {
  dimensionsUnitOfMeasure: Scalars['String']
  height: Maybe<Scalars['Float']>
  length: Maybe<Scalars['Float']>
  weight: Maybe<Scalars['Float']>
  weightUnitOfMeasure: Scalars['String']
  width: Maybe<Scalars['Float']>
}

export type PlaidLink = BankLink & {
  deleted: Scalars['Boolean']
  fixAvailable: Maybe<BankLinkFixEnum>
  id: Scalars['ID']
  name: Scalars['String']
}

export type PreliminaryConsolidatedInvoicesGroupType = {
  invoices: Array<Invoice>
  position: Scalars['Int']
}

export type ProcessingEstimate = {
  nearestProcessingDate: Scalars['ISO8601Date']
  payerDebitEta: Array<Scalars['ISO8601Date']>
  processingDate: Scalars['ISO8601Date']
  vendorCreditEta: Array<Scalars['ISO8601Date']>
}

export type ProcessingEstimates = {
  direct: ProcessingEstimate
  financed: ProcessingEstimate
}

export type Profile = {
  accountingFirms: Maybe<Array<Business>>
  actions: UserActions
  applicationTheme: ApplicationThemeEnum
  authProvider: Maybe<AuthProviderTypeEnum>
  clients: Maybe<Array<UserClient>>
  company: Scalars['String']
  country: Maybe<Scalars['String']>
  email: Scalars['String']
  emailConfirmationPending: Scalars['Boolean']
  firstName: Scalars['String']
  id: Scalars['String']
  internalEmail: Maybe<Scalars['String']>
  lastName: Scalars['String']
  loginEmail: Maybe<Scalars['String']>
  memberships: Array<Membership>
  multiBusiness: Scalars['Boolean']
  name: Scalars['String']
  operational: Scalars['Boolean']
  phoneNumber: Maybe<Scalars['String']>
  totp: Maybe<Totp>
}

export enum ProviderEnum {
  FINALOOP = 'FINALOOP',
  NETSUITE = 'NETSUITE',
  QUICK_BOOKS = 'QUICK_BOOKS',
  XERO = 'XERO',
}

export type PublicBusiness = {
  /** @deprecated Use preferredReceivingAccount instead */
  account: Maybe<PublicLocalBusinessAccount>
  address: Maybe<Address>
  businessStructure: Maybe<BusinessStructureEnum>
  companyName: Maybe<Scalars['String']>
  displayName: Scalars['String']
  emailDomains: Array<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  id: Scalars['String']
  is1099Eligible: Maybe<Scalars['Boolean']>
  lastName: Maybe<Scalars['String']>
  officialName: Scalars['String']
  preferredReceivingAccount: Maybe<PublicLocalBusinessAccount>
  /** Active verified accounts connected by the business */
  receivingAccounts: Array<PublicLocalBusinessAccount>
  taxpayerId: Maybe<Scalars['String']>
  verified: Scalars['Boolean']
  /** W9 form uploaded by the business */
  w9Form: Maybe<Attachment>
  website: Maybe<Scalars['String']>
}

export type PublicInvoice = {
  account: Maybe<Account>
  actions: PublicInvoiceActions
  amount: Maybe<Money>
  attachments: Array<Attachment>
  dueDate: Maybe<Scalars['ISO8601Date']>
  issueDate: Maybe<Scalars['ISO8601Date']>
  memo: Maybe<Scalars['String']>
  number: Maybe<Scalars['String']>
  paidOn: Maybe<Scalars['ISO8601Date']>
  payableUrl: Maybe<Scalars['String']>
  payerBusiness: Maybe<PublicBusiness>
  payerLocalBusiness: Maybe<LocalBusiness>
  payerNotificationsEmail: Maybe<Scalars['String']>
  scheduledOn: Maybe<Scalars['ISO8601Date']>
  sentAt: Maybe<Scalars['ISO8601DateTime']>
  status: InvoiceStatusEnum
  uuid: Maybe<Scalars['String']>
  vendorAccount: Maybe<LocalBusinessAccount>
  vendorBusiness: Maybe<PublicBusiness>
}

export type PublicInvoiceActions = {
  createPayment: Action
  createPublicLinkToken: Action
  link: Action
  removePayerAccount: Action
  revokePayment: Action
  updateBusinessDetails: Action
  verifyAccount: Action
}

export type PublicLocalBusiness = {
  address: Maybe<Address>
  business: Maybe<PublicBusiness>
  businessName: Maybe<Scalars['String']>
  businessStructure: Maybe<BusinessStructureEnum>
  businessStructureTaxClassification: Maybe<BusinessStructureTaxClassificationEnum>
  exemptFatcaReportingCode: Maybe<Scalars['String']>
  exemptPayeeCode: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  id: Scalars['String']
  is1099Eligible: Maybe<Scalars['Boolean']>
  lastName: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  taxpayerId: Maybe<Scalars['String']>
  w9Form: Maybe<Attachment>
}

export type PublicLocalBusinessAccount = {
  accountNumberType: Maybe<VendorAccountNumberTypeEnum>
  bankAddress: Maybe<Address>
  bankName: Maybe<Scalars['String']>
  bicSwift: Maybe<Scalars['String']>
  destinationCountry: Scalars['String']
  id: Scalars['ID']
  lastUsedOn: Maybe<Scalars['ISO8601Date']>
  maskedAccountNumber: Scalars['String']
  maskedRoutingNumber: Maybe<Scalars['String']>
  maskedWireRoutingNumber: Maybe<Scalars['String']>
  preferredReceivingAccount: Scalars['Boolean']
}

export type PublicPurchaseOrderInputObject = {
  contactEmail?: InputMaybe<Scalars['String']>
  contactName?: InputMaybe<Scalars['String']>
  contactPhone?: InputMaybe<Scalars['String']>
  issueDate?: InputMaybe<Scalars['ISO8601Date']>
  lineItems?: InputMaybe<Array<PublicPurchaseOrderLineItemInputObject>>
  location?: InputMaybe<LocationInputObject>
  memo?: InputMaybe<Scalars['String']>
  number?: InputMaybe<Scalars['String']>
  paymentTerms?: InputMaybe<Scalars['String']>
  purchaserName?: InputMaybe<Scalars['String']>
  receiveByDate?: InputMaybe<Scalars['ISO8601Date']>
  shippingMethod?: InputMaybe<Scalars['String']>
  shippingTerms?: InputMaybe<Scalars['String']>
  taxPercentage?: InputMaybe<Scalars['Float']>
  vendorName?: InputMaybe<Scalars['String']>
}

export type PublicPurchaseOrderLineItemInputObject = {
  amountCents?: InputMaybe<Scalars['Float']>
  amountCurrency?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  quantity?: InputMaybe<Scalars['Float']>
  sku?: InputMaybe<Scalars['String']>
  unitOfMeasure?: InputMaybe<Scalars['String']>
}

export type PurchaseDetailsInputObject = {
  amount?: InputMaybe<MoneyInputObject>
  leadTimeDays?: InputMaybe<Scalars['Int']>
  sku?: InputMaybe<Scalars['String']>
  unitOfMeasure?: InputMaybe<Scalars['String']>
  unitOfMeasureConversion?: InputMaybe<Scalars['Float']>
  vendorLocalBusinessId: Scalars['ID']
}

export type PurchaseDetailsType = {
  id: Scalars['ID']
  leadTimeDays: Maybe<Scalars['Int']>
  sku: Maybe<Scalars['String']>
  unitCost: Maybe<Money>
  unitOfMeasure: Maybe<Scalars['String']>
  unitOfMeasureConversion: Maybe<Scalars['Float']>
  vendorLocalBusiness: Maybe<LocalBusiness>
}

export type PurchaseOrder = {
  accountingSyncEvents: Maybe<Array<SyncEvent>>
  accountingUrl: Maybe<Scalars['String']>
  actions: PurchaseOrderActions
  additionalExpenses: Array<AdditionalExpense>
  additionalExpensesTotal: Money
  allowedShippingTerms: Array<Scalars['String']>
  amount: Maybe<Money>
  approval: Maybe<Approval>
  archived: Scalars['Boolean']
  attachment: Maybe<Attachment>
  attachments: Array<Attachment>
  auditTrailEvents: Array<AuditTrailEvents>
  contactEmail: Maybe<Scalars['String']>
  contactName: Maybe<Scalars['String']>
  contactPhone: Maybe<Scalars['String']>
  createdAt: Scalars['ISO8601DateTime']
  fullyReceivedAt: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  /** @deprecated use linkedDocuments instead */
  invoices: Array<Invoice>
  issueDate: Maybe<Scalars['ISO8601Date']>
  labels: Array<Label>
  lineItems: Array<PurchaseOrderLineItem>
  lineItemsSubtotal: Money
  lineItemsTotal: Money
  linkedDocuments: Array<LinkedDocument>
  location: Maybe<Location>
  memo: Maybe<Scalars['String']>
  number: Maybe<Scalars['String']>
  payments: Array<Payment>
  paymentsForecast: Maybe<Array<PaymentsForecast>>
  paymentsOfLinkedInvoices: Array<Payment>
  possibleDuplicates: Array<PurchaseOrder>
  purchaseOrderApproval: Maybe<PurchaseOrderApproval>
  purchaseOrderApprovalRule: Maybe<PurchaseOrderApprovalRule>
  /** @deprecated use linkedDocuments instead */
  receipts: Array<Receipt>
  receiveByDate: Maybe<Scalars['ISO8601Date']>
  shippingMethod: Maybe<Scalars['String']>
  shippingTerms: Maybe<Scalars['String']>
  shippingVendors: Array<LocalBusiness>
  showSyncHistory: Maybe<Scalars['Boolean']>
  source: PurchaseOrderSourceEnum
  status: PurchaseOrderStatusEnum
  syncError: Maybe<SyncError>
  syncObjectStatus: Maybe<SyncObjectStatus>
  taxAmount: Money
  taxPercentage: Maybe<Scalars['Float']>
  timelineEvents: Maybe<Array<PurchaseOrderTimelineEvent>>
  vendorLeadTimes: Array<VendorLeadTime>
  vendorLocalBusiness: Maybe<LocalBusiness>
  vendorPaymentTerms: Array<VendorPaymentTerm>
  vendorTerm: Maybe<VendorTerms>
}

export type PurchaseOrderActions = {
  attachDocumentPurchaseOrder: Action
  createPurchaseOrder: Action
  requestRuleBasedApproval: Action
  updatePurchaseOrder: Action
  updateVendor: Action
}

export type PurchaseOrderApproval = {
  approvedBy: Maybe<Array<PurchaseOrderApproverType>>
  approvedOrRejectedOn: Maybe<Scalars['ISO8601Date']>
  approvers: Maybe<Array<Scalars['ID']>>
  requestingUser: Maybe<PurchaseOrderApproverType>
}

export type PurchaseOrderApprovalRule = {
  approvalRule: Maybe<Rule>
  approvalSteps: Maybe<Array<PurchaseOrderApprovalStep>>
}

export type PurchaseOrderApprovalStep = {
  approvalType: RuleActionConditionEnum
  approvers: Maybe<Array<PurchaseOrderApproverType>>
  stepNumber: Maybe<Scalars['Int']>
}

export type PurchaseOrderApproverType = {
  id: Scalars['ID']
  name: Scalars['String']
}

export enum PurchaseOrderArchivedStatusEnum {
  ACTIVE_AND_ARCHIVED = 'ACTIVE_AND_ARCHIVED',
  ACTIVE_ONLY = 'ACTIVE_ONLY',
  ARCHIVED_ONLY = 'ARCHIVED_ONLY',
}

export enum PurchaseOrderEventTypeEnum {
  PO_ADDED = 'PO_ADDED',
  PO_ADDED_AUTOMATICALLY = 'PO_ADDED_AUTOMATICALLY',
  PO_APPROVAL_CANCELLED = 'PO_APPROVAL_CANCELLED',
  PO_APPROVAL_CANCELLED_PLAN_CHANGE = 'PO_APPROVAL_CANCELLED_PLAN_CHANGE',
  PO_APPROVAL_REQUESTED = 'PO_APPROVAL_REQUESTED',
  PO_APPROVED = 'PO_APPROVED',
  PO_CANCELED = 'PO_CANCELED',
  PO_CLOSED = 'PO_CLOSED',
  PO_CREATED = 'PO_CREATED',
  PO_OPENED = 'PO_OPENED',
  PO_REJECTED = 'PO_REJECTED',
  PO_SENT_TO_VENDOR = 'PO_SENT_TO_VENDOR',
}

export type PurchaseOrderInputObject = {
  amountCents?: InputMaybe<Scalars['SafeInt']>
  amountCurrency?: InputMaybe<Scalars['String']>
  contactEmail?: InputMaybe<Scalars['String']>
  contactName?: InputMaybe<Scalars['String']>
  contactPhone?: InputMaybe<Scalars['String']>
  issueDate?: InputMaybe<Scalars['ISO8601Date']>
  location?: InputMaybe<LocationInputObject>
  locationId?: InputMaybe<Scalars['ID']>
  memo?: InputMaybe<Scalars['String']>
  number?: InputMaybe<Scalars['String']>
  receiveByDate?: InputMaybe<Scalars['ISO8601Date']>
  shippingMethod?: InputMaybe<Scalars['String']>
  shippingTerms?: InputMaybe<Scalars['String']>
  shippingVendorIds?: InputMaybe<Array<Scalars['ID']>>
  status?: InputMaybe<PurchaseOrderStatusEnum>
  taxPercentage?: InputMaybe<Scalars['Float']>
  vendorLocalBusinessId?: InputMaybe<Scalars['ID']>
  vendorPaymentTerms?: InputMaybe<Array<VendorPaymentTermInputObject>>
}

export type PurchaseOrderLineItem = {
  accountingAccount: Maybe<AccountingAccount>
  accountingCustomerOrProject: Maybe<AccountingDictionary>
  amountCents: Maybe<Scalars['Float']>
  amountCurrency: Maybe<Scalars['String']>
  catalogItem: Maybe<CatalogItem>
  catalogItemVariation: Maybe<CatalogItemVariation>
  children: Maybe<Array<PurchaseOrderLineItem>>
  /** @deprecated Use itemName instead */
  description: Maybe<Scalars['String']>
  fractionalLandedCost: Maybe<InfinitePrecisionMoney>
  hasSimilarCatalogItem: Scalars['Boolean']
  id: Scalars['ID']
  itemName: Maybe<Scalars['String']>
  position: Maybe<Scalars['Int']>
  quantity: Maybe<Scalars['Float']>
  unitOfMeasure: Maybe<Scalars['String']>
}

export type PurchaseOrderLineItemInputObject = {
  accountingAccountId?: InputMaybe<Scalars['ID']>
  accountingCustomerOrProjectId?: InputMaybe<Scalars['ID']>
  amountCents?: InputMaybe<Scalars['Float']>
  amountCurrency?: InputMaybe<Scalars['String']>
  catalogItemId?: InputMaybe<Scalars['ID']>
  catalogItemVariationId?: InputMaybe<Scalars['ID']>
  children?: InputMaybe<Array<PurchaseOrderLineItemInputObject>>
  id?: InputMaybe<Scalars['ID']>
  itemName?: InputMaybe<Scalars['String']>
  position?: InputMaybe<Scalars['Int']>
  quantity?: InputMaybe<Scalars['Float']>
  unitOfMeasure?: InputMaybe<Scalars['String']>
}

export enum PurchaseOrderPermissionsEnum {
  ATTACH_PURCHASE_ORDER_DOCUMENT = 'ATTACH_PURCHASE_ORDER_DOCUMENT',
  CREATE_PAYMENT_ON_PURCHASE_ORDER = 'CREATE_PAYMENT_ON_PURCHASE_ORDER',
  CREATE_PURCHASE_ORDER = 'CREATE_PURCHASE_ORDER',
  DELETE_PURCHASE_ORDER = 'DELETE_PURCHASE_ORDER',
  LINK_DOCUMENTS_TO_PURCHASE_ORDER = 'LINK_DOCUMENTS_TO_PURCHASE_ORDER',
  READ_PURCHASE_ORDER = 'READ_PURCHASE_ORDER',
  REQUEST_PURCHASE_ORDER_APPROVAL = 'REQUEST_PURCHASE_ORDER_APPROVAL',
  UPDATE_PURCHASE_ORDER = 'UPDATE_PURCHASE_ORDER',
  UPDATE_PURCHASE_ORDER_ARCHIVED = 'UPDATE_PURCHASE_ORDER_ARCHIVED',
  UPDATE_PURCHASE_ORDER_LABELS = 'UPDATE_PURCHASE_ORDER_LABELS',
}

export type PurchaseOrderPostApprovalWorkflow = {
  body?: InputMaybe<Scalars['String']>
  openPostApproval?: InputMaybe<Scalars['Boolean']>
  receivingEmails?: InputMaybe<Array<Scalars['String']>>
}

export enum PurchaseOrderSortableFieldEnum {
  CREATED_AT = 'CREATED_AT',
  ISSUE_DATE = 'ISSUE_DATE',
  NUMBER = 'NUMBER',
  RECEIVE_BY_DATE = 'RECEIVE_BY_DATE',
}

export enum PurchaseOrderSortingDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type PurchaseOrderSortingInputObject = {
  direction: PurchaseOrderSortingDirectionEnum
  field: PurchaseOrderSortableFieldEnum
}

export enum PurchaseOrderSourceEnum {
  GENERATED = 'GENERATED',
  UPLOADED = 'UPLOADED',
}

export enum PurchaseOrderStatusEnum {
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  DRAFT = 'DRAFT',
  IN_REVIEW = 'IN_REVIEW',
  OPEN = 'OPEN',
  REJECTED = 'REJECTED',
}

export type PurchaseOrderTimelineEvent = TimelineEventInterface & {
  createdAt: Maybe<Scalars['ISO8601DateTime']>
  displayText: Scalars['String']
  eventType: PurchaseOrderEventTypeEnum
  icon: IconEnum
  user: Maybe<Approver>
}

export type PurchaseOrders = {
  data: Array<PurchaseOrder>
  pagination: Pagination
}

export type PurchaseOrdersFilteringInputObject = {
  archivedStatus?: InputMaybe<PurchaseOrderArchivedStatusEnum>
  createdAt?: InputMaybe<DateFilteringInputObject>
  expectedPayments?: InputMaybe<DateFilteringInputObject>
  invoiceNumber?: InputMaybe<Scalars['String']>
  labelIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  number?: InputMaybe<Scalars['String']>
  purchaseOrderIds?: InputMaybe<Array<Scalars['ID']>>
  statuses?: InputMaybe<Array<PurchaseOrderStatusEnum>>
  vendorLocalBusinessIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  vendorName?: InputMaybe<Scalars['String']>
}

export type PurposeCode = {
  code: Scalars['String']
  description: Scalars['String']
}

export type Query = {
  account: Account
  accountantDashboard: AccountantDashboard
  accountingCounters: AccountingCounters
  accountingObjectsWithError: SyncObjects
  accountingState: Maybe<AccountingState>
  accountingSyncErrorSummaries: SyncErrorSummaries
  accountingSyncEvents: Maybe<SyncEvents>
  accounts: Array<Account>
  accountsForBilling: Array<Account>
  addressValidation: AddressValidation
  amortizedEarlyRepaymentInterestSavings: Money
  amortizedLoanEarlyRepaymentPreview: AmortizedLoanEarlyRepaymentPreview
  apAgingSummary: Array<ApAgingBucketSummary>
  attachment: Maybe<Attachment>
  auditTrailEvents: Array<AuditTrailEvents>
  banners: Banners
  beneficialOwnerDataFields: BeneficialOwnersDataFields
  beneficialOwners: Array<BeneficialOwner>
  /** @deprecated Use pagedBillingInvoices instead */
  billingInvoices: Array<BillingInvoiceType>
  billingMethods: Array<BillingMethod>
  billingPlanFeatures: Array<BillingPlanFeatures>
  bulletEptEarlyRepaymentPreview: BulletEptEarlyRepaymentPreview
  businessFeatures: BusinessFeaturesType
  businesses: Array<Business>
  businessesForSwitcher: Array<Business>
  cashAdvanceFinancingOptions: FinancingOptions
  cashOutflows: Array<CashOutflow>
  catalogItemLandedCostDetails: Array<CatalogItemLandedCostDetails>
  catalogItemPropertyTemplates: Array<CatalogItemProperty>
  catalogItemVariations: CatalogItemVariations
  catalogItems: CatalogItems
  catalogItemsWaitingForSync: Maybe<CatalogItems>
  checkBillingPlanUpgrade: BillingPlanUpgrade
  checkEmail: EmailTypeEnum
  checkInvitation: Maybe<Invitation>
  checkPayerInvitation: PayerInvitation
  checkSkuUniqueness: ValidationResult
  clientApplication: ClientApplication
  clientBillingDashboard: ClientBillingDashboardType
  clientBillingInvoices: ClientBillingInvoicesType
  clientManagerDashboard: ClientManagerDashboard
  clientPlans: Array<BillingPlanType>
  counters: Counters
  countries: Array<Country>
  currencies: Array<Currency>
  dashboard: Dashboard
  deactivatedBusinesses: Array<PublicBusiness>
  deepLinkedDocuments: Array<LinkedDocument>
  disclosures: Disclosures
  discounts: Array<BillingDiscountType>
  discrepancyRules: Array<DiscrepancyRule>
  dueDatesForConsolidatedGroups: Array<ConsolidatedPaymentGroupWithDueDateType>
  earlyRepaymentBalance: RepaymentBalance
  eptMetrics: EptMetrics
  estimateRecurrentPaymentsTimelines: Array<RecurrentPaymentsEstimatedTimeline>
  exchangeFeeData: ExchangeFeeData
  excludedFromSync: Array<SyncObject>
  extendedDowngradeDetails: Maybe<Array<BillingExtendedDowngradeDetailsType>>
  fetchUnauthenticatedShopifyRedirect: Scalars['String']
  finaloopState: Maybe<FinaloopState>
  finicityConnectUrlV2: ConnectUrl
  finicityConnections: Array<FinicityLink>
  fullRepaymentBalance: FullRepaymentBalance
  holidays: Array<Holiday>
  informationRequest: Maybe<InformationRequest>
  integrationsState: IntegrationsState
  internationalWireFields: Array<InternationalWireFieldsDataFieldType>
  invoice: Invoice
  invoiceOcrData: Maybe<InvoiceOcr>
  invoiceStatusCounts: InvoiceStatusCounts
  invoices: Array<Invoice>
  invoicesAddressesValidation: Maybe<Array<InvoiceAddressValidationType>>
  invoicesFilteringOptions: InvoiceFilteringOptions
  invoicesPossibleForAllocation: Maybe<Invoices>
  invoicesWaitingForSync: Maybe<Invoices>
  journeys: Array<Journey>
  kybDataRequest: KybDataRequest
  labels: Array<Label>
  latestGenBillingPlans: Array<BillingPlanType>
  linkableCatalogItems: PagedLinkableCatalogItems
  linkableOrderDocuments: PagedLinkableOrderDocuments
  linkedDocuments: Array<LinkedDocument>
  listReports: Maybe<Reports>
  localBusinessAccounts: Array<LocalBusinessAccount>
  localBusinesses: LocalBusinesses
  locations: Array<Location>
  marketingVirtualCard: Maybe<MarketingVirtualCard>
  marketingVirtualCardAuthorizations: AuthorizationPaginated
  marketingVirtualCardSecure: Maybe<MarketingVirtualCardSecure>
  marketingVirtualCardTransactions: TransactionPaginated
  matchDocuments: DocumentsMatchingData
  middeskSearch: MiddeskSearch
  /** @deprecated Use processingEstimates instead */
  nearestProcessingDate: Scalars['ISO8601Date']
  netsuiteState: Maybe<NetsuiteState>
  /** @deprecated Use generic unpaid_bills instead. */
  netsuiteUnpaidBills: Maybe<Array<NetsuiteUnpaidBill>>
  newBillingPlans: Array<BillingPlanType>
  newCashAdvanceEptGrandTotal: Maybe<Money>
  newEptGrandTotal: Maybe<Money>
  /** @deprecated Use processingEstimates instead */
  newPaymentEtaFromPayer: AccountEstimatedDebitDate
  newPaymentFinancingOptions: FinancingOptions
  notificationSettings: NotificationSettings
  pagedBillingInvoices: BillingInvoicesType
  pagedInventoryDemandForecasts: PagedInventoryDemandForecast
  pagedInventoryTransfers: PagedInventoryTransfers
  pagedInvoices: Invoices
  pagedLocations: PagedLocations
  pagedOutboundOrders: PagedOutboundOrders
  pagedReconciliationItems: ReconciliationItems
  pagedRecurrentPaymentsSchedules: RecurrentPaymentsPagedSchedules
  pagedSalesChannelConfigurations: PagedSalesChannelConfigurations
  pagedWorkOrders: PagedWorkOrders
  payerInvitation: PayerInvitationV2
  /** @deprecated Use processingEstimates instead */
  paymentEtaToVendor: PaymentEstimatedDelay
  paymentsPageCutoffWarning: Maybe<Scalars['String']>
  permissionSets: Array<PermissionSet>
  permissions: Permissions
  plaidConnections: Array<PlaidLink>
  plaidCreateLinkToken: Scalars['String']
  plaidCreateLinkTokenPublicInvoice: Scalars['String']
  preliminaryConsolidatedInvoicesGroups: Array<PreliminaryConsolidatedInvoicesGroupType>
  previewAsPayableInvoice: PayableInvoicePreview
  processingEstimates: ProcessingEstimates
  profile: Profile
  promotionCodes: Array<BillingPromotionCodeType>
  publicBusinesses: Array<PublicBusiness>
  publicInvoice: PublicInvoice
  purchaseOrders: PurchaseOrders
  purchaseOrdersWaitingForSync: Maybe<PurchaseOrders>
  purposeCodes: Array<PurposeCode>
  quickBooksLoginUrl: Scalars['String']
  quickBooksState: Maybe<QuickBooksState>
  /** @deprecated Use generic unpaid_bills instead. */
  quickBooksUnpaidBills: Maybe<Array<QuickBooksUnpaidBill>>
  rateLimits: Array<RateLimit>
  receipts: Receipts
  receivableInvoices: ReceivableInvoices
  receivables: Receivables
  reconciliationItem: ReconciliationItem
  reconciliationItemsFilteringOptions: ReconciliationItemFilteringOptions
  reconciliationItemsPossibleForAllocation: ReconciliationItems
  recurrentPaymentsSchedule: RecurrentPaymentsSchedule
  referralCodeV2: Maybe<CodeTypeV2>
  report: Maybe<Report>
  report1099Years: Array<ReportYear>
  reportsFilteringOptions: ReportFilteringOptions
  ruleMetadata: RuleMetadata
  rules: Array<Rule>
  salesChannels: Array<OutboundOrderChannel>
  searchOrderDocuments: PagedOrderDocuments
  settleInboxAutopayableConfig: Maybe<SettleInboxAutopayableConfigType>
  settleInboxMessages: SettleInboxMessages
  shopifyState: Maybe<ShopifyState>
  signIns: Array<SignIn>
  subscriptionFreezingDetails: Maybe<Message>
  suggestedVendorAccounts: Maybe<Array<InvoiceSuggestedVendorAccount>>
  /** @deprecated use teamMembersV2 instead */
  teamMember: TeamMember
  teamMemberV2: Maybe<TeamMember>
  teamMembers: TeamMembers
  testingMicroDeposits: TestingMicroDeposits
  testingTokens: TestingTokens
  totalDebits: TotalDebits
  totp: Maybe<Totp>
  trackstarIntegrations: Array<TrackstarIntegration>
  trackstarLinkToken: Scalars['String']
  transactionalTodoListSummary: Array<TransactionalTodoListSummary>
  transfer: Transfer
  unimportedPurchaseOrders: Maybe<UnimportedPurchaseOrders>
  unitsOfMeasure: Array<Scalars['String']>
  unpaidBills: Maybe<Array<UnpaidBill>>
  unpaidBillsPaginated: Maybe<UnpaidBillsPaginated>
  upcomingBillingInvoice: BillingInvoiceType
  userRole: Maybe<UserRole>
  userRoles: Array<UserRole>
  validateReferralCode: ValidateReferralCode
  vendorCredit: VendorCredit
  vendorCreditsWaitingForSync: Maybe<VendorCredits>
  vendorFinancingOffer: VendorFinancingOffer
  vendorInvitation: VendorInvitation
  vendorLeadTimes: Array<VendorLeadTime>
  vendorTermOptions: Array<VendorTermOption>
  xeroLoginUrl: Scalars['String']
  xeroState: Maybe<XeroState>
  /** @deprecated Use generic unpaid_bills instead. */
  xeroUnpaidBills: Maybe<Array<XeroUnpaidBill>>
}

export type QueryaccountArgs = {
  accountId: Scalars['ID']
}

export type QueryaccountingObjectsWithErrorArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  syncErrorMessage: Scalars['String']
  syncObjectType: SyncObjectTypeEnum
}

export type QueryaccountingSyncErrorSummariesArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QueryaccountingSyncEventsArgs = {
  endDate?: InputMaybe<Scalars['ISO8601Date']>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  startDate?: InputMaybe<Scalars['ISO8601Date']>
  syncObjectType: SyncObjectTypeEnum
}

export type QueryaccountsArgs = {
  all?: InputMaybe<Scalars['Boolean']>
  lockbox?: InputMaybe<Scalars['Boolean']>
  paying?: InputMaybe<Scalars['Boolean']>
}

export type QueryaddressValidationArgs = {
  addressId?: InputMaybe<Scalars['ID']>
  inputAddress?: InputMaybe<AddressInputObject>
}

export type QueryamortizedEarlyRepaymentInterestSavingsArgs = {
  date?: InputMaybe<Scalars['ISO8601Date']>
  paymentId: Scalars['ID']
}

export type QueryamortizedLoanEarlyRepaymentPreviewArgs = {
  paymentId: Scalars['ID']
  repaymentAmount: MoneyInputObject
  repaymentDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type QueryapAgingSummaryArgs = {
  includedLocalBusinessIds?: InputMaybe<Array<Scalars['ID']>>
  numDistinctVendors?: InputMaybe<Scalars['Int']>
}

export type QueryattachmentArgs = {
  id: Scalars['ID']
}

export type QueryauditTrailEventsArgs = {
  entityId: Scalars['ID']
}

export type QuerybannersArgs = {
  businessId?: InputMaybe<Scalars['ID']>
}

export type QuerybeneficialOwnerDataFieldsArgs = {
  countryCode: Scalars['String']
}

export type QuerybillingInvoicesArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerybulletEptEarlyRepaymentPreviewArgs = {
  paymentId: Scalars['ID']
  repaymentAmount: MoneyInputObject
  repaymentDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type QuerybusinessesArgs = {
  businessId?: InputMaybe<Scalars['ID']>
}

export type QuerybusinessesForSwitcherArgs = {
  businessId?: InputMaybe<Scalars['ID']>
}

export type QuerycashAdvanceFinancingOptionsArgs = {
  amount: MoneyInputObject
}

export type QuerycashOutflowsArgs = {
  apForecastEnabled?: InputMaybe<Scalars['Boolean']>
}

export type QuerycatalogItemLandedCostDetailsArgs = {
  catalogItemOrVariationId: Scalars['ID']
  filterLimit?: InputMaybe<Scalars['Int']>
}

export type QuerycatalogItemVariationsArgs = {
  catalogItemVariationsFilter?: InputMaybe<CatalogItemVariationsFilteringInputObject>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerycatalogItemsArgs = {
  catalogItemsFilter?: InputMaybe<CatalogItemsFilteringInputObject>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerycatalogItemsWaitingForSyncArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerycheckBillingPlanUpgradeArgs = {
  billingPlanId: Scalars['ID']
}

export type QuerycheckEmailArgs = {
  email: Scalars['String']
}

export type QuerycheckInvitationArgs = {
  invitationToken: Scalars['String']
}

export type QuerycheckPayerInvitationArgs = {
  invitationCode: Scalars['String']
}

export type QuerycheckSkuUniquenessArgs = {
  catalogItemVariationId: Scalars['ID']
  sku: Scalars['String']
}

export type QueryclientApplicationArgs = {
  applicationId: Scalars['ID']
}

export type QueryclientBillingDashboardArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QueryclientBillingInvoicesArgs = {
  clientBusinessId: Scalars['ID']
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QueryclientManagerDashboardArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerydeactivatedBusinessesArgs = {
  businessId?: InputMaybe<Scalars['ID']>
}

export type QuerydeepLinkedDocumentsArgs = {
  inventoryTransferId?: InputMaybe<Scalars['ID']>
  invoiceId?: InputMaybe<Scalars['ID']>
  purchaseOrderId?: InputMaybe<Scalars['ID']>
  receiptId?: InputMaybe<Scalars['ID']>
}

export type QuerydisclosuresArgs = {
  template?: InputMaybe<Scalars['Boolean']>
}

export type QuerydueDatesForConsolidatedGroupsArgs = {
  groups: Array<ConsolidatePaymentDueDateForGroupInputObject>
  paymentMethod: PaymentMethodEnum
}

export type QueryearlyRepaymentBalanceArgs = {
  amount: MoneyInputObject
  paymentId: Scalars['ID']
  repaymentDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type QueryestimateRecurrentPaymentsTimelinesArgs = {
  endDate: Scalars['ISO8601Date']
  interval: RecurrentPaymentsIntervalEnum
  paymentAttributes: PaymentAttributesForEstimatesInputObject
  paymentMethod: PaymentMethodEnum
  startDate: Scalars['ISO8601Date']
}

export type QueryexchangeFeeDataArgs = {
  amount: MoneyInputObject
}

export type QueryextendedDowngradeDetailsArgs = {
  planFromId: Scalars['ID']
  planToId: Scalars['ID']
}

export type QueryfetchUnauthenticatedShopifyRedirectArgs = {
  hmac: Scalars['String']
  host: Scalars['String']
  session?: InputMaybe<Scalars['String']>
  shop: Scalars['String']
  timestamp: Scalars['String']
}

export type QueryfinicityConnectUrlV2Args = {
  finicityConnectionId?: InputMaybe<Scalars['ID']>
}

export type QueryfullRepaymentBalanceArgs = {
  paymentId: Scalars['ID']
  repaymentDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type QueryholidaysArgs = {
  beforeDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type QueryinformationRequestArgs = {
  token: Scalars['String']
}

export type QueryinternationalWireFieldsArgs = {
  country: Scalars['String']
  currency: Scalars['String']
  targetType: InternationalWireTargetTypeEnum
}

export type QueryinvoiceArgs = {
  invoiceId: Scalars['ID']
}

export type QueryinvoiceOcrDataArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>
  receivableId?: InputMaybe<Scalars['ID']>
}

export type QueryinvoicesArgs = {
  invoiceIds: Array<Scalars['ID']>
}

export type QueryinvoicesAddressesValidationArgs = {
  invoiceIds: Array<Scalars['ID']>
  paymentMethod: PaymentMethodEnum
}

export type QueryinvoicesFilteringOptionsArgs = {
  businessId?: InputMaybe<Scalars['ID']>
}

export type QueryinvoicesPossibleForAllocationArgs = {
  page?: InputMaybe<Scalars['Int']>
  paymentId: Scalars['ID']
  perPage?: InputMaybe<Scalars['Int']>
}

export type QueryinvoicesWaitingForSyncArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QueryjourneysArgs = {
  businessId?: InputMaybe<Scalars['ID']>
}

export type QuerykybDataRequestArgs = {
  dataRequestId: Scalars['ID']
}

export type QuerylabelsArgs = {
  labelId?: InputMaybe<Scalars['ID']>
  search?: InputMaybe<Scalars['String']>
}

export type QuerylinkableCatalogItemsArgs = {
  linkableCatalogItemsFilter?: InputMaybe<LinkableCatalogItemsFilteringInputObject>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerylinkableOrderDocumentsArgs = {
  linkableOrderDocumentsFilter?: InputMaybe<LinkableOrderDocumentsFilteringInputObject>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerylinkedDocumentsArgs = {
  inventoryTransferId?: InputMaybe<Scalars['ID']>
  invoiceId?: InputMaybe<Scalars['ID']>
  purchaseOrderId?: InputMaybe<Scalars['ID']>
  receiptId?: InputMaybe<Scalars['ID']>
}

export type QuerylistReportsArgs = {
  category?: InputMaybe<Scalars['String']>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  statuses?: InputMaybe<Array<StatusEnum>>
}

export type QuerylocalBusinessAccountsArgs = {
  all?: InputMaybe<Scalars['Boolean']>
  destinationCountry?: InputMaybe<Scalars['String']>
  localBusinessId: Scalars['ID']
}

export type QuerylocalBusinessesArgs = {
  fulltext?: InputMaybe<Scalars['Boolean']>
  invited?: InputMaybe<Scalars['Boolean']>
  localBusinessId?: InputMaybe<Scalars['ID']>
  localBusinessIds?: InputMaybe<Array<Scalars['ID']>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  role?: InputMaybe<LocalBusinessRoleEnum>
  search?: InputMaybe<Scalars['String']>
  verified?: InputMaybe<Scalars['Boolean']>
  withDeleted?: InputMaybe<Scalars['Boolean']>
  withEmptyName?: InputMaybe<Scalars['Boolean']>
  withInvoices?: InputMaybe<Scalars['Boolean']>
  withW8OrW9FormMissing?: InputMaybe<Scalars['Boolean']>
}

export type QuerylocationsArgs = {
  locationId?: InputMaybe<Scalars['ID']>
}

export type QuerymarketingVirtualCardAuthorizationsArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerymarketingVirtualCardTransactionsArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerymatchDocumentsArgs = {
  linkedDocumentIds: Array<Scalars['ID']>
  recordUsage?: InputMaybe<Scalars['Boolean']>
}

export type QuerymiddeskSearchArgs = {
  businessId: Scalars['ID']
  legalName: Scalars['String']
  stateOfFormation: Scalars['String']
}

export type QuerynearestProcessingDateArgs = {
  ept: Scalars['Boolean']
  paymentMethod: PaymentMethodEnum
}

export type QuerynewCashAdvanceEptGrandTotalArgs = {
  amount?: InputMaybe<MoneyInputObject>
  offerIds: Array<Scalars['ID']>
  paymentId?: InputMaybe<Scalars['ID']>
}

export type QuerynewEptGrandTotalArgs = {
  amount: MoneyInputObject
  invoiceId: Scalars['ID']
  offerIds: Array<Scalars['ID']>
}

export type QuerynewPaymentEtaFromPayerArgs = {
  amount?: InputMaybe<MoneyInputObject>
  date: Scalars['ISO8601Date']
  invoiceId: Scalars['ID']
  offerIds?: InputMaybe<Array<Scalars['ID']>>
}

export type QuerynewPaymentFinancingOptionsArgs = {
  amount: MoneyInputObject
  invoiceId: Scalars['ID']
  paymentMethod?: InputMaybe<PaymentMethodEnum>
  vendorPaymentDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type QuerypagedBillingInvoicesArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerypagedInventoryDemandForecastsArgs = {
  filter?: InputMaybe<InventoryDemandForecastFilteringInputObject>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  period?: InputMaybe<InventoryDemandForecastPeriod>
  sorting?: InputMaybe<InventoryDemandForecastSortingInputObject>
}

export type QuerypagedInventoryTransfersArgs = {
  filter?: InputMaybe<InventoryTransfersFilteringInputObject>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorting?: InputMaybe<InventoryTransferSortingInputObject>
}

export type QuerypagedInvoicesArgs = {
  invoiceIds?: InputMaybe<Array<Scalars['ID']>>
  invoicesFilter?: InputMaybe<InvoicesFilteringInputObject>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorting?: InputMaybe<InvoiceSortingInputObject>
}

export type QuerypagedLocationsArgs = {
  locationFilter?: InputMaybe<LocationFilter>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerypagedOutboundOrdersArgs = {
  filter?: InputMaybe<OutboundOrdersFilteringInputObject>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorting?: InputMaybe<OutboundOrdersSortingInputObject>
}

export type QuerypagedReconciliationItemsArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  reconciliationItemIds?: InputMaybe<Array<Scalars['ID']>>
  reconciliationItemsFilter?: InputMaybe<ReconciliationItemsFilteringInputObject>
  sorting?: InputMaybe<ReconciliationItemSortingInputObject>
}

export type QuerypagedRecurrentPaymentsSchedulesArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerypagedSalesChannelConfigurationsArgs = {
  filter?: InputMaybe<SalesChannelConfigurationsFilteringInputObject>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerypagedWorkOrdersArgs = {
  filter?: InputMaybe<WorkOrdersFilteringInputObject>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerypayerInvitationArgs = {
  invitationCode: Scalars['String']
}

export type QuerypaymentEtaToVendorArgs = {
  invoiceId: Scalars['ID']
  isExtendedTerm: Scalars['Boolean']
  paymentMethod: PaymentMethodEnum
}

export type QueryplaidCreateLinkTokenArgs = {
  plaidItemId?: InputMaybe<Scalars['ID']>
}

export type QueryplaidCreateLinkTokenPublicInvoiceArgs = {
  uuid: Scalars['String']
}

export type QuerypreliminaryConsolidatedInvoicesGroupsArgs = {
  requestsData: Array<ConsolidatePaymentPreliminaryGroupRequestInputObject>
}

export type QuerypreviewAsPayableInvoiceArgs = {
  settleInboxAttachmentId: Scalars['ID']
}

export type QueryprocessingEstimatesArgs = {
  financingDays?: InputMaybe<Scalars['Int']>
  invoiceId?: InputMaybe<Scalars['ID']>
  paymentAttributes?: InputMaybe<PaymentAttributesForEstimatesInputObject>
  paymentMethod: PaymentMethodEnum
  scheduledFor?: InputMaybe<Scalars['ISO8601Date']>
}

export type QuerypromotionCodesArgs = {
  code: Scalars['String']
}

export type QuerypublicBusinessesArgs = {
  publicBusinessIds?: InputMaybe<Array<Scalars['ID']>>
  search?: InputMaybe<Scalars['String']>
}

export type QuerypublicInvoiceArgs = {
  uuid: Scalars['String']
}

export type QuerypurchaseOrdersArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  purchaseOrdersFilter?: InputMaybe<PurchaseOrdersFilteringInputObject>
  purchaseOrdersSorting?: InputMaybe<PurchaseOrderSortingInputObject>
}

export type QuerypurchaseOrdersWaitingForSyncArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerypurposeCodesArgs = {
  currency?: InputMaybe<Scalars['String']>
  destinationCountry?: InputMaybe<Scalars['String']>
}

export type QueryreceiptsArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  receiptsFilter?: InputMaybe<ReceiptsFilteringInputObject>
}

export type QueryreceivableInvoicesArgs = {
  invoiceIds?: InputMaybe<Array<Scalars['ID']>>
  invoicesFilter?: InputMaybe<InvoicesFilteringInputObject>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorting?: InputMaybe<ReceivableInvoicesSortingInputObject>
}

export type QueryreceivablesArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  receivableId?: InputMaybe<Scalars['ID']>
}

export type QueryreconciliationItemArgs = {
  reconciliationItemId: Scalars['ID']
}

export type QueryreconciliationItemsPossibleForAllocationArgs = {
  includesVendor?: InputMaybe<Scalars['Boolean']>
  invoiceId: Scalars['ID']
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QueryrecurrentPaymentsScheduleArgs = {
  scheduleId: Scalars['ID']
}

export type QueryreportArgs = {
  reportId: Scalars['ID']
}

export type QueryrulesArgs = {
  ruleId?: InputMaybe<Scalars['ID']>
}

export type QuerysearchOrderDocumentsArgs = {
  filter?: InputMaybe<PagedOrderDocumentsFilterInput>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerysettleInboxMessagesArgs = {
  archived: Scalars['Boolean']
  messageId?: InputMaybe<Scalars['ID']>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  query?: InputMaybe<Scalars['String']>
}

export type QuerysuggestedVendorAccountsArgs = {
  invoiceId: Scalars['ID']
}

export type QueryteamMemberArgs = {
  teamMemberId: Scalars['ID']
}

export type QueryteamMemberV2Args = {
  teamMemberId: Scalars['ID']
}

export type QueryteamMembersArgs = {
  teamMemberStatus?: InputMaybe<TeamMemberStatusEnum>
  userRoleGroup?: InputMaybe<UserRoleGroupEnum>
}

export type QuerytestingMicroDepositsArgs = {
  email: Scalars['String']
}

export type QuerytestingTokensArgs = {
  email: Scalars['String']
}

export type QuerytotalDebitsArgs = {
  reconciliationItemIds?: InputMaybe<Array<Scalars['ID']>>
  reconciliationItemsFilter?: InputMaybe<ReconciliationItemsFilteringInputObject>
}

export type QuerytrackstarIntegrationsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>
}

export type QuerytransferArgs = {
  transferId: Scalars['ID']
}

export type QueryunimportedPurchaseOrdersArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  searchTerm?: InputMaybe<Scalars['String']>
}

export type QueryunpaidBillsPaginatedArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QueryuserRoleArgs = {
  roleId: Scalars['ID']
}

export type QueryuserRolesArgs = {
  businessId?: InputMaybe<Scalars['ID']>
  userRoleGroup?: InputMaybe<UserRoleGroupEnum>
}

export type QueryvalidateReferralCodeArgs = {
  medium?: InputMaybe<Scalars['String']>
  referralCode: Scalars['String']
}

export type QueryvendorCreditArgs = {
  vendorCreditId: Scalars['ID']
}

export type QueryvendorCreditsWaitingForSyncArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QueryvendorFinancingOfferArgs = {
  invoiceId: Scalars['ID']
}

export type QueryvendorInvitationArgs = {
  invitationToken: Scalars['String']
}

export type QueryvendorLeadTimesArgs = {
  localBusinessId: Scalars['ID']
  purchaseOrderId?: InputMaybe<Scalars['ID']>
}

export type QueryvendorTermOptionsArgs = {
  localBusinessId?: InputMaybe<Scalars['ID']>
}

export type QuickBooksAccount = {
  accountSubType: Scalars['String']
  accountType: Scalars['String']
  active: Scalars['Boolean']
  children: Array<QuickBooksAccount>
  classification: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  number: Maybe<Scalars['String']>
  subAccount: Scalars['Boolean']
}

export type QuickBooksAccountMapping = {
  accountId: Scalars['ID']
  accountingAccountId: Maybe<Scalars['ID']>
  externalAccountId: Maybe<Scalars['ID']>
  quickBooksAccountId: Maybe<Scalars['ID']>
}

export type QuickBooksAccountMappingInputObject = {
  accountId: Scalars['ID']
  externalAccountId?: InputMaybe<Scalars['ID']>
  quickBooksAccountId?: InputMaybe<Scalars['ID']>
}

export type QuickBooksDictionary = {
  children: Array<QuickBooksDictionary>
  id: Scalars['ID']
  name: Scalars['String']
}

export type QuickBooksState = {
  accountMappings: Array<QuickBooksAccountMapping>
  accounts: Array<QuickBooksAccount>
  autoImportBills: Scalars['Boolean']
  autoImportPurchaseOrders: Scalars['Boolean']
  bankAccounts: Array<QuickBooksAccount>
  bookCloseDate: Maybe<Scalars['ISO8601Date']>
  classes: Array<QuickBooksDictionary>
  classesAvailable: Scalars['Boolean']
  connected: Scalars['Boolean']
  creditCardAccounts: Array<QuickBooksAccount>
  eptSyncEnabled: Scalars['Boolean']
  eptSyncStartDate: Maybe<Scalars['ISO8601Date']>
  expenseAccounts: Array<QuickBooksAccount>
  feeExpensesAccountId: Maybe<Scalars['ID']>
  filteredAccounts: Array<QuickBooksAccount>
  liabilityAccounts: Array<QuickBooksAccount>
  locations: Array<QuickBooksDictionary>
  locationsAvailable: Scalars['Boolean']
  multiCurrency: Scalars['Boolean']
  prepaymentsAccountId: Maybe<Scalars['ID']>
  readOnly: Scalars['Boolean']
  receivableBankAccountId: Maybe<Scalars['ID']>
  settleLiabilityAccountId: Maybe<Scalars['ID']>
  standalonePaymentsExpensesAccountId: Maybe<Scalars['ID']>
  syncAutomatically: Scalars['Boolean']
  syncErrorMessage: Maybe<Scalars['String']>
  syncScheduledAt: Maybe<Scalars['ISO8601DateTime']>
  syncStartDate: Maybe<Scalars['ISO8601Date']>
  syncStartedAt: Maybe<Scalars['ISO8601DateTime']>
  syncState: SyncStateEnum
  syncUncategorizedExpenses: Scalars['Boolean']
  syncedAt: Maybe<Scalars['ISO8601DateTime']>
  trackClasses: Scalars['Boolean']
  trackLocations: Scalars['Boolean']
  unallocatedExpensesAccountId: Maybe<Scalars['ID']>
}

export type QuickBooksUnpaidBill = {
  amount: Maybe<Money>
  balance: Maybe<Money>
  dueDate: Maybe<Scalars['ISO8601Date']>
  id: Scalars['ID']
  issueDate: Maybe<Scalars['ISO8601Date']>
  number: Maybe<Scalars['String']>
  url: Maybe<Scalars['String']>
  vendorName: Maybe<Scalars['String']>
}

export type RateLimit = {
  expiresAt: Maybe<Scalars['ISO8601DateTime']>
  resource: ResourceEnum
}

export enum ReasonEnum {
  BLOCKED = 'BLOCKED',
  IN_REVIEW = 'IN_REVIEW',
  NEED_BENEFICIAL_OWNER = 'NEED_BENEFICIAL_OWNER',
  NEED_BUSINESS_INFO = 'NEED_BUSINESS_INFO',
  NEED_SIGNIFICANT_CONTROL = 'NEED_SIGNIFICANT_CONTROL',
}

export type Receipt = {
  actions: ReceiptActions
  attachment: Maybe<Attachment>
  attachments: Array<Attachment>
  auditTrailEvents: Array<AuditTrailEvents>
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  issueDate: Maybe<Scalars['ISO8601Date']>
  lineItems: Array<ReceiptLineItem>
  linkedDocuments: Array<LinkedDocument>
  location: Maybe<Location>
  memo: Maybe<Scalars['String']>
  number: Maybe<Scalars['String']>
  receivedOn: Maybe<Scalars['ISO8601Date']>
  shippingVendors: Array<LocalBusiness>
  vendorLocalBusiness: Maybe<LocalBusiness>
}

export type ReceiptActions = {
  createReceipt: Action
  updateReceipt: Action
}

export type ReceiptCreateInputObject = {
  inventoryTransferId?: InputMaybe<Scalars['ID']>
  invoiceId?: InputMaybe<Scalars['ID']>
  number?: InputMaybe<Scalars['String']>
  purchaseOrderId?: InputMaybe<Scalars['ID']>
  uploadSignedId?: InputMaybe<Scalars['ID']>
  workOrderId?: InputMaybe<Scalars['ID']>
}

export type ReceiptInputObject = {
  issueDate?: InputMaybe<Scalars['ISO8601Date']>
  locationId?: InputMaybe<Scalars['ID']>
  memo?: InputMaybe<Scalars['String']>
  number?: InputMaybe<Scalars['String']>
  receivedOn?: InputMaybe<Scalars['ISO8601Date']>
  shippingVendorIds?: InputMaybe<Array<Scalars['ID']>>
  vendorLocalBusinessId?: InputMaybe<Scalars['ID']>
}

export type ReceiptLineItem = {
  catalogItem: Maybe<CatalogItem>
  catalogItemVariation: Maybe<CatalogItemVariation>
  children: Array<ReceiptLineItem>
  hasInventory: Scalars['Boolean']
  hasSimilarCatalogItem: Scalars['Boolean']
  id: Scalars['ID']
  itemName: Maybe<Scalars['String']>
  lotCode: Maybe<Scalars['String']>
  position: Maybe<Scalars['Int']>
  previousReceivedQuantity: Maybe<Scalars['Float']>
  quantity: Maybe<Scalars['Float']>
  quantityOrdered: Maybe<Scalars['Float']>
  sku: Maybe<Scalars['String']>
  unitOfMeasure: Maybe<Scalars['String']>
}

export type ReceiptLineItemInputObject = {
  catalogItemId?: InputMaybe<Scalars['ID']>
  catalogItemVariationId?: InputMaybe<Scalars['ID']>
  children?: InputMaybe<Array<ReceiptLineItemInputObject>>
  id?: InputMaybe<Scalars['ID']>
  itemName?: InputMaybe<Scalars['String']>
  lotCode?: InputMaybe<Scalars['String']>
  position?: InputMaybe<Scalars['Int']>
  quantity?: InputMaybe<Scalars['Float']>
  unitOfMeasure?: InputMaybe<Scalars['String']>
}

export enum ReceiptPermissionsEnum {
  ATTACH_RECEIPT_DOCUMENT = 'ATTACH_RECEIPT_DOCUMENT',
  CREATE_RECEIPT = 'CREATE_RECEIPT',
  DELETE_RECEIPT = 'DELETE_RECEIPT',
  LINK_DOCUMENTS_TO_RECEIPT = 'LINK_DOCUMENTS_TO_RECEIPT',
  READ_RECEIPT = 'READ_RECEIPT',
  UPDATE_RECEIPT = 'UPDATE_RECEIPT',
  UPDATE_RECEIPT_ARCHIVED = 'UPDATE_RECEIPT_ARCHIVED',
}

export type Receipts = {
  data: Array<Receipt>
  pagination: Pagination
}

export type ReceiptsFilteringInputObject = {
  number?: InputMaybe<Scalars['String']>
  receiptIds?: InputMaybe<Array<Scalars['ID']>>
  vendorLocalBusinessIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
}

export type Receivable = {
  account: Maybe<Account>
  accountNumber: Maybe<Scalars['String']>
  agreement: Maybe<Scalars['String']>
  amount: Maybe<Money>
  attachments: Array<Attachment>
  balancePaymentAmount: Maybe<Money>
  balancePaymentDate: Maybe<Scalars['ISO8601Date']>
  balancePaymentStatus: Maybe<BalancePaymentStatusEnum>
  ccAddress: Maybe<Scalars['String']>
  collectionDate: Maybe<Scalars['ISO8601Date']>
  collectionMethod: Maybe<ReceivableCollectionMethodEnum>
  createdAt: Scalars['ISO8601DateTime']
  /** @deprecated use 'payer.first_name'/'payer.last_name' instead */
  customerContactName: Maybe<Scalars['String']>
  /** @deprecated use 'payer.notifications_email' instead */
  customerEmail: Maybe<Scalars['String']>
  /** @deprecated use 'payer.name' instead */
  customerName: Maybe<Scalars['String']>
  debitApproved: Maybe<Scalars['Boolean']>
  deletable: Scalars['Boolean']
  depositDate: Scalars['ISO8601Date']
  dueDate: Maybe<Scalars['ISO8601Date']>
  expectedRepaymentDate: Maybe<Scalars['ISO8601Date']>
  feeAmount: Maybe<Money>
  feePercent: Maybe<Scalars['Float']>
  id: Scalars['ID']
  initialDepositAmount: Maybe<Money>
  issueDate: Maybe<Scalars['ISO8601Date']>
  labels: Array<Label>
  number: Maybe<Scalars['String']>
  payerBusiness: Maybe<LocalBusiness>
  payment: Maybe<Payment>
  paymentMethod: Maybe<PaymentMethodEnum>
  receivableAmount: Maybe<Money>
  requestedAt: Maybe<Scalars['ISO8601DateTime']>
  revokable: Scalars['Boolean']
  routingNumber: Maybe<Scalars['String']>
  settleRepaymentAmount: Maybe<Money>
  showsDetails: Scalars['Boolean']
  signedDocuments: Array<SignedDocument>
  status: ReceivableStatusEnum
}

export enum ReceivableCollectionMethodEnum {
  SETTLE_DEBITS_MY_BUSINESS = 'SETTLE_DEBITS_MY_BUSINESS',
  SETTLE_INVOICES_CUSTOMER = 'SETTLE_INVOICES_CUSTOMER',
}

export type ReceivableInputObject = {
  accountId?: InputMaybe<Scalars['ID']>
  amountCents?: InputMaybe<Scalars['SafeInt']>
  amountCurrency?: InputMaybe<Scalars['String']>
  collectionMethod?: InputMaybe<ReceivableCollectionMethodEnum>
  customerContactName?: InputMaybe<Scalars['String']>
  customerEmail?: InputMaybe<Scalars['String']>
  customerName?: InputMaybe<Scalars['String']>
  dueDate?: InputMaybe<Scalars['ISO8601Date']>
  expectedRepaymentDate?: InputMaybe<Scalars['ISO8601Date']>
  issueDate?: InputMaybe<Scalars['ISO8601Date']>
  number?: InputMaybe<Scalars['String']>
  payerBusinessId?: InputMaybe<Scalars['ID']>
}

export type ReceivableInvoice = {
  actions: InvoiceActions
  allocations: Maybe<Array<Allocation>>
  amount: Maybe<Money>
  archived: Scalars['Boolean']
  attachments: Array<Attachment>
  balanceDue: Maybe<Money>
  createdAt: Scalars['ISO8601DateTime']
  dueDate: Maybe<Scalars['ISO8601Date']>
  dueDays: Maybe<Scalars['Int']>
  dueToVendorAmount: Maybe<Money>
  id: Scalars['ID']
  issueDate: Maybe<Scalars['ISO8601Date']>
  liabilityAmount: Maybe<Money>
  lineItems: Array<ReceivableInvoiceLineItem>
  memo: Maybe<Scalars['String']>
  number: Maybe<Scalars['String']>
  paidOn: Maybe<Scalars['ISO8601Date']>
  paidWithMethod: Maybe<PaymentMethodEnum>
  paperCheckDetails: Maybe<PaperCheckDetails>
  payerBusiness: Maybe<PublicBusiness>
  payerLocalBusiness: Maybe<LocalBusiness>
  paymentsTotalAmount: Money
  possibleDuplicates: Array<ReceivableInvoice>
  purchaseOrderNumber: Maybe<Scalars['String']>
  purposeCode: Maybe<Scalars['String']>
  sentAt: Maybe<Scalars['ISO8601DateTime']>
  status: InvoiceStatusEnum
  statuses: Array<InvoiceStatusEnum>
  statusesForBadge: Array<InvoiceStatusEnum>
  timelineEvents: Maybe<Array<TimelineEvent>>
  totalUsdAmount: Maybe<Money>
  transfers: Array<ReceivableTransfer>
  unscheduledAmount: Maybe<Money>
  uploadedByCurrentBusiness: Scalars['Boolean']
  url: Maybe<Scalars['String']>
  vendorAccount: Maybe<LocalBusinessAccount>
  vendorAmount: Maybe<Money>
  vendorBusiness: Maybe<PublicBusiness>
  vendorCreditApplications: Array<VendorCreditApplication>
  vendorCreditAppliedTotal: Money
  vendorCurrency: Maybe<Currency>
  vendorFinancingConfirmation: Maybe<VendorFinancingConfirmation>
  vendorLocalBusiness: Maybe<LocalBusiness>
  vendorReceivedAmount: Money
}

export type ReceivableInvoiceInputObject = {
  amountCents?: InputMaybe<Scalars['SafeInt']>
  description?: InputMaybe<Scalars['String']>
  dueDate?: InputMaybe<Scalars['ISO8601Date']>
  issueDate?: InputMaybe<Scalars['ISO8601Date']>
  memo?: InputMaybe<Scalars['String']>
  number?: InputMaybe<Scalars['String']>
  payerLocalBusinessId?: InputMaybe<Scalars['ID']>
}

export type ReceivableInvoiceLineItem = {
  amount: Money
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  quantity: Maybe<Scalars['Float']>
  tax: Scalars['Boolean']
  unitAmount: Maybe<Money>
}

export type ReceivableInvoices = {
  data: Array<ReceivableInvoice>
  pagination: Pagination
}

export enum ReceivableInvoicesSortingDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type ReceivableInvoicesSortingInputObject = {
  direction: ReceivableInvoicesSortingDirectionEnum
  field: ReceivableSortableFieldEnum
}

export type ReceivableLineItemInputObject = {
  lineItemDetails: InvoiceLineItemDetailsInputObject
}

export type ReceivablePayment = {
  allocations: Maybe<Array<Allocation>>
  consolidated: Scalars['Boolean']
  errorMessage: Maybe<Scalars['String']>
  eta: Maybe<Array<Scalars['ISO8601Date']>>
  failedAt: Maybe<Scalars['ISO8601Date']>
  id: Scalars['ID']
  invoices: Array<ReceivableInvoice>
  paidWithMethod: Maybe<PaymentMethodEnum>
  processedOn: Maybe<Scalars['ISO8601Date']>
  purposeCode: Maybe<Scalars['String']>
  receivedOn: Maybe<Scalars['ISO8601Date']>
  scheduledOn: Scalars['ISO8601Date']
  secondaryStatusDescription: Maybe<Scalars['String']>
  statusDescription: Maybe<Scalars['String']>
  transfers: Array<ReceivableTransfer>
  vendorAmount: Money
  vendorPaymentDate: Scalars['ISO8601Date']
}

export enum ReceivableSortableFieldEnum {
  AMOUNT = 'AMOUNT',
  AMOUNT_CURRENCY = 'AMOUNT_CURRENCY',
  CREATED_AT = 'CREATED_AT',
  DUE_DATE = 'DUE_DATE',
  INVOICE_NUMBER = 'INVOICE_NUMBER',
  ISSUE_DATE = 'ISSUE_DATE',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
}

export enum ReceivableStatusEnum {
  APPROVED = 'APPROVED',
  DEBIT_APPROVAL_REQUESTED = 'DEBIT_APPROVAL_REQUESTED',
  DEBIT_PROCESSED = 'DEBIT_PROCESSED',
  DEBIT_SCHEDULED = 'DEBIT_SCHEDULED',
  DENIED = 'DENIED',
  DEPOSIT_PAID = 'DEPOSIT_PAID',
  DEPOSIT_PROCESSED = 'DEPOSIT_PROCESSED',
  DEPOSIT_SCHEDULED = 'DEPOSIT_SCHEDULED',
  DRAFT = 'DRAFT',
  OVERDUE = 'OVERDUE',
  OVERPAID = 'OVERPAID',
  REPAID = 'REPAID',
  REPAID_PARTIAL = 'REPAID_PARTIAL',
  WAITING = 'WAITING',
}

export type ReceivableTransfer = {
  amount: Maybe<Money>
  id: Scalars['ID']
  includedInConsolidatedPayment: Scalars['Boolean']
  payment: Maybe<ReceivablePayment>
  status: InvoiceStatusEnum
}

export type Receivables = {
  data: Array<Receivable>
  pagination: Pagination
}

export enum ReceivablesPermissionsEnum {
  RECEIVABLES = 'RECEIVABLES',
}

export type ReconciliationItem = {
  allowAccountChange: Scalars['Boolean']
  amount: Money
  approvers: Array<Approver>
  arrivedOn: Maybe<Scalars['ISO8601Date']>
  canceledAt: Maybe<Scalars['ISO8601DateTime']>
  debitedOn: Maybe<Scalars['ISO8601Date']>
  earlyRepaidOn: Maybe<Scalars['ISO8601Date']>
  earlyRepayment: Scalars['Boolean']
  etaRange: Maybe<Array<Scalars['ISO8601Date']>>
  failedAt: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  interest: Maybe<Money>
  onHold: Scalars['Boolean']
  paidFromAccount: Scalars['String']
  paidFromAccountObject: Maybe<Account>
  paidToAccount: Scalars['String']
  paidToAccountObject: Maybe<LocalBusinessAccount>
  /** Only set for refund items, in which case this field returns the business' name */
  paidToBusinessName: Maybe<Scalars['String']>
  payment: Payment
  paymentMethod: PaymentMethodEnum
  principal: Maybe<Money>
  refundItem: Maybe<ReconciliationItem>
  releasedAt: Maybe<Scalars['ISO8601DateTime']>
  retriedAt: Maybe<Scalars['ISO8601DateTime']>
  retriedBy: Maybe<Approver>
  scheduledFor: Scalars['ISO8601Date']
  sentAt: Maybe<Scalars['ISO8601DateTime']>
  status: ReconciliationItemStatusEnum
  type: ReconciliationItemTypeEnum
  usdAmount: Money
  vendorLocalBusiness: Maybe<LocalBusiness>
}

export type ReconciliationItemDateFilteringOption = {
  code: Maybe<Scalars['String']>
  empty: Scalars['Boolean']
  from: Maybe<Scalars['ISO8601Date']>
  name: Maybe<Scalars['String']>
  to: Maybe<Scalars['ISO8601Date']>
}

export type ReconciliationItemDateFilteringOptions = {
  field: ReconciliationItemSortableFieldEnum
  presets: Array<ReconciliationItemDateFilteringOption>
}

export type ReconciliationItemFilteringOptions = {
  dates: Array<ReconciliationItemDateFilteringOptions>
  epts: Maybe<Array<EptFilterEnum>>
}

export enum ReconciliationItemSortableFieldEnum {
  AMOUNT = 'AMOUNT',
  ARRIVED_AT = 'ARRIVED_AT',
  DEBITED_ON = 'DEBITED_ON',
  SCHEDULED_FOR = 'SCHEDULED_FOR',
  SENT_AT = 'SENT_AT',
  STATUS = 'STATUS',
  VENDOR_NAME = 'VENDOR_NAME',
}

export enum ReconciliationItemSortingDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type ReconciliationItemSortingInputObject = {
  direction: ReconciliationItemSortingDirectionEnum
  field: ReconciliationItemSortableFieldEnum
}

export enum ReconciliationItemStatusEnum {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INITIATED = 'INITIATED',
  ON_HOLD = 'ON_HOLD',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REFUNDED = 'REFUNDED',
  RETURNED = 'RETURNED',
  SCHEDULED = 'SCHEDULED',
}

export enum ReconciliationItemTypeEnum {
  AMORTIZED_INSTALLMENT = 'AMORTIZED_INSTALLMENT',
  AMORTIZED_VENDOR_PAYMENT = 'AMORTIZED_VENDOR_PAYMENT',
  CASH_ADVANCE = 'CASH_ADVANCE',
  CASH_ADVANCE_REPAYMENT = 'CASH_ADVANCE_REPAYMENT',
  DIRECT = 'DIRECT',
  EPT_FEE = 'EPT_FEE',
  EPT_REPAYMENT = 'EPT_REPAYMENT',
  EPT_VENDOR_PAYMENT = 'EPT_VENDOR_PAYMENT',
  REFUND = 'REFUND',
}

export type ReconciliationItems = {
  data: Array<ReconciliationItem>
  pagination: Pagination
}

export type ReconciliationItemsFilteringInputObject = {
  amountCents?: InputMaybe<InvoiceAmountFilteringInputObject>
  amountCurrencies?: InputMaybe<Array<Scalars['String']>>
  approverIds?: InputMaybe<Array<Scalars['ID']>>
  approverName?: InputMaybe<Scalars['String']>
  confirmationNumber?: InputMaybe<Scalars['String']>
  confirmationNumbers?: InputMaybe<Array<Scalars['String']>>
  debitedOn?: InputMaybe<DateFilteringInputObject>
  extensionAvailableDueSoon?: InputMaybe<Scalars['Boolean']>
  extensionFees?: InputMaybe<Scalars['Boolean']>
  financingPeriod?: InputMaybe<Array<EptFilterEnum>>
  fromPayerAccount?: InputMaybe<Scalars['Boolean']>
  fromSettleAccount?: InputMaybe<Scalars['Boolean']>
  hasAccountWithIssue?: InputMaybe<Scalars['Boolean']>
  includeOverdueApprovals?: InputMaybe<Scalars['Boolean']>
  installments?: InputMaybe<Scalars['Boolean']>
  itemType?: InputMaybe<Array<ReconciliationItemTypeEnum>>
  notLinkedToOrderDocuments?: InputMaybe<Array<Scalars['ID']>>
  payFromAccountIds?: InputMaybe<Array<Scalars['ID']>>
  payToSettle?: InputMaybe<Scalars['Boolean']>
  paymentMethods?: InputMaybe<Array<PaymentMethodEnum>>
  scheduledByIds?: InputMaybe<Array<Scalars['ID']>>
  scheduledByName?: InputMaybe<Scalars['String']>
  scheduledFor?: InputMaybe<DateFilteringInputObject>
  statuses?: InputMaybe<Array<ReconciliationItemStatusEnum>>
  suitableForDeferral?: InputMaybe<Scalars['Boolean']>
  vendorLocalBusinessIds?: InputMaybe<Array<Scalars['ID']>>
  vendorName?: InputMaybe<Scalars['String']>
}

export enum ReconciliationItemsPermissionsEnum {
  PAGED_RECONCILIATION_ITEMS = 'PAGED_RECONCILIATION_ITEMS',
  RECONCILIATION_ITEM = 'RECONCILIATION_ITEM',
}

export type RecurrentPaymentsEstimatedTimeline = {
  dateIsShifted: Scalars['Boolean']
  processingEstimates: ProcessingEstimate
  scheduledFor: Scalars['ISO8601Date']
}

export enum RecurrentPaymentsIntervalEnum {
  BIMONTHLY = 'BIMONTHLY',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export type RecurrentPaymentsPagedSchedules = {
  data: Array<RecurrentPaymentsSchedule>
  pagination: Pagination
}

export type RecurrentPaymentsSchedule = {
  actions: RecurrentPaymentsScheduleActions
  endDate: Scalars['ISO8601Date']
  id: Scalars['ID']
  interval: RecurrentPaymentsIntervalEnum
  lastPaymentDate: Maybe<Scalars['ISO8601Date']>
  nextPaymentDate: Maybe<Scalars['ISO8601Date']>
  paidPaymentsCount: Scalars['Int']
  paymentTemplate: RecurrentPaymentsTemplate
  payments: Array<Payment>
  startDate: Scalars['ISO8601Date']
  totalPaymentsCount: Scalars['Int']
}

export type RecurrentPaymentsScheduleActions = {
  changePayFromAccount: Action
  changePayToAccount: Action
  revokePayments: Action
  updateInternationalDetails: Action
}

export type RecurrentPaymentsScheduleInputObject = {
  endDate: Scalars['ISO8601Date']
  interval: RecurrentPaymentsIntervalEnum
  paymentData: RecurrentPaymentsTemplateInputObject
  startDate: Scalars['ISO8601Date']
}

export type RecurrentPaymentsTemplate = {
  accountingAccount: Maybe<AccountingAccount>
  accountingCategoryClass: Maybe<AccountingDictionary>
  accountingMemo: Maybe<Scalars['String']>
  otherReasonNote: Maybe<Scalars['String']>
  payerAccount: Maybe<Account>
  paymentDescription: Maybe<Scalars['String']>
  paymentMethod: PaymentMethodEnum
  purposeCode: Maybe<Scalars['String']>
  reasonCode: Maybe<PaymentReasonEnum>
  vendorAccount: Maybe<LocalBusinessAccount>
  vendorAmount: Money
  vendorLocalBusiness: LocalBusiness
}

export type RecurrentPaymentsTemplateInputObject = {
  accountingDetails?: InputMaybe<PaymentAccountingDetailsInputObject>
  otherReasonNote?: InputMaybe<Scalars['String']>
  payerAccountId: Scalars['ID']
  paymentDescription?: InputMaybe<Scalars['String']>
  paymentMethod: PaymentMethodEnum
  purposeCode?: InputMaybe<Scalars['String']>
  reasonCode?: InputMaybe<PaymentReasonEnum>
  vendorAccountId?: InputMaybe<Scalars['ID']>
  vendorAmount: MoneyInputObject
  vendorLocalBusinessId: Scalars['ID']
}

/** Referral data for new business that was referred */
export type Referral = {
  affiliateName: Maybe<Scalars['String']>
  referralCode: Scalars['String']
  type: ReferralTypeEnum
}

export enum ReferralSourceEnum {
  ACCOUNTANT = 'ACCOUNTANT',
  COMMUNITY_GROUP = 'COMMUNITY_GROUP',
  EVENT = 'EVENT',
  FRIEND = 'FRIEND',
  INVESTORS = 'INVESTORS',
  LINKEDIN = 'LINKEDIN',
  META = 'META',
  NEWS_ARTICLE = 'NEWS_ARTICLE',
  OTHER = 'OTHER',
  OUTREACH = 'OUTREACH',
  PODCAST = 'PODCAST',
  SEARCH_ENGINE = 'SEARCH_ENGINE',
}

export enum ReferralTypeEnum {
  AFFILIATE = 'AFFILIATE',
  REFERRAL = 'REFERRAL',
}

export type RepaymentBalance = {
  currentAccruedInterest: Money
  currentInterestPaid: Money
  currentPrincipalBalance: Money
  daysOfPeriodPaid: Scalars['Int']
  outstandingBalance: Money
  outstandingInterest: Money
  outstandingPrincipal: Money
  paymentId: Scalars['ID']
  periodDays: Scalars['Int']
  postRepaymentInterestPaid: Money
  principalPaid: Money
  repaymentAmount: Money
  repaymentDate: Scalars['ISO8601Date']
}

export type Report = {
  attachedReports: Array<AttachedReport>
  category: CategoryEnum
  createdAt: Scalars['ISO8601DateTime']
  error: Maybe<Scalars['String']>
  id: Scalars['ID']
  status: StatusEnum
}

export type ReportDateFilteringOption = {
  code: Maybe<Scalars['String']>
  from: Maybe<Scalars['ISO8601Date']>
  name: Maybe<Scalars['String']>
  to: Maybe<Scalars['ISO8601Date']>
}

export type ReportFilteringOptions = {
  reportInputDateRanges: Array<ReportDateFilteringOption>
}

export type ReportYear = {
  historical: Scalars['Boolean']
  year: Scalars['Int']
}

export type Reports = {
  data: Array<Report>
  pagination: Pagination
}

export enum ResourceEnum {
  FINICITY_CONNECT = 'FINICITY_CONNECT',
}

export type RoutingNumberValidation = {
  bankName: Maybe<Scalars['String']>
  validationMessage: Maybe<Scalars['String']>
  validationStatus: RoutingNumberValidationStatusEnum
}

export enum RoutingNumberValidationStatusEnum {
  ACH_AND_WIRE = 'ACH_AND_WIRE',
  ACH_ONLY = 'ACH_ONLY',
  INVALID = 'INVALID',
  WIRE_ONLY = 'WIRE_ONLY',
}

export type Rule = {
  accountingRelated: Scalars['Boolean']
  actions: Array<RuleAction>
  conditions: Array<RuleCondition>
  description: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  priority: Scalars['Int']
  status: RuleStatusEnum
  targetType: Maybe<RuleTypeEnum>
}

export type RuleAction = {
  action: RuleActionEnum
  condition: Maybe<RuleActionConditionEnum>
  values: Maybe<Array<Scalars['String']>>
}

export enum RuleActionConditionEnum {
  ALL_OF_THESE_COWORKERS = 'ALL_OF_THESE_COWORKERS',
  ANY_OF_THESE_COWORKERS = 'ANY_OF_THESE_COWORKERS',
}

export enum RuleActionEnum {
  REQUIRE_APPROVAL = 'REQUIRE_APPROVAL',
}

export type RuleActionInputObject = {
  action: RuleActionEnum
  condition?: InputMaybe<RuleActionConditionEnum>
  values?: InputMaybe<Array<Scalars['String']>>
}

export type RuleCondition = {
  attributeName: RuleConditionAttributeNameEnum
  condition: RuleConditionEnum
  value: Maybe<Scalars['String']>
  values: Maybe<Array<RuleConditionValueType>>
}

export enum RuleConditionAttributeNameEnum {
  ACCOUNT = 'ACCOUNT',
  AMOUNT = 'AMOUNT',
  CLASS = 'CLASS',
  DEPARTMENT = 'DEPARTMENT',
  LOCAL_BUSINESS = 'LOCAL_BUSINESS',
  LOCATION = 'LOCATION',
  TRACKING_CATEGORY_ONE = 'TRACKING_CATEGORY_ONE',
  TRACKING_CATEGORY_TWO = 'TRACKING_CATEGORY_TWO',
  USER = 'USER',
}

export enum RuleConditionEnum {
  ANY_IN = 'ANY_IN',
  EQUALS = 'EQUALS',
  IN = 'IN',
  IS_GREATER_THAN = 'IS_GREATER_THAN',
  IS_GREATER_THAN_OR_EQUAL = 'IS_GREATER_THAN_OR_EQUAL',
  IS_LESS_THAN = 'IS_LESS_THAN',
  IS_LESS_THAN_OR_EQUAL = 'IS_LESS_THAN_OR_EQUAL',
  NOT_IN = 'NOT_IN',
}

export type RuleConditionInputObject = {
  attributeName: RuleConditionAttributeNameEnum
  condition: RuleConditionEnum
  value?: InputMaybe<Scalars['String']>
  values?: InputMaybe<Array<Scalars['String']>>
}

export type RuleConditionValueType = AccountingAccount | AccountingCategoryType | LocalBusiness | Profile

export type RuleMetadata = {
  actions: Array<RuleMetadataAction>
  conditions: Array<RuleMetadataCondition>
  defaultAction: RuleActionEnum
  defaultCondition: RuleConditionAttributeNameEnum
  defaultName: Scalars['String']
}

export type RuleMetadataAction = {
  code: RuleActionEnum
  conditionLabel: Maybe<Scalars['String']>
  conditions: Maybe<Array<RuleMetadataActionCondition>>
  defaultCondition: Maybe<RuleActionConditionEnum>
  name: Scalars['String']
  values: Maybe<Array<SelectItem>>
}

export type RuleMetadataActionCondition = {
  code: RuleActionConditionEnum
  name: Scalars['String']
}

export type RuleMetadataCondition = {
  code: RuleConditionAttributeNameEnum
  conditions: Array<RuleMetadataConditionCondition>
  defaultCondition: RuleConditionEnum
  defaultValue: Maybe<Scalars['String']>
  multipleValues: Scalars['Boolean']
  name: Scalars['String']
}

export type RuleMetadataConditionCondition = {
  code: RuleConditionEnum
  name: Scalars['String']
}

export enum RuleStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum RuleTypeEnum {
  INVOICE = 'INVOICE',
  PAYMENT = 'PAYMENT',
  PURCHASEORDER = 'PURCHASEORDER',
}

export enum RulesPermissionsEnum {
  ACTIVATE_RULE = 'ACTIVATE_RULE',
  CREATE_RULE = 'CREATE_RULE',
  DEACTIVATE_RULE = 'DEACTIVATE_RULE',
  DELETE_RULE = 'DELETE_RULE',
  RULES = 'RULES',
  UPDATE_RULE = 'UPDATE_RULE',
}

export type SalesChannelConfiguration = {
  channel: Scalars['String']
  id: Scalars['ID']
  integrationOneType: SalesChannelIntegrationEnum
  integrationTwoType: SalesChannelIntegrationEnum
  state: SalesChannelConfigurationStateEnum
}

export type SalesChannelConfigurationInputObject = {
  id: Scalars['ID']
  state: SalesChannelConfigurationStateEnum
}

export enum SalesChannelConfigurationPermissionsEnum {
  UPDATE_SALES_CHANNEL_CONFIGURATION = 'UPDATE_SALES_CHANNEL_CONFIGURATION',
  VIEW_SALES_CHANNEL_CONFIGURATION = 'VIEW_SALES_CHANNEL_CONFIGURATION',
}

export enum SalesChannelConfigurationStateEnum {
  DO_NOT_MERGE = 'DO_NOT_MERGE',
  MERGE = 'MERGE',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
}

export type SalesChannelConfigurationsFilteringInputObject = {
  state: SalesChannelConfigurationStateEnum
}

export type SalesChannelInputObject = {
  description?: InputMaybe<Scalars['String']>
  name: Scalars['String']
}

export enum SalesChannelIntegrationEnum {
  MANUAL = 'MANUAL',
  SHOPIFY = 'SHOPIFY',
  TRACKSTAR = 'TRACKSTAR',
}

export type SalesDetailType = {
  amount: Maybe<Money>
  dataSource: CatalogItemSalesDataSourceEnum
  id: Scalars['ID']
  sku: Maybe<Scalars['String']>
}

export type SectionFieldsInput = {
  additionalFields?: InputMaybe<Array<SectionFieldsInput>>
  intValue?: InputMaybe<Scalars['Int']>
  key: Scalars['String']
  stringValue?: InputMaybe<Scalars['String']>
}

export type SelectItem = {
  code: Scalars['String']
  name: Scalars['String']
}

export type SettleInboxAttachment = {
  attachment: Attachment
  convertedAt: Maybe<Scalars['ISO8601DateTime']>
  createdDocument: Maybe<CreatedDocument>
  id: Scalars['ID']
  payableAutocreated: Maybe<Scalars['Boolean']>
  possibleInvoiceDuplicates: Array<Invoice>
}

export type SettleInboxAutopayableConfigType = {
  allowlistCount: Scalars['Int']
  allowlistEmails: Maybe<Array<Scalars['String']>>
  id: Scalars['ID']
}

export enum SettleInboxConfigPermissionsEnum {
  UPSERT_SETTLE_INBOX_AUTOPAYABLE_CONFIG = 'UPSERT_SETTLE_INBOX_AUTOPAYABLE_CONFIG',
}

export enum SettleInboxEventTypeEnum {
  CONVERTED_TO_PAYABLE = 'CONVERTED_TO_PAYABLE',
  SWITCH_TO_PRO = 'SWITCH_TO_PRO',
}

export type SettleInboxMessage = {
  archived: Scalars['Boolean']
  attachments: Array<SettleInboxAttachment>
  body: Maybe<Scalars['String']>
  createdInvoices: Array<Invoice>
  id: Scalars['ID']
  read: Scalars['Boolean']
  receivedAt: Scalars['ISO8601DateTime']
  senderEmail: Scalars['String']
  senderName: Maybe<Scalars['String']>
  subject: Maybe<Scalars['String']>
  timelineEvents: Array<SettleInboxTimelineEvent>
}

export type SettleInboxMessages = {
  data: Array<SettleInboxMessage>
  pagination: Pagination
}

export enum SettleInboxPermissionsEnum {
  CREATE_PAYABLE_FROM_SETTLE_INBOX = 'CREATE_PAYABLE_FROM_SETTLE_INBOX',
  CREATE_VENDOR_CREDIT_FROM_SETTLE_INBOX = 'CREATE_VENDOR_CREDIT_FROM_SETTLE_INBOX',
  CREATE_W9_FROM_SETTLE_INBOX = 'CREATE_W9_FROM_SETTLE_INBOX',
  DELETE_SETTLE_INBOX_MESSAGES = 'DELETE_SETTLE_INBOX_MESSAGES',
  READ_SETTLE_INBOX_AUTOPAYABLE_CONFIG = 'READ_SETTLE_INBOX_AUTOPAYABLE_CONFIG',
  UPDATE_SETTLE_INBOX_MESSAGE_ARCHIVED = 'UPDATE_SETTLE_INBOX_MESSAGE_ARCHIVED',
  UPDATE_SETTLE_INBOX_MESSAGE_READ = 'UPDATE_SETTLE_INBOX_MESSAGE_READ',
}

export type SettleInboxTimelineEvent = {
  createdAt: Maybe<Scalars['ISO8601DateTime']>
  displayText: Scalars['String']
  eventType: SettleInboxEventTypeEnum
  icon: IconEnum
  user: Maybe<Approver>
}

export enum ShopifyFeaturePermissionEnum {
  NO_SYNC = 'NO_SYNC',
  READ = 'READ',
}

export enum ShopifyFeatureTypeEnum {
  CATALOG = 'CATALOG',
  ORDERS = 'ORDERS',
}

export type ShopifyIntegrationFeature = {
  featureType: ShopifyFeatureTypeEnum
  id: Scalars['ID']
  locked: Scalars['Boolean']
  permission: ShopifyFeaturePermissionEnum
  title: Scalars['String']
}

export type ShopifyState = {
  connected: Scalars['Boolean']
  domain: Scalars['String']
  features: Array<ShopifyIntegrationFeature>
  id: Scalars['ID']
  lastSyncedAt: Maybe<Scalars['ISO8601DateTime']>
  syncScheduledAt: Maybe<Scalars['ISO8601DateTime']>
  syncStartedAt: Maybe<Scalars['ISO8601DateTime']>
  syncState: ShopifySyncStateEnum
}

export enum ShopifySyncStateEnum {
  NOT_SYNCED = 'NOT_SYNCED',
  READY_TO_SYNC = 'READY_TO_SYNC',
  SYNCED = 'SYNCED',
  SYNC_BLOCKED = 'SYNC_BLOCKED',
  SYNC_FAILED = 'SYNC_FAILED',
  SYNC_IN_PROGRESS = 'SYNC_IN_PROGRESS',
}

export type SignIn = {
  active: Scalars['Boolean']
  createdAt: Maybe<Scalars['ISO8601DateTime']>
  current: Scalars['Boolean']
  ip: Scalars['String']
  jwtId: Scalars['String']
  revoked: Scalars['Boolean']
  revokedAt: Maybe<Scalars['ISO8601DateTime']>
}

export type SignedDocument = {
  byteSize: Scalars['Int']
  contentType: Scalars['String']
  filename: Scalars['String']
  id: Scalars['ID']
  type: Maybe<Scalars['String']>
  url: Scalars['String']
}

export enum SignedDocumentTypesEnum {
  AMORTIZED_LOAN_AGREEMENT = 'AMORTIZED_LOAN_AGREEMENT',
  BULLET_EPT_AGREEMENT = 'BULLET_EPT_AGREEMENT',
  EXTENDED_PAYMENT_TERM_AGREEMENT = 'EXTENDED_PAYMENT_TERM_AGREEMENT',
  FACTORING_AGREEMENT = 'FACTORING_AGREEMENT',
  FACTORING_AGREEMENT_V2 = 'FACTORING_AGREEMENT_V2',
  PAYEE_AGENT_DISCLOSURE = 'PAYEE_AGENT_DISCLOSURE',
}

export enum SignupInterestsEnum {
  BILL_PAY = 'BILL_PAY',
  PURCHASING = 'PURCHASING',
  WORKING_CAPITAL = 'WORKING_CAPITAL',
}

export type StandalonePaymentInputObject = {
  accountId: Scalars['ID']
  accountingDetails?: InputMaybe<PaymentAccountingDetailsInputObject>
  amount: MoneyInputObject
  localBusinessId: Scalars['ID']
  orderDocumentId?: InputMaybe<Scalars['ID']>
  otherReasonNote?: InputMaybe<Scalars['String']>
  paymentDescription?: InputMaybe<Scalars['String']>
  paymentMethod: PaymentMethodEnum
  processDate: Scalars['ISO8601Date']
  purchaseOrderId?: InputMaybe<Scalars['ID']>
  purposeCode?: InputMaybe<Scalars['String']>
  reasonCode?: InputMaybe<PaymentReasonEnum>
  receivingAccountId?: InputMaybe<Scalars['ID']>
}

export enum StatusEnum {
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  STARTED = 'STARTED',
  WAITING = 'WAITING',
}

export enum SuggestedVendorAccountTypeEnum {
  EMAIL = 'EMAIL',
  OCR = 'OCR',
}

export type SyncError = {
  date: Maybe<Scalars['ISO8601Date']>
  errorMessage: Maybe<Scalars['String']>
  fixClaimed: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['ID']>
}

export type SyncErrorSummaries = {
  data: Array<SyncErrorSummary>
  pagination: Pagination
}

export type SyncErrorSummary = {
  count: Scalars['Int']
  syncErrorMessage: Scalars['String']
  syncObjectType: SyncObjectTypeEnum
}

export type SyncEvent = {
  displaySubtext: Maybe<Scalars['String']>
  displayText: Scalars['String']
  eventType: SyncEventTypeEnum
  icon: SyncEventIconEnum
  id: Scalars['ID']
  occurredAt: Scalars['ISO8601DateTime']
  syncObject: Maybe<SyncObject>
  syncObjectType: Maybe<SyncObjectTypeEnum>
}

export enum SyncEventIconEnum {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
  ERRORED = 'ERRORED',
  SYNCED = 'SYNCED',
}

export enum SyncEventTypeEnum {
  CREATED_EXTERNALLY = 'CREATED_EXTERNALLY',
  DELETED_EXTERNALLY = 'DELETED_EXTERNALLY',
  DELETED_LOCALLY = 'DELETED_LOCALLY',
  EXCLUDED_FROM_SYNC = 'EXCLUDED_FROM_SYNC',
  FIX_CLAIMED = 'FIX_CLAIMED',
  FIX_UNCLAIMED = 'FIX_UNCLAIMED',
  INCLUDED_IN_SYNC = 'INCLUDED_IN_SYNC',
  JOURNAL_ENTRY_EXPORTED = 'JOURNAL_ENTRY_EXPORTED',
  MARKED_AS_PAID = 'MARKED_AS_PAID',
  PAYMENT_DELETED = 'PAYMENT_DELETED',
  PAYMENT_EXPORTED = 'PAYMENT_EXPORTED',
  PAYMENT_REVERSAL_EXPORTED = 'PAYMENT_REVERSAL_EXPORTED',
  SYNC_ERRORED = 'SYNC_ERRORED',
  SYNC_INITIATED = 'SYNC_INITIATED',
  UPDATED_EXTERNALLY = 'UPDATED_EXTERNALLY',
  UPDATED_LOCALLY = 'UPDATED_LOCALLY',
}

export type SyncEvents = {
  data: Array<SyncEvent>
  pagination: Pagination
}

export type SyncObject = CatalogItem | Invoice | Payment | PurchaseOrder | VendorCredit

export type SyncObjectStatus = {
  excludedFromSync: Scalars['Boolean']
  objectSyncStatusEnum: ObjectSyncStatusEnum
  syncDisabledBy: Maybe<Scalars['String']>
}

export enum SyncObjectTypeEnum {
  CATALOG_ITEM = 'CATALOG_ITEM',
  INVOICE = 'INVOICE',
  PAYMENT = 'PAYMENT',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  VENDOR_CREDIT = 'VENDOR_CREDIT',
}

export type SyncObjects = {
  data: Array<SyncObject>
  pagination: Pagination
}

export enum SyncStateEnum {
  NOT_SYNCED = 'NOT_SYNCED',
  READY_TO_SYNC = 'READY_TO_SYNC',
  SYNCED = 'SYNCED',
  SYNCED_WITH_ERRORS = 'SYNCED_WITH_ERRORS',
  SYNC_IN_PROGRESS = 'SYNC_IN_PROGRESS',
}

export type TeamMember = {
  clients: Maybe<Array<UserClient>>
  deactivated: Scalars['Boolean']
  deletable: Scalars['Boolean']
  email: Scalars['String']
  firstName: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName: Maybe<Scalars['String']>
  membership: Membership
  name: Maybe<Scalars['String']>
  reactivateable: Scalars['Boolean']
  totp: Maybe<Totp>
}

export enum TeamMemberStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type TeamMembers = {
  invitations: Array<Invitation>
  users: Array<TeamMember>
}

export enum TeamPermissionsEnum {
  ACTIVATE_TEAM_MEMBER = 'ACTIVATE_TEAM_MEMBER',
  CUSTOM_ROLES = 'CUSTOM_ROLES',
  DELETE_TEAM_MEMBER = 'DELETE_TEAM_MEMBER',
  INVITE_TEAM_MEMBER = 'INVITE_TEAM_MEMBER',
  REQUIRE_MFA = 'REQUIRE_MFA',
  REVOKE_INVITATION = 'REVOKE_INVITATION',
  SET_OWN_ROLE = 'SET_OWN_ROLE',
  SET_TEAM_MEMBER_ROLE = 'SET_TEAM_MEMBER_ROLE',
  TEAM_MEMBERS = 'TEAM_MEMBERS',
}

export type TestingMicroDeposits = {
  amount1: Maybe<Scalars['Int']>
  amount2: Maybe<Scalars['Int']>
}

export type TestingTokens = {
  emailVerificationToken: Maybe<Scalars['String']>
  lastLocalBusinessInvitationToken: Maybe<Scalars['String']>
  lastPaymentConfirmationToken: Maybe<Scalars['String']>
  lastTeamInvitationToken: Maybe<Scalars['String']>
  lastVendorInvitationToken: Maybe<Scalars['String']>
  passwordResetToken: Maybe<Scalars['String']>
}

export type TimelineEvent = TimelineEventInterface & {
  createdAt: Maybe<Scalars['ISO8601DateTime']>
  displayText: Scalars['String']
  eventType: TimelineEventTypeEnum
  icon: IconEnum
  invoiceEventType: InvoiceEventTypeEnum
  user: Maybe<Approver>
}

export type TimelineEventInterface = {
  createdAt: Maybe<Scalars['ISO8601DateTime']>
  displayText: Scalars['String']
  icon: IconEnum
  user: Maybe<Approver>
}

export enum TimelineEventTypeEnum {
  APPLIED_VENDOR_CREDITS_CLOSED = 'APPLIED_VENDOR_CREDITS_CLOSED',
  APPLIED_VENDOR_CREDITS_UPDATED = 'APPLIED_VENDOR_CREDITS_UPDATED',
  COLLECTION_EMAIL_SENT = 'COLLECTION_EMAIL_SENT',
  EPT_AGREEMENT_SIGNED = 'EPT_AGREEMENT_SIGNED',
  EPT_TERMS_CHANGED = 'EPT_TERMS_CHANGED',
  INVOICE_APPROVAL_CANCELLED = 'INVOICE_APPROVAL_CANCELLED',
  INVOICE_APPROVAL_CANCELLED_PLAN_CHANGE = 'INVOICE_APPROVAL_CANCELLED_PLAN_CHANGE',
  INVOICE_APPROVAL_REQUESTED = 'INVOICE_APPROVAL_REQUESTED',
  INVOICE_APPROVED = 'INVOICE_APPROVED',
  INVOICE_ARCHIVED = 'INVOICE_ARCHIVED',
  INVOICE_CREATED_BY_EMAIL = 'INVOICE_CREATED_BY_EMAIL',
  INVOICE_CREATED_BY_IMPORT = 'INVOICE_CREATED_BY_IMPORT',
  INVOICE_CREATED_BY_USER = 'INVOICE_CREATED_BY_USER',
  INVOICE_CREATED_FROM_SETTLE_INBOX = 'INVOICE_CREATED_FROM_SETTLE_INBOX',
  INVOICE_MARKED_AS_PAID = 'INVOICE_MARKED_AS_PAID',
  INVOICE_MARKED_AS_UNPAID = 'INVOICE_MARKED_AS_UNPAID',
  INVOICE_RECEIVED_IN_SETTLE_INBOX = 'INVOICE_RECEIVED_IN_SETTLE_INBOX',
  INVOICE_REJECTED = 'INVOICE_REJECTED',
  INVOICE_SENT = 'INVOICE_SENT',
  INVOICE_VIEWED_BY_PAYER = 'INVOICE_VIEWED_BY_PAYER',
  PAYMENT_APPROVAL_CANCELLED = 'PAYMENT_APPROVAL_CANCELLED',
  PAYMENT_APPROVAL_CANCELLED_PLAN_CHANGE = 'PAYMENT_APPROVAL_CANCELLED_PLAN_CHANGE',
  PAYMENT_APPROVAL_REQUESTED = 'PAYMENT_APPROVAL_REQUESTED',
  PAYMENT_APPROVED = 'PAYMENT_APPROVED',
  PAYMENT_CANCELLED = 'PAYMENT_CANCELLED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_FROM_PAYER_PAID = 'PAYMENT_FROM_PAYER_PAID',
  PAYMENT_FROM_PAYER_PROCESSED = 'PAYMENT_FROM_PAYER_PROCESSED',
  PAYMENT_FULLY_REVERSED = 'PAYMENT_FULLY_REVERSED',
  PAYMENT_ON_HOLD = 'PAYMENT_ON_HOLD',
  PAYMENT_PARTIALLY_REPAID_EARLY = 'PAYMENT_PARTIALLY_REPAID_EARLY',
  PAYMENT_REFUND_COMPLETED = 'PAYMENT_REFUND_COMPLETED',
  PAYMENT_REFUND_INITIATED = 'PAYMENT_REFUND_INITIATED',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
  PAYMENT_RELEASED = 'PAYMENT_RELEASED',
  PAYMENT_REPAID_EARLY = 'PAYMENT_REPAID_EARLY',
  PAYMENT_RESCHEDULED = 'PAYMENT_RESCHEDULED',
  PAYMENT_RETRIED = 'PAYMENT_RETRIED',
  PAYMENT_SCHEDULED = 'PAYMENT_SCHEDULED',
  PAYMENT_TO_VENDOR_PAID = 'PAYMENT_TO_VENDOR_PAID',
  PAYMENT_TO_VENDOR_PAPER_CHECK_CASHED = 'PAYMENT_TO_VENDOR_PAPER_CHECK_CASHED',
  PAYMENT_TO_VENDOR_PROCESSED = 'PAYMENT_TO_VENDOR_PROCESSED',
  RECEIVABLE_PURCHASED = 'RECEIVABLE_PURCHASED',
  RECEIVABLE_PURCHASE_CANCELED = 'RECEIVABLE_PURCHASE_CANCELED',
  SWITCH_TO_PRO = 'SWITCH_TO_PRO',
  VENDOR_CREDIT_ADDED = 'VENDOR_CREDIT_ADDED',
  VENDOR_CREDIT_APPLIED = 'VENDOR_CREDIT_APPLIED',
  VENDOR_CREDIT_CLOSED = 'VENDOR_CREDIT_CLOSED',
  VENDOR_CREDIT_CREATED = 'VENDOR_CREDIT_CREATED',
  VENDOR_CREDIT_DELETED = 'VENDOR_CREDIT_DELETED',
  VENDOR_CREDIT_UNAPPLIED = 'VENDOR_CREDIT_UNAPPLIED',
  W8_OR_W9_EMAIL_REQUEST_SENT = 'W8_OR_W9_EMAIL_REQUEST_SENT',
  W8_OR_W9_SUBMITTED = 'W8_OR_W9_SUBMITTED',
}

export enum TimelineEventTypeEnumV2 {
  APPLIED_VENDOR_CREDITS_CLOSED = 'APPLIED_VENDOR_CREDITS_CLOSED',
  APPLIED_VENDOR_CREDITS_UPDATED = 'APPLIED_VENDOR_CREDITS_UPDATED',
  COLLECTION_EMAIL_SENT = 'COLLECTION_EMAIL_SENT',
  CONVERTED_TO_PAYABLE = 'CONVERTED_TO_PAYABLE',
  EPT_AGREEMENT_SIGNED = 'EPT_AGREEMENT_SIGNED',
  EPT_TERMS_CHANGED = 'EPT_TERMS_CHANGED',
  INVOICE_APPROVAL_CANCELLED = 'INVOICE_APPROVAL_CANCELLED',
  INVOICE_APPROVAL_CANCELLED_PLAN_CHANGE = 'INVOICE_APPROVAL_CANCELLED_PLAN_CHANGE',
  INVOICE_APPROVAL_REQUESTED = 'INVOICE_APPROVAL_REQUESTED',
  INVOICE_APPROVED = 'INVOICE_APPROVED',
  INVOICE_ARCHIVED = 'INVOICE_ARCHIVED',
  INVOICE_CREATED_BY_EMAIL = 'INVOICE_CREATED_BY_EMAIL',
  INVOICE_CREATED_BY_IMPORT = 'INVOICE_CREATED_BY_IMPORT',
  INVOICE_CREATED_BY_USER = 'INVOICE_CREATED_BY_USER',
  INVOICE_CREATED_FROM_SETTLE_INBOX = 'INVOICE_CREATED_FROM_SETTLE_INBOX',
  INVOICE_MARKED_AS_PAID = 'INVOICE_MARKED_AS_PAID',
  INVOICE_MARKED_AS_UNPAID = 'INVOICE_MARKED_AS_UNPAID',
  INVOICE_RECEIVED_IN_SETTLE_INBOX = 'INVOICE_RECEIVED_IN_SETTLE_INBOX',
  INVOICE_REJECTED = 'INVOICE_REJECTED',
  INVOICE_SENT = 'INVOICE_SENT',
  INVOICE_VIEWED_BY_PAYER = 'INVOICE_VIEWED_BY_PAYER',
  OUTBOUND_ORDER_CREATED = 'OUTBOUND_ORDER_CREATED',
  OUTBOUND_ORDER_FULFILLED = 'OUTBOUND_ORDER_FULFILLED',
  OUTBOUND_ORDER_PAYMENT_CAPTURED = 'OUTBOUND_ORDER_PAYMENT_CAPTURED',
  OUTBOUND_ORDER_SHIPPED = 'OUTBOUND_ORDER_SHIPPED',
  PAYMENT_APPROVAL_CANCELLED = 'PAYMENT_APPROVAL_CANCELLED',
  PAYMENT_APPROVAL_CANCELLED_PLAN_CHANGE = 'PAYMENT_APPROVAL_CANCELLED_PLAN_CHANGE',
  PAYMENT_APPROVAL_REQUESTED = 'PAYMENT_APPROVAL_REQUESTED',
  PAYMENT_APPROVED = 'PAYMENT_APPROVED',
  PAYMENT_CANCELLED = 'PAYMENT_CANCELLED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_FROM_PAYER_PAID = 'PAYMENT_FROM_PAYER_PAID',
  PAYMENT_FROM_PAYER_PROCESSED = 'PAYMENT_FROM_PAYER_PROCESSED',
  PAYMENT_FULLY_REVERSED = 'PAYMENT_FULLY_REVERSED',
  PAYMENT_ON_HOLD = 'PAYMENT_ON_HOLD',
  PAYMENT_PARTIALLY_REPAID_EARLY = 'PAYMENT_PARTIALLY_REPAID_EARLY',
  PAYMENT_REFUND_COMPLETED = 'PAYMENT_REFUND_COMPLETED',
  PAYMENT_REFUND_INITIATED = 'PAYMENT_REFUND_INITIATED',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
  PAYMENT_RELEASED = 'PAYMENT_RELEASED',
  PAYMENT_REPAID_EARLY = 'PAYMENT_REPAID_EARLY',
  PAYMENT_RESCHEDULED = 'PAYMENT_RESCHEDULED',
  PAYMENT_RETRIED = 'PAYMENT_RETRIED',
  PAYMENT_SCHEDULED = 'PAYMENT_SCHEDULED',
  PAYMENT_TO_VENDOR_PAID = 'PAYMENT_TO_VENDOR_PAID',
  PAYMENT_TO_VENDOR_PAPER_CHECK_CASHED = 'PAYMENT_TO_VENDOR_PAPER_CHECK_CASHED',
  PAYMENT_TO_VENDOR_PROCESSED = 'PAYMENT_TO_VENDOR_PROCESSED',
  PO_ADDED = 'PO_ADDED',
  PO_ADDED_AUTOMATICALLY = 'PO_ADDED_AUTOMATICALLY',
  PO_APPROVAL_CANCELLED = 'PO_APPROVAL_CANCELLED',
  PO_APPROVAL_CANCELLED_PLAN_CHANGE = 'PO_APPROVAL_CANCELLED_PLAN_CHANGE',
  PO_APPROVAL_REQUESTED = 'PO_APPROVAL_REQUESTED',
  PO_APPROVED = 'PO_APPROVED',
  PO_CANCELED = 'PO_CANCELED',
  PO_CLOSED = 'PO_CLOSED',
  PO_CREATED = 'PO_CREATED',
  PO_OPENED = 'PO_OPENED',
  PO_REJECTED = 'PO_REJECTED',
  PO_SENT_TO_VENDOR = 'PO_SENT_TO_VENDOR',
  RECEIVABLE_PURCHASED = 'RECEIVABLE_PURCHASED',
  RECEIVABLE_PURCHASE_CANCELED = 'RECEIVABLE_PURCHASE_CANCELED',
  SWITCH_TO_PRO = 'SWITCH_TO_PRO',
  VENDOR_CREDIT_ADDED = 'VENDOR_CREDIT_ADDED',
  VENDOR_CREDIT_APPLIED = 'VENDOR_CREDIT_APPLIED',
  VENDOR_CREDIT_CLOSED = 'VENDOR_CREDIT_CLOSED',
  VENDOR_CREDIT_CREATED = 'VENDOR_CREDIT_CREATED',
  VENDOR_CREDIT_DELETED = 'VENDOR_CREDIT_DELETED',
  VENDOR_CREDIT_UNAPPLIED = 'VENDOR_CREDIT_UNAPPLIED',
  W8_OR_W9_EMAIL_REQUEST_SENT = 'W8_OR_W9_EMAIL_REQUEST_SENT',
  W8_OR_W9_SUBMITTED = 'W8_OR_W9_SUBMITTED',
}

export type TimelineEventV2 = TimelineEventInterface & {
  createdAt: Maybe<Scalars['ISO8601DateTime']>
  displayText: Scalars['String']
  eventType: TimelineEventTypeEnumV2
  icon: IconEnum
  user: Maybe<Approver>
}

export type TotalDebits = {
  cashAdvanceCreditsToBusinessAccounts: Array<Money>
  creditsToBusinessAccounts: Array<Money>
  debitsFromBusinessAccounts: Array<Money>
  debitsFromSettleAccounts: Array<Money>
  paymentsToVendorAccounts: Array<Money>
  repaymentsToSettle: Array<Money>
}

export type TotalDemandForecastData = {
  endDate: Scalars['ISO8601Date']
  quantity: Scalars['Float']
  startDate: Scalars['ISO8601Date']
}

export type Totp = {
  activatedAt: Scalars['ISO8601DateTime']
}

export type TotpSetupInfo = {
  qrCodeUri: Scalars['String']
  recoveryCodes: Array<Scalars['String']>
  secret: Scalars['String']
}

export enum TrackstarFeaturePermissionEnum {
  NO_SYNC = 'NO_SYNC',
  READ = 'READ',
  READ_WRITE = 'READ_WRITE',
}

export enum TrackstarFeatureTypeEnum {
  CATALOG = 'CATALOG',
  GRNS = 'GRNS',
  ORDERS = 'ORDERS',
}

export type TrackstarIntegration = {
  catalogItemsLastSyncedAt: Maybe<Scalars['ISO8601DateTime']>
  connectedAt: Maybe<Scalars['ISO8601DateTime']>
  connectionId: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  features: Array<TrackstarIntegrationFeature>
  id: Scalars['ID']
  integrationName: Scalars['String']
  inventoryLastSyncedAt: Maybe<Scalars['ISO8601DateTime']>
  lastSyncedAt: Maybe<Scalars['ISO8601DateTime']>
  locationsLastSyncedAt: Maybe<Scalars['ISO8601DateTime']>
  syncErrorMessage: Maybe<Scalars['String']>
  syncScheduledAt: Maybe<Scalars['ISO8601DateTime']>
  syncStartedAt: Maybe<Scalars['ISO8601DateTime']>
  syncState: TrackstarIntegrationSyncStateEnum
}

export type TrackstarIntegrationFeature = {
  featureType: TrackstarFeatureTypeEnum
  id: Scalars['ID']
  locked: Scalars['Boolean']
  permission: TrackstarFeaturePermissionEnum
  title: Scalars['String']
}

export enum TrackstarIntegrationSyncStateEnum {
  NOT_SYNCED = 'NOT_SYNCED',
  SYNCED = 'SYNCED',
  SYNC_FAILED = 'SYNC_FAILED',
  SYNC_IN_PROGRESS = 'SYNC_IN_PROGRESS',
  SYNC_SCHEDULED = 'SYNC_SCHEDULED',
}

export type TransactionPaginated = {
  data: Array<MarketingVirtualCardTransaction>
  pagination: Pagination
}

export type TransactionalTodoListSummary = {
  count: Scalars['Int']
  reason: DashboardActionableItemReasonEnum
}

export type Transfer = {
  /** @deprecated use field from Allocation, will be removed during deprecating Transfer entity */
  actions: TransferActions
  /**
   * Temporary field to support the transition to Allocation entity
   * @deprecated will be removed during deprecating Transfer entity
   */
  allocation: Maybe<Allocation>
  /** @deprecated use field from Allocation, will be removed during deprecating Transfer entity */
  amount: Maybe<Money>
  /** @deprecated use field from Allocation, will be removed during deprecating Transfer entity */
  exchangeFeeAmount: Maybe<Money>
  /** @deprecated use field from Allocation, will be removed during deprecating Transfer entity */
  exchangeRate: Maybe<Scalars['String']>
  /** @deprecated use field from Allocation, will be removed during deprecating Transfer entity */
  financingOptions: FinancingOptions
  /** @deprecated use field from Allocation, will be removed during deprecating Transfer entity */
  id: Scalars['ID']
  /** @deprecated use field from Allocation, will be removed during deprecating Transfer entity */
  includedInConsolidatedPayment: Scalars['Boolean']
  /** @deprecated use field from Allocation, will be removed during deprecating Transfer entity */
  invoice: Maybe<Invoice>
  /** @deprecated Use Payment.isCashAdvance, will be removed during deprecating Transfer entity */
  isCashAdvance: Scalars['Boolean']
  /** @deprecated use field from Allocation, will be removed during deprecating Transfer entity */
  isExtendedPaymentTerm: Scalars['Boolean']
  /**
   * Returns true when the transfer matches applied filter. Eg. when splitted invoice has 2 transfers: PENDING and SCHEDULED, and filter by PENDING status applied, PENDING transfer will have the field value 'true' and SCHEDULED transfer - 'false'.
   * @deprecated use field from Allocation, will be removed during deprecating Transfer entity
   */
  matchesFilter: Scalars['Boolean']
  /** @deprecated use field from Allocation, will be removed during deprecating Transfer entity */
  payment: Maybe<Payment>
  /**
   * Error code for Inactive Payment
   * @deprecated use field from Allocation, will be removed during deprecating Transfer entity
   */
  paymentErrorCode: Maybe<Scalars['String']>
  /**
   * Error message for Inactive Payment
   * @deprecated use field from Allocation, will be removed during deprecating Transfer entity
   */
  paymentErrorMessage: Maybe<Scalars['String']>
  /** @deprecated use field from Allocation, will be removed during deprecating Transfer entity */
  reconciliationItems: Array<ReconciliationItem>
  /** @deprecated use field from Allocation, will be removed during deprecating Transfer entity */
  status: InvoiceStatusEnum
  /** @deprecated use field from Allocation, will be removed during deprecating Transfer entity */
  statuses: Array<InvoiceStatusEnum>
  /** @deprecated use field from Allocation, will be removed during deprecating Transfer entity */
  totalUsdAmount: Maybe<Money>
  /** @deprecated use field from Allocation, will be removed during deprecating Transfer entity */
  usdAmount: Maybe<Money>
}

export type TransferActionableItem = {
  allocation: Maybe<Allocation>
  reason: DashboardActionableItemReasonEnum
  transfer: Transfer
}

export type TransferActions = {
  retryPayment: Action
}

export type TransferDetails = {
  achTraceNumber: Maybe<Scalars['String']>
  amount: Money
  checkNumber: Maybe<Scalars['String']>
  confirmationNumber: Maybe<Scalars['String']>
  earlyRepayment: Scalars['Boolean']
  /** @deprecated Use payment.etaToVendor or payment.etaFromPayer instead */
  estimatedArrivalDate: Scalars['ISO8601Date']
  memo: Maybe<Scalars['String']>
  paidFrom: Maybe<Scalars['String']>
  paidTo: Maybe<Scalars['String']>
  paymentMethod: Maybe<PaymentMethodEnum>
  processedAt: Maybe<Scalars['ISO8601DateTime']>
  status: PaymentStatusEnum
  swiftUetrNumber: Maybe<Scalars['String']>
  userInitiated: Scalars['Boolean']
}

export type UnimportedPurchaseOrder = {
  amount: Maybe<Money>
  id: Scalars['ID']
  issueDate: Maybe<Scalars['ISO8601Date']>
  number: Maybe<Scalars['String']>
  vendorName: Maybe<Scalars['String']>
}

export type UnimportedPurchaseOrders = {
  data: Maybe<Array<UnimportedPurchaseOrder>>
  pagination: Maybe<Pagination>
}

export type UnmatchedLine = {
  invoiceLine: Maybe<LineItemDetails>
  purchaseOrderLine: Maybe<LineItemDetails>
  receiptLine: Maybe<LineItemDetails>
}

export type UnpaidBill = {
  amount: Maybe<Money>
  balance: Maybe<Money>
  dueDate: Maybe<Scalars['ISO8601Date']>
  id: Scalars['ID']
  issueDate: Maybe<Scalars['ISO8601Date']>
  number: Maybe<Scalars['String']>
  url: Maybe<Scalars['String']>
  vendorName: Maybe<Scalars['String']>
}

export type UnpaidBillsPaginated = {
  data: Maybe<Array<UnpaidBill>>
  pagination: Maybe<Pagination>
}

export type UploadedDocument = {
  documentRequest: Maybe<Scalars['ID']>
  documents: Maybe<Array<Scalars['ID']>>
}

export type UserActions = {
  addBusiness: Action
  viewAccountantDashboard: Action
}

export type UserClient = {
  business: PublicBusiness
  /** @deprecated Use user_role instead */
  role: MembershipRolesEnum
  user: TeamMember
  userRole: UserRole
}

export type UserRole = {
  available: Scalars['Boolean']
  business: Maybe<Business>
  clientManager: Scalars['Boolean']
  deleted: Scalars['Boolean']
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  permissionSets: Array<PermissionSet>
  roleType: MembershipRolesEnum
}

export enum UserRoleGroupEnum {
  BUSINESS = 'BUSINESS',
  CLIENT_MANAGER = 'CLIENT_MANAGER',
}

export enum UserTraitsEnum {
  APPROVER = 'APPROVER',
  CLERK = 'CLERK',
  CLIENT_MANAGER_ADMIN = 'CLIENT_MANAGER_ADMIN',
  CLIENT_MANAGER_TEAM_MEMBER = 'CLIENT_MANAGER_TEAM_MEMBER',
  MULTI_BUSINESS = 'MULTI_BUSINESS',
  PROCUREMENT = 'PROCUREMENT',
  REGULAR_MEMBER = 'REGULAR_MEMBER',
  SIGN_IN_LOCKED = 'SIGN_IN_LOCKED',
  VIEWER = 'VIEWER',
  WITH_MFA = 'WITH_MFA',
}

/** Validates referral codes passed to sign up flow */
export type ValidateReferralCode = {
  promoCode: Maybe<BillingPromotionCodeType>
  referral: Maybe<Referral>
  utms: Maybe<LongLinkUtms>
  valid: Scalars['Boolean']
}

export type ValidationResult = {
  message: Maybe<Scalars['String']>
  valid: Scalars['Boolean']
}

export enum VendorAccountNumberTypeEnum {
  ACH = 'ACH',
  CLABE = 'CLABE',
  IBAN = 'IBAN',
  OTHER = 'OTHER',
}

export type VendorAmount = {
  amount: Money
  vendor: LocalBusiness
}

export type VendorCredit = {
  accountingCategoryClassId: Maybe<Scalars['ID']>
  accountingCategoryDepartmentId: Maybe<Scalars['ID']>
  accountingCategoryLocationId: Maybe<Scalars['ID']>
  accountingSyncEvents: Maybe<Array<SyncEvent>>
  accountingUrl: Maybe<Scalars['String']>
  actions: VendorCreditActions
  amount: Maybe<Money>
  amountRemaining: Maybe<Money>
  amountUsed: Maybe<Money>
  associatedInvoice: Maybe<Invoice>
  attachment: Maybe<Attachment>
  createdAt: Scalars['ISO8601DateTime']
  /** @deprecated Use sync_object_status instead */
  excludedFromSync: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  issueDate: Maybe<Scalars['ISO8601Date']>
  lineItem: VendorCreditLineItem
  number: Maybe<Scalars['String']>
  quickbooksLocationId: Maybe<Scalars['ID']>
  showSyncHistory: Maybe<Scalars['Boolean']>
  status: VendorCreditStatusEnum
  syncError: Maybe<SyncError>
  syncObjectStatus: Maybe<SyncObjectStatus>
  vendorCreditApplications: Array<VendorCreditApplication>
  vendorLocalBusiness: Maybe<LocalBusiness>
}

export type VendorCreditActions = {
  add: Action
  delete: Action
}

export type VendorCreditApplication = {
  amount: Maybe<Money>
  appliedBy: Maybe<Approver>
  appliedOn: Scalars['ISO8601DateTime']
  closed: Scalars['Boolean']
  closedAt: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  invoice: Maybe<Invoice>
  scheduledFor: Maybe<Scalars['ISO8601Date']>
  vendorCredit: VendorCredit
}

export type VendorCreditApplicationInputObject = {
  amountCents: Scalars['SafeInt']
  amountCurrency: Scalars['String']
  vendorCreditId: Scalars['ID']
}

export type VendorCreditInputObject = {
  accountingAccountId?: InputMaybe<Scalars['ID']>
  accountingCategoryClassId?: InputMaybe<Scalars['ID']>
  accountingCategoryDepartmentId?: InputMaybe<Scalars['ID']>
  accountingCategoryLocationId?: InputMaybe<Scalars['ID']>
  accountingCategoryOneId?: InputMaybe<Scalars['ID']>
  accountingCategoryTwoId?: InputMaybe<Scalars['ID']>
  amountCents?: InputMaybe<Scalars['SafeInt']>
  amountCurrency?: InputMaybe<Scalars['String']>
  associatedInvoiceId?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  issueDate?: InputMaybe<Scalars['ISO8601Date']>
  number?: InputMaybe<Scalars['String']>
  quickBooksAccountId?: InputMaybe<Scalars['ID']>
  quickBooksClassId?: InputMaybe<Scalars['ID']>
  quickBooksLocationId?: InputMaybe<Scalars['ID']>
  trackingCategoryOneId?: InputMaybe<Scalars['ID']>
  trackingCategoryTwoId?: InputMaybe<Scalars['ID']>
  vendorLocalBusinessId?: InputMaybe<Scalars['ID']>
  xeroAccountId?: InputMaybe<Scalars['ID']>
}

export type VendorCreditLineItem = {
  accountingAccount: Maybe<AccountingAccount>
  accountingAccountId: Maybe<Scalars['ID']>
  accountingCategoryClassId: Maybe<Scalars['ID']>
  accountingCategoryDepartmentId: Maybe<Scalars['ID']>
  accountingCategoryLocationId: Maybe<Scalars['ID']>
  accountingCategoryOneId: Maybe<Scalars['ID']>
  accountingCategoryTwoId: Maybe<Scalars['ID']>
  amount: Money
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  quantity: Maybe<Scalars['Float']>
  quickbooksCategory: Maybe<AccountingAccount>
  quickbooksCategoryId: Maybe<Scalars['ID']>
  quickbooksClass: Maybe<QuickBooksDictionary>
  quickbooksClassId: Maybe<Scalars['ID']>
  trackingCategoryOne: Maybe<AccountingDictionary>
  trackingCategoryOneId: Maybe<Scalars['ID']>
  trackingCategoryTwo: Maybe<AccountingDictionary>
  trackingCategoryTwoId: Maybe<Scalars['ID']>
  unitAmount: Maybe<Money>
  xeroCategory: Maybe<AccountingAccount>
  xeroCategoryId: Maybe<Scalars['String']>
}

export enum VendorCreditStatusEnum {
  APPLIED = 'APPLIED',
  CLOSED = 'CLOSED',
  DRAFT = 'DRAFT',
  PARTIALLY_APPLIED = 'PARTIALLY_APPLIED',
  UNAPPLIED = 'UNAPPLIED',
}

export type VendorCredits = {
  data: Array<VendorCredit>
  pagination: Pagination
}

export enum VendorCreditsPermissionsEnum {
  ADD_VENDOR_CREDIT = 'ADD_VENDOR_CREDIT',
  APPLY_VENDOR_CREDIT = 'APPLY_VENDOR_CREDIT',
  CREATE_VENDOR_CREDIT = 'CREATE_VENDOR_CREDIT',
  DELETE_VENDOR_CREDIT = 'DELETE_VENDOR_CREDIT',
  UNAPPLY_VENDOR_CREDIT = 'UNAPPLY_VENDOR_CREDIT',
  UPDATE_VENDOR_CREDIT = 'UPDATE_VENDOR_CREDIT',
  VENDOR_CREDIT = 'VENDOR_CREDIT',
}

export type VendorFinancingConfirmation = {
  agreementDocument: SignedDocument
  feeAmount: Money
  feePercent: Scalars['Float']
  id: Scalars['ID']
  originalInvoiceAmount: Money
  payment: ReceivablePayment
}

export type VendorFinancingOffer = {
  feeAmount: Money
  feePercent: Scalars['Float']
  invoiceId: Scalars['ID']
  vendorAmount: Money
  vendorPaymentDate: Scalars['ISO8601Date']
  vendorPaymentEta: Maybe<Array<Scalars['ISO8601Date']>>
}

export type VendorInvitation = {
  address: Maybe<Address>
  businessName: Scalars['String']
  email: Maybe<Scalars['String']>
  hasSettleUser: Scalars['Boolean']
  invitedBy: Maybe<VendorInviter>
  name: Maybe<Scalars['String']>
  receivableInvoice: Maybe<ReceivableInvoice>
  senderName: Maybe<Scalars['String']>
  status: Maybe<VendorInvitationStatusEnum>
  vendorHasBusiness: Scalars['Boolean']
}

export enum VendorInvitationStatusEnum {
  ACCEPTED = 'ACCEPTED',
  REVOKED = 'REVOKED',
  SENT = 'SENT',
}

export type VendorInviter = {
  businessName: Scalars['String']
  senderName: Scalars['String']
}

export type VendorLeadTime = {
  days: Maybe<Scalars['Int']>
  event: Scalars['String']
  id: Scalars['ID']
  localBusinessId: Maybe<Scalars['ID']>
  purchaseOrderId: Maybe<Scalars['ID']>
}

export enum VendorLeadTimeEventEnum {
  BILLING = 'BILLING',
  PRODUCTION = 'PRODUCTION',
  RECEIVING = 'RECEIVING',
  SHIPPING = 'SHIPPING',
}

export type VendorLeadTimeInputObject = {
  days: Scalars['Int']
  event: VendorLeadTimeEventEnum
  id?: InputMaybe<Scalars['ID']>
  localBusinessId?: InputMaybe<Scalars['ID']>
  purchaseOrderId?: InputMaybe<Scalars['ID']>
}

export type VendorPaymentTerm = {
  id: Scalars['ID']
  localBusinessId: Scalars['ID']
  paymentDate: Maybe<Scalars['ISO8601DateTime']>
  percentage: Scalars['Int']
  vendorTermOption: Maybe<VendorTermOption>
}

export type VendorPaymentTermInputObject = {
  id?: InputMaybe<Scalars['ID']>
  localBusinessId?: InputMaybe<Scalars['ID']>
  paymentDate?: InputMaybe<Scalars['ISO8601DateTime']>
  percentage?: InputMaybe<Scalars['Int']>
  vendorTermOptionId?: InputMaybe<Scalars['ID']>
}

export type VendorTermInputObject = {
  default?: InputMaybe<Scalars['Boolean']>
  leadTimes?: InputMaybe<Array<VendorLeadTimeInputObject>>
  paymentTerms?: InputMaybe<Array<VendorPaymentTermInputObject>>
  vendorTermId?: InputMaybe<Scalars['ID']>
}

export type VendorTermOption = {
  days: Maybe<Scalars['Int']>
  id: Scalars['ID']
  localBusinessId: Maybe<Scalars['ID']>
  termType: Scalars['String']
}

export type VendorTermOptionInputObject = {
  days?: InputMaybe<Scalars['Int']>
  localBusinessId: Scalars['ID']
  termType: Scalars['String']
}

export type VendorTerms = {
  deletedAt: Maybe<Scalars['ISO8601DateTime']>
  deletedBy: Maybe<Profile>
  id: Scalars['ID']
  isDefault: Scalars['Boolean']
  localBusinessId: Maybe<Scalars['ID']>
  purchaseOrderId: Maybe<Scalars['ID']>
  vendorLeadTimes: Maybe<Array<VendorLeadTime>>
  vendorPaymentTerms: Maybe<Array<VendorPaymentTerm>>
}

export enum VerificationSourceEnum {
  FINICITY = 'FINICITY',
  MODERN_TREASURY = 'MODERN_TREASURY',
  OTHER = 'OTHER',
  PLAID = 'PLAID',
}

export type WarningMessage = {
  actions: Array<WarningMessageAction>
  message: Scalars['String']
  name: WarningMessageNameEnum
}

export type WarningMessageAction = {
  label: Scalars['String']
  type: WarningMessageActionTypeEnum
  variant: WarningMessageActionVariantEnum
}

export enum WarningMessageActionTypeEnum {
  ADD_OR_REQUEST_W8_OR_W9 = 'ADD_OR_REQUEST_W8_OR_W9',
  OPEN_PAYMENT_HOLD_SETTINGS = 'OPEN_PAYMENT_HOLD_SETTINGS',
  RELEASE_PAYMENT_ON_W8_OR_W9_HOLD = 'RELEASE_PAYMENT_ON_W8_OR_W9_HOLD',
}

export enum WarningMessageActionVariantEnum {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export enum WarningMessageNameEnum {
  PAYMENT_ACCOUNT_PROCESSING_AT_RISK = 'PAYMENT_ACCOUNT_PROCESSING_AT_RISK',
  PAYMENT_ELIGIBLE_FOR_W8_OR_W9_HOLD = 'PAYMENT_ELIGIBLE_FOR_W8_OR_W9_HOLD',
  PAYMENT_ON_W8_OR_W9_HOLD = 'PAYMENT_ON_W8_OR_W9_HOLD',
  PAYMENT_PENDING_APPROVAL = 'PAYMENT_PENDING_APPROVAL',
}

export type WorkOrder = {
  actions: WorkOrderActions
  additionalExpenses: Array<AdditionalExpense>
  attachment: Maybe<Attachment>
  createdAt: Scalars['ISO8601DateTime']
  fullyReceivedAt: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  issueDate: Maybe<Scalars['ISO8601Date']>
  lineItems: Array<WorkOrderLineItem>
  linkedDocuments: Array<LinkedDocument>
  location: Maybe<Location>
  locationEmail: Maybe<Scalars['String']>
  locationEmailDate: Maybe<Scalars['ISO8601Date']>
  locationEmailMessage: Maybe<Scalars['String']>
  locationEmailScheduledDate: Maybe<Scalars['ISO8601Date']>
  manufacturingVendor: Maybe<LocalBusiness>
  manufacturingVendorEmailDate: Maybe<Scalars['ISO8601Date']>
  memo: Maybe<Scalars['String']>
  number: Maybe<Scalars['String']>
  payments: Array<Payment>
  paymentsOfLinkedInvoices: Array<Payment>
  receiveByDate: Maybe<Scalars['ISO8601Date']>
  requestedCompletionDate: Maybe<Scalars['ISO8601Date']>
  shippingVendor: Maybe<LocalBusiness>
  status: WorkOrderStatusEnum
}

export type WorkOrderActions = {
  cancel: Action
  close: Action
  edit: Action
  open: Action
  revertToDraft: Action
}

export enum WorkOrderArchivedStatusEnum {
  ACTIVE_AND_ARCHIVED = 'ACTIVE_AND_ARCHIVED',
  ACTIVE_ONLY = 'ACTIVE_ONLY',
  ARCHIVED_ONLY = 'ARCHIVED_ONLY',
}

export type WorkOrderEmailInputObject = {
  locationEmail: Scalars['String']
  locationEmailScheduledDate: Scalars['ISO8601Date']
  locationMessage?: InputMaybe<Scalars['String']>
  manufacturingVendorEmail: Scalars['String']
  manufacturingVendorMessage: Scalars['String']
}

export type WorkOrderInputObject = {
  issueDate?: InputMaybe<Scalars['ISO8601Date']>
  locationEmailMessage?: InputMaybe<Scalars['String']>
  locationEmailScheduledDate?: InputMaybe<Scalars['ISO8601Date']>
  locationId?: InputMaybe<Scalars['ID']>
  manufacturingVendorId?: InputMaybe<Scalars['ID']>
  memo?: InputMaybe<Scalars['String']>
  number?: InputMaybe<Scalars['String']>
  receiveByDate?: InputMaybe<Scalars['ISO8601Date']>
  requestedCompletionDate?: InputMaybe<Scalars['ISO8601Date']>
  shippingVendorId?: InputMaybe<Scalars['ID']>
  status?: InputMaybe<WorkOrderStatusEnum>
}

export type WorkOrderLineItem = {
  /** The timestamp of the BOM snapshot, formatted for display in the BOM table. */
  bomSnapshotTimestamp: Maybe<Scalars['String']>
  bomTableRows: Array<BomTableRowType>
  /** @deprecated Use 'catalogItemVariation' instead */
  catalogItem: Maybe<CatalogItemVariation>
  catalogItemVariation: Maybe<CatalogItemVariation>
  createdAt: Scalars['ISO8601DateTime']
  fractionalLandedCost: Maybe<InfinitePrecisionMoney>
  id: Scalars['ID']
  name: Maybe<Scalars['String']>
  quantity: Maybe<Scalars['Float']>
  sku: Maybe<Scalars['String']>
  unitOfMeasure: Maybe<Scalars['String']>
}

export type WorkOrderLineItemInputObject = {
  catalogItemId?: InputMaybe<Scalars['ID']>
  quantity?: InputMaybe<Scalars['Float']>
  unitOfMeasure?: InputMaybe<Scalars['String']>
}

export enum WorkOrderPermissionsEnum {
  CREATE_PAYMENT_ON_WORK_ORDER = 'CREATE_PAYMENT_ON_WORK_ORDER',
  CREATE_WORK_ORDER = 'CREATE_WORK_ORDER',
  READ_WORK_ORDER = 'READ_WORK_ORDER',
  UPDATE_WORK_ORDER = 'UPDATE_WORK_ORDER',
}

export enum WorkOrderStatusEnum {
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
}

export type WorkOrdersFilteringInputObject = {
  archivedStatus?: InputMaybe<WorkOrderArchivedStatusEnum>
  ids?: InputMaybe<Array<Scalars['ID']>>
  search?: InputMaybe<Scalars['String']>
  statuses?: InputMaybe<Array<WorkOrderStatusEnum>>
}

export type XeroAccount = {
  accountSubType: Scalars['String']
  accountType: Scalars['String']
  active: Scalars['Boolean']
  children: Array<XeroAccount>
  classification: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  number: Maybe<Scalars['String']>
  subAccount: Scalars['Boolean']
}

export type XeroAccountMapping = {
  accountId: Scalars['ID']
  accountingAccountId: Maybe<Scalars['ID']>
  externalAccountId: Maybe<Scalars['ID']>
  xeroAccountId: Maybe<Scalars['ID']>
}

export type XeroAccountMappingInputObject = {
  accountId: Scalars['ID']
  externalAccountId?: InputMaybe<Scalars['ID']>
  xeroAccountId?: InputMaybe<Scalars['ID']>
}

export type XeroState = {
  accountMappings: Array<XeroAccountMapping>
  accounts: Array<XeroAccount>
  bankAccounts: Array<XeroAccount>
  bookCloseDate: Maybe<Scalars['ISO8601Date']>
  connected: Scalars['Boolean']
  creditCardAccounts: Array<XeroAccount>
  eptSyncEnabled: Scalars['Boolean']
  eptSyncStartDate: Maybe<Scalars['ISO8601Date']>
  expenseAccounts: Array<XeroAccount>
  feeExpensesAccountId: Maybe<Scalars['ID']>
  filteredAccounts: Array<XeroAccount>
  liabilityAccounts: Array<XeroAccount>
  readOnly: Scalars['Boolean']
  settleLiabilityAccountId: Maybe<Scalars['ID']>
  syncAutomatically: Scalars['Boolean']
  syncErrorMessage: Maybe<Scalars['String']>
  syncScheduledAt: Maybe<Scalars['ISO8601DateTime']>
  syncStartDate: Maybe<Scalars['ISO8601Date']>
  syncStartedAt: Maybe<Scalars['ISO8601DateTime']>
  syncState: SyncStateEnum
  syncUncategorizedExpenses: Scalars['Boolean']
  syncedAt: Maybe<Scalars['ISO8601DateTime']>
  trackCategoryOne: Scalars['Boolean']
  trackCategoryTwo: Scalars['Boolean']
  trackingCategoryOneName: Maybe<Scalars['String']>
  trackingCategoryOneOptions: Maybe<Array<AccountingDictionary>>
  trackingCategoryTwoName: Maybe<Scalars['String']>
  trackingCategoryTwoOptions: Maybe<Array<AccountingDictionary>>
  unallocatedExpensesAccountId: Maybe<Scalars['ID']>
}

export type XeroUnpaidBill = {
  amount: Maybe<Money>
  balance: Maybe<Money>
  dueDate: Maybe<Scalars['ISO8601Date']>
  id: Scalars['ID']
  issueDate: Maybe<Scalars['ISO8601Date']>
  number: Maybe<Scalars['String']>
  url: Maybe<Scalars['String']>
  vendorName: Maybe<Scalars['String']>
}
